import { createAsyncThunk } from "@reduxjs/toolkit";
import { getResourceExplorerPossibleFiltersThunk } from "./getResourceExplorerPossibleFiltersThunk";
import { RootState } from "../../../store";
import { enabledProvidersByBillingStatusSelector } from "../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

export const getResourceExplorerEnabledProvidersPossibleFiltersThunk =
  createAsyncThunk(
    "resourceExplorer/getResourceExplorerEnabledProvidersPossibleFiltersThunk",
    async (_, { dispatch, getState }) => {
      const state = getState() as RootState;

      const enabledProviders = enabledProvidersByBillingStatusSelector(state);

      enabledProviders.forEach((provider) => {
        dispatch(
          getResourceExplorerPossibleFiltersThunk({
            provider,
          }),
        );
      });
    },
  );
