import { FC } from "react";

import { useFlag } from "@unleash/proxy-client-react";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../../../../store/navigation/utils/types";
import { DashboardCreateFolderOrItemDialog } from "../create-folder-or-item-dialog/DashboardCreateFolderOrItemDialog";
import { DashboardCreateFolderOrItemDialogV2 } from "../create-folder-or-item-dialog-v2/DashboardCreateFolderOrItemDialogV2";

interface DashboardCreateFolderOrItemDialogProps {
  onClose(): void;
  type: NavigationHierarchyItemType;
  folderId?: string;
  visibility?: NavigationItemsVisibilityType;
}

export const DashboardCreateFolderOrItemDialogWrapper: FC<
  DashboardCreateFolderOrItemDialogProps
> = ({ onClose, type, folderId, visibility }) => {
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  if (enableNavigationHierarchyVisibility) {
    return (
      <DashboardCreateFolderOrItemDialogV2
        onClose={onClose}
        type={type}
        folderId={folderId}
        visibility={visibility}
      />
    );
  }

  return (
    <DashboardCreateFolderOrItemDialog
      onClose={onClose}
      type={type}
      folderId={folderId}
    />
  );
};
