import { FC, Fragment } from "react";
import { CardContent, Stack, Typography } from "@mui/material";
import moment from "moment";
import { money } from "../../../../../../utils/numeral/money";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import {
  Billing,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";

interface AccountCardFooterProps {
  billing?: Billing;
  provider?: ProviderType;
}

export const AccountCardFooter: FC<AccountCardFooterProps> = ({
  billing,
  provider,
}) => {
  return (
    <CardContent
      sx={{
        "&.MuiCardContent-root:last-child": {
          paddingBottom: 2,
        },
      }}
    >
      <Stack direction="row" alignItems="center">
        <Typography
          variant="body2"
          color="text.primary"
          fontWeight="bold"
          minHeight={16}
        >
          {provider && getProviderName(provider, { title: true })} charge as of
          last month{" "}
          {billing?.last_month_total_cost_end_date && provider && (
            <Fragment>
              {billing?.last_month_total_cost_end_date &&
                `(${moment(billing?.last_month_total_cost_end_date).format(
                  "MMM DD",
                )})`}
            </Fragment>
          )}
        </Typography>
      </Stack>
      <Typography color="text.secondary" justifySelf="flex-end">
        {billing?.last_month_total_cost
          ? money(billing.last_month_total_cost)
          : "N/A"}
      </Typography>
    </CardContent>
  );
};
