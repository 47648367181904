import moment, { Moment, unitOfTime } from "moment";
import momentTz from "moment-timezone";
import { getTimeZoneObject } from "./timeZones";
import { DateDataPoint } from "../../../services/cloudchipr.api";

export type MomentDateTimeUnitsType = unitOfTime.DurationConstructor;

export const weekDaysIndex = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

type DateTimeFormats =
  | "default"
  | "dateTime"
  | "dateWithComma"
  | "date"
  | "dayMonthName"
  | "time"
  | "week"
  | "yearMonthDay"
  | "slashSeparated"
  | "dateTimeWithComma"
  | "weekDayDate"
  | "dashSeparated"
  | "monthNameYear";

const DateTimeFormatValues: Map<DateTimeFormats, string> = new Map([
  ["week", "wo"],
  ["time", "hh:mm A"],
  ["date", "DD MMM YYYY"],
  ["slashSeparated", "DD/MM/YYYY"],
  ["dashSeparated", "DD-MM-YYYY"],
  ["dateWithComma", "DD MMM, YYYY"],
  ["weekDayDate", "ddd, DD MMM YYYY"],
  ["dateTimeWithComma", "DD MMM, YYYY, hh:mm A"],
  ["dayMonthName", "DD MMM"],
  ["yearMonthDay", "YYYY-MM-DD"],
  ["monthNameYear", "MMM YYYY"],
  ["dateTime", "DD MMM YYYY hh:mm A"],
  ["default", "YYYY-MM-DD[T]HH:mm:ss"],
]);

export const formatDate = (
  date: string | Moment,
  options?: {
    type?: DateTimeFormats;
    format?: string;
    utc?: boolean;
  },
) => {
  date = isDateMomentObject(date) ? date : moment(date);

  if (!date.isValid()) {
    return "";
  }

  let format = options?.format;

  if (!format) {
    format = DateTimeFormatValues.get(options?.type ?? "default");
  }

  if (options?.utc) {
    date = date.utc();
  }

  return date.format(format);
};

export function formatDateByFrequencyType(
  chartFrequency: DateDataPoint,
  value: string,
): string {
  switch (chartFrequency) {
    case "daily": {
      return formatDate(value, { type: "date" });
    }
    case "weekly": {
      return (
        moment(value).startOf("week").format("DD MMM") +
        " - " +
        moment(value).endOf("week").format("DD MMM")
      );
    }
    case "monthly": {
      return formatDate(value, { type: "monthNameYear" });
    }
    default: {
      return formatDate(value, { type: "dateTime" });
    }
  }
}

export function dateToUtcByTimeZone(date: string | null, timeZone?: string) {
  if (!date || !timeZone) return date;

  return formatDate(moment.tz(date, "YYYY-MM-DD[T]HH:mm:ss", timeZone), {
    utc: true,
  });
}

export function dateFromUtcByTimeZone(date: string | null, timeZone?: string) {
  if (!timeZone) {
    timeZone = getTimeZoneObject().tzCode;
  }

  if (!date) return date;

  return formatDate(moment.utc(formatDate(date)).tz(timeZone));
}

export function resetTimeInDate(date: string): string {
  return moment(date).set({ hour: 0, minute: 0, second: 0 }).format();
}

export const getDateNowByTimeZone = (timeZone: string): string => {
  timeZone = timeZone || getTimeZoneObject().tzCode;

  return formatDate(momentTz().tz(timeZone));
};

export const isDateMomentObject = (date: unknown): date is Moment => {
  return date instanceof moment;
};
