import { FC, useMemo } from "react";
import { TextField, Tooltip } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Dates } from "../../../../services/cloudchipr.api";
import { UseMenuHookOpenMenu } from "../../../../utils/hooks/useMenu.hook";
import { useHover } from "../../../../utils/hooks/useHover.hook";
import { PreDefinedRanges } from "../utils/constants/types";
import { generateFromToByPeriod } from "../utils/helpers/period/generateFromToByPeriod";
import { formatDate } from "../../../../utils/helpers/date-time/datetime-format";
import { generateLabelByPeriod } from "../utils/helpers/period/generateLabelByPeriod";

export type InputSize = "xsmall" | "small";

interface DateRangePickerInputProps {
  open: boolean;
  selectedDates: Dates;
  onClick: UseMenuHookOpenMenu;
  fullWidth?: boolean;
  ranges: PreDefinedRanges;
  size?: InputSize;
}

export const DateRangePickerInput: FC<DateRangePickerInputProps> = ({
  open,
  onClick,
  selectedDates,
  fullWidth,
  ranges,
  size = "small",
}) => {
  const { ref, hovered } = useHover();

  const { tooltip, label } = useMemo(() => {
    const selectedLabel = selectedDates.label;
    const selectedRange = selectedLabel ? ranges[selectedLabel] : null;
    const dateLabelFromPeriod = generateLabelByPeriod(selectedDates);

    let fromDate = "";
    let toDate = "";

    if (selectedRange?.dateFrom && selectedRange?.dateTo) {
      fromDate = selectedRange?.dateFrom;
      toDate = selectedRange?.dateTo;
    } else if (
      selectedDates.unit &&
      selectedDates.value &&
      selectedDates.type
    ) {
      const { to, from } = generateFromToByPeriod(
        selectedDates.value,
        selectedDates.type,
        selectedDates.unit,
      );

      fromDate = from;
      toDate = to;
    } else {
      fromDate = selectedDates?.from ?? "";
      toDate = selectedDates?.to ?? "";
    }

    const fromToDates = `${formatDate(fromDate, {
      type: "date",
    })} - ${formatDate(toDate, { type: "date" })}`;

    const withTooltip =
      !selectedDates?.from && !selectedDates?.to && fromDate && toDate;

    return {
      label: selectedRange?.label || dateLabelFromPeriod || fromToDates,
      tooltip: withTooltip ? fromToDates : "",
    };
  }, [selectedDates, ranges]);

  const inputSize = size === "xsmall" ? 225 : 270;

  return (
    <Tooltip arrow title={tooltip} placement="top" open={!!tooltip && hovered}>
      <TextField
        ref={ref}
        size={size}
        value={label ?? ""}
        onClick={onClick}
        fullWidth={fullWidth}
        sx={{ bgcolor: "white", width: !fullWidth ? inputSize : undefined }}
        InputProps={{
          readOnly: true,
          startAdornment,
          sx: { cursor: "pointer" },
          inputProps: { sx: { cursor: "pointer", pl: 1 } },
          endAdornment: open ? openIcon : closedIcon,
        }}
      />
    </Tooltip>
  );
};

const openIcon = <ArrowDropUpIcon color="action" fontSize="small" />;
const closedIcon = <ArrowDropDownIcon color="action" fontSize="small" />;
const startAdornment = <CalendarTodayIcon color="action" fontSize="small" />;
