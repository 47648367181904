import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getCostAnomalyAlertsThunk } from "./getCostAnomalyAlertsThunk";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { alertsDataSelector } from "../../selectors/common/data/alertsDataSelector";
import { resetAlertsData, toggleAlertsOpenDrawer } from "../../alertsSlice";
import {
  createCostAnomalyAlertFixedCacheKey,
  editCostAnomalyAlertFixedCacheKey,
} from "../../utils/constants/fixedCacheKeys";
import { getViewLinkByAlertData } from "../../utils/helpers/getViewLinkByAlertData";
import { constAnomalyAlertPayloadDataSelector } from "../../selectors/cost-anomaly-alerts/constAnomalyAlertPayloadDataSelector";

export const saveCostAnomalyAlertsThunk = createAsyncThunk(
  "alerts/saveCostAnomalyAlertsThunk",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      postUsersMeOrganisationsCurrentAlertsCostAnomaly,
      patchUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId,
    } = cloudChiprApi.endpoints;

    const data = alertsDataSelector(state);
    const body = constAnomalyAlertPayloadDataSelector(state);

    const viewLink = getViewLinkByAlertData(
      data.grouping,
      data.timeIntervalUnit,
      data.timeInterval,
    );

    try {
      let response;

      if (data.id) {
        response = await dispatch(
          patchUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId.initiate(
            {
              alertId: data.id,
              body,
            },
            { fixedCacheKey: editCostAnomalyAlertFixedCacheKey },
          ),
        );
      } else {
        response = await dispatch(
          postUsersMeOrganisationsCurrentAlertsCostAnomaly.initiate(
            {
              body: {
                ...body,
                view_link: viewLink,
              },
            },
            { fixedCacheKey: createCostAnomalyAlertFixedCacheKey },
          ),
        ).unwrap();
      }

      dispatch(toggleAlertsOpenDrawer());
      dispatch(resetAlertsData());

      await dispatch(getCostAnomalyAlertsThunk()).unwrap();

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
