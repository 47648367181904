import { regionFilterChangedSelector } from "./is-filter-changed-by-type/regionFilterChangedSelector";
import { serviceFilterChangedSelector } from "./is-filter-changed-by-type/serviceFilterChangedSelector";
import { accountFilterChangedSelector } from "./is-filter-changed-by-type/accountFilterChangedSelector";
import { chargeTypeFilterChangedSelector } from "./is-filter-changed-by-type/chargeTypeFilterChangedSelector";
import { tagFilterChangedSelector } from "./is-filter-changed-by-type/tagFilterChangedSelector";
import { creditTypeFilterChangedSelector } from "./is-filter-changed-by-type/creditTypeFilterChangedSelector";
import { productFamilyChangedSelector } from "./is-filter-changed-by-type/productFamilyChangedSelector";
import { instanceTypeFilterChangedSelector } from "./is-filter-changed-by-type/instanceTypeFilterChangedSelector";
import { instanceFamilyFilterChangedSelector } from "./is-filter-changed-by-type/instanceFamilyFilterChangedSelector";
import { dimensionFiltersChangedSelector } from "./dimensionFiltersChangedSelector";
import { resourceExplorerAppliedFiltersSelector } from "../resourceExplorerAppliedFiltersSelector";
import { RootState } from "../../../../store";
import { resourceExplorerDataChangedSelector } from "../../current-resource-explorer/checkers/resourceExplorerDataChangedSelector";
import { resourceExplorerViewDataByIdSelector } from "../../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { getResourceExplorerRestructuredFilters } from "../../../utils/helpers/getResourceExplorerRestructuredFilters";
import { resourceExplorerProvidersSelector } from "../../current-resource-explorer/resourceExplorerProvidersSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { resourceExplorerIdSelector } from "../../current-resource-explorer/resourceExplorerIdSelector";
import { resourceExplorerDatesChangedSelector } from "../../current-resource-explorer/checkers/resourceExplorerDatesChangedSelector";
import { enabledProvidersByBillingStatusSelector } from "../../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

export const anyFilterChangedSelector = (state: RootState): boolean => {
  const viewId = resourceExplorerIdSelector(state);

  const initialResourceExplorerData = resourceExplorerViewDataByIdSelector(
    state,
    viewId,
  );

  const enableProviders = enabledProvidersByBillingStatusSelector(state);

  const resourceExplorer = resourceExplorerViewDataByIdSelector(state, viewId);
  const isDefaultResourceExplorer = resourceExplorer?.is_default;
  const initialProviders =
    initialResourceExplorerData?.cloud_providers?.filter((item) =>
      enableProviders.includes(item),
    ) ?? [];
  const initialFilters = initialResourceExplorerData?.filters;

  const mutatedInitialFilter = initialFilters
    ? getResourceExplorerRestructuredFilters(
        initialFilters,
        initialProviders,
        enableProviders,
      )
    : {};
  const resourceExplorerProviders = resourceExplorerProvidersSelector(state);
  const filter = resourceExplorerAppliedFiltersSelector(state);

  const regionChanged = regionFilterChangedSelector(state);
  const serviceChanged = serviceFilterChangedSelector(state);
  const accountChanged = accountFilterChangedSelector(state);
  const chargeTypeChanged = chargeTypeFilterChangedSelector(state);
  const costAllocationTagChanged = tagFilterChangedSelector(state);
  const creditTypeChanged = creditTypeFilterChangedSelector(state);
  const productFamilyChanged = productFamilyChangedSelector(state);
  const instanceTypeFilterChanged = instanceTypeFilterChangedSelector(state);
  const datesChangedSelector = resourceExplorerDatesChangedSelector(
    state,
    viewId,
  );
  const instanceFamilyFilterChanged =
    instanceFamilyFilterChangedSelector(state);

  const resourceExplorerDataChanged = resourceExplorerDataChangedSelector(
    state,
    viewId,
  );
  const dimensionFiltersChanged = dimensionFiltersChangedSelector(state);

  const filterProviderCount = (Object.keys(filter) as ProviderType[]).reduce(
    (result, item) => {
      if (Object.keys(filter?.[item] ?? {}).length) {
        ++result;
      }
      return result;
    },
    0,
  );

  const filterCountsChanged =
    resourceExplorerProviders.length > 1
      ? Object.keys(mutatedInitialFilter)?.length !== filterProviderCount
      : false;

  let providerChanged;

  if (isDefaultResourceExplorer) {
    // cause we fill enableProviders to default resource explorer providers list
    providerChanged =
      enableProviders.length !== resourceExplorerProviders.length;
  } else if (enableProviders.length === 1) {
    providerChanged = false;
  } else {
    providerChanged = !isDeepEqual(
      [...initialProviders]?.sort(stringSorting),
      [...resourceExplorerProviders]?.sort(stringSorting),
    );
  }

  return (
    regionChanged ||
    productFamilyChanged ||
    instanceTypeFilterChanged ||
    instanceFamilyFilterChanged ||
    serviceChanged ||
    accountChanged ||
    chargeTypeChanged ||
    creditTypeChanged ||
    resourceExplorerDataChanged ||
    costAllocationTagChanged ||
    providerChanged ||
    filterCountsChanged ||
    datesChangedSelector ||
    dimensionFiltersChanged
  );
};

const stringSorting = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (b < a) {
    return 1;
  }
  return 0;
};
