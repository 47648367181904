import { FC } from "react";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { NavItem } from "../../../nav-item/NavItem";
import { ResourceExplorer } from "../../../../../../services/cloudchipr.api";
import { ResourceExplorerV2ViewActionButton } from "../view-action-menu/ResourceExplorerV2ViewActionButton";

interface ResourceExplorerV2NavigationViewNavItemProps {
  view: ResourceExplorer;
}
export const ResourceExplorerV2NavigationViewNavItem: FC<
  ResourceExplorerV2NavigationViewNavItemProps
> = ({ view }) => {
  return (
    <NavItem
      nested
      primary={view.name}
      to={`/resource-explorer/${view.id}`}
      icon={InsertChartOutlinedOutlinedIcon}
      secondaryAction={
        !view.is_default ? (
          <ResourceExplorerV2ViewActionButton
            id={view.id}
            name={view.name}
            visibility="visible_to_everyone"
          />
        ) : null
      }
      secondaryActionHover
    />
  );
};
