import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDefaultThresholdByType, setAlertsData } from "../../alertsSlice";
import { RootState } from "../../../store";
import { awsOrganizationSelector } from "../../../commitments/selectors/organisations/awsOrganizationSelector";
import { alertsDrawerEditModeSelector } from "../../selectors/alertsDrawerEditModeSelector";

export const initiateUtilizationAlertThunk = createAsyncThunk(
  "alerts/initiateUtilizationAlert",
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const awsOrganizations = awsOrganizationSelector(state);
    const firstOrgId = awsOrganizations?.at(0)?.organization_id;
    const editMode = alertsDrawerEditModeSelector(state);

    dispatch(addDefaultThresholdByType("day"));

    if (!editMode && firstOrgId) {
      dispatch(setAlertsData({ providerOrganisationId: firstOrgId }));
    }
  },
);
