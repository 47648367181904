import { useCallback } from "react";
import { useAllResourcesVisibility } from "./useAllResourcesVisibility.hook";
import { useToggledResourceTypes } from "../../../../account/utils/hooks/useToggledResources";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { resourcesNotIncludedInScheduleSelect } from "../constants/resourcesNotIncludedInScheduleSelect";
import { offHoursAvailableResourceTypes } from "../constants/common";
import { AutomationType } from "../../../../../../store/automations/utils/types/common";

export function useResourceVisibilityHook({
  accountIds,
  provider,
  visibleFor,
}: {
  accountIds?: string | null;
  provider?: ProviderType | null;
  visibleFor: AutomationType | null;
}) {
  const toggledResourceTypes = useToggledResourceTypes();

  const resourcesVisibility = useAllResourcesVisibility({
    accountIds,
    provider,
  });

  return useCallback(
    (resourceType: ResourceType) => {
      const isVisible =
        Object.values(resourcesVisibility).every((visibility) => {
          return visibility?.[resourceType]?.show;
        }) &&
        !toggledResourceTypes.get(resourceType) &&
        !resourcesNotIncludedInScheduleSelect.has(resourceType);

      return visibleFor === "offHours"
        ? isVisible && offHoursAvailableResourceTypes.includes(resourceType)
        : isVisible;
    },
    [visibleFor, toggledResourceTypes, resourcesVisibility],
  );
}
