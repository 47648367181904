import { sortStringArrayByAlphanumeric } from "../../../components/common/account-select/utils/helpers/sorting/sortStringArrayByAlphanumeric";
import { CleanActionTypes } from "../../../utils/clean-options";

export const getProtectResourceCacheKey = (
  accountId: string,
  resourceIds: string[],
) => {
  const ids = sortStringArrayByAlphanumeric(resourceIds).reduce(
    (result, item) => {
      result = result + item;
      return result;
    },
    "",
  );

  return accountId + ids;
};

export const isResourceNotCleanable = (
  {
    isProtected,
    markedForDeletion,
    notAllowedActions,
  }: {
    isProtected: boolean;
    markedForDeletion: boolean;
    notAllowedActions?: string[];
  },
  cleanActionType: string,
): boolean => {
  return (
    isProtected ||
    markedForDeletion ||
    !!notAllowedActions?.includes(cleanActionType)
  );
};

export const getResourceCleanActionType = (action: CleanActionTypes) => {
  if (action === "start") {
    return "start";
  }

  if (action === "stop") {
    return "stop";
  }

  return "terminate";
};
