import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentsGaugeChartCard } from "../../../../common/components/chart/gauge-chart/CommitmentsGaugeChartCard";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsChartTypeSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";
import { CommitmentsChartTypeSelect } from "../../../../common/components/chart/CommitmentsChartTypeSelect";
import { reservationsGranularDataTotalCommitmentSelector } from "../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataTotalCommitmentSelector";
import { reservationsGranularDataTotalCoverageSelector } from "../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataTotalCoverageSelector";
import { reservationsGranularDataTotalUtilizationSelector } from "../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataTotalUtilizationSelector";
import { reservationsGranularDataUncoveredUsageSelector } from "../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataUncoveredUsageSelector";
import { reservationsForGranularLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForGranularLoadingSelector";

export const ReservationsGaugeChart: FC = () => {
  const totalCommitment = useAppSelector(
    reservationsGranularDataTotalCommitmentSelector,
  );
  const totalCoverage = useAppSelector(
    reservationsGranularDataTotalCoverageSelector,
  );
  const totalUtilization = useAppSelector(
    reservationsGranularDataTotalUtilizationSelector,
  );
  const uncoveredUsage = useAppSelector(
    reservationsGranularDataUncoveredUsageSelector,
  );
  const loading = useAppSelector(reservationsForGranularLoadingSelector);

  const contentView = useAppSelector(commitmentsChartTypeSelector);

  if (contentView !== "gauge") {
    return null;
  }

  return (
    <Stack spacing={1}>
      <CommitmentsChartTypeSelect />

      <Stack direction="row" spacing={2}>
        <CommitmentsGaugeChartCard
          title="Utilization"
          loading={loading}
          primary={{
            label: "Utilization",
            value: totalUtilization?.amount,
            percent: totalUtilization?.percent,
          }}
          secondary={{
            label: "Commitment",
            value: totalCommitment,
          }}
        />

        <CommitmentsGaugeChartCard
          title="Coverage"
          loading={loading}
          primary={{
            label: "Covered",
            value: totalCoverage?.amount,
            percent: totalCoverage?.percent,
          }}
          secondary={{
            label: "Not Covered (On-Demand)",
            value: uncoveredUsage,
          }}
        />
      </Stack>
    </Stack>
  );
};
