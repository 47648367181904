import { useCallback } from "react";
import { VisibilityState } from "@tanstack/react-table";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import {
  generateStorageKey,
  useLocalStorageHook,
} from "../../../../../utils/hooks/useLocalStorage.hook";
import { ResourceType } from "../../../../../services/cloudchipr.api";

export function useColumnsVisibility(resourceType: ResourceType) {
  const { getItem, setItem } = useLocalStorageHook();

  const accountId = useAppSelector(currentAccountIdSelector) || "";
  const key = generateStorageKey({
    accountId,
    resourceType,
    identifier: "tableConfig",
  });

  const config = getItem(key);
  const initialVisibility = config?.visibilities;

  const setVisibility = useCallback(
    (visibilities: VisibilityState) => {
      setItem(key, { ...config, visibilities });
    },
    [key, setItem, config],
  );

  const defaultVisibility = {
    smart_tag: false,
    created_at: !hideLunchTimeColumns.has(resourceType),
    all_time_spent: false,
    ...initialVisibility,
  };

  return {
    visibility: defaultVisibility as Record<string, boolean>,
    setVisibility,
  };
}

const hideLunchTimeColumns = new Set(["elb", "lb", "az_lb", "disk", "az_disk"]);
