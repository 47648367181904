import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { ReservationCoverageDrawerContentHeaderInfoCards } from "./ReservationCoverageDrawerContentHeaderInfoCards";
import { ReservationCoverageDrawerChartHeader } from "./ReservationCoverageDrawerChartHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { CommitmentsDrawerHeader } from "../../../../common/components/drawer/header/CommitmentsDrawerHeader";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { reservationSelectedCoverageDataAccountNameAndIdSelector } from "../../../../../../../store/commitments/selectors/reservations/details-drawer/resource-type/reservationSelectedCoverageDataAccountNameAndIdSelector";
import { getReservationDetailsThunk } from "../../../../../../../store/commitments/thunks/getReservationDetailsThunk";
import { CommitmentsDrawerContent } from "../../../../common/components/drawer/CommitmentsDrawerContent";
import { ReservedInstanceDrawerChart } from "../common/ReservedInstanceDrawerChart";
import { ReservationDrawerDataGrid } from "../common/data-grid/ReservationDrawerDataGrid";

interface ReservationCoverageDrawerContentProps {
  onClose(): void;
}

export const ReservationCoverageDrawerContent: FC<
  ReservationCoverageDrawerContentProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(
    reservationSelectedCoverageDataAccountNameAndIdSelector,
  );
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);

  useDidMount(() => {
    dispatch(getReservationDetailsThunk());
  });

  return (
    <Fragment>
      <CommitmentsDrawerHeader
        title={targetType === "resource_type" ? "Resource Type" : accountId}
        onClose={onClose}
      >
        {targetType === "resource_type" && (
          <ReservationCoverageDrawerContentHeaderInfoCards />
        )}
      </CommitmentsDrawerHeader>

      <Box width="80dvw" bgcolor="grey.50" height="100%">
        <CommitmentsDrawerContent title="Coverage" icon="utilization">
          <ReservedInstanceDrawerChart
            ChartHeader={ReservationCoverageDrawerChartHeader}
          />

          <ReservationDrawerDataGrid viewTab="coverage" />
        </CommitmentsDrawerContent>
      </Box>
    </Fragment>
  );
};
