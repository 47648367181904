import { SavingsPlanType } from "../../../../../../services/cloudchipr.api";
import { recommendationsTableColumns } from "../../components/content/recommendations/data-grid/commitmentsRecommendationsDataGridColumns";

export const filterRecommendationsColumns = (
  orgExists: boolean,
  savingsPlanTypes?: SavingsPlanType[],
) => {
  let columns = recommendationsTableColumns;

  if (orgExists) {
    columns = columns.filter((column) => column.accessorKey !== "account");
  }

  if (savingsPlanTypes?.includes("ec2_instance")) {
    return columns;
  }

  return columns.filter(
    (column) =>
      !["instance_family", "region"].includes(column.accessorKey ?? ""),
  );
};
