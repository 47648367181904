import { ResourceExplorerData } from "../../../../../../../services/cloudchipr.api";

export const resourceExplorerDefaultData: ResourceExplorerData = {
  date_label: "past_fourteen_days",
  grouping: "service",
  data_point: "daily",
  from_date: null,
  to_date: null,
  forecast_option: "month",
  dates: { label: "past_fourteen_days" },
  show_budget_line: true,
  trend_type: "month_over_month",
};
