import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { FC } from "react";
import { AlertsCostsAnomalySetThreshold } from "./AlertsCostsAnomalySetThreshold";

export const AlertsCostsAnomalyThresholdsSection: FC = () => {
  return (
    <Stack spacing={1} width="max-content">
      <Typography variant="body2" fontWeight="medium">
        Set threshold
      </Typography>
      <AlertsCostsAnomalySetThreshold type="percent" />
      <AlertsCostsAnomalySetThreshold type="cost" />
    </Stack>
  );
};
