import { FC, Fragment, useCallback } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { FormikErrors, FormikHelpers } from "formik";
import { GracePeriodNotifications } from "../../../../../../../automations/workflows/single-workflow/form/actions-section/grace-period/GracePeriodNotifications";
import { GracePeriodFrequency } from "../../../../../../../automations/workflows/single-workflow/form/actions-section/grace-period/GracePeriodFrequency";
import { GracePeriod } from "../../../../../../../../../services/cloudchipr.api";
import { ActionTypeSwitch } from "../../../../../../common/create/actions/ActionTypeSwitch";
import { ModalFilterItem } from "../../../../../../../common/filters/filters-selection/utils/types/types";
import { secondsOnWeeks } from "../../../../../utils/helpers/gracePeriodCalculations";
import { ScheduleActionsStepInitialData } from "../../../../../../common/utils/types/types";

interface GracePeriodSectionProps {
  gracePeriod?: GracePeriod;
  setFieldValue: FormikHelpers<ModalFilterItem>["setFieldValue"];
  error?: FormikErrors<ScheduleActionsStepInitialData["grace_period"]>;
}

export const GracePeriodSection: FC<GracePeriodSectionProps> = ({
  gracePeriod,
  setFieldValue,
  error,
}) => {
  const gracePeriodChangeHandler = useCallback(
    (gracePeriod: GracePeriod | null) => {
      setFieldValue("grace_period", gracePeriod);
    },
    [setFieldValue],
  );

  const gracePeriodSwitchHandler = useCallback(() => {
    let period: GracePeriod = {
      period: secondsOnWeeks,
      notifications: [],
    };

    if (gracePeriod) {
      period = null;
    }

    setFieldValue("grace_period", period);
  }, [setFieldValue, gracePeriod]);

  const periodChangeHandler = useCallback(
    (period: number) => {
      gracePeriodChangeHandler({ ...gracePeriod, period });
    },
    [gracePeriod, gracePeriodChangeHandler],
  );

  return (
    <Fragment>
      <Divider />

      <ActionTypeSwitch
        onChange={gracePeriodSwitchHandler}
        isChecked={!!gracePeriod}
        label="Enable Grace Period Before Actions"
        info="When enabled, actions will only be taken after a notification is sent and the grace period has expired. Users will be able to protect or backup their resources during the grace period. "
        beta={true}
      />

      {gracePeriod && (
        <Stack>
          <Typography variant="body2" color="text.secondary">
            Notify first, wait for grace period before taking actions.
          </Typography>

          <GracePeriodFrequency
            period={gracePeriod.period}
            onChange={periodChangeHandler}
            error={error?.period}
          />

          <GracePeriodNotifications
            error={error}
            gracePeriod={gracePeriod}
            onChange={gracePeriodChangeHandler}
          />
        </Stack>
      )}
    </Fragment>
  );
};
