import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment/moment";
import {
  ProviderType,
  ResourceFiltersWithAction,
  ResourceType,
  Schedule,
  ScheduleAccount,
  ScheduleActionType,
} from "../../../services/cloudchipr.api";
import { initiateWorkflow } from "../schedulesSlice";
import { getTimeZoneObject } from "../../../utils/helpers/date-time/timeZones";
import { formatDate } from "../../../utils/helpers/date-time/datetime-format";
import { getResourceTypeName } from "../../../utils/helpers/resources/getResourceTypeName";

export type InitiateWorkflowThunkArgs = {
  account: ScheduleAccount;
  regions: string[];
  filters: ResourceFiltersWithAction[];
  resourceType?: ResourceType;
  provider?: ProviderType;
};

export const initiateWorkflowThunk = createAsyncThunk(
  "automations/initiateWorkflow",
  (
    {
      account,
      filters,
      regions,
      resourceType,
      provider,
    }: InitiateWorkflowThunkArgs,
    { dispatch },
  ) => {
    const serviceName = resourceType
      ? getResourceTypeName(resourceType, {
          service: true,
          singular: true,
          type: "abbreviation",
        })
      : "";

    const name = `${serviceName} Workflow | ${formatDate(moment(), {
      type: "dateTime",
    })}`;

    const workflow: Schedule = {
      name,
      regions,
      cloud_provider: provider ?? "aws",

      description: "",
      filter: filters,
      accounts: [account],
      time_zone: getTimeZoneObject().tzCode,
      emails: null,
      weekly_schedule_interval: null,
      daily_schedule_interval: null,
      id: "",
      cron: null,
      created_at: "",
      status: "inactive",
      start_date_time: "",
      end_date_time: null,
      min_threshold: null,
      action: "" as ScheduleActionType,
      metadata: { state: "normal", next_run_at: "", future_run_dates: [] },
    };

    dispatch(initiateWorkflow(workflow));
  },
);
