import { AccountFilterSetV2 } from "../../../../../../services/cloudchipr.api";

export const getDefaultFilterTemplates = (data: AccountFilterSetV2[]) => {
  const defaultTemplate = data.find(
    ({ filter_set }) =>
      filter_set.is_default && filter_set.source === "cloudchipr",
  );

  const c8rTemplates = data.filter(
    ({ filter_set }) =>
      filter_set.source === "cloudchipr" &&
      defaultTemplate?.filter_set?.id !== filter_set.id,
  );

  if (!defaultTemplate) {
    return c8rTemplates;
  }

  return [defaultTemplate, ...c8rTemplates];
};
