import { UnleashClient as UnleashClientFromPackage } from "unleash-proxy-client";
import { UNLEASH_CONFIG } from "../utils/env-var/env-var-constants";

// Initialize the Unleash client
const UnleashClient = new UnleashClientFromPackage({
  ...UNLEASH_CONFIG,
});

// Start the Unleash client
UnleashClient.start();

export default UnleashClient;
