import { ChangeEvent, FC, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ResourceExplorerCreateOrEditDialogActions } from "./components/ResourceExplorerCreateOrEditDialogActions";
import { ResourceExplorerCreateOrEditDialogContent } from "./components/ResourceExplorerCreateOrEditDialogContent";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { DialogTitleClosable } from "../dialog-utils/DialogTitleClosable";
import { createResourceExplorerLoadingSelector } from "../../../store/resource-explorer/selectors/loading/createResourceExplorerLoadingSelector";
import { resourceExplorerPersistThunk } from "../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerPersistThunk";
import { ResourceExplorerActionType } from "../../pages/resource-explorer/utils/types/types";

interface ResourceExplorerCreateOrEditDialogProps {
  open: boolean;
  title: string;
  onClose(): void;
  type: ResourceExplorerActionType;
  viewId?: string;
}
export const ResourceExplorerCreateOrEditDialog: FC<
  ResourceExplorerCreateOrEditDialogProps
> = ({ viewId, type, open, title, onClose }) => {
  const [viewName, setViewName] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createResourceExplorerLoading = useAppSelector(
    createResourceExplorerLoadingSelector,
  );

  const closeDialogHandler = useCallback(() => {
    onClose();
    setViewName("");
  }, [onClose]);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setViewName(event.target.value);
    },
    [],
  );

  const submitFormHandler = useCallback(() => {
    dispatch(
      resourceExplorerPersistThunk({
        name: viewName,
        type: type,
        viewId,
      }),
    )
      .unwrap()
      .then((data: any) => {
        if (type === "create" && data) {
          closeDialogHandler();
          navigate({ pathname: `/resource-explorer/${data?.id}` });
        }
      });
  }, [dispatch, viewId, viewName, closeDialogHandler, type, navigate]);

  return (
    <Dialog open={open} onClose={closeDialogHandler} maxWidth="sm" fullWidth>
      <DialogTitleClosable title={title} onClose={closeDialogHandler} />
      <DialogContent
        sx={{
          pb: 4,
        }}
        dividers
      >
        <ResourceExplorerCreateOrEditDialogContent
          value={viewName}
          onTextFieldChange={nameChangeHandler}
        />
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <ResourceExplorerCreateOrEditDialogActions
          isLoading={createResourceExplorerLoading}
          disabled={!viewName.trim()}
          onSubmit={submitFormHandler}
          onCloseDialog={closeDialogHandler}
        />
      </DialogActions>
    </Dialog>
  );
};
