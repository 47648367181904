import { RootState } from "../../../../store";
import { defaultFilterTemplateFiltersByAccountIdSelector } from "../../../../filters/selectors/filter-set/default/defaultFilterTemplateFiltersByAccountIdSelector";
import { automationProviderSelector } from "../fields/automationProviderSelector";
import { automationAccountIdsSelector } from "../fields/automationAccountIdsSelector";

export const allDefaultFiltersForAutomationSelector = (state: RootState) => {
  const provider = automationProviderSelector(state);
  const accountIds = automationAccountIdsSelector(state);

  if (!provider) {
    return;
  }

  return defaultFilterTemplateFiltersByAccountIdSelector(
    state,
    accountIds[0],
    provider,
  );
};
