import { FC } from "react";
import { TextField } from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";

interface DashboardCreateFolderOrItemDialogContentProps {
  value: string;
  onTextFieldChange: StandardInputProps["onChange"];
  label: string;
}
export const DashboardCreateFolderOrItemDialogContent: FC<
  DashboardCreateFolderOrItemDialogContentProps
> = ({ value, onTextFieldChange, label }) => {
  return (
    <TextField
      value={value}
      onChange={onTextFieldChange}
      name="name"
      label={label}
      fullWidth
      size="small"
      autoFocus
    />
  );
};
