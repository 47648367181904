import { FC, useCallback } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useToggle } from "rooks";

export type FilterSelectOptions = {
  value: string;
  label: string;
  tooltip?: string;
  disabled?: boolean;
}[];

interface FilterSelectProps {
  label: string;
  selectedValues: string[];
  onChange(value: string[]): void;
  options: FilterSelectOptions;
}

export const FilterSelect: FC<FilterSelectProps> = ({
  label,
  selectedValues,
  onChange,
  options,
}) => {
  const [open, toggleOpen] = useToggle(false);
  const changeHandler = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      onChange(e.target.value as string[]);
    },
    [onChange],
  );

  const renderValue = useCallback(
    (values: string[]) => {
      return options
        .filter((option) => values.includes(option.value))
        .map((option) => option.label)
        .join(", ");
    },
    [options],
  );

  return (
    <FormControl size="xsmall" sx={{ width: 170 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        open={open}
        label={label}
        value={selectedValues}
        renderValue={renderValue}
        onChange={changeHandler}
        onOpen={toggleOpen}
        onClose={toggleOpen}
      >
        {options?.map(({ tooltip, label, value, disabled }) => {
          const lastSelectedOneLeft =
            selectedValues.length === 1 && selectedValues.at(0) === value;

          return (
            <MenuItem
              key={value}
              value={value}
              sx={{ pl: 0.5 }}
              disabled={disabled || lastSelectedOneLeft}
            >
              <Checkbox size="small" checked={selectedValues.includes(value)} />

              {label}

              {tooltip && (
                <Tooltip title={tooltip} placement="top" arrow>
                  <InfoOutlinedIcon
                    color="action"
                    fontSize="small"
                    sx={{ ml: 1 }}
                  />
                </Tooltip>
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
