import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import { commonGroupings, specificGroupings } from "../constants/groupings";
import { getAzureTagsFeatureToggleToStorage } from "../../../../utils/helpers/azure-tags/getAzureTagsFeatureToggleToStorage";

export const getDefaultGroupings = (providers: ProviderType[]) => {
  const enableAzureTags = getAzureTagsFeatureToggleToStorage();

  const filteredForAzure = commonGroupings.filter(
    (grouping) =>
      !["cost_allocation_tag", "cost_allocation_tag_value"].includes(
        grouping ?? "",
      ),
  );

  const groupings = new Set(
    enableAzureTags ? commonGroupings : filteredForAzure,
  );

  const disabledGroupings: Record<string, ProviderType[]> = {};

  const provider = providers.at(0);
  if (providers.length === 1 && provider) {
    specificGroupings[provider].forEach((grouping) => {
      groupings.add(grouping);
    });
  } else {
    const arrOfGroupings = providers.map(
      (provider) => specificGroupings[provider],
    );

    providers.forEach((provider) => {
      specificGroupings[provider].forEach((grouping) => {
        const allProviderHasTheSameGroupings = arrOfGroupings.every(
          (providerGroupings) => providerGroupings?.includes(grouping),
        );

        if (allProviderHasTheSameGroupings) {
          groupings.add(grouping);
        } else if (grouping) {
          disabledGroupings[grouping] = disabledGroupings[grouping]
            ? [...disabledGroupings[grouping], provider]
            : [provider];
        }
      });
    });
  }

  return {
    disabledGroupings,
    groupingOptions: Array.from(groupings),
  };
};
