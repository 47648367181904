import { FC, useCallback } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffectOnceWhen } from "rooks";
import { ResourceExplorerV2ViewActionButton } from "../view-action-menu/ResourceExplorerV2ViewActionButton";
import { ResourceExplorerV2ViewFolderActionButton } from "../folder-actions-menu/ResourceExplorerV2ViewFolderActionButton";
import { ResourceExplorerV2Visibility } from "../visibility-types/ResourceExplorerV2Visibility";
import { SortableTree } from "../../../sortable-tree/SortableTree";
import {
  SortableTreeOnchangeArgs,
  SortableTreeItemActionArgs,
  SortableTreeFolderActionArgs,
} from "../../../sortable-tree/utils/types";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { updateResourceExplorerHierarchyThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/updateResourceExplorerHierarchyThunk";
import {
  resetNavigationResourceExplorer,
  setNavigationResourceExplorer,
} from "../../../../../../store/navigation/navigationSlice";
import { useAppAbility } from "../../../../../../services/permissions";
import { navigationResourceExplorerPublicDataSelector } from "../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerPublicDataSelector";
import { getResourceExplorerViewsHierarchyThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/getResourceExplorerViewsHierarchyThunk";
import { getResourceExplorerVisibilityHierarchyThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";

export const ResourceExplorerHierarchyDndWrapper: FC = () => {
  const hierarchyData = useAppSelector(
    navigationResourceExplorerPublicDataSelector,
  );
  const { cannot, can } = useAppAbility();

  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );
  const resourceExplorerAvailable = can("view", "resource-explorer");

  const canNotCreateView = cannot("create", "resource-explorer");
  const dispatch = useAppDispatch();
  const renderFolderAction = useCallback(
    ({ id, name }: SortableTreeFolderActionArgs) => {
      return (
        <ResourceExplorerV2ViewFolderActionButton
          id={id}
          name={name}
          visibility="visible_to_everyone"
        />
      );
    },
    [],
  );

  const renderItemAction = useCallback(
    ({ id, name, parentId }: SortableTreeItemActionArgs) => {
      return (
        <ResourceExplorerV2ViewActionButton
          id={id}
          name={name}
          folderId={(parentId as string) ?? undefined}
          visibility="visible_to_everyone"
        />
      );
    },
    [],
  );

  const hierarchyChangeHandler = useCallback(
    ({ index, id, parentId, items }: SortableTreeOnchangeArgs) => {
      if (!items) {
        return;
      }
      dispatch(
        setNavigationResourceExplorer({
          data: items,
          type: "visible_to_everyone",
        }),
      );
      dispatch(
        updateResourceExplorerHierarchyThunk({
          viewId: id as string,
          index,
          folderId: (parentId as string) ?? undefined,
        }),
      );
    },
    [dispatch],
  );

  const navigateToHandler = useCallback((id: UniqueIdentifier) => {
    return `/resource-explorer/${id}`;
  }, []);

  const resetHandler = useCallback(() => {
    dispatch(resetNavigationResourceExplorer());
  }, [dispatch]);

  useEffectOnceWhen(() => {
    if (enableNavigationHierarchyVisibility) {
      dispatch(getResourceExplorerVisibilityHierarchyThunk());
    } else {
      dispatch(getResourceExplorerViewsHierarchyThunk());
    }
  }, resourceExplorerAvailable);

  if (enableNavigationHierarchyVisibility) {
    return <ResourceExplorerV2Visibility />;
  }

  if (!hierarchyData) {
    return null;
  }

  return (
    <SortableTree
      items={hierarchyData}
      renderItemAction={renderItemAction}
      renderFolderAction={renderFolderAction}
      onChange={hierarchyChangeHandler}
      getNavigateTo={navigateToHandler}
      onReset={resetHandler}
      icon={InsertChartOutlinedOutlinedIcon}
      disabled={canNotCreateView}
      emptyText="No views inside"
    />
  );
};
