import { currentBudgetsDataSelector } from "../../currentBudgetsDataSelector";
import { RootState } from "../../../../store";
import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../../../../resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";
import UnleashClient from "../../../../../services/UnleashClient";
import { resourceExplorerViewsHierarchyViewsSelector } from "../../../../resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyViewsSelector";
import { ResourceExplorer } from "../../../../../services/cloudchipr.api";

export const budgetFirstAvailableViewIdSelector = (
  state: RootState,
): string | undefined => {
  const budgets = currentBudgetsDataSelector(state);

  const views = resourceExplorerViewsDataSelector(state);
  const mergedHierarchyVisibilityItems =
    resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector(state);
  const hierarchyItems = resourceExplorerViewsHierarchyViewsSelector(state);

  const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
    "EnableNavigationHierarchyVisibility",
  );
  const enableResourceExplorerFolderViews = UnleashClient.isEnabled(
    "EnableResourceExplorerFolderViews",
  );
  let data = views;
  if (enableNavigationHierarchyVisibility) {
    data = mergedHierarchyVisibilityItems ?? [];
  } else if (enableResourceExplorerFolderViews) {
    data = hierarchyItems ?? [];
  }
  return data.reduce(
    (result, view) => {
      if (result) {
        return result;
      }
      const isNotSelectedView = budgets?.every((budget) => {
        return budget.view.id !== view.id;
      });

      if (isNotSelectedView) {
        result = view;
      }

      return result;
    },
    null as ResourceExplorer | null,
  )?.id;
};
