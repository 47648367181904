import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { ReservedInstanceDrawerContentHeaderInfoCards } from "./ReservedInstanceDrawerContentHeaderInfoCards";
import { ReservedInstanceDrawerChartHeader } from "./ReservedInstanceDrawerChartHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { CommitmentsDrawerHeader } from "../../../../common/components/drawer/header/CommitmentsDrawerHeader";
import { getReservationDetailsThunk } from "../../../../../../../store/commitments/thunks/getReservationDetailsThunk";
import { CommitmentsDrawerContent } from "../../../../common/components/drawer/CommitmentsDrawerContent";
import { ReservedInstanceDrawerChart } from "../common/ReservedInstanceDrawerChart";
import { ReservationDrawerDataGrid } from "../common/data-grid/ReservationDrawerDataGrid";

interface ReservedInstanceDrawerContentProps {
  onClose(): void;
}

export const ReservedInstanceDrawerContent: FC<
  ReservedInstanceDrawerContentProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const targetId = useAppSelector(commitmentsDetailsDrawerTargetIdSelector);

  useDidMount(() => {
    dispatch(getReservationDetailsThunk());
  });

  return (
    <Fragment>
      <CommitmentsDrawerHeader title={targetId} onClose={onClose}>
        <ReservedInstanceDrawerContentHeaderInfoCards />
      </CommitmentsDrawerHeader>

      <Box width="80dvw" bgcolor="grey.50" height="100%">
        <CommitmentsDrawerContent title="Utilization" icon="utilization">
          <ReservedInstanceDrawerChart
            ChartHeader={ReservedInstanceDrawerChartHeader}
          />

          <ReservationDrawerDataGrid viewTab="commitment" />
        </CommitmentsDrawerContent>
      </Box>
    </Fragment>
  );
};
