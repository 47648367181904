import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getDashboardsVisibilityHierarchyThunk } from "./getDashboardsVisibilityHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

interface UpdateDashboardVisibilityPublicHierarchyThunkArg {
  id: string;
  folderId?: string;
  index?: number;
}

export const updateDashboardVisibilityPublicHierarchyThunk = createAsyncThunk(
  "dashboard/updateDashboardVisibilityPublicHierarchy",
  async (
    { id, folderId, index }: UpdateDashboardVisibilityPublicHierarchyThunkArg,
    { dispatch },
  ) => {
    const {
      patchUsersMeOrganisationsCurrentDashboardHierarchyVisibleToEveryone,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentDashboardHierarchyVisibleToEveryone.initiate(
          {
            body: {
              id,
              parent_id: folderId,
              index,
            },
          },
        ),
      ).unwrap();
      dispatch(getDashboardsVisibilityHierarchyThunk());
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
