import { normalizeWeeklyCronText } from "../../../../../schedule/classic/utils/helpers/normalizeWeeklyCronText";
import { getRunDateFromCron } from "../../../../../schedule/common/utils/helpers/common";
import { generateCronForPayload } from "../../../../../../../store/automations/utils/helpers/cron/generateCronForPayload";
import {
  AutomationFrequencyType,
  WorkflowSpecificData,
} from "../../../../../../../store/automations/utils/types/common";

export const generateWorkflowSchedulerText = (
  frequency: AutomationFrequencyType,
  scheduler?: WorkflowSpecificData["scheduler"],
) => {
  const runTime = scheduler?.runTime ?? null;
  const weekDays = scheduler?.weekDays;
  const repeatValue = scheduler?.repeatValue;

  const cron = generateCronForPayload(
    frequency,
    runTime,
    repeatValue,
    weekDays,
  );

  const cronRunDate = getRunDateFromCron(cron);

  return normalizeWeeklyCronText(cronRunDate, repeatValue ?? null);
};
