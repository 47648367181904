import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import {
  AlertsData,
  AlertsInterface,
  AlertsThresholdUnit,
  AlertTabType,
} from "./utils/types/types";
import { alertsInitialData } from "./utils/constants/constants";

const initialState: AlertsInterface = {
  config: {
    activeTab: "costAnomaly",
    openDrawer: false,
  },
  data: alertsInitialData,
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: initialState,
  reducers: {
    setAlertsActiveTab: (state, action: PayloadAction<AlertTabType>) => {
      state.config.activeTab = action.payload;
    },
    toggleAlertsOpenDrawer: (state) => {
      state.config.openDrawer = !state.config.openDrawer;
    },
    resetAlertsData: (state) => {
      state.data = initialState.data;
    },
    resetAlerts: () => {
      return initialState;
    },
    setAlertsData: (state, action: PayloadAction<Partial<AlertsData>>) => {
      state.data = { ...state.data, ...action.payload };
    },
    addDefaultThresholdByType: (
      state,
      action: PayloadAction<AlertsThresholdUnit>,
    ) => {
      state.data.thresholds.push({
        id: uuid(),
        unit: action.payload,
        amount: "1",
      });
    },
    deleteThresholdById: (state, action: PayloadAction<string>) => {
      const index = state.data.thresholds.findIndex(
        (threshold) => threshold.id === action.payload,
      );

      if (index === -1) {
        return;
      }

      state.data.thresholds.splice(index, 1);
    },
    updateThresholdAmountById: (
      state,
      action: PayloadAction<{ id: string; amount: string }>,
    ) => {
      const index = state.data.thresholds.findIndex(
        (threshold) => threshold.id === action.payload.id,
      );

      if (index === -1) {
        return;
      }

      state.data.thresholds[index].amount = action.payload.amount;
    },
  },
});

export const {
  setAlertsActiveTab,
  toggleAlertsOpenDrawer,
  resetAlertsData,
  setAlertsData,
  addDefaultThresholdByType,
  deleteThresholdById,
  updateThresholdAmountById,
  resetAlerts,
} = alertsSlice.actions;

export default alertsSlice.reducer;
