import { FC, Fragment, useCallback, useRef, useState } from "react";
import { Divider, Stack, useTheme } from "@mui/material";
import {
  PanelGroup,
  Panel,
  PanelResizeHandle,
  ImperativePanelHandle,
  ImperativePanelGroupHandle,
} from "react-resizable-panels";
import { Outlet, useLocation } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { Navigation } from "../navigation/Navigation";
import { SystemMessages } from "../system-message/SystemMessages";
import { useAppDispatch } from "../../store/hooks";
import { setNavCollapsed } from "../../store/common/commonSlice";
import { useLayoutConfigHook } from "../navigation/utils/hooks/useLayoutConfigHook";
import { IntegrationDialogs } from "../common/integration-dialogs/IntegrationDialogs";
import { FeatureUpdates } from "../feature-updates/FeatureUpdates";
import { SettingsPageNavigation } from "../navigation/components/settings/SettingsPageNavigation";
import { COMPONENT_ID } from "../../utils/constants/components-ids";

export type OutletContextType = {
  expandNavigation(): void;
  collapseNavigation(): void;
  layoutSizes: number[];
};

export const MainLayout: FC = () => {
  const dispatch = useAppDispatch();
  const { min, max, collapsedSize } = useLayoutConfigHook();

  const sideBarRef = useRef<ImperativePanelHandle>(null);
  const panelGroupRef = useRef<ImperativePanelGroupHandle>(null);

  const [layoutSizes, setLayoutSizes] = useState<number[]>();

  const handleCollapse = useCallback(() => {
    dispatch(setNavCollapsed(true));
    setLayoutSizes(panelGroupRef.current?.getLayout());
  }, [dispatch]);

  const handleExpand = useCallback(() => {
    dispatch(setNavCollapsed(false));
    setLayoutSizes(panelGroupRef.current?.getLayout());
  }, [dispatch]);

  const handleCollapseNavigation = useCallback(() => {
    sideBarRef.current?.collapse();
  }, []);

  const handleExpandNavigation = useCallback(() => {
    sideBarRef.current?.expand();
  }, []);

  const theme = useTheme();

  const location = useLocation();
  const settingsSelected = location.pathname.startsWith("/settings");

  return (
    <PanelGroup
      autoSaveId={COMPONENT_ID.MAIN_NAVIGATION}
      direction="horizontal"
      style={{ height: "100vh", backgroundColor: grey[50] }}
      ref={panelGroupRef}
    >
      <Panel
        id={COMPONENT_ID.MAIN_NAVIGATION}
        order={1}
        collapsible
        minSize={min}
        maxSize={max}
        collapsedSize={collapsedSize}
        onCollapse={handleCollapse}
        onExpand={handleExpand}
        ref={sideBarRef}
        style={{ zIndex: theme.zIndex.drawer }}
      >
        <Navigation onCollapse={handleCollapseNavigation} />
      </Panel>
      <PanelResizeHandle>
        <Divider orientation="vertical" />
      </PanelResizeHandle>
      <Panel id={COMPONENT_ID.CONTENT_WRAPPER} order={2}>
        <PanelGroup direction="horizontal">
          {settingsSelected && (
            <Fragment>
              <Panel
                minSize={min}
                maxSize={max}
                order={1}
                id={COMPONENT_ID.SETTINGS_NAVIGATION}
              >
                <SettingsPageNavigation />
              </Panel>
              <PanelResizeHandle>
                <Divider orientation="vertical" />
              </PanelResizeHandle>
            </Fragment>
          )}
          <Panel order={2}>
            <Stack sx={{ height: "100%", overflowY: "auto" }}>
              <SystemMessages />
              <Stack
                flexGrow={1}
                overflow="auto"
                id={COMPONENT_ID.APP_OUTLET_WRAPPER}
              >
                <Outlet
                  context={{
                    expandNavigation: handleExpandNavigation,
                    collapseNavigation: handleCollapseNavigation,
                    layoutSizes,
                  }}
                />
              </Stack>
            </Stack>
            <IntegrationDialogs />
            <FeatureUpdates />
          </Panel>
        </PanelGroup>
      </Panel>
    </PanelGroup>
  );
};
