import { billingStatusByProviderSelector } from "./billingStatusByProviderSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const enabledProvidersByBillingStatusSelector = (
  state: RootState,
): ProviderType[] => {
  const awsBillingStatus = billingStatusByProviderSelector(state, "aws");
  const gcpBillingStatus = billingStatusByProviderSelector(state, "gcp");
  const azureBillingStatus = billingStatusByProviderSelector(state, "azure");

  const providers: ProviderType[] = [];

  if (awsBillingStatus && !missingProviderStatuses.includes(awsBillingStatus)) {
    providers.push("aws");
  }
  if (gcpBillingStatus && !missingProviderStatuses.includes(gcpBillingStatus)) {
    providers.push("gcp");
  }
  if (
    azureBillingStatus &&
    !missingProviderStatuses.includes(azureBillingStatus)
  ) {
    providers.push("azure");
  }

  return providers;
};
const missingProviderStatuses = ["missing_setup", "waiting_for_cloud_provider"];
