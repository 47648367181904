import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { LinearProgress, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ResourceCardToolbar } from "./toolbar/ResourceCardToolbar";
import {
  nfSortingConverter,
  nfVisibilitiesConverter,
} from "./utils/nf-tmp-helpers";
import { generateColumns } from "./utils/data-grid/columns-setup/resourcesColumnsGenerator";
import { resourcesDataGridSubRow } from "./subrow/ResourcesSubRowNF";
import { expandableResourceTypes } from "./utils/data-grid/constants";
import { generateMetricsFrequenciesFromContextData } from "./utils/csv-data/generateMetricsFrequenciesFromContextData";
import { ColumnSetupType } from "../../../../../../../../../storybook/data-grid/utils/types/types";
import { DataGrid } from "../../../../../../../../../storybook/data-grid/DataGrid";
import { WithResourceType } from "../../../../../../utils/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceTypeDataLoadingSelector } from "../../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataLoadingSelector";
import { useColumnsVisibility } from "../../../../../../utils/hooks/useColumnsVisibility.hook";
import { setSelectedResources } from "../../../../../../../../../store/account/accountSlice";
import { currentAccountSelectedGroupedRowsIdsByResourceTypeSelector } from "../../../../../../../../../store/account/selectors/current-account/resources/selection/currentAccountSelectedGroupedRowsIdsByResourceTypeSelector";
import { useColumnsSorting } from "../../../../../../utils/hooks/useColumnsSorting.hook";
import { ResourceDataType } from "../../../../../../../../../store/account/utils/types/types";
import { notActionableResourceTypes } from "../../../../../../../../../utils/constants/resources/resources";
import { useDataGridContext } from "../../../../../../../../../storybook/data-grid/DataGridProvider";

interface ResourcesDataGridProps extends WithResourceType {
  fullHeight?: boolean;
  data: ResourceDataType[];
  columns: ColumnSetupType<any>[];
}

export const ResourcesDataGrid: FC<ResourcesDataGridProps> = memo(
  ({ data, columns, resourceType, fullHeight }) => {
    const dispatch = useAppDispatch();

    const loading = useAppSelector((state) =>
      resourceTypeDataLoadingSelector(state, resourceType),
    );
    const selectedResources = useAppSelector((state) =>
      currentAccountSelectedGroupedRowsIdsByResourceTypeSelector(
        state,
        resourceType,
      ),
    );

    const { visibility, setVisibility } = useColumnsVisibility(resourceType);
    const { sorting, setSorting } = useColumnsSorting(resourceType, columns);
    const {
      cellSpecificMetadata: { data: cellData },
    } = useDataGridContext();
    const metricsFrequencies = useMemo(
      () => generateMetricsFrequenciesFromContextData(cellData),
      [cellData],
    );

    const onRowSelectionChange = useCallback(
      (data: Record<string, boolean>): void => {
        dispatch(
          setSelectedResources({
            resourceType,
            selectedResources: data,
            parentSource: resourceType,
          }),
        );
      },
      [dispatch, resourceType],
    );

    const memoizedVisibility = useMemo(() => {
      return nfVisibilitiesConverter(visibility);
    }, [visibility]);

    const memoizedSorting = useMemo(
      () => nfSortingConverter(sorting),
      [sorting],
    );

    const columnsSetup = useMemo(() => {
      const config = {
        includeExpander: expandableResourceTypes.has(resourceType),
        includeCheckbox: !notActionableResourceTypes.has(resourceType),
        includeActions: !notActionableResourceTypes.has(resourceType),
        metricsFrequencies,
      };

      return generateColumns(
        columns as ColumnSetupType<ResourceDataType>[],
        config,
      );
    }, [columns, resourceType, metricsFrequencies]);

    return (
      <DataGrid
        globalFilter
        enableRowsVirtualization={data.length > 30}
        data={data}
        key={resourceType}
        columns={columnsSetup}
        columnResizeMode="onEnd"
        enableStickyColumns={!!data?.length}
        styles={fullHeight ? fullHeightStyles : styles}
        rowExpanding={{
          renderExpandedRowSubRow: resourcesDataGridSubRow,
          parentRowStyles: { bgcolor: grey[100], "& > td": { pl: 4 } },
        }}
        columnVisibility={{
          initialVisibility: memoizedVisibility,
          onColumnsVisibilityChange: setVisibility,
        }}
        columnSorting={{
          initialSort: memoizedSorting,
          onColumnsSortingChange: setSorting,
        }}
        rowSelection={{
          rowSelectionChange: onRowSelectionChange,
          initialSelectedItems: selectedResources?.[resourceType] ?? {},
        }}
        toolbar={{
          renderToolbar: (props) => {
            return (
              <Fragment>
                <ResourceCardToolbar {...props} resourceType={resourceType} />
                {loading && <LinearProgress />}
              </Fragment>
            );
          },
        }}
      />
    );
  },
);

const styles = {
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.200",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    background: "white",
  },
  tableToolbar: { px: 2 },
  tableContainer: { maxHeight: 580 },
};

const fullHeightStyles = {
  ...styles,
  tableContainer: { ...styles.tableContainer, maxHeight: "unset" },
};
