import * as Yup from "yup";
import { getEmailsValidationSchema } from "../../../../components/common/integration-dialogs/components/email/utils/validation";
import { threeMonthsBySeconds } from "../../../../components/pages/schedule/classic/components/create/actions-step/components/utils/constants";
import { getGracePeriodNotificationsValidationSchema } from "../../../../components/pages/schedule/classic/utils/helpers/gracePeriodValidation";
import { integrationNotificationValidationSchema } from "../../../../components/pages/common/notification-selection/utils/validation/validation";
import { Emails } from "../../../../services/cloudchipr.api";

const automationValidationSchema = {
  name: Yup.string().required("Name is required."),

  accountIds: Yup.array()
    .of(Yup.string().required("Selecting a cloud account is required."))
    .min(1, "Selecting a cloud account is required.")
    .required("Selecting a cloud account is required."),

  regions: Yup.array()
    .of(Yup.string().required("Selecting a region is required."))
    .min(1, "Selecting a region is required.")
    .required("Selecting a region is required."),

  notifications: Yup.array()
    .nullable()
    .when("action", {
      is: (action: string) => action?.includes("notify"),
      then: Yup.array()
        .nullable()
        .when("emails", {
          is: (emails: Emails) => !emails,
          then: Yup.array()
            .of(integrationNotificationValidationSchema)
            .min(
              1,
              "Notification method is required when action notification is enabled.",
            )
            .required(
              "Notification method is required when action notification is enabled.",
            )
            .typeError(
              "Notification method is required when action notification is enabled.",
            ),
          otherwise: Yup.array()
            .of(integrationNotificationValidationSchema)
            .nullable(),
        }),
    }),

  emails: Yup.object()
    .nullable()
    .when("action", {
      is: (action: string) => action?.includes("notify"),
      then: getEmailsValidationSchema(true),
      otherwise: Yup.object().nullable(),
    }),

  filter: Yup.array().when("accountIds", {
    is: (accountIds: string[]) => !!accountIds.length,
    then: Yup.array()
      .of(
        Yup.object({
          filter: Yup.object({
            type: Yup.string(),
            filter_items: Yup.array().min(1, "Selecting a filter is required."),
          }),
        }),
      )
      .min(1, "Selecting a service is required."),
    otherwise: Yup.array(),
  }),
};

export const workflowValidationSchema = {
  ...automationValidationSchema,

  gracePeriod: Yup.object({
    period: Yup.number()
      .required()
      .min(1, "Minimum value is 1.")
      .max(threeMonthsBySeconds, "Value can’t be more than 3 months."),
    notifications: Yup.array()
      // @ts-expect-error | types looks ok, can't understand what's the reason
      .of(getGracePeriodNotificationsValidationSchema())
      .required("Notification method is required when grace period is enabled.")
      .min(1, "Notification method is required when grace period is enabled."),
  }).nullable(),
};
