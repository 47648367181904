import { FC, useCallback } from "react";
import { enqueueSnackbar } from "notistack";
import { ConfirmationDialog } from "../../../../common/modals/ConfirmationDialog";
import {
  OffHoursSchedule,
  useGetV2UsersMeOffHoursSchedulesQuery,
  usePatchV2UsersMeOffHoursSchedulesByScheduleIdMutation,
} from "../../../../../services/cloudchipr.api";
import {
  generateOffHoursUpdatingData,
  getOffHoursUpdatePayloadData,
} from "../utils/helpers/helpers";

interface WithoutAccountsOffHoursDialogProps {
  offHours: OffHoursSchedule;
  onClose(): void;
}

export const WithoutAccountsOffHoursDialog: FC<
  WithoutAccountsOffHoursDialogProps
> = ({ onClose, offHours }) => {
  const { refetch } = useGetV2UsersMeOffHoursSchedulesQuery({});

  const [updateSchedule, { isLoading }] =
    usePatchV2UsersMeOffHoursSchedulesByScheduleIdMutation();

  const deleteHandler = useCallback(async () => {
    await updateSchedule(
      getOffHoursUpdatePayloadData(
        generateOffHoursUpdatingData({ ...offHours, status: "deleted" }),
      ),
    );
    enqueueSnackbar("Off Hours successfully deleted.", {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "success" },
    });
    await refetch();

    onClose();
  }, [offHours, onClose, refetch, updateSchedule]);

  return (
    <ConfirmationDialog
      open
      title="Schedule has no accounts"
      dialogContent="The account you previously added to this schedule is no longer connected. Want to delete incomplete schedule?"
      CancelButtonProps={{
        onClick: onClose,
      }}
      ConfirmButtonProps={{
        loading: isLoading,
        onClick: deleteHandler,
        children: "Delete",
      }}
      onClick={deleteHandler}
      onClose={onClose}
    />
  );
};
