import {
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
} from "../../../../../../../services/cloudchipr.api";

export const filterAvailableFieldsChecker = (
  type: ResourceExplorerDynamicFilterItemType,
  operator: FilterOperatorType,
) => {
  let hasKey = false;
  let hasValue = true;

  if (type === "tag") {
    hasKey = true;

    if (["exists", "does_not_exist"].includes(operator)) {
      hasValue = false;
    }
  }

  return { hasKey, hasValue };
};
