import { alertsCostAnomalyDataViewIdSelector } from "./data/alertsCostAnomalyDataViewIdSelector";
import { alertsCostAnomalyDataGroupingSelector } from "./data/alertsCostAnomalyDataGroupingSelector";
import { alertsCostAnomalyDataTimeIntervalSelector } from "./data/alertsCostAnomalyDataTimeIntervalSelector";
import { alertsCostAnomalyDataTimeIntervalUnitSelector } from "./data/alertsCostAnomalyDataTimeIntervalUnitSelector";
import { RootState } from "../../../store";
import { getDatesFromTimeInterval } from "../../utils/helpers/getDatesFromTimeInterval";
import { AlertsData } from "../../utils/types/types";
import { DateDataPoint } from "../../../../services/cloudchipr.api";

export const getCostAnomalyPreviewRequestArgsSelector = (state: RootState) => {
  const viewId = alertsCostAnomalyDataViewIdSelector(state);
  const grouping = alertsCostAnomalyDataGroupingSelector(state);
  const timeInterval = alertsCostAnomalyDataTimeIntervalSelector(state);
  const timeIntervalUnit = alertsCostAnomalyDataTimeIntervalUnitSelector(state);

  const { dateTo, dateFrom } = getDatesFromTimeInterval(
    timeInterval,
    timeIntervalUnit,
  );

  if (!viewId) {
    return null;
  }

  return {
    resourceExplorerViewId: viewId,
    dateFrom,
    dateTo,
    dataPoint: datePointFromTimeIntervalUnit.get(timeIntervalUnit),
    grouping,
  };
};

const datePointFromTimeIntervalUnit = new Map<
  AlertsData["timeIntervalUnit"],
  DateDataPoint
>([
  ["days", "daily"],
  ["weeks", "weekly"],
  ["months", "monthly"],
]);
