import { costBreakdownWidgetViewIdByWidgetIdSelector } from "./costBreakdownWidgetViewIdByWidgetIdSelector";

import { RootState } from "../../../../store";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { sortedResourceExplorersSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/sortedResourceExplorersSelector";
import { selectedViewProvidersSelector } from "../common/selectedViewProvidersSelector";

export const costBreakdownWidgetProvidersSelector = (
  state: RootState,
  widgetId?: string,
): ProviderType[] => {
  if (!widgetId) {
    return selectedViewProvidersSelector(state);
  }
  const views = sortedResourceExplorersSelector(state);

  const viewId = costBreakdownWidgetViewIdByWidgetIdSelector(state, widgetId);

  return (views?.find((item) => item.id === viewId)?.cloud_providers ??
    []) as ProviderType[];
};
