import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FC, useCallback } from "react";
import { useAppAbility } from "../../../../services/permissions";
import { toggleAlertsOpenDrawer } from "../../../../store/alerts/alertsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { alertsActiveTabSelector } from "../../../../store/alerts/selectors/alertsActiveTabSelector";
import { initiateCostAnomalyAlertThunk } from "../../../../store/alerts/thunks/cost-anomaly-alerts/initiateCostAnomalyAlertThunk";
import { initiateUtilizationAlertThunk } from "../../../../store/alerts/thunks/utilization-alerts/initiateUtilizationAlertThunk";

interface CreateAlertsButtonProps {
  variant?: "contained" | "outlined";
}

export const CreateAlertButton: FC<CreateAlertsButtonProps> = ({
  variant = "contained",
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const cannotView = cannot("edit", "alert");
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);

  const toggleOpenDrawer = useCallback(() => {
    if (alertsActiveTab === "costAnomaly") {
      dispatch(initiateCostAnomalyAlertThunk());
    } else {
      dispatch(initiateUtilizationAlertThunk());
    }

    dispatch(toggleAlertsOpenDrawer());
  }, [dispatch, alertsActiveTab]);

  return (
    <Button
      variant={variant}
      color="primary"
      size="small"
      sx={{ textTransform: "none" }}
      onClick={toggleOpenDrawer}
      disabled={cannotView}
    >
      <AddIcon sx={{ mr: 1 }} fontSize="small" /> Create Alert
    </Button>
  );
};
