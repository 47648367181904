import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import { useAppAbility } from "../../../../../../../services/permissions";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";
import {
  CostAnomalyAlert,
  UtilizationAndCoverageAlert,
} from "../../../../../../../services/cloudchipr.api";
import { ConfirmationDialog } from "../../../../../../common/modals/ConfirmationDialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { duplicateCostAnomalyAlertThunk } from "../../../../../../../store/alerts/thunks/cost-anomaly-alerts/duplicateCostAnomalyAlertThunk";
import { isCostAnomalyAlert } from "../utils/helpers/isCostAnomalyAlert";
import { saveCostAnomalyLoadingSelector } from "../../../../../../../store/alerts/selectors/cost-anomaly-alerts/saveCostAnomalyLoadingSelector";
import { duplicateUtilizationAlertThunk } from "../../../../../../../store/alerts/thunks/utilization-alerts/duplicateUtilizationAlertThunk";
import { saveUtilizationLoadingSelector } from "../../../../../../../store/alerts/selectors/utilization-alerts/saveUtilizationLoadingSelector";

interface AlertDataGridDuplicateActionProps {
  alertsActiveTab: AlertTabType;
  alert: CostAnomalyAlert | UtilizationAndCoverageAlert;
}

export const AlertDataGridDuplicateAction: FC<
  AlertDataGridDuplicateActionProps
> = ({ alert, alertsActiveTab }) => {
  const dispatch = useAppDispatch();

  const { cannot } = useAppAbility();
  const cannotEdit = cannot("edit", "alert");

  const { open, openDialog, closeDialog } = useDialog();
  const [localName, setLocalName] = useState(`${alert.name} (copy)`);

  const costAnomalyLoading = useAppSelector(saveCostAnomalyLoadingSelector);
  const utilizationLoading = useAppSelector(saveUtilizationLoadingSelector);
  const disabled = !localName || alert.name === localName.trim();
  const loading = costAnomalyLoading || utilizationLoading;

  const inputChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLocalName(e.target.value);
  }, []);

  const handleDuplicate = useCallback(() => {
    if (alertsActiveTab === "costAnomaly" && isCostAnomalyAlert(alert)) {
      dispatch(duplicateCostAnomalyAlertThunk({ ...alert, name: localName }));
    } else {
      dispatch(
        duplicateUtilizationAlertThunk({
          ...(alert as UtilizationAndCoverageAlert),
          name: localName,
        }),
      );
    }
  }, [alert, alertsActiveTab, dispatch, localName]);

  return (
    <Fragment>
      <MenuItem dense onClick={openDialog} disabled={cannotEdit}>
        <ListItemIcon>
          <ContentCopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Duplicate</ListItemText>
      </MenuItem>

      <ConfirmationDialog
        open={open}
        onClose={closeDialog}
        ConfirmButtonProps={{
          onClick: handleDuplicate,
          loading: loading,
          disabled: disabled,
          children: "duplicate",
        }}
        CancelButtonProps={{
          onClick: closeDialog,
          disabled: loading,
        }}
        title={`Duplicate Alert`}
        dividers
        dialogContent={
          <Stack spacing={1}>
            <Typography variant="body2">Alert Name</Typography>
            <TextField
              autoFocus
              fullWidth
              type="text"
              value={localName}
              size="small"
              variant="outlined"
              onChange={inputChangeHandler}
              error={!localName}
            />
          </Stack>
        }
      />
    </Fragment>
  );
};
