import { formatNotificationsFromHtmlToSlack } from "../../../../../utils/helpers/formatNotificationsFromHtmlToSlack";
import {
  generateAutomationPayloadData,
  getAutomationSteps,
  isFilterAdded,
} from "../../../common/utils/helpers/common";
import {
  PatchV2UsersMeSchedulesByScheduleIdApiArg,
  PostV2UsersMeSchedulesApiArg,
  ResourceFiltersWithAction,
  Schedule,
  ScheduleActionType,
} from "../../../../../../services/cloudchipr.api";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";
import { WorkflowFormData } from "../types/types";
import { AutomationsStepsType } from "../../../common/utils/types/types";
import { getCronFrequency } from "../../../../../../store/automations/utils/helpers/cron/getCronFrequency";
import { getStartsOnDate } from "../../../common/utils/constants/common";

export const generateWorkflowsSteps = (
  schedule: WorkflowFormData,
): AutomationsStepsType => {
  const isFirstCompleted = !!(
    schedule.name &&
    schedule.account_ids?.length &&
    schedule.provider &&
    schedule.regions.length
  );

  const isSecondCompleted = !!(
    isFirstCompleted && isFilterAdded(schedule.filter)
  );
  const isThirdCompleted =
    isSecondCompleted &&
    !!(schedule.action?.includes("notify")
      ? !!(schedule?.notifications?.length || schedule?.emails?.to?.length)
      : schedule.action);

  const isFourthCompleted = !!(
    isThirdCompleted &&
    (schedule.start_date_time || schedule.cron)
  );

  return getAutomationSteps(
    isFirstCompleted,
    isSecondCompleted,
    isThirdCompleted,
    isFourthCompleted,
  );
};

const configureWorkflowResources = (
  resources: ResourceFiltersWithAction[],
  action: ScheduleActionType,
) => {
  if (action !== "notify") {
    return resources;
  }

  return resources.map((resource) => {
    return {
      ...resource,
      action: undefined,
      snapshot: undefined,
    };
  });
};

export const getWorkflowCreatePayloadData = (
  schedule: WorkflowFormData,
): PostV2UsersMeSchedulesApiArg => {
  const data = generateAutomationPayloadData(schedule);
  const frequency = getCronFrequency(schedule.cron);
  const updatedNotifications = formatNotificationsFromHtmlToSlack(
    schedule.notifications,
  );

  const updatedGracePeriod = formatNotificationsFromHtmlToSlack(
    schedule.grace_period?.notifications,
  );
  return {
    body: {
      ...data.body,
      notifications: updatedNotifications,
      cron: schedule.cron,
      action: schedule.action,
      is_snoozed: schedule.is_snoozed,
      grace_period: schedule?.grace_period
        ? {
            ...schedule?.grace_period,
            notifications: updatedGracePeriod,
          }
        : undefined,
      weekly_schedule_interval:
        frequency === "weekly" ? schedule.weekly_schedule_interval : null,
      daily_schedule_interval:
        frequency === "daily" ? schedule.daily_schedule_interval : null,
      filter: configureWorkflowResources(schedule.filter, schedule.action),
    },
  };
};

export const getWorkflowUpdatePayloadData = (
  schedule: WorkflowFormData,
): PatchV2UsersMeSchedulesByScheduleIdApiArg => {
  const data = generateAutomationPayloadData(schedule);

  const frequency = getCronFrequency(schedule.cron);

  const { start_date_time, time_zone } = schedule;
  const startDate = start_date_time || getStartsOnDate(time_zone, true);

  const updatedNotifications = formatNotificationsFromHtmlToSlack(
    schedule.notifications,
  );
  const updatedGracePeriod = formatNotificationsFromHtmlToSlack(
    schedule.grace_period?.notifications,
  );

  return {
    scheduleId: schedule.id,
    body: {
      ...data.body,
      notifications: updatedNotifications,
      start_date_time: startDate,
      status: schedule.status,
      cron: schedule.cron,
      action: schedule.action,
      is_snoozed: schedule.is_snoozed,
      grace_period: schedule?.grace_period
        ? {
            ...schedule?.grace_period,
            notifications: updatedGracePeriod,
          }
        : undefined,
      weekly_schedule_interval:
        frequency === "weekly" && schedule.weekly_schedule_interval
          ? +schedule.weekly_schedule_interval
          : null,
      daily_schedule_interval:
        frequency === "daily" && schedule.daily_schedule_interval
          ? +schedule.daily_schedule_interval
          : null,
      filter: configureWorkflowResources(schedule.filter, schedule.action),
    },
  };
};

export const generateWorkflowEditingData = (
  schedule: Schedule,
): WorkflowFormData => {
  const { time_zone, accounts, ...other } = schedule;
  const timeZone = time_zone || getTimeZoneObject().tzCode;

  return {
    ...other,
    emails: other.emails ?? null,
    account_ids: accounts.map(({ id }) => id),
    provider: accounts?.[0]?.provider,
    time_zone: timeZone,
  };
};

export const generateWorkflowUpdatingData = (
  schedule: Schedule,
): WorkflowFormData => {
  const { accounts, ...other } = schedule;

  return {
    ...other,
    account_ids: accounts.map(({ id }) => id),
    provider: accounts?.[0]?.provider,
  };
};
