import { FC, Fragment, useCallback } from "react";
import { ProviderFilterCard } from "./ProviderFilterCard";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import {
  DynamicFiltersType,
  DynamicProviderFilterGroup,
} from "../../utils/types/common";

interface ProvidersDynamicFiltersProps {
  filters: DynamicFiltersType;
  setFilters(filters: DynamicFiltersType): void;
}

export const ProvidersDynamicFilters: FC<ProvidersDynamicFiltersProps> = ({
  filters,
  setFilters,
}) => {
  const filtersChangeHandler = useCallback(
    (newFilters: DynamicProviderFilterGroup | null, provider: ProviderType) => {
      if (newFilters === null) {
        const { [provider]: removed, ...restFilters } = filters;

        setFilters(restFilters);
      } else {
        setFilters({ ...filters, [provider]: newFilters });
      }
    },
    [setFilters, filters],
  );

  const providerFiltersRemoveHandler = useCallback(
    (provider: ProviderType) => {
      const { [provider]: removed, ...restFilters } = filters;

      setFilters(restFilters);
    },
    [setFilters, filters],
  );

  return (
    <Fragment>
      {Object.keys(filters).map((key) => {
        const provider = key as ProviderType;
        const providerFilters = filters[provider];

        if (!providerFilters) {
          return null;
        }

        return (
          <ProviderFilterCard
            key={provider}
            provider={provider}
            filters={providerFilters}
            onFiltersChange={filtersChangeHandler}
            onProviderFiltersRemove={providerFiltersRemoveHandler}
          />
        );
      })}
    </Fragment>
  );
};
