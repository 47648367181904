import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";

interface CostAnomalyPreviewTableHeaderCellProps {
  title: string;
  description: string;
  tooltipTitle?: string;
}

export const CostAnomalyPreviewTableHeaderCell: FC<
  CostAnomalyPreviewTableHeaderCellProps
> = ({ title, description, tooltipTitle }) => {
  return (
    <Tooltip arrow placement="top" title={tooltipTitle}>
      <Stack>
        <Typography variant="caption" fontWeight="medium">
          {title}
        </Typography>
        <TypographyWithTooltip
          title={description}
          fontSize={10}
          color="text.secondary"
        />
      </Stack>
    </Tooltip>
  );
};
