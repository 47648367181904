import { ColumnDef, Row } from "@tanstack/react-table";
import { SxProps } from "@mui/system";
import { grey } from "@mui/material/colors";
import { getColumnSize } from "./resourceColumnSizes";
import { getSortingFnByKeyAndType } from "./getSortingFnByKeyAndType";
import { customCell } from "../../../cells/TableCellByType";
import { HeaderCell } from "../../../cells/header-cells/HeaderCell";
import {
  ColumnSetupType,
  isCellStylesCallable,
} from "../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { filterFnByCellType } from "../../../../../../../../../../../../storybook/data-grid/utils/filters/common";
import { wrapWithParentKey } from "../../nf-tmp-helpers";
import { RowSelectCheckboxHeaderCell } from "../../../cells/row-select-checkbox-cell/RowSelectCheckboxHeaderCell";
import { ResourceDataType } from "../../../../../../../../../../../../store/account/utils/types/types";
import { RowExpandingCell } from "../../../cells/row-expanding-cell/RowExpandingCell";
import { RowExpandingHeaderCell } from "../../../cells/row-expanding-cell/RowExpandingHeaderCell";
import { RowSelectionCellCheckbox } from "../../../../../../../../../../../../storybook/data-grid/common/RowSelectionCellCheckbox";
import { MetricsFrequenciesType } from "../../types/metricsFrequenciesType";

interface GenerateColumnsConfigs {
  includeCheckbox?: boolean;
  includeActions?: boolean;
  includeExpander?: boolean;
  disableSticky?: boolean;
  metricsFrequencies?: MetricsFrequenciesType;
  alwaysShowSortIcon?: boolean;
}

export const generateColumns = (
  setupData: ColumnSetupType<ResourceDataType>[],
  configs?: GenerateColumnsConfigs,
): ColumnDef<ResourceDataType>[] => {
  const {
    alwaysShowSortIcon,
    includeCheckbox,
    includeActions,
    includeExpander,
    disableSticky,
    metricsFrequencies,
  } = configs ?? {};
  const tdStyles: SxProps = {
    py: 0.25,
    px: 2,
  };

  const columns = setupData.map(({ type, headerTooltip, ...props }, index) => {
    const headerTitle =
      typeof props.header === "string" ? props.header : props.meta?.headerTitle;

    const minSize = getColumnSize({
      type: type,
      enableSorting: props.enableSorting,
      headerTooltip: headerTooltip,
    });

    return {
      cell: customCell(type),
      enableSorting: true,
      enableResizing: true,
      minSize: minSize,
      filterFn: filterFnByCellType.get(type) ?? "defaultFilter",
      sortingFn: getSortingFnByKeyAndType(
        type,
        props?.accessorKey,
        props?.id,
        metricsFrequencies,
      ),
      ...props,

      // todo: (nf) manually change accessor keys in all columns, when resources new data structure will approved
      accessorKey: props.accessorKey
        ? wrapWithParentKey(props.accessorKey)
        : undefined,
      id: wrapWithParentKey(props.id ?? props.accessorKey ?? `${index}`),

      header: (header) => {
        if (props.header instanceof Function) {
          return props.header(header);
        }

        return (
          <HeaderCell
            title={props?.header?.toString() || ""}
            tooltip={headerTooltip}
          />
        );
      },

      meta: {
        alwaysShowSortIcon: alwaysShowSortIcon ?? true,
        headerTitle,
        ...props.meta,
        cellStyles: (row: Row<any>) => {
          const resource = row.original.resource;

          let cellStyles = props.meta?.cellStyles;
          if (isCellStylesCallable(cellStyles)) {
            cellStyles = cellStyles(row);
          }

          const styles = { ...cellStyles, ...tdStyles };

          if (resource.is_protected || resource.isUnavailable) {
            return {
              ...styles,
              bgcolor: grey[100],
            };
          }

          return styles;
        },
      },
    } as ColumnDef<ResourceDataType>;
  });

  if (includeCheckbox) {
    columns.unshift({
      id: "select",
      size: 40,
      enableResizing: false,
      enableGlobalFilter: false,
      header: ({ table }) => <RowSelectCheckboxHeaderCell table={table} />,
      cell: ({ row, table }) => (
        <RowSelectionCellCheckbox
          row={row}
          table={table}
          disabled={row.original.resource.marked_for_deletion}
        />
      ),
      meta: {
        hideFromSettings: true,
        sticky: !disableSticky ? "left" : undefined,
        cellStyles: (row: Row<any>) => {
          const resource = row.original.resource;

          if (resource.is_protected || resource.isUnavailable) {
            return {
              bgcolor: grey[100],
            };
          }

          return {
            borderRight: "none !important",
          };
        },
      },
    });
  }

  if (includeExpander) {
    columns.unshift({
      id: "expander",
      size: 45,
      enableResizing: false,
      enableGlobalFilter: false,
      cell: ({ row }) => <RowExpandingCell row={row} />,
      header: ({ table }) => <RowExpandingHeaderCell table={table} />,
      meta: {
        hideFromSettings: true,
        sticky: !disableSticky ? "left" : undefined,
        cellStyles: (row: Row<any>) => {
          const resource = row.original.resource;

          if (resource.is_protected || resource.isUnavailable) {
            return {
              bgcolor: grey[100],
            };
          }

          return {};
        },
      },
    });
  }

  if (includeActions) {
    columns.push({
      id: "actions",
      enableResizing: false,
      enableGlobalFilter: false,
      cell: customCell("actions"),
      size: 100,
      meta: {
        hideFromSettings: true,
        sticky: "right",
        cellStyles: (row: Row<any>) => {
          const resource = row.original.resource;

          if (resource.is_protected || resource.isUnavailable) {
            return {
              ...tdStyles,
              bgcolor: grey[100],
            };
          }

          return tdStyles;
        },
      },
    });
  }

  return columns;
};
