import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import {
  DateLabelNullable,
  NullableDate,
} from "../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { generateLargestCostChangesWidgetDateCompareInfo } from "../../../../adding-widget/widget-create/widget-setups/larges-cost-changes/utils/helpers/generateLargestCostChangesWidgetDateCompareInfo";

interface LargestCostChangesWidgetFooterProps {
  prevDate?: {
    from?: NullableDate;
    to?: NullableDate;
  };
  date?: { from?: NullableDate; to?: NullableDate; label?: DateLabelNullable };
}

export const LargestCostChangesWidgetFooter: FC<
  LargestCostChangesWidgetFooterProps
> = ({ prevDate, date }) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dateCompareData = useMemo(() => {
    return generateLargestCostChangesWidgetDateCompareInfo({
      prevDate: {
        ...prevDate,
        label: date?.label,
      },
      date: date,
      quarterStartMonth,
    });
  }, [prevDate, date, quarterStartMonth]);

  return (
    <Typography
      fontWeight="bold"
      sx={{ display: "inline" }}
      variant="body2"
      color="text.secondary"
      px={2}
      pb={1}
    >
      {dateCompareData.startDateLabel}{" "}
      {dateCompareData.startDateRange && (
        <Typography variant="body2" component="span">
          ({dateCompareData.startDateRange}){" "}
        </Typography>
      )}
      <Typography variant="body2" component="span">
        compared with{" "}
      </Typography>
      {dateCompareData.endDateLabel}
      {dateCompareData.endDateRange && (
        <Typography variant="body2" component="span">
          {" "}
          ({dateCompareData.endDateRange})
        </Typography>
      )}
    </Typography>
  );
};
