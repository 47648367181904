import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ResourceExplorerInterface,
  MultiFilters,
  ResourceExplorerPath,
  ResourceExplorerDimension,
} from "./utils/types/resourceExplorer";
import { ResourceExplorerDataGridView } from "./utils/types/resourceExplorerDataGrid";
import {
  ResourceExplorerFilterItem,
  DateDataPoint,
  DateLabelNullable,
  NullableDate,
  ProviderType,
  ResourceExplorerGrouping,
  ForecastOption,
  Dates,
  ResourceExplorerData,
  TrendType,
} from "../../services/cloudchipr.api";

const initialState: ResourceExplorerInterface = {
  id: "",
  data: null,
  lastState: { filters: null, data: null },
  filters: {},
  path: [],
  providers: [],
  search: "",
  quarterEndForecastVisibility: false,
  dataGridView: null,
  dimension: null,
  csvDataHash: "",
};

export const resourceExplorerSlice = createSlice({
  name: "resourceExplorer",
  initialState: initialState,
  reducers: {
    setResourceExplorerId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setProviders: (state, action: PayloadAction<ProviderType[]>) => {
      state.providers = action.payload;
    },
    addProvider: (state, action: PayloadAction<ProviderType>) => {
      state.providers = [...state.providers, action.payload];
    },
    removeProvider: (state, action: PayloadAction<ProviderType>) => {
      state.providers = state.providers.filter(
        (item) => item !== action.payload,
      );
    },
    setResourceExplorerSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setResourceExplorerDataGridView: (
      state,
      action: PayloadAction<ResourceExplorerDataGridView>,
    ) => {
      state.dataGridView = action.payload;
    },
    setQuarterEndForecastVisibility: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.quarterEndForecastVisibility = action.payload;
    },
    setMultiFilters: (state, action: PayloadAction<MultiFilters>) => {
      state.filters = action.payload;
      state.lastState.filters = state.filters;
      state.lastState.data = state.data;
    },
    addMultiFilterByProvider: (state, action: PayloadAction<ProviderType>) => {
      state.filters[action.payload] = {};
      state.lastState.filters = state.filters;
      state.lastState.data = state.data;

      state.providers = [
        ...state.providers.filter((item) => item !== action.payload),
        action.payload,
      ];
    },
    updateMultiFilterByProvider: (
      state,
      action: PayloadAction<{
        provider: ProviderType;
        filter: ResourceExplorerFilterItem;
      }>,
    ) => {
      const { provider, filter } = action.payload;
      state.filters[provider] = {
        ...state.filters[provider],
        [filter.key]: {
          ...filter,
          cloud_provider: provider,
        },
      };
      state.lastState.filters = state.filters;
      state.lastState.data = state.data;

      if (!state.filters[provider]) {
        state.providers = [
          ...state.providers.filter((item) => item !== provider),
          provider,
        ];
      }
    },
    removeFilterByProvider: (state, action: PayloadAction<ProviderType>) => {
      const newRefFilters = { ...state.filters };

      delete newRefFilters[action.payload];
      state.filters = newRefFilters;
    },
    setResourceExplorerData: (
      state,
      action: PayloadAction<ResourceExplorerData>,
    ) => {
      const { date_label, from_date, to_date } = action.payload;

      state.lastState.filters = state.filters;
      state.lastState.data = state.data;

      state.data = {
        ...action.payload,
        from_date: date_label && from_date ? null : from_date,
        to_date: date_label && to_date ? null : to_date,
      };
    },
    setResourceExplorerGrouping: (
      state,
      action: PayloadAction<{
        grouping: ResourceExplorerGrouping;
        groupValues?: string[];
      }>,
    ) => {
      if (!state.data) {
        return;
      }

      state.lastState.filters = state.filters;
      state.lastState.data = state.data;

      state.data.grouping = action.payload.grouping;
      state.data.group_values = action.payload.groupValues;
    },
    setResourceExplorerDataPoint: (
      state,
      action: PayloadAction<DateDataPoint>,
    ) => {
      if (!state.data) {
        return;
      }

      state.lastState.filters = state.filters;
      state.lastState.data = state.data;

      state.data.data_point = action.payload;
    },
    setResourceExplorerDateRanges: (
      state,
      action: PayloadAction<{
        from: NullableDate;
        to: NullableDate;
        label: DateLabelNullable;
      }>,
    ) => {
      const { to, label, from } = action.payload;

      if (!state.data) {
        return;
      }

      state.lastState.filters = state.filters;
      state.lastState.data = state.data;

      state.data.to_date = to;
      state.data.from_date = from;
      state.data.date_label = label;
    },
    setResourceExplorerDates: (state, action: PayloadAction<Dates>) => {
      if (!state.data) {
        return;
      }

      state.lastState.filters = state.filters;
      state.lastState.data = state.data;
      state.data.dates = action.payload;
    },
    setResourceExplorerForecastOption: (
      state,
      action: PayloadAction<ForecastOption>,
    ) => {
      if (!state.data) {
        return;
      }

      state.data.forecast_option = action.payload;
    },
    rollbackState: (state) => {
      if (state.lastState.filters) {
        state.filters = state.lastState.filters;
      }

      state.data = state.lastState.data;

      return state;
    },
    resetResourceExplorer: () => {
      return initialState;
    },

    setResourceExplorerPath: (
      state,
      action: PayloadAction<ResourceExplorerPath[]>,
    ) => {
      state.path = action.payload;
    },
    toggleShowBudgetLine: (state) => {
      if (!state.data) {
        return;
      }

      state.data.show_budget_line = !state.data.show_budget_line;
    },
    setResourceExplorerCsvDataHash: (state, action: PayloadAction<string>) => {
      state.csvDataHash = action.payload;
    },
    setResourceExplorerTrendType: (state, action: PayloadAction<TrendType>) => {
      if (!state.data) {
        return;
      }

      state.lastState.filters = state.filters;
      state.lastState.data = state.data;

      state.data.trend_type = action.payload;
    },

    // dimension
    setResourceExplorerDimension: (
      state,
      action: PayloadAction<ResourceExplorerDimension | null>,
    ) => {
      state.dimension = action.payload;
    },
    setResourceExplorerDimensionId: (state, action: PayloadAction<string>) => {
      state.dimension = {
        dimensionId: action.payload,
        categoryIds: [],
      };
    },
    setResourceExplorerCategoryIds: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      if (state.dimension) {
        state.dimension.categoryIds = action.payload;
      }
    },
  },
});

export const {
  setResourceExplorerPath,
  resetResourceExplorer,
  setResourceExplorerData,
  setResourceExplorerDataPoint,
  setResourceExplorerGrouping,
  setResourceExplorerDateRanges,
  setResourceExplorerDates,
  setResourceExplorerId,
  rollbackState,
  setMultiFilters,
  updateMultiFilterByProvider,
  addMultiFilterByProvider,
  removeFilterByProvider,
  setProviders,
  addProvider,
  removeProvider,
  setResourceExplorerSearch,
  setResourceExplorerForecastOption,
  setQuarterEndForecastVisibility,
  setResourceExplorerDataGridView,
  toggleShowBudgetLine,
  setResourceExplorerCsvDataHash,
  setResourceExplorerTrendType,
  // dimension
  setResourceExplorerDimension,
  setResourceExplorerDimensionId,
  setResourceExplorerCategoryIds,
} = resourceExplorerSlice.actions;

export default resourceExplorerSlice.reducer;
