import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { ProviderLogo } from "../../../../../../common/provider-logo/ProviderLogo";
import { getProviderName } from "../../../../../../../utils/helpers/providers/getProviderName";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { setRecommendationsProviderFilter } from "../../../../../../../store/recommendations/recommendationsSlice";
import { connectedProvidersSelector } from "../../../../../../../store/common/selectors/connectedProvidersSelector";

export const OffHoursRecommendationsProviderFilter: FC = () => {
  const dispatch = useAppDispatch();
  const providers = useAppSelector(connectedProvidersSelector);

  const options = providers.map((provider) => ({
    value: provider,
    hideOnly: true,
    label: (
      <Stack direction="row" spacing={1}>
        <ProviderLogo provider={provider} width={24} />
        <Typography variant="body2">
          {getProviderName(provider, { title: true })}
        </Typography>
      </Stack>
    ),
  }));

  const changeHandler = useCallback(
    (providers: ProviderType[]) => {
      dispatch(setRecommendationsProviderFilter(providers));
    },
    [dispatch],
  );

  return (
    <DropdownSelect
      label="Cloud Providers"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};
