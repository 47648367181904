import { Fragment } from "react";
import { IconButton } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useToggle } from "rooks";
import { ViewSettingsDrawer } from "./components/ViewSettingsDrawer";

export const ViewSettings = () => {
  const [openDrawer, toggleOpenDrawer] = useToggle(false);

  return (
    <Fragment>
      <IconButton size="small" onClick={toggleOpenDrawer}>
        <SettingsOutlinedIcon fontSize="small" />
      </IconButton>
      <ViewSettingsDrawer open={openDrawer} onClose={toggleOpenDrawer} />
    </Fragment>
  );
};
