import { RootState } from "../../../../store";
import { billingAccountStatusAllProvidersSelector } from "../../../../common/selectors/billing-status/billingAccountStatusAllProvidersSelector";
import { getCurrentProvidersByProviderBillingStatusDataSelector } from "../../../../common/selectors/billing-status/getCurrentProvidersByProviderBillingStatusDataSelector";
import { enabledProvidersByBillingStatusSelector } from "../../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

export const isResourceExplorerAvailableSelector = (
  state: RootState,
): boolean => {
  const enabledProviders = enabledProvidersByBillingStatusSelector(state);

  const provider = enabledProviders[0];
  const billingStatusesAllProviders =
    billingAccountStatusAllProvidersSelector(state);

  const billingStatuses =
    getCurrentProvidersByProviderBillingStatusDataSelector(
      state,
      provider,
    )?.billing_account_statuses;

  const activeAccountExist = !!billingStatuses?.some(
    (item) => item.status === "active",
  );

  return (
    enabledProviders.length !== 1 ||
    billingStatusesAllProviders[provider]?.status === "active" ||
    activeAccountExist
  );
};
