import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import UnleashClient from "../../../../services/UnleashClient";
import { getDashboardsVisibilityHierarchyThunk } from "../dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";
import { getDashboardsHierarchyThunk } from "../dashboard-hierarchy/getDashboardsHierarchyThunk";

export const deleteDashboardHierarchyFolderThunkFixedCacheKey =
  "deleteDashboardFolderThunk-fixedCacheKey";

export const deleteDashboardFolderThunk = createAsyncThunk(
  "dashboard/deleteDashboardFolder",
  async (id: string, { dispatch }) => {
    const {
      deleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderId,
      deleteUsersMeOrganisationsCurrentDashboardFoldersByFolderId,
    } = cloudChiprApi.endpoints;

    const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
      "EnableNavigationHierarchyVisibility",
    );

    try {
      if (enableNavigationHierarchyVisibility) {
        await dispatch(
          deleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderId.initiate(
            {
              folderId: id,
            },
            {
              fixedCacheKey: deleteDashboardHierarchyFolderThunkFixedCacheKey,
            },
          ),
        ).unwrap();
        dispatch(getDashboardsVisibilityHierarchyThunk());
      } else {
        await dispatch(
          deleteUsersMeOrganisationsCurrentDashboardFoldersByFolderId.initiate(
            {
              folderId: id,
            },
            {
              fixedCacheKey: deleteDashboardHierarchyFolderThunkFixedCacheKey,
            },
          ),
        ).unwrap();
        dispatch(getDashboardsHierarchyThunk());
      }

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Folder successfully deleted.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
