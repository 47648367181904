import { FC, SyntheticEvent, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { NavigationVisibilitySectionDialogContent } from "../../../common/visibility-types/NavigationVisibilitySectionDialogContent";
import { NavigationVisibilitySectionDialogActions } from "../../../common/visibility-types/NavigationVisibilitySectionDialogActions";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../../../../store/navigation/utils/types";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { createResourceExplorerVisibilityHierarchyFolderOrItemThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/createResourceExplorerVisibilityHierarchyFolderOrItemThunk";
import { createResourceExplorerFolderLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerFolderLoadingSelector";
import { createResourceExplorerLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerLoadingSelector";
import { getPressEnterHandler } from "../../../../../utils/helpers/getPressEnterHandler";

interface ResourceExplorerV2ViewCreateFolderOrViewDialogV2Props {
  onClose(): void;
  type: NavigationHierarchyItemType;
  folderId?: string;
  visibility?: NavigationItemsVisibilityType;
  title?: string;
  viewId?: string;
}

export const ResourceExplorerV2ViewCreateFolderOrViewDialogV2: FC<
  ResourceExplorerV2ViewCreateFolderOrViewDialogV2Props
> = ({
  onClose,
  type,
  folderId,
  visibility: fixedVisibility,
  title,
  viewId,
}) => {
  const navigate = useNavigate();
  const [viewName, setViewName] = useState("");
  const [visibility, setVisibility] = useState(
    fixedVisibility ?? "visible_to_everyone",
  );

  const createViewLoading = useAppSelector(
    createResourceExplorerLoadingSelector,
  );

  const createFolderLoading = useAppSelector(
    createResourceExplorerFolderLoadingSelector,
  );

  const dispatch = useAppDispatch();
  const closeDialogHandler = useCallback(() => {
    onClose();
    setViewName("");
  }, [onClose]);

  const nameChangeHandler = useCallback((event: any) => {
    setViewName(event.target.value);
  }, []);

  const createItemCallbackHandler = useCallback(
    (id: string) => {
      navigate({ pathname: `/resource-explorer/${id}` });
    },
    [navigate],
  );
  const visibilityChangeHandler = useCallback(
    (_: SyntheticEvent, value: string) => {
      setVisibility(value as NavigationItemsVisibilityType);
    },
    [],
  );

  const submitFormHandler = useCallback(async () => {
    const response = await dispatch(
      createResourceExplorerVisibilityHierarchyFolderOrItemThunk({
        name: viewName,
        type,
        visibility,
        folderId,
        viewId,
      }),
    ).unwrap();

    if (response) {
      createItemCallbackHandler(response?.id);
    }
    onClose();
  }, [
    createItemCallbackHandler,
    dispatch,
    onClose,
    viewName,
    type,
    visibility,
    folderId,
    viewId,
  ]);

  const handlePressEnter = getPressEnterHandler(
    submitFormHandler,
    createViewLoading || createFolderLoading || !viewName.length,
  );

  return (
    <Dialog
      open={!!type}
      onClose={closeDialogHandler}
      maxWidth="sm"
      fullWidth
      onKeyDown={handlePressEnter}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable
        title={title ?? (type === "item" ? "Add View" : "Add Folder")}
        onClose={closeDialogHandler}
      />
      <DialogContent
        sx={{
          pb: 3,
        }}
        dividers
      >
        <NavigationVisibilitySectionDialogContent
          value={viewName}
          onTextFieldChange={nameChangeHandler}
          label={type === "item" ? "View Name" : "Folder Name"}
          visibility={visibility}
          onVisibilityChange={visibilityChangeHandler}
          disabledVisibility={fixedVisibility}
        />
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <NavigationVisibilitySectionDialogActions
          onClose={closeDialogHandler}
          onSubmit={submitFormHandler}
          disabled={!viewName.trim()?.length}
          loading={createViewLoading || createFolderLoading}
        />
      </DialogActions>
    </Dialog>
  );
};
