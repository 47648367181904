import { FC, Fragment, useCallback } from "react";
import { Divider } from "@mui/material";
import { SchedulesResource } from "./SchedulesResource";
import {
  ProviderType,
  ResourceFilters,
  ResourceType,
  ResourceFiltersWithAction,
} from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { defaultFilterTemplateFiltersByAccountIdSelector } from "../../../../../../store/filters/selectors/filter-set/default/defaultFilterTemplateFiltersByAccountIdSelector";
import { AutomationType } from "../../../../../../store/automations/utils/types/common";

interface SchedulesResourcesListItemProps {
  resourceFilters: ResourceFiltersWithAction;
  resources: ResourceFiltersWithAction[];
  index: number;
  accountIds: string[];
  provider: ProviderType;
  error: string;
  regions: string[];
  scheduleType: AutomationType;
  scheduleAction: string | null;
  scheduleId: string;
  gracePeriodEnabled: boolean;
  setChangedFilters(filters: ResourceFiltersWithAction[]): void;
  addActionToFilter(filter: ResourceFilters): ResourceFiltersWithAction;
}

export const SchedulesResourcesListItem: FC<
  SchedulesResourcesListItemProps
> = ({
  resourceFilters,
  index,
  provider,
  resources,
  setChangedFilters,
  error,
  accountIds,
  regions,
  scheduleType,
  addActionToFilter,
  scheduleAction,
  scheduleId,
  gracePeriodEnabled,
}) => {
  const allDefaultFilters = useAppSelector((state) =>
    defaultFilterTemplateFiltersByAccountIdSelector(
      state,
      accountIds[0],
      provider,
    ),
  );

  const submitFiltersHandler = useCallback(
    (resourceFilters: ResourceFilters) => {
      const newFilters = resources.map((resource) => {
        return resourceFilters.type === resource.filter.type
          ? {
              ...resource,
              filter: resourceFilters,
            }
          : resource;
      });

      setChangedFilters(newFilters);
    },
    [setChangedFilters, resources],
  );

  const updateResource = useCallback(
    (type: ResourceType) => {
      const newFilters = resources.map((resource, i) => {
        const defaultFilters = allDefaultFilters?.[type];

        return i === index && defaultFilters
          ? addActionToFilter(defaultFilters)
          : resource;
      });

      setChangedFilters(newFilters);
    },
    [resources, allDefaultFilters, index, setChangedFilters, addActionToFilter],
  );

  const removeResource = useCallback(() => {
    setChangedFilters(resources.filter((_, i) => i !== index));
  }, [index, resources, setChangedFilters]);

  return (
    <Fragment>
      <SchedulesResource
        error={error}
        gracePeriodEnabled={gracePeriodEnabled}
        scheduleId={scheduleId}
        regions={regions}
        provider={provider}
        resources={resources}
        accountIds={accountIds}
        scheduleType={scheduleType}
        removeResource={removeResource}
        updateResource={updateResource}
        scheduleAction={scheduleAction}
        resourceFilters={resourceFilters}
        onFiltersSubmit={submitFiltersHandler}
      />

      {index !== resources.length - 1 && <Divider>AND</Divider>}
    </Fragment>
  );
};
