import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  cloudChiprApi,
  ResourceExplorerPossibleFilter,
  GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFiltersApiArg,
} from "../../../../services/cloudchipr.api";

type GetResourceExplorerPossibleFiltersThunkArgs = {
  provider: GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFiltersApiArg["provider"];
  forceRefetch?: boolean;
};

export const getResourceExplorerPossibleFiltersThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerPossibleFilters",
  async (
    { provider, forceRefetch }: GetResourceExplorerPossibleFiltersThunkArgs,
    { dispatch },
  ): Promise<ResourceExplorerPossibleFilter[] | undefined> => {
    const {
      getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFilters,
    } = cloudChiprApi.endpoints;

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFilters.initiate(
          { provider },
          { forceRefetch: forceRefetch },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
