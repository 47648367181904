import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { CommitmentsChartMetadataCard } from "../../../../../common/components/chart/CommitmentsChartMetadataCard";
import { useAppSelector } from "../../../../../../../../store/hooks";

import { money } from "../../../../../../../../utils/numeral/money";
import { reservationsGranularDataTotalCommitmentSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataTotalCommitmentSelector";
import { reservationsGranularDataTotalUtilizationSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataTotalUtilizationSelector";
import { reservationsGranularDataTotalCoverageSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataTotalCoverageSelector";
import { reservationsDataIsNotAvailableSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/reservationsDataIsNotAvailableSelector";
import { reservationsForGranularLoadingSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForGranularLoadingSelector";

export const ReservationsMultiTypeChartMetadata: FC = () => {
  const totalCommitment = useAppSelector(
    reservationsGranularDataTotalCommitmentSelector,
  );
  const totalUtilization = useAppSelector(
    reservationsGranularDataTotalUtilizationSelector,
  );

  const totalCoverage = useAppSelector(
    reservationsGranularDataTotalCoverageSelector,
  );

  const loading = useAppSelector(reservationsForGranularLoadingSelector);
  const notAvailable = useAppSelector(reservationsDataIsNotAvailableSelector);
  const notAvailableMessage = notAvailable ? "N/A" : "-";
  const utilizationAmount =
    totalUtilization?.amount === null
      ? notAvailableMessage
      : money(totalUtilization?.amount);

  return (
    <Stack
      spacing={2}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <CommitmentsChartMetadataCard
        title="Utilization"
        loading={loading}
        value={utilizationAmount}
        percent={totalUtilization?.percent}
      />
      <CommitmentsChartMetadataCard
        loading={loading}
        title="Reserved"
        value={money(totalCommitment)}
      />
      <CommitmentsChartMetadataCard
        loading={loading}
        title="Covered"
        value={money(totalCoverage?.amount)}
        percent={totalCoverage?.percent}
      />
    </Stack>
  );
};
