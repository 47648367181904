import { FC, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDidMount } from "rooks";
import { Box, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Interval } from "../../../../common/create/scheduler/Interval";
import {
  startEndDatesValidation,
  resourceStopStartValidation,
} from "../../../../common/utils/constants/validation";
import { StopStartDates } from "../StopStartDates";
import {
  DateTimeContent,
  ScheduleDatesInterface,
} from "../../../../common/create/scheduler/DateTimeContent";
import { NextPrevious } from "../../../../common/create/NextPrevious";
import { OffHoursSchedulerData } from "../../../utils/types/types";
import { getTimeFromCron } from "../../../../../../../store/automations/utils/helpers/cron/getTimeFromCron";
import { parseCron } from "../../../../../../../store/automations/utils/helpers/cron/parseCron";
import { generateDailyCron } from "../../../../../../../store/automations/utils/helpers/cron/generateDailyCron";

interface InitialValuesInterface {
  interval: number;
  runAt: string | null;
  endAt: string | null;
}

const initialValues: InitialValuesInterface & ScheduleDatesInterface = {
  runAt: null,
  endAt: null,
  interval: 0,
  startDate: "",
  endDate: "",
  timeZone: "",
  id: "",
};

const validationSchema = Yup.object({
  interval: Yup.number().required().min(1, "Please select interval"),
  endAt: resourceStopStartValidation("stop"),
  runAt: resourceStopStartValidation("start"),
  startDate: startEndDatesValidation,
  endDate: startEndDatesValidation,
});

interface DailyProps {
  stopCron: string | null;
  startCron: string | null;

  setScheduleDatesData(data: OffHoursSchedulerData): void;

  onPreviousStep(): void;

  isDirty: boolean;
}

export const Daily: FC<DailyProps & ScheduleDatesInterface> = memo(
  ({
    setScheduleDatesData,
    startCron,
    stopCron,
    onPreviousStep,
    timeZone,
    endDate,
    startDate,
    id,
    isDirty,
  }) => {
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        const stopCron = generateDailyCron(values.interval, values.endAt);
        const startCron = generateDailyCron(values.interval, values.runAt);

        setScheduleDatesData({
          startDate: values.startDate,
          endDate: values.endDate,
          startCron,
          stopCron,
          timeZone: values.timeZone,
        });
      },
    });

    const {
      values,
      errors,
      touched,
      submitForm,
      setFieldValue,
      resetForm,
      dirty,
      isValid,
      setValues,
    } = formik;

    useDidMount(() => {
      const { daysOfMonth } = parseCron(startCron);

      const values = {
        interval: daysOfMonth || 1,
        runAt: getTimeFromCron(startCron),
        endAt: getTimeFromCron(stopCron),
        timeZone,
        endDate,
        startDate,
        id,
      };

      if (id) {
        resetForm({ values });
      } else {
        setValues(values);
      }
    });

    return (
      <TabPanel value="daily">
        <form>
          <Interval
            type="Days"
            value={values.interval}
            error={touched.interval && errors.interval}
            setFieldValue={setFieldValue}
          />
          <Box mt={3}>
            <StopStartDates
              variant="stop"
              name="endAt"
              label="Stop Resources At"
              setFieldValue={setFieldValue}
              error={touched.endAt && errors.endAt}
              value={values.endAt}
            />
          </Box>
          <Box mt={3} display="flex" alignItems="center">
            <StopStartDates
              variant="start"
              name="runAt"
              label="Start Resources At"
              setFieldValue={setFieldValue}
              error={touched.runAt && errors.runAt}
              value={values.runAt}
            />
            <Tooltip
              arrow
              title="Cloudchipr will only start resources that were stopped previously from this schedule. No other resources will be affected."
            >
              <InfoOutlinedIcon
                sx={{ verticalAlign: "middle", color: "text.secondary", ml: 2 }}
                fontSize="small"
              />
            </Tooltip>
          </Box>
          <DateTimeContent
            endDate={values.endDate}
            startDate={values.startDate}
            timeZone={values.timeZone}
            endDateError={touched.endDate && errors.endDate}
            startDateError={touched.startDate && errors.startDate}
            setFieldValue={setFieldValue}
          />
          <Box mt={12}>
            <NextPrevious
              isNextDisabled={!id ? !dirty || !isValid : !isValid}
              onNext={submitForm}
              onPrevious={onPreviousStep}
              isDirty={dirty || isDirty}
            />
          </Box>
        </form>
      </TabPanel>
    );
  },
);
