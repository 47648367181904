import { FC, useMemo } from "react";
import { AlertThreshold } from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { joinThresholds } from "../cost-anomaly/utils/helpers/joinThresholds";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";

interface AlertsDataGridThresholdCellProps {
  thresholds: AlertThreshold[];
  alertType: AlertTabType;
}

export const AlertsDataGridThresholdCell: FC<
  AlertsDataGridThresholdCellProps
> = ({ thresholds, alertType }) => {
  const title = useMemo(() => {
    return joinThresholds(thresholds, alertType);
  }, [thresholds, alertType]);

  if (!title.trim()) {
    return "-";
  }

  return <TypographyWithTooltip variant="inherit" title={title} />;
};
