import { RootState } from "../../../store";
import { getCurrentProvidersByProviderBillingStatusDataSelector } from "../../../common/selectors/billing-status/getCurrentProvidersByProviderBillingStatusDataSelector";

export const missingSetupSelector = (state: RootState): boolean => {
  const billingStatuses =
    getCurrentProvidersByProviderBillingStatusDataSelector(
      state,
      "aws",
    )?.billing_account_statuses;

  return !!billingStatuses?.some(({ status }) => status === "missing_setup");
};
