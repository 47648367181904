import { Stack, Tooltip, Typography } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import {
  SavingsPlanCoverageGrouping,
  CommitmentResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { ReservationsCoverageDataWithId } from "../../../../../common/utils/types";
import { CommitmentsTableName } from "../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { HeaderCellWidthDateTooltip } from "../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { commitmentsResourceTypeNames } from "../../../../../common/utils/constants/labels";

const groupedByAccountColumns: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      accessorKey: "account.name" as keyof ReservationsCoverageDataWithId,
      header: "Account",
      cell: ({ row }) => {
        return (
          <CommitmentsTableName
            type="account"
            id={row.original.id}
            name={
              <Typography variant="body2" align="left" component="div">
                <Stack direction="row" spacing={0.5}>
                  {row.original.account?.is_management_account && (
                    <Tooltip title="Management account" placement="top" arrow>
                      <StarsIcon color="primary" fontSize="small" />
                    </Tooltip>
                  )}

                  <span> {row.original.account?.name}</span>
                </Stack>

                {row.original.account?.provider_account_id}
              </Typography>
            }
          />
        );
      },
    },
  ];

const groupedByResourceColumns: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      id: "resource_type",
      accessorKey:
        "resource_type.resource_type" as keyof ReservationsCoverageDataWithId,
      header: "Resource Type",
      cell: ({ row }) => {
        const rt = row.original.resource_type
          ?.resource_type as CommitmentResourceType;

        return (
          <CommitmentsTableName
            type="resource_type"
            id={row.original.id}
            name={
              <Typography variant="body2" align="left">
                {commitmentsResourceTypeNames[rt]}
              </Typography>
            }
          />
        );
      },
    },
    {
      id: "instance_type",
      accessorKey:
        "resource_type.instance_type" as keyof ReservationsCoverageDataWithId,
      header: "Instance Type",
      cell: ({ row }) => {
        return row.original.resource_type?.instance_type ?? "-";
      },
    },
    {
      id: "platform",
      accessorKey:
        "resource_type.platform" as keyof ReservationsCoverageDataWithId,
      header: "Platform",
      cell: ({ row }) => {
        return row.original.resource_type?.platform ?? null;
      },
    },

    {
      id: "region",
      accessorKey:
        "resource_type.region" as keyof ReservationsCoverageDataWithId,
      header: "Region/AZ",
      cell: ({ row }) => {
        return row.original.resource_type?.region ?? null;
      },
    },
  ];

const columnsWithoutGrouping: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      accessorKey: "covered_usage",
      header: () => (
        <Stack alignItems="end" flex={1}>
          <HeaderCellWidthDateTooltip title="Spend Covered by RIs" />
        </Stack>
      ),

      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.covered_usage)}
        </Typography>
      ),
      meta: { headerTitle: "Spend Covered by RIs" },
    },
    {
      accessorKey: "covered_hours",
      header: "Covered Hours",
      cell: ({ row }) => `${row.original.covered_hours}h`,
    },

    {
      accessorKey: "uncovered_usage",
      header: () => (
        <Stack alignItems="end" flex={1}>
          <HeaderCellWidthDateTooltip title="On-Demand Spend" />
        </Stack>
      ),
      meta: { headerTitle: "On-Demand Spend" },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.uncovered_usage)}
        </Typography>
      ),
    },
    {
      accessorKey: "net_savings",
      header: () => (
        <Typography
          align="right"
          variant="body2"
          fontWeight="medium"
          width="100%"
        >
          Net Savings
        </Typography>
      ),
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.net_savings)}
        </Typography>
      ),
    },
    {
      accessorKey: "coverage",
      header: () => <HeaderCellWidthDateTooltip title="Coverage" />,
      cell: ({ row }) => (
        <CommitmentsProgressBar value={row.original.coverage} />
      ),
    },
  ];

const accountGroupedColumns = [
  ...groupedByAccountColumns,
  ...columnsWithoutGrouping,
];
const resourceGroupedColumns = [
  ...groupedByResourceColumns,
  ...columnsWithoutGrouping,
];

export const reservationCoverageTableColumns = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<ReservationsCoverageDataWithId>[] => {
  if (grouping === "account") {
    return accountGroupedColumns;
  }

  if (grouping === "resource_type") {
    return resourceGroupedColumns;
  }

  return [];
};
