import { RootState } from "../../../../store";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../resourceExplorerFilterByKeyAndProviderSelector";
import {
  ProviderType,
  ResourceExplorerFilterItem,
  ResourceExplorerFilterKeyType,
} from "../../../../../services/cloudchipr.api";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { resourceExplorerProvidersSelector } from "../../current-resource-explorer/resourceExplorerProvidersSelector";
import { getResourceExplorerRestructuredFilters } from "../../../utils/helpers/getResourceExplorerRestructuredFilters";
import { resourceExplorerViewDataByIdSelector } from "../../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { enabledProvidersByBillingStatusSelector } from "../../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

interface AppliedFiltersDependOnInitialOperatorSelectorArg {
  viewId: string;
  key: ResourceExplorerFilterKeyType;
  provider: ProviderType;
}
export const appliedFiltersDependOnInitialOperatorSelector = (
  state: RootState,
  { viewId, key, provider }: AppliedFiltersDependOnInitialOperatorSelectorArg,
): ResourceExplorerFilterItem | undefined => {
  const filter = resourceExplorerFilterByKeyAndProviderSelector(
    state,
    key,
    provider,
  );

  if (!filter) {
    return;
  }

  const resourceExplorerViewFilters = resourceExplorerViewDataByIdSelector(
    state,
    viewId,
  )?.filters;

  const possibleFilters = resourceExplorerPossibleFiltersByProviderSelector(
    state,
    provider,
  )?.[key];
  const resourceExplorerProviders = resourceExplorerProvidersSelector(state);
  const enableProviders = enabledProvidersByBillingStatusSelector(state);

  const mutatedInitialFilter = resourceExplorerViewFilters
    ? getResourceExplorerRestructuredFilters(
        resourceExplorerViewFilters,
        resourceExplorerProviders,
        enableProviders,
      )
    : null;

  if (mutatedInitialFilter?.[provider]?.[key]?.operator === filter?.operator) {
    return filter;
  }

  if (mutatedInitialFilter?.[provider]?.[key]?.operator === "not_in") {
    const value = possibleFilters?.values?.reduce((result, item) => {
      if (!filter?.value?.includes(item.value)) {
        result.push(item.value);
      }
      return result;
    }, [] as string[]);

    return {
      ...filter,
      operator: "not_in",
      value,
    };
  }

  const value = possibleFilters?.values?.reduce((result, item) => {
    if (filter?.value?.includes(item.value)) {
      result.push(item.value);
    }
    return result;
  }, [] as string[]);

  return {
    ...filter,
    operator: "in",
    value,
  };
};
