import { FC, Fragment, useCallback, useMemo } from "react";
import { ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { DashboardV2ItemMoveActionMenu } from "./DashboardV2ItemMoveActionMenu";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { dashboardHierarchyFoldersSelector } from "../../../../../../../../store/dashboards/selectors/dashboard-hierarchy/dashboardHierarchyFoldersSelector";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";

interface DashboardV2ItemMoveActionProps {
  id: string;
  folderId?: string;
  onClose(): void;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2ItemMoveAction: FC<DashboardV2ItemMoveActionProps> = ({
  id,
  folderId,
  onClose,
  visibility,
}) => {
  const { anchor, open, openMenu, closeMenu: closeMoveMenu } = useMenuHook();
  const folders = useAppSelector((state) =>
    dashboardHierarchyFoldersSelector(state, visibility),
  );

  const filteredFolders = useMemo(() => {
    return folders?.filter((item) => item.id !== folderId);
  }, [folders, folderId]);

  const moveMenuItemDisabled = !filteredFolders?.length && !folderId;

  const closeMenusHandler = useCallback(() => {
    onClose();
    closeMoveMenu();
  }, [onClose, closeMoveMenu]);

  return (
    <Fragment>
      <Tooltip
        arrow
        title={moveMenuItemDisabled ? "No available folders to move to." : ""}
        placement="top"
        {...navigationHierarchyItemNoDndKey}
      >
        <span>
          <MenuItem
            onClick={openMenu}
            sx={menuItemSx}
            disabled={moveMenuItemDisabled}
          >
            <ListItemIcon>
              <DriveFileMoveOutlinedIcon fontSize="small" sx={iconSx} />
            </ListItemIcon>
            <Typography variant="body2">Move</Typography>
            <ListItemIcon sx={{ justifyContent: "flex-end", ...iconSx }}>
              <ChevronRightOutlinedIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </span>
      </Tooltip>

      <DashboardV2ItemMoveActionMenu
        folderId={folderId}
        id={id}
        open={open}
        onClose={closeMenusHandler}
        anchor={anchor}
        visibility={visibility}
      />
    </Fragment>
  );
};

const iconSx = { minWidth: 28 };

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};
