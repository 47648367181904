import { FC, Fragment, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { Schedule } from "../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { WithoutAccountsWorkflowDialog } from "../WithoutAccountsWorkflowDialog";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { ScheduleResourcesPreviewDrawer } from "../../../common/resources-preview/ScheduleResourcesPreviewDrawer";
import { ScheduleResourcesPreviewDrawerAppBar } from "../../../common/resources-preview/components/app-bar/ScheduleResourcesPreviewDrawerAppBar";
import { useSchedulePreviewDatesInfoHook } from "../../utils/hooks/useSchedulePreviewDatesInfo.hook";

interface ScheduledJobsListGridNameCellProps {
  schedule: Schedule;
}

export const ScheduledJobsDataGridNameCell: FC<
  ScheduledJobsListGridNameCellProps
> = ({ schedule }) => {
  const [searchParams] = useSearchParams();
  const scheduleIdForPreview = searchParams.get("preview");
  const enableNewAutomations = useFlag("EnableWorkflowAutomationSinglePage");

  const { open, openDialog, closeDialog } = useDialog();

  const withoutAccounts = useMemo(() => {
    return schedule.accounts.every((account) => account.status !== "connected");
  }, [schedule]);

  const editingUrl = enableNewAutomations
    ? `/automations/workflow/edit/${schedule.id}`
    : `/schedule/classic/edit/${schedule.id}`;

  const accountIds = schedule.accounts.map((account) => account.id);
  const gracePeriodValue = schedule?.grace_period?.period;

  const { nextRuns, frequency } = useSchedulePreviewDatesInfoHook({
    nextRun: schedule?.metadata?.next_run_at ?? "",
    timeZone: schedule.time_zone,
    cron: schedule.cron,
    weeklyInterval: schedule.weekly_schedule_interval,
  });

  useEffectOnceWhen(
    openDialog,
    schedule.id === scheduleIdForPreview && !withoutAccounts,
  );

  if (withoutAccounts) {
    return (
      <Fragment>
        <TypographyWithTooltip
          title={schedule.name}
          onClick={openDialog}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        />
        <TypographyWithTooltip variant="body2" title={schedule.description} />

        {open && (
          <WithoutAccountsWorkflowDialog
            onClose={closeDialog}
            workflow={schedule as Schedule}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <TypographyWithTooltip
        title={schedule.name}
        color="primary"
        onClick={openDialog}
        sx={{
          textDecoration: "underline",
          textUnderlineOffset: 4,
          cursor: "pointer",
        }}
      />

      <TypographyWithTooltip variant="body2" title={schedule.description} />

      {schedule.filter && (
        <ScheduleResourcesPreviewDrawer
          open={open}
          gracePeriodEnabled={!!schedule.grace_period}
          scheduleId={schedule.id}
          scheduleAction={schedule.action}
          onClose={closeDialog}
          regions={schedule.regions}
          filters={schedule.filter}
          accountIds={accountIds}
          status={schedule.status}
          name={schedule.name}
          editingUrl={editingUrl}
        >
          <ScheduleResourcesPreviewDrawerAppBar
            scheduleId={schedule.id}
            regions={schedule.regions}
            nextRuns={nextRuns}
            frequency={frequency}
            accountIds={accountIds}
            filters={schedule.filter}
            gracePeriodValue={gracePeriodValue}
          />
        </ScheduleResourcesPreviewDrawer>
      )}
    </Fragment>
  );
};
