import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { newCleanProcessSelector } from "../selectors/newCleanProcessSelector";
import {
  cloudChiprApi,
  PutUsersMeAccountsByAccountIdResourcesApiArg,
} from "../../../services/cloudchipr.api";
import {
  addCleanProcess,
  CleanResourceType,
  setCurrentCleanId,
  setNewClean,
} from "../cleanSlice";
import { cleanResourcesSelector } from "../selectors/cleanResourcesSelector";
import { resetSelectedResources } from "../../account/accountSlice";
import { getCurrentAccountResourceTypeDataThunk } from "../../account/thunks/filters-get/getCurrentAccountResourceTypeDataThunk";
import { getResourceCleanActionType } from "../utils/helpers";

export const cleanResourcesThunk = createAsyncThunk(
  "clean/cleanResources",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const newCleanProcess = newCleanProcessSelector(state);

    const resourcesForClean = cleanResourcesSelector(state);

    if (!newCleanProcess?.accountId || !resourcesForClean?.length) {
      return;
    }

    const cleanResourcesData = resourcesForClean.map((r) => {
      return {
        id: r.id,
        type: r.resource_type,
        action: getResourceCleanActionType(r.cleanAction),
        snapshot: r.cleanAction === "snapshot_and_delete",
        provider_unique_identifier: r.provider_unique_identifier,
      };
    }) as CleanResourceType[];

    const request: PutUsersMeAccountsByAccountIdResourcesApiArg = {
      accountId: newCleanProcess.accountId,
      body: cleanResourcesData,
    };

    const { putUsersMeAccountsByAccountIdResources } = cloudChiprApi.endpoints;

    const { execution_id } = await dispatch(
      putUsersMeAccountsByAccountIdResources.initiate(request),
    ).unwrap();

    if (execution_id) {
      const cleanProcess = {
        executionLogId: execution_id,
        cleanProcess: {
          ...newCleanProcess,
          resources: cleanResourcesData,
        },
      };

      dispatch(
        getCurrentAccountResourceTypeDataThunk(newCleanProcess.resourceType),
      );

      dispatch(addCleanProcess(cleanProcess));
      dispatch(setNewClean(null));
      dispatch(setCurrentCleanId(execution_id));
      dispatch(resetSelectedResources(newCleanProcess.resourceType));
    }
  },
);
