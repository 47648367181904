import { resourceExplorerViewsHierarchyDataSelector } from "./resourceExplorerViewsHierarchyDataSelector";
import {
  ReFolderV2,
  ResourceExplorerFolder,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { isResourceExplorerFolder } from "../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";
import { resourceExplorerHierarchyVisibilityFoldersSelector } from "../views-hierarchy-visibility/resourceExplorerHierarchyVisibilityFoldersSelector";
import UnleashClient from "../../../../services/UnleashClient";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";

export const resourceExplorerViewsHierarchyFoldersSelector = (
  state: RootState,
  visibility: NavigationItemsVisibilityType,
): ResourceExplorerFolder[] | ReFolderV2[] | undefined => {
  const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
    "EnableNavigationHierarchyVisibility",
  );

  if (enableNavigationHierarchyVisibility) {
    return resourceExplorerHierarchyVisibilityFoldersSelector(
      state,
      visibility,
    );
  }
  const hierarchy = resourceExplorerViewsHierarchyDataSelector(state);

  if (!hierarchy) {
    return;
  }
  return hierarchy.reduce((result, item) => {
    if (isResourceExplorerFolder(item)) {
      result.push(item);
    }
    return result;
  }, [] as ResourceExplorerFolder[]);
};
