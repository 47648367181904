import { FC, useCallback } from "react";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DashboardDeleteActionItemDialog } from "./DashboardDeleteActionItemDialog";
import { DashboardRenameActionItemDialog } from "./DashboardRenameActionItemDialog";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";

interface DashboardListItemActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
}

export const DashboardListItemActionsMenu: FC<
  DashboardListItemActionsMenuProps
> = ({ name, id, open, anchor, closeMenu }) => {
  const {
    open: removeDashboardDialogOpen,
    openDialog: openRemoveDashboardDialog,
    closeDialog: closeRemoveDashboardDialog,
  } = useDialog();

  const {
    open: renameDashboardDialogOpen,
    openDialog: openRenameDashboardDialog,
    closeDialog: closeRenameDashboardDialog,
  } = useDialog();

  const eventStopHandler = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const removeDialogCloseHandler = useCallback(() => {
    closeMenu();
    closeRemoveDashboardDialog();
    closeRenameDashboardDialog();
  }, [closeMenu, closeRemoveDashboardDialog, closeRenameDashboardDialog]);

  const removeClickHandler = useCallback(() => {
    closeMenu();
    openRemoveDashboardDialog();
  }, [closeMenu, openRemoveDashboardDialog]);

  const renameClickHandler = useCallback(() => {
    openRenameDashboardDialog();
    closeMenu();
  }, [closeMenu, openRenameDashboardDialog]);

  return (
    <Box onClick={eventStopHandler}>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        disablePortal
        autoFocus={false}
      >
        <MenuItem onClick={renameClickHandler}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>

        <MenuItem onClick={removeClickHandler}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: "error" }}>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>

      {removeDashboardDialogOpen && (
        <DashboardDeleteActionItemDialog
          open
          id={id}
          name={name}
          closeDialog={removeDialogCloseHandler}
        />
      )}

      {renameDashboardDialogOpen && (
        <DashboardRenameActionItemDialog
          open
          id={id}
          name={name}
          closeDialog={removeDialogCloseHandler}
        />
      )}
    </Box>
  );
};
