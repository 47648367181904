import { FC, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerAppBarActions } from "./components/ResourceExplorerAppBarActions";
import { ResourceExplorerBillingStatuses } from "./components/resource-explorer-billing-statuses/ResourceExplorerBillingStatuses";
import { ResourceExplorerResetButton } from "./components/ResourceExplorerResetButton";
import { ResourceExplorerToolbar } from "./components/resource-explorer-toolbar/ResourceExplorerToolbar";
import { ResourceExplorerAppBarNavigation } from "./components/breadcrumb-navigation/ResourceExplorerAppBarNavigation";
import { ResourceExplorerFilters } from "../filters/ResourceExplorerFilters";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { ResourceExplorerCreateOrEditDialog } from "../../../../common/resource-explorer-create-or-edit-dialog/ResourceExplorerCreateOrEditDialog";
import { ResourceExplorerActionType } from "../../utils/types/types";
import { PageHeader } from "../../../common/PageHeader";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { ResourceExplorerCreateFolderOrItemDialogWrapper } from "../../../../navigation/components/resource-explorer-v2/components/common/ResourceExplorerCreateFolderOrItemDialogWrapper";

interface ResourceExplorerAppBarProps {
  viewId: string;
}

export const ResourceExplorerAppBar: FC<ResourceExplorerAppBarProps> = ({
  viewId,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );
  const [dialogType, setDialogType] = useState<
    ResourceExplorerActionType | undefined
  >();
  const widgetsGettingLoading = useAppSelector(
    resourceExplorerWidgetDataLoadingSelector,
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  const loading = widgetsGettingLoading || viewGettingLoading;

  const openDialogHandler = useCallback(
    (type: ResourceExplorerActionType) => {
      setDialogType(type);
      openDialog();
    },
    [openDialog, setDialogType],
  );

  const closeDialogHandler = useCallback(() => {
    setDialogType(undefined);
  }, [setDialogType]);

  return (
    <PageHeader
      sticky
      loading={loading}
      breadcrumbs={<ResourceExplorerAppBarNavigation viewId={viewId} />}
      actions={
        <Stack direction="row" spacing={2}>
          <ResourceExplorerBillingStatuses />

          <ResourceExplorerResetButton />

          <ResourceExplorerAppBarActions
            openDialog={openDialogHandler}
            viewId={viewId}
          />
        </Stack>
      }
      extra={
        <Stack spacing={1}>
          <ResourceExplorerFilters viewId={viewId} />
          <ResourceExplorerToolbar />

          {dialogType &&
            (enableNavigationHierarchyVisibility ? (
              <ResourceExplorerCreateFolderOrItemDialogWrapper
                type="item"
                title="Save as New View"
                onClose={closeDialogHandler}
                viewId={viewId}
              />
            ) : (
              <ResourceExplorerCreateOrEditDialog
                open={open}
                title="Save as New View"
                onClose={closeDialog}
                viewId={viewId}
                type={dialogType}
              />
            ))}
        </Stack>
      }
    />
  );
};
