import { RootState } from "../../../../../store";

import { reservationsDataForGranularSelector } from "../reservationsDataForGranularSelector";

export const reservationsCoverageAverageSelector = (
  state: RootState,
): number | null => {
  const data = reservationsDataForGranularSelector(state);

  return data?.totals?.average_coverage ?? null;
};
