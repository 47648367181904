import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";

export const uncoveredUsageColumn: ColumnSetupType<SavingsPlanCoverageDataWithId> =
  {
    accessorKey: "uncovered_usage",
    header: () => (
      <Typography
        align="right"
        width="100%"
        variant="body2"
        fontWeight="medium"
      >
        On-Demand Spend
      </Typography>
    ),
    meta: { headerTitle: "On-Demand Spend" },
    cell: ({ row }) => (
      <Typography align="right" pr={4} variant="body2">
        {money(row.original.uncovered_usage)}
      </Typography>
    ),
  };
