import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { setNavigationResourceExplorer } from "../../../navigation/navigationSlice";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { flattenHierarchyData } from "../../../navigation/utils/helpers/flattenHierarchyData";
import UnleashClient from "../../../../services/UnleashClient";
import { flattenResourceExplorerHierarchyData } from "../../selectors/views-hierarchy/utils/flattenResourceExplorerHierarchyData";

export const getResourceExplorerViewsHierarchyThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerViewsHierarchy",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentResourceExplorerViewsHierarchy } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerViewsHierarchy.initiate(
          {},
          { forceRefetch: true },
        ),
      ).unwrap();
      const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
        "EnableNavigationHierarchyVisibility",
      );

      if (response) {
        if (enableNavigationHierarchyVisibility) {
          dispatch(
            setNavigationResourceExplorer({
              data: flattenHierarchyData(response),
              type: "visible_to_everyone",
            }),
          );
        } else {
          dispatch(
            setNavigationResourceExplorer({
              data: flattenResourceExplorerHierarchyData(response),
              type: "visible_to_everyone",
            }),
          );
        }
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
