import { FC, useCallback } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { ResourceExplorerV2ViewFolderListItemActionsMenu } from "./ResourceExplorerV2ViewFolderListItemActionsMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { ResourceExplorerCreateFolderOrItemDialogWrapper } from "../common/ResourceExplorerCreateFolderOrItemDialogWrapper";

interface ResourceExplorerViewWithFolderActionButtonProps {
  id: string;
  name: string;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerV2ViewFolderActionButton: FC<
  ResourceExplorerViewWithFolderActionButtonProps
> = ({ id, name, visibility }) => {
  const { cannot } = useAppAbility();
  const cannotDeleteResourceExplorer = cannot("delete", "resource-explorer");
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const {
    open: openAdd,
    openDialog: openAddDialog,
    closeDialog: closeAddDialog,
  } = useDialog();

  const closeDialog = useCallback(() => {
    closeAddDialog();
    closeMenu();
  }, [closeMenu, closeAddDialog]);

  if (cannotDeleteResourceExplorer) {
    return null;
  }

  return (
    <Stack direction="row" spacing={0.5}>
      <Tooltip title="Add view" arrow placement="top">
        <IconButton size="small" onClick={openAddDialog}>
          <AddIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <ResourceExplorerV2ViewFolderListItemActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        visibility={visibility}
      />

      {openAdd && (
        <ResourceExplorerCreateFolderOrItemDialogWrapper
          onClose={closeDialog}
          type="item"
          folderId={id}
          visibility={visibility}
        />
      )}
    </Stack>
  );
};
