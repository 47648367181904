import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { SavingsPlanDrawerContentHeaderInfoCards } from "./SavingsPlanDrawerContentHeaderInfoCards";
import { SavingsPlanDrawerRecommendationsSection } from "./recommendations/SavingsPlanDrawerRecommendationsSection";
import { SavingsPlanDrawerChartHeader } from "./SavingsPlanDrawerChartHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { CommitmentsDrawerHeader } from "../../../../common/components/drawer/header/CommitmentsDrawerHeader";
import { selectedSavingsPlanURLSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/savings-plan/selectedSavingsPlanURLSelector";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";
import { CommitmentsDrawerContent } from "../../../../common/components/drawer/CommitmentsDrawerContent";
import { UtilizationAndCoverageDrawerChart } from "../common/UtilizationAndCoverageDrawerChart";
import { UtilizationAndCoverageDrawerDataGrid } from "../common/data-grid/UtilizationAndCoverageDrawerDataGrid";

interface SavingsPlanDrawerContentProps {
  onClose(): void;
}

export const SavingsPlanDrawerContent: FC<SavingsPlanDrawerContentProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const savingsPlanId = useAppSelector(
    commitmentsDetailsDrawerTargetIdSelector,
  );
  const savingsPlanUrl = useAppSelector(selectedSavingsPlanURLSelector);

  useDidMount(() => {
    dispatch(getUtilizationAndCoverageDetailsThunk());
  });

  return (
    <Fragment>
      <CommitmentsDrawerHeader
        title={savingsPlanId}
        onClose={onClose}
        url={savingsPlanUrl}
      >
        <SavingsPlanDrawerContentHeaderInfoCards />
      </CommitmentsDrawerHeader>

      <Box width="80dvw" bgcolor="grey.50" height="100%">
        <CommitmentsDrawerContent title="Utilization" icon="utilization">
          <UtilizationAndCoverageDrawerChart
            ChartHeader={SavingsPlanDrawerChartHeader}
          />

          <UtilizationAndCoverageDrawerDataGrid viewTab="commitment" />
        </CommitmentsDrawerContent>

        <SavingsPlanDrawerRecommendationsSection />
      </Box>
    </Fragment>
  );
};
