type TwinDataKeysPrefixes = {
  primary: string;
  secondary: string;
};

export const costByCategoryChartPrefixes: TwinDataKeysPrefixes = {
  primary: "Total Cost",
  secondary: "Filtered Resources",
};

export const multiStackChartPrefixSeparator =
  "__multiStackChartPrefixSeparator__";
