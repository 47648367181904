import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import {
  ResourceFiltersWithAction,
  ScheduleStatus,
} from "../../../../../../services/cloudchipr.api";
import { ScheduleResourcesPreviewDrawer } from "../../resources-preview/ScheduleResourcesPreviewDrawer";
import { ScheduleResourcesPreviewDrawerAppBar } from "../../resources-preview/components/app-bar/ScheduleResourcesPreviewDrawerAppBar";

interface SchedulePreviewFoundResourcesProps {
  count: number;
  accountIds: string[];
  regions: string[];
  drawerTitle: string;
  scheduleId: string;
  status: ScheduleStatus;
  gracePeriodValue?: number;
  nextRuns?: { title: string; date: string }[];
  frequency?: { title: string; label: string; tooltip: string }[];
  filters: ResourceFiltersWithAction[];
  scheduleAction: string | null;
}

export const SchedulePreviewFoundResources: FC<
  SchedulePreviewFoundResourcesProps
> = ({
  count,
  status,
  gracePeriodValue,
  drawerTitle,
  accountIds,
  regions,
  filters,
  nextRuns,
  frequency,
  scheduleAction,
  scheduleId,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  return (
    <Fragment>
      <Typography variant="subtitle2" ml={2} mb={1}>
        <Typography
          mr={1}
          component="span"
          fontWeight="bold"
          color="primary"
          fontSize="inherit"
          onClick={openDialog}
          sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
        >
          {count} resources
        </Typography>
        found based on filters.
      </Typography>

      <ScheduleResourcesPreviewDrawer
        open={open}
        gracePeriodEnabled={!!gracePeriodValue}
        scheduleId={scheduleId}
        scheduleAction={scheduleAction}
        onClose={closeDialog}
        regions={regions}
        filters={filters}
        accountIds={accountIds}
        status={status}
        name={drawerTitle}
      >
        <ScheduleResourcesPreviewDrawerAppBar
          regions={regions}
          scheduleId={scheduleId}
          nextRuns={nextRuns}
          accountIds={accountIds}
          frequency={frequency}
          filters={filters}
          gracePeriodValue={gracePeriodValue}
        />
      </ScheduleResourcesPreviewDrawer>
    </Fragment>
  );
};
