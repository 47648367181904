import { useState, useMemo, PropsWithChildren, useCallback } from "react";
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  ColumnOrderState,
  ColumnResizeMode,
  getExpandedRowModel,
  Table,
} from "@tanstack/react-table";
import { useEffectOnceWhen } from "rooks";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { validateDataGridPassedData } from "./utils/validations";
import { DataGridComponent } from "./DataGridComponent";
import { sortingFns } from "./utils/sorts/common";
import { filterFns } from "./utils/filters/common";
import { useDnDDataHook } from "./utils/hooks/useDnDData.hook";
import { Enableness, ConfigProps, StylesProps } from "./utils/types/prop-types";
import { defaultPageSize } from "./utils/constants";
import { TableOptionsType } from "./utils/types/types";
import { useRowSelection } from "./utils/hooks/useRowSelection.hook";
import { useColumnsSorting } from "./utils/hooks/useColumnsSorting.hook";
import { useColumnsVisibility } from "./utils/hooks/useColumnsVisibility.hook";
import { useColumnGlobalFilter } from "./utils/hooks/useColumnGlobalFilter.hook";
import { useRowExpanding } from "./utils/hooks/useRowExpanding.hook";
import { useDataGridContext } from "./DataGridProvider";
import { WithGetRowProps } from "./table-body/RowDnDWrapper";

export interface DataGridProps<T>
  extends ConfigProps,
    StylesProps,
    Enableness,
    WithGetRowProps {
  data: T[];
  includeHeader?: boolean;
  columns: ColumnDef<T>[];
  showTableBody?: boolean;
  columnResizeMode?: ColumnResizeMode;
  afterFirstRenderCallback?(table: Table<any>): void;
  onRowsDndChange?: (data: any, drop?: boolean) => void;
}
//todo: for row-selection and row-expanding DataGrid must be wrapped with DataGridProvider, better to check, if it's not wrapped and no need to wrap it outside so then wrap inside of DataGrid

export const DataGrid = <T extends object>({
  styles,
  toolbar,
  pagination,
  onRowsDndChange,
  columnResizeMode,
  getRowProps,
  data: initialData,
  columns: initialColumns,
  afterFirstRenderCallback,
  globalFilter: globalFilterConfig,
  rowSelection: rowSelectionConfig,
  rowExpanding: rowExpandingConfig,
  columnSorting: columnSortingConfig,
  columnVisibility: columnVisibilityConfig,
  includeHeader = true,
  enableSortingRemoval = false,
  showTableBody = true,
  enableRowDnD = false,
  enableColumnsDnD = false,
  enableColumnsFilter = false,
  enableStickyColumns = false,
  enableRowsVirtualization = false,
  enableUniqueValuesOnSearch = false,
  enableColumnVirtualization = false,
}: PropsWithChildren<DataGridProps<T>>) => {
  const { wrappedWithProvider } = useDataGridContext();

  const { data, reorderRow } = useDnDDataHook(
    initialData,
    enableColumnsDnD || enableRowDnD,
    onRowsDndChange,
  );
  const rowIds = useMemo(() => {
    return data.map((row) => row.id);
  }, [data]);
  const sensors = useSensors(useSensor(PointerSensor));

  const { columnVisibility, columnsVisibilityChangeHandler } =
    useColumnsVisibility(columnVisibilityConfig);
  const { columnSorting, columnsSortingChangeHandler } = useColumnsSorting(
    initialColumns,
    columnSortingConfig,
  );

  const { globalFilter, globalFilterChangeHandler } =
    useColumnGlobalFilter(globalFilterConfig);

  const { expanded, rowExpandChange } = useRowExpanding(
    data,
    rowExpandingConfig,
  );
  const { rowSelection, ...rowSelectionOptions } = useRowSelection(
    data,
    rowSelectionConfig,
  );

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    enableColumnsDnD ? initialColumns.map((column) => column.id as string) : [],
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const columns = useMemo(() => initialColumns, [initialColumns]);

  const tableOptions = useMemo(() => {
    return {
      data,
      columns,
      filterFns,
      sortingFns,
      columnResizeMode,
      initialState: {},
      enableSortingRemoval,
      state: {
        columnOrder,
        rowSelection,
        globalFilter,
        columnFilters,
        columnVisibility,
        sorting: columnSorting,
        expanded,
      },
      getCoreRowModel: getCoreRowModel(),
      onColumnOrderChange: setColumnOrder,
      onGlobalFilterChange: globalFilterChangeHandler,
      globalFilterFn: filterFns.globalFilter,
      onColumnFiltersChange: setColumnFilters,
      getSortedRowModel: getSortedRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      onSortingChange: columnsSortingChangeHandler,
      onColumnVisibilityChange: columnsVisibilityChangeHandler,
      onExpandedChange: rowExpandChange,
      getRowId: (row) => {
        return row.original?.id || row.id || Object.values(row).join("_");
      },
      ...rowSelectionOptions,
    } as TableOptionsType;
  }, [
    data,
    expanded,
    columns,
    columnOrder,
    globalFilter,
    rowSelection,
    columnSorting,
    columnFilters,
    columnVisibility,
    columnResizeMode,
    rowSelectionOptions,
    columnsSortingChangeHandler,
    columnsVisibilityChangeHandler,
    globalFilterChangeHandler,
    rowExpandChange,
    enableSortingRemoval,
  ]);

  if (pagination) {
    const pageSize =
      typeof pagination !== "boolean" ? pagination?.pageSize : defaultPageSize;

    tableOptions.getPaginationRowModel = getPaginationRowModel();
    tableOptions.initialState.pagination = {
      pageSize: pageSize || defaultPageSize,
      pageIndex: 0,
    };
  }

  if (rowExpandingConfig) {
    tableOptions.getRowCanExpand = rowExpandingConfig?.getCanRowExpand
      ? rowExpandingConfig?.getCanRowExpand
      : () => true;
    tableOptions.getExpandedRowModel = getExpandedRowModel();
  }

  if (enableUniqueValuesOnSearch) {
    tableOptions.getFacetedUniqueValues = getFacetedUniqueValues();
  }

  const table = useReactTable(tableOptions);

  validateDataGridPassedData({
    columns: initialColumns,
    table,
    enableColumnsDnD,
    enableColumnsFilter,
    enableColumnVisibility: !!columnVisibility,
    wrappedWithProvider,
    enableExpandingRows: !!rowExpandingConfig,
    enableRowSelection: !!rowSelectionConfig,
  });

  const removeColumnSortingOnDragStart = useCallback(() => {
    columnsSortingChangeHandler?.([]);
  }, [columnsSortingChangeHandler]);

  useEffectOnceWhen(() => {
    afterFirstRenderCallback?.(table);
  }, !!afterFirstRenderCallback);

  const dataGrid = (
    <DataGridComponent
      table={table}
      enableColumnVirtualization={enableColumnVirtualization}
      styles={styles}
      getRowProps={getRowProps}
      enableRowDnD={enableRowDnD}
      enableSortingRemoval={enableSortingRemoval}
      globalFilter={globalFilter}
      showTableBody={showTableBody}
      setGlobalFilter={globalFilterChangeHandler}
      pagination={pagination}
      enableColumnsDnD={enableColumnsDnD}
      columnResizeMode={columnResizeMode}
      enableColumnsSort={!!columnSorting}
      enableGlobalFilter={!!globalFilterConfig}
      enableStickyColumns={enableStickyColumns}
      enableColumnsFilter={enableColumnsFilter}
      rowExpandingConfig={rowExpandingConfig}
      enableColumnVisibility={!!columnVisibility}
      enableRowsVirtualization={enableRowsVirtualization}
      toolbar={toolbar}
      includeHeader={includeHeader}
    />
  );

  if (enableColumnsDnD || enableRowDnD) {
    return (
      <DndContext
        sensors={sensors}
        onDragEnd={reorderRow}
        collisionDetection={closestCenter}
        onDragStart={removeColumnSortingOnDragStart}
      >
        <SortableContext items={rowIds} strategy={verticalListSortingStrategy}>
          {dataGrid}
        </SortableContext>
      </DndContext>
    );
  }

  return dataGrid;
};
