import { FC } from "react";
import { TextField } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const FilterKeySelectTriggerComponent: FC<
  FilterTriggerComponentProps
> = ({ selectedValues, onClick, error, disabled, open }) => {
  return (
    <TextField
      fullWidth
      label="Key"
      size="xsmall"
      focused={false}
      error={!!error}
      disabled={disabled}
      onClick={onClick}
      value={selectedValues.at(0)}
      InputProps={{
        readOnly: true,
        endAdornment: open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
        ),
      }}
    />
  );
};
