import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";

export const alertsCreatedByFilter = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const createdBy = row?.original?.createdByEmail?.toLowerCase();
  const filterValue = keyword.toLowerCase().trim();

  if (!createdBy) {
    return false;
  }

  return createdBy.includes(filterValue);
};
