import { resourceExplorerIdSelector } from "../resourceExplorerIdSelector";
import { resourceExplorerViewsHierarchyDataSelector } from "../../views-hierarchy/resourceExplorerViewsHierarchyDataSelector";
import { resourceExplorerHierarchyVisibilitiesDataFoldersSelector } from "../../views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataFoldersSelector";
import { RootState } from "../../../../store";
import { isResourceExplorerFolder } from "../../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";
import UnleashClient from "../../../../../services/UnleashClient";

export const currentResourceExplorerFolderSelector = (state: RootState) => {
  const viewId = resourceExplorerIdSelector(state);
  const hierarchy = resourceExplorerViewsHierarchyDataSelector(state);

  const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
    "EnableNavigationHierarchyVisibility",
  );
  if (enableNavigationHierarchyVisibility) {
    const folders =
      resourceExplorerHierarchyVisibilitiesDataFoldersSelector(state);

    return folders?.find((item) => {
      return item.items?.some((view) => view.id === viewId);
    });
  }

  return hierarchy?.find((item) => {
    if (isResourceExplorerFolder(item)) {
      return item.views?.some((view) => view.id === viewId);
    }
  });
};
