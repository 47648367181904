import { FC, useCallback } from "react";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { useFlag } from "@unleash/proxy-client-react";
import {
  workflowDrawerHeaderId,
  workflowDrawerWrapperId,
} from "./constnats/common";
import { CreateWorkflowSubmittedMessage } from "./CreateWorkflowSubmittedMessage";
import { ScheduledJobsForm } from "../../../schedule/classic/components/create/ScheduledJobsForm";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { workflowCreationDialogOpenSelector } from "../../../../../store/schedules/selectors/workflowCreationDialogOpenSelector";
import { automationInDrawerOpenSelector } from "../../../../../store/automations/selectros/common/automationInDrawerOpenSelector";
import { WorkflowForm } from "../single-workflow/WorkflowForm";
import { resetAutomationsState } from "../../../../../store/automations/automationsSlice";

export const CreateWorkflowDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const enableNewAutomations = useFlag("EnableWorkflowAutomationSinglePage");

  const automationInDrawerOpen = useAppSelector(automationInDrawerOpenSelector);
  const workflowCreationDialogOpen = useAppSelector(
    workflowCreationDialogOpenSelector,
  );
  const open = enableNewAutomations
    ? automationInDrawerOpen
    : workflowCreationDialogOpen;

  const closeHandler = useCallback(() => {
    dispatch(resetAutomationsState());
  }, [dispatch]);

  const afterSubmitHandler = useCallback(
    (id: string, name: string) => {
      closeHandler();
      enqueueSnackbar("", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
          action: <CreateWorkflowSubmittedMessage id={id} name={name} />,
        },
      });
    },
    [enqueueSnackbar, closeHandler],
  );

  return (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={closeHandler}
      PaperProps={{
        sx: { height: "94vh", borderRadius: 3 },
        id: workflowDrawerWrapperId,
      }}
    >
      <Stack
        px={2}
        py={1}
        top={0}
        direction="row"
        bgcolor="white"
        borderBottom={1}
        position="sticky"
        alignItems="center"
        borderColor="grey.300"
        justifyContent="space-between"
        zIndex={(theme) => theme.zIndex.appBar + 1}
      >
        <Typography variant="h6">Schedule a Workflow</Typography>

        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <div id={workflowDrawerHeaderId} />

      <Box mb={4}>
        {enableNewAutomations ? (
          <WorkflowForm />
        ) : (
          <ScheduledJobsForm inDrawer onAfterSubmit={afterSubmitHandler} />
        )}
      </Box>
    </Drawer>
  );
};
