import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardContent, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffectOnceWhen } from "rooks";
import { ScheduleComponent } from "./scheduler-step/ScheduleComponent";
import { ScheduledJobsPreview } from "./preview/ScheduledJobsPreview";
import { ScheduledJobsActionsStep } from "./actions-step/ScheduledJobsActionsStep";
import {
  ProviderType,
  Schedule,
  ScheduleActionType,
  useGetProvidersByProviderRegionsQuery,
  useGetV2UsersMeSchedulesByScheduleIdQuery,
  usePatchV2UsersMeSchedulesByScheduleIdMutation,
  usePostV2UsersMeSchedulesMutation,
} from "../../../../../../services/cloudchipr.api";
import { SchedulesResourcesList } from "../../../common/create/resources/SchedulesResourcesList";
import { automationValidationSchema } from "../../../common/utils/constants/validation";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";
import {
  ScheduleEnvironment,
  ScheduleEnvironmentType,
} from "../../../common/create/ScheduleEnvironment";
import { NextPrevious } from "../../../common/create/NextPrevious";
import { ScheduleStepper } from "../../../common/create/ScheduleStepper";
import { workflowDefaultProviderSelector } from "../../../../../../store/schedules/selectors/workflowDefaultProviderSelector";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  generateWorkflowsSteps,
  generateWorkflowEditingData,
  getWorkflowCreatePayloadData,
  getWorkflowUpdatePayloadData,
} from "../../utils/helpers/helpers";
import {
  WorkflowSchedulerData,
  WorkflowFormData,
} from "../../utils/types/types";
import { currentWorkflowSelector } from "../../../../../../store/schedules/selectors/currentWorkflowSelector";
import { getCronFrequency } from "../../../../../../store/automations/utils/helpers/cron/getCronFrequency";
import { getWorkflowsThunk } from "../../../../../../store/automations/thunks/wokrflows/getWorkflowsThunk";
import { schedulesLoadingSelector } from "../../../../../../store/schedules/selectors/schedule/schedulesLoadingSelector";

const initialValues: WorkflowFormData = {
  name: "",
  action: "" as ScheduleActionType,
  regions: [],
  account_ids: [],
  filter: [],
  cron: null,
  start_date_time: "",
  end_date_time: null,
  provider: "" as ProviderType,
  cloud_provider: "" as ProviderType,
  min_threshold: null,
  is_snoozed: true,
  emails: null,
  weekly_schedule_interval: null,

  id: "",
  description: "",
  created_at: "",
  time_zone: getTimeZoneObject().tzCode,
  status: "inactive",
  metadata: { state: "normal", next_run_at: "", future_run_dates: [] },
  daily_schedule_interval: null,
};

const validationSchema = automationValidationSchema;

interface ScheduledJobsFormProps {
  schedule?: Schedule;
  // on schedules refactoring remove this, and make responsive schedule component
  inDrawer?: boolean;
  onAfterSubmit?(id: string, name: string): void;
}

export const ScheduledJobsForm: FC<ScheduledJobsFormProps> = ({
  schedule,
  inDrawer,
  onAfterSubmit,
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const defaultProvider = useAppSelector(workflowDefaultProviderSelector);
  const workflowFromState = useAppSelector(currentWorkflowSelector);
  const listLoading = useAppSelector(schedulesLoadingSelector);

  const [activeStep, setActiveStep] = useState(workflowFromState ? 1 : 0);

  const { data: awsRegions } = useGetProvidersByProviderRegionsQuery({
    provider: "aws",
  });
  const { data: gcpRegions } = useGetProvidersByProviderRegionsQuery({
    provider: "gcp",
  });
  const { data: azureRegions } = useGetProvidersByProviderRegionsQuery({
    provider: "azure",
  });

  const [createSchedule, { isLoading: isCreating }] =
    usePostV2UsersMeSchedulesMutation();
  const [updateSchedule, { isLoading: isUpdating }] =
    usePatchV2UsersMeSchedulesByScheduleIdMutation();
  const { refetch } = useGetV2UsersMeSchedulesByScheduleIdQuery(
    { scheduleId: schedule?.id || "" },
    { skip: !schedule?.id },
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        let response;

        if (values.id) {
          response = await updateSchedule(
            getWorkflowUpdatePayloadData(values),
          ).unwrap();
        } else {
          response = await createSchedule(
            getWorkflowCreatePayloadData(values),
          ).unwrap();
        }

        await dispatch(getWorkflowsThunk(true));

        if (onAfterSubmit) {
          onAfterSubmit(response.id, response.name);
          return;
        }

        if (values.id) {
          refetch();
        }

        !inDrawer && routerBackHandler();

        enqueueSnackbar(
          `Workflow successfully ${values.id ? "updated" : "created"}.`,
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: { severity: "success" },
          },
        );
      } catch (e) {
        enqueueSnackbar("Something went wrong", {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "error" },
        });
      }
    },
  });

  const {
    values,
    touched,
    submitForm,
    setFieldValue,
    errors,
    setValues,
    resetForm,
    dirty,
  } = formik;

  const routerBackHandler = useCallback(
    () => navigate("/schedule/classic"),
    [navigate],
  );

  const steps = useMemo(() => {
    return generateWorkflowsSteps(values);
  }, [values]);

  const nextStepHandler = useCallback(() => {
    setActiveStep((step) => step + 1);
  }, []);
  const previousStepHandler = useCallback(() => {
    setActiveStep((step) => step - 1);
  }, []);

  const setScheduleDatesData = useCallback(
    ({
      cron,
      endDate,
      startDate,
      timeZone,
      weeklyInterval,
      dailyScheduleInterval,
    }: WorkflowSchedulerData) => {
      setFieldValue("cron", cron);
      setFieldValue("end_date_time", endDate);
      setFieldValue("start_date_time", startDate);
      setFieldValue("time_zone", timeZone);

      if (weeklyInterval) {
        setFieldValue("weekly_schedule_interval", weeklyInterval);
      }
      if (dailyScheduleInterval && getCronFrequency(cron) === "daily") {
        setFieldValue("daily_schedule_interval", dailyScheduleInterval);
      }

      nextStepHandler();
    },
    [setFieldValue, nextStepHandler],
  );

  const setEnvironmentHandler = useCallback(
    (data: ScheduleEnvironmentType) => {
      setValues((values) => ({ ...values, ...data }));
      nextStepHandler();
    },
    [setValues, nextStepHandler],
  );

  useEffectOnceWhen(
    () => {
      if (!defaultProvider) {
        return;
      }

      const regions = {
        aws: awsRegions,
        gcp: gcpRegions,
        azure: azureRegions,
      };

      resetForm({
        values: {
          ...initialValues,
          regions: regions[defaultProvider]?.map((region) => region.key) || [],
          provider: defaultProvider,
        },
      });
    },
    !!defaultProvider &&
      !!awsRegions &&
      !!azureRegions &&
      !!gcpRegions &&
      !schedule &&
      !workflowFromState,
  );

  useEffectOnceWhen(() => {
    if (workflowFromState) {
      resetForm({ values: generateWorkflowEditingData(workflowFromState) });
    }
  }, !!workflowFromState);

  useEffect(() => {
    if (schedule) {
      const values = generateWorkflowEditingData(schedule);
      resetForm({ values });
    }
  }, [schedule, resetForm]);

  useEffectOnceWhen(() => {
    if (
      schedule?.accounts?.every((account) => account.status !== "connected")
    ) {
      routerBackHandler();
    }
  }, !!schedule);

  if ((schedule?.id && !values.id) || !values.provider) {
    return null;
  }

  const scheduleForm = (
    <Fragment>
      <ScheduleStepper
        steps={steps}
        inDrawer={inDrawer}
        activeStep={activeStep}
        changeStep={setActiveStep}
      />

      <Container maxWidth="md">
        <Fragment>
          {activeStep === 0 && (
            <ScheduleEnvironment
              {...values}
              parentScheduleType="workflow"
              onFieldChange={setFieldValue}
              onNext={setEnvironmentHandler}
              hideCancelButton={inDrawer}
              providerChangeDisabled={!!workflowFromState}
            />
          )}

          {activeStep === 1 && (
            <Fragment>
              <SchedulesResourcesList
                gracePeriodEnabled={!!values.grace_period}
                scheduleId={values.id}
                scheduleAction={values.action}
                resources={values.filter}
                parentScheduleType="workflow"
                setFieldValue={setFieldValue}
                disabled={!values.regions.length}
                provider={values.provider}
                accountIds={values.account_ids}
                errors={touched.filter && errors.filter}
                regions={values.regions}
              />
              <NextPrevious
                onNext={nextStepHandler}
                onPrevious={previousStepHandler}
                hideCancelButton={!!inDrawer}
                isNextDisabled={!steps[activeStep].completed}
                isDirty={dirty}
              />
            </Fragment>
          )}

          {activeStep === 2 && (
            <ScheduledJobsActionsStep
              provider={values.provider}
              emails={values.emails}
              accountIds={values.account_ids}
              notifications={values.notifications}
              resourceFilters={values.filter}
              action={values.action}
              onNext={nextStepHandler}
              onFieldChange={setFieldValue}
              onPrevious={previousStepHandler}
              isDirty={dirty}
              snoozed={!!values.is_snoozed}
              hideCancelButton={!!inDrawer}
              minThreshold={values.min_threshold}
              gracePeriod={values.grace_period}
            />
          )}

          {activeStep === 3 && (
            <ScheduleComponent
              id={values.id}
              weeklyInterval={values.weekly_schedule_interval}
              period={values.grace_period?.period}
              cron={values.cron}
              timeZone={values.time_zone}
              endDate={values.end_date_time}
              onPreviousStep={previousStepHandler}
              startDate={values.start_date_time || null}
              setScheduleDatesData={setScheduleDatesData}
              hideCancelButton={inDrawer}
              isDirty={dirty}
            />
          )}

          {activeStep === 4 && (
            <ScheduledJobsPreview
              {...values}
              isLoading={isCreating || isUpdating || listLoading}
              onSubmit={submitForm}
              onPrevious={previousStepHandler}
              hideCancelButton={inDrawer}
              onFieldChange={setFieldValue}
            />
          )}
        </Fragment>
      </Container>
    </Fragment>
  );

  if (inDrawer) {
    return scheduleForm;
  }

  return (
    <Container maxWidth="md" sx={{ p: 2 }} disableGutters>
      <Card variant="outlined">
        <CardContent>{scheduleForm}</CardContent>
      </Card>
    </Container>
  );
};
