import { FC, memo, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffectOnceWhen } from "rooks";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useResourceExplorerPreferences } from "../../../../utils/hooks/useResourceExplorerPreferences.hook";
import { MultiTypeChart } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { moneyFormatter } from "../../../../../../../utils/numeral/moneyFormatter";
import { budgetDataOnChartSelector } from "../../../../../../../store/resource-explorer/selectors/budget/budgetDataOnChartSelector";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { resourceExplorerDataPointSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataPointSelector";
import { resourceExplorerShowBudgetLineSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerShowBudgetLineSelector";
import { useMultiTypeChartContext } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { urlToObject } from "../../../../../../utils/helpers/dataToUrlToDataConvertors";
import { resourceExplorerGroupingSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { ZoomChangeFunction } from "../../../../../../../storybook/charts/multi-type-chart/utils/hooks/useChartZoom.hook";
import { changeResourceExplorerDateThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer/changeResourceExplorerDateThunk";
import { ChartType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { resourceExplorerPossibleChartTypes } from "../../../../../../../store/resource-explorer/utils/types/resourceExplorer";

interface ResourceExplorerChartProps {
  viewId: string;
}

export const ResourceExplorerChart: FC<ResourceExplorerChartProps> = memo(
  ({ viewId }) => {
    const dispatch = useAppDispatch();
    const budgetsEnabled = useFlag("EnableResourcesExplorerBudgets");
    const [searchParams] = useSearchParams();

    const budgetLineData = useAppSelector(budgetDataOnChartSelector);
    const showBudgetLine = useAppSelector(
      resourceExplorerShowBudgetLineSelector,
    );
    const loading = useAppSelector(resourceExplorerWidgetDataLoadingSelector);
    const dataPoint = useAppSelector(resourceExplorerDataPointSelector);
    const grouping = useAppSelector(resourceExplorerGroupingSelector);

    const { changeChartPreferences, chartPreferences } =
      useResourceExplorerPreferences(viewId);
    const { chartType, zoomAvailable } = useMultiTypeChartContext();

    const referenceLines = useMemo(() => {
      return budgetLineData && showBudgetLine && budgetsEnabled
        ? budgetLineData
        : [];
    }, [budgetLineData, showBudgetLine, budgetsEnabled]);

    const yAxisFormatter = useCallback((value: number | string) => {
      return moneyFormatter(+value);
    }, []);

    const chartZoomHandler = useCallback<ZoomChangeFunction>(
      (start, end) => {
        const dateTo = formatDate(moment(end), {
          type: "yearMonthDay",
        });
        const dateFrom = formatDate(moment(start), {
          type: "yearMonthDay",
        });

        if (!dateTo || !dateFrom) {
          return;
        }

        dispatch(
          changeResourceExplorerDateThunk({
            label: null,
            from: dateFrom,
            to: dateTo,
          }),
        );
      },
      [dispatch],
    );

    const path = searchParams.get("path") as string;
    const chartTypeFromPath = urlToObject(path)?.at(-1)?.chartType;

    const chartTypeChangeHandler = useCallback(
      (type: ChartType) => {
        changeChartPreferences("type", type);
      },
      [changeChartPreferences],
    );

    const isPathChartTypeCorrect = resourceExplorerPossibleChartTypes.includes(
      chartTypeFromPath ?? "",
    );

    useEffectOnceWhen(() => {
      chartType?.onChange(chartTypeFromPath);
    }, !!chartTypeFromPath && isPathChartTypeCorrect);

    useEffectOnceWhen(
      () => {
        chartType?.onChange(chartPreferences.type);
      },
      !!chartPreferences?.type &&
        (!chartTypeFromPath || !isPathChartTypeCorrect),
    );

    return (
      <Box mt={3}>
        <MultiTypeChart
          zoom={zoomAvailable}
          highlight
          selectable
          loading={loading}
          showLoader={false}
          selectionType="select"
          tooltipProps={{
            showTotal: grouping !== "none",
            showReferenceLinesInTooltip: true,
            tooltipLabelFormatter:
              dataPoint === "daily"
                ? (label: string) => formatDate(label, { type: "weekDayDate" })
                : undefined,
          }}
          referenceLines={referenceLines}
          yAxisFormatter={yAxisFormatter}
          xAxisTickProps={budgetLineData ? xAxisTickProps : undefined}
          onChartTypeChange={chartTypeChangeHandler}
          availableTypes={resourceExplorerPossibleChartTypes}
          emptyText="No data by your selected date range and filters."
          onZoomChange={chartZoomHandler}
        />
      </Box>
    );
  },
);

const xAxisTickProps = { parentPadding: { left: 50 } };
