import { FC, useCallback, useEffect } from "react";
import { Box, FormHelperText, IconButton, Stack } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AutomationResourceItem } from "./AutomationResourceItem";
import { AutomationResourceFilters } from "./resource-filters/AutomationResourceFilters";
import { EmptyFilterAlert } from "./EmptyFilterAlert";
import { AutomationResourceActions } from "./resource-actions/AutomationResourceActions";
import { ResourceFiltersWithAction } from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { automationAccountIdsSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { setAutomationData } from "../../../../../../../../store/automations/automationsSlice";
import { getScheduleSelectedResourcesThunk } from "../../../../../../../../store/schedules/thunks/getScheduleSelectedResourcesThunk";
import { ScheduleFoundResources } from "../../../../../../schedule/common/create/resources/ScheduleFoundResources";
import { automationRegionsSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationRegionsSelector";
import { workflowActionSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowActionSelector";
import { workflowGracePeriodSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowGracePeriodSelector";
import { automationIdSelector } from "../../../../../../../../store/automations/selectros/common/automationIdSelector";
import { automationTypeSelector } from "../../../../../../../../store/automations/selectros/common/automationTypeSelector";
import { automationResourcesSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";

interface AutomationResourceItemProps {
  resourceFilters: ResourceFiltersWithAction;
  index: number;
  error: string;
}

export const AutomationResource: FC<AutomationResourceItemProps> = ({
  resourceFilters,
  index,
  error,
}) => {
  const dispatch = useAppDispatch();

  const accountIds = useAppSelector(automationAccountIdsSelector);
  const regions = useAppSelector(automationRegionsSelector);
  const automationType = useAppSelector(automationTypeSelector);
  const workflowAction = useAppSelector(workflowActionSelector);
  const resources = useAppSelector(automationResourcesSelector);

  const automationId = useAppSelector(automationIdSelector) ?? "";

  const gracePeriodEnabled = !!useAppSelector(workflowGracePeriodSelector)
    ?.period;
  const resourceType = resourceFilters.filter.type;

  const filterChangeHandler = useCallback(
    (filter: ResourceFiltersWithAction[]) => {
      dispatch(setAutomationData({ filter }));
    },
    [dispatch],
  );

  const removeResource = useCallback(() => {
    filterChangeHandler(resources.filter((_, i) => i !== index));
  }, [index, resources, filterChangeHandler]);

  useEffect(() => {
    dispatch(
      getScheduleSelectedResourcesThunk({
        regions,
        scheduleId: automationId,
        accountIds,
        resourceFilters: resourceFilters.filter,
      }),
    );
  }, [accountIds, regions, automationId, resourceFilters.filter, dispatch]);

  return (
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <Box position="relative" flex={1}>
        <AutomationResourceItem
          index={index}
          onChange={filterChangeHandler}
          filters={resourceFilters.filter}
        />

        <Stack
          mt={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack alignItems="start">
            <AutomationResourceFilters
              index={index}
              onChange={filterChangeHandler}
              resourceFilters={resourceFilters}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
          </Stack>

          <ScheduleFoundResources
            regions={regions}
            scheduleId={automationId}
            accountIds={accountIds}
            resourceType={resourceType}
            scheduleAction={workflowAction}
            resourceFilters={resourceFilters}
            gracePeriodEnabled={gracePeriodEnabled}
          />
        </Stack>

        <EmptyFilterAlert
          show={!resourceFilters?.filter?.filter_items?.length}
        />

        {automationType !== "offHours" && workflowAction?.includes("clean") && (
          <AutomationResourceActions resourceFilters={resourceFilters} />
        )}
      </Box>

      {resources.length > 1 && (
        <IconButton onClick={removeResource}>
          <DeleteOutlineIcon />
        </IconButton>
      )}
    </Stack>
  );
};
