import { FC, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Theme } from "@mui/material";

import { getCostBreakdownV2WidgetTableViewColumns } from "./utils/helpers/getCostBreakdownV2WidgetTableViewColumns";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../storybook/data-grid/DataGrid";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { costBreakdownV2WidgetTableViewDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetTableViewDataSelector";
import { costBreakdownV2WidgetForecastOptionSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetForecastOptionSelector";
import { costBreakdownV2WidgetGroupingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetGroupingSelector";

interface CostBreakdownWidgetTableViewProps {
  widgetId: string;
}

export const CostBreakdownV2WidgetTableView: FC<
  CostBreakdownWidgetTableViewProps
> = ({ widgetId }) => {
  const gridData =
    useAppSelector((state) =>
      costBreakdownV2WidgetTableViewDataSelector(state, widgetId),
    ) ?? [];
  const grouping = useAppSelector((state) =>
    costBreakdownV2WidgetGroupingSelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    costBreakdownV2WidgetForecastOptionSelector(state, widgetId),
  );

  const columns = useMemo(() => {
    return getCostBreakdownV2WidgetTableViewColumns({
      widgetId,
      forecastOption,
      grouping,
    });
  }, [widgetId, forecastOption, grouping]);

  return (
    <DataGrid
      styles={styles}
      columns={columns}
      data={gridData ?? []}
      enableRowsVirtualization={gridData.length > 30}
      columnResizeMode="onEnd"
    />
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], py: 0.5, pl: 2 },
  },

  tableHeaderRow: {
    "& th": {
      p: 1,
      verticalAlign: "inherit",
      bgcolor: grey[100],
    },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    height: 40,
  },

  tableContainer: {
    border: 1,
    borderColor: grey[200],
    maxHeight: `${widgetSetupContentChartHeight}px`,
  },
};
