import {
  CostAnomalyThresholdDataType,
  CostAnomalyThresholdType,
} from "../types/types";
import { CostAnomalyAlertTimeIntervalUnit } from "../../../../../../../../../store/alerts/utils/types/types";

export const costsAnomalyThresholdDataByType = new Map<
  CostAnomalyThresholdType,
  CostAnomalyThresholdDataType
>([
  [
    "percent",
    {
      title: "Min percentage change",
      unit: "%",
      tooltipTitle:
        "Only trigger alerts for cost changes greater than this percentage.",
    },
  ],
  [
    "cost",
    {
      title: "Min cost change",
      unit: "$",
      tooltipTitle:
        "Only trigger alerts for cost changes greater than this amount.",
    },
  ],
]);

export const intervalLimitByIntervalUnit = new Map<
  CostAnomalyAlertTimeIntervalUnit,
  number
>([
  ["days", 365],
  ["weeks", 52],
  ["months", 12],
]);
