import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { selectedViewSelector } from "./selectedViewSelector";
import { getResourceExplorerMutatedFilters } from "../../../../resource-explorer/utils/helpers/getResourceExplorerMutatedFilters";
import { resourceExplorerAllPossibleInitialFiltersSelector } from "../../../../resource-explorer/selectors/filters/possible-filters/resourceExplorerAllPossibleInitialFiltersSelector";
import { mergeREViewAndEnabledProviders } from "../../../../resource-explorer/utils/helpers/mergeREViewAndEnabledProviders";
import { enabledProvidersByBillingStatusSelector } from "../../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

export const viewChargeTypesFilterSelector = createDraftSafeSelector(
  [
    selectedViewSelector,
    enabledProvidersByBillingStatusSelector,
    resourceExplorerAllPossibleInitialFiltersSelector,
  ],
  (view, enabledProviders, possibleFilters) => {
    if (!view) {
      return;
    }

    const cloudProviders = mergeREViewAndEnabledProviders(
      enabledProviders,
      view.cloud_providers ?? [],
      view.is_default,
    );

    const filters = getResourceExplorerMutatedFilters(
      view.filters,
      possibleFilters,
      cloudProviders,
      Array.from(enabledProviders),
    );

    return {
      aws: filters?.aws?.charge_type?.value as string[],
      azure: filters?.azure?.charge_type?.value as string[],
    };
  },
);
