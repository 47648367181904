import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { createResourceExplorerVisibilityHierarchyItemThunk } from "./createResourceExplorerVisibilityHierarchyItemThunk";
import { updateResourceExplorerVisibilityHierarchyThunk } from "./updateResourceExplorerVisibilityHierarchyThunk";
import { PostV2UsersMeOrganisationsCurrentDashboardsApiArg } from "../../../../services/cloudchipr.api";

interface CreateResourceExplorerVisibilityHierarchyItemByFolderIdThunkArg {
  name: string;
  folderId: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
}

export const createResourceExplorerVisibilityHierarchyItemByFolderIdThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerVisibilityHierarchyItemByFolderId",
    async (
      {
        name,
        folderId,
        visibility,
      }: CreateResourceExplorerVisibilityHierarchyItemByFolderIdThunkArg,
      { dispatch },
    ) => {
      try {
        const response = await dispatch(
          createResourceExplorerVisibilityHierarchyItemThunk({
            name,
            visibility,
          }),
        ).unwrap();

        if (!response?.id) {
          return;
        }

        await dispatch(
          updateResourceExplorerVisibilityHierarchyThunk({
            folderId,
            id: response.id,
            visibility,
          }),
        );

        return response;
      } catch (e) {
        // @ts-expect-error TODO: return to this after adding error type
        const errMessage = e?.data?.message || "Something went wrong";
        const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
            onClose: () => closeSnackbar(snackbarId),
          },
        });
      }
    },
  );
