import { RootState } from "../../../../../store";

import { savingsPlansDataForGranularSelector } from "../savingsPlansDataForGranularSelector";

export const savingsPlansCoverageAverageSelector = (
  state: RootState,
): number | null => {
  const data = savingsPlansDataForGranularSelector(state);

  return data?.totals?.average_coverage ?? null;
};
