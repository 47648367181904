import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getWorkflowByIdThunk = createAsyncThunk(
  "automation/getWorkflowById",
  async (scheduleId: string, { dispatch }) => {
    const { getV2UsersMeSchedulesByScheduleId } = cloudChiprApi.endpoints;

    return await dispatch(
      getV2UsersMeSchedulesByScheduleId.initiate(
        { scheduleId },
        { forceRefetch: true },
      ),
    ).unwrap();
  },
);
