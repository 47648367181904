import { FC, useCallback } from "react";
import { FilterSelect } from "./FilterSelect";
import { setCommitmentsRecommendationsPaymentOption } from "../../../../../../../../store/commitments/commitmentsSlice";
import { CommitmentPaymentOption } from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { commitmentsPaymentOptionLabels } from "../../../../../common/utils/constants/labels";
import { commitmentsRecommendationsPaymentOptionSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsPaymentOptionSelector";

export const PaymentOptionsFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(commitmentsRecommendationsPaymentOptionSelector);

  const changeHandler = useCallback(
    (option: string[]) => {
      dispatch(
        setCommitmentsRecommendationsPaymentOption(
          option as CommitmentPaymentOption[],
        ),
      );
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <FilterSelect
      selectedValues={value}
      label="Payment Options"
      options={options}
      onChange={changeHandler}
    />
  );
};

const options = [
  {
    value: "all_upfront",
    label: commitmentsPaymentOptionLabels.all_upfront,
  },
  {
    value: "partial_upfront",
    label: commitmentsPaymentOptionLabels.partial_upfront,
  },
  {
    value: "no_upfront",
    label: commitmentsPaymentOptionLabels.no_upfront,
  },
];
