import { FC, useCallback } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { setCommitmentsDataViewTab } from "../../../../../../../../store/commitments/commitmentsSlice";
import { DataViewTab } from "../../../../../../../../store/commitments/utils/types/types";
import { savingsPlansGranularDataTotalUtilizationSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalUtilizationSelector";
import { savingsPlansGranularDataTotalCoverageSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalCoverageSelector";
import { useGetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsQuery } from "../../../../../../../../services/cloudchipr.api";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { money } from "../../../../../../../../utils/numeral/money";

export const UtilizationAndCoverageDataViewTabs: FC = () => {
  const dispatch = useAppDispatch();
  const enableSavingsPlanRecommendation = useFlag(
    "EnableSavingsPlanRecommendation",
  );

  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  const { data } =
    useGetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsQuery({
      providerOrganisationId: orgId,
    });

  const totalUtilization = useAppSelector(
    savingsPlansGranularDataTotalUtilizationSelector,
  );

  const totalCoverage = useAppSelector(
    savingsPlansGranularDataTotalCoverageSelector,
  );

  const tabChangeHandler = useCallback(
    (_: unknown, tab: string | null) => {
      if (!tab) {
        return;
      }

      dispatch(setCommitmentsDataViewTab(tab as DataViewTab));
    },
    [dispatch],
  );

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={tab}
      color="primary"
      sx={{ borderRadius: 2 }}
      onChange={tabChangeHandler}
    >
      <ToggleButton value="commitment" sx={toggleButtonSx}>
        Savings Plans{" "}
        {!!totalUtilization?.percent &&
          `(Utilization ${totalUtilization?.percent}%)`}
      </ToggleButton>

      <ToggleButton value="coverage" sx={toggleButtonSx}>
        Coverage {!!totalCoverage?.percent && `(${totalCoverage?.percent}%)`}
      </ToggleButton>

      {enableSavingsPlanRecommendation && (
        <ToggleButton value="recommendations" sx={toggleButtonSx}>
          Recommendations{" "}
          {data?.savings_amount !== null &&
            ` (Savings ~${money(data?.savings_amount)}/mo)`}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

const toggleButtonSx = {
  textTransform: "none",
  borderRadius: 2,
};
