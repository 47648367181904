import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { commitmentsRecommendationsDataSelector } from "./commitmentsRecommendationsDataSelector";
import { SavingsPlanRecommendation } from "../../../../../services/cloudchipr.api";
import { commitmentsRecommendationsFiltersSelector } from "../filters/commitmentsRecommendationsFiltersSelector";
import { filterRecommendationsByFilters } from "../../../utils/helpers/filterRecommendationsByFilters";

export const commitmentsRecommendationsFilteredDataSelector =
  createDraftSafeSelector(
    [
      commitmentsRecommendationsDataSelector,
      commitmentsRecommendationsFiltersSelector,
    ],
    (
      data,
      filters,
    ): (SavingsPlanRecommendation & { id: string })[] | undefined => {
      return filterRecommendationsByFilters(
        filters,
        data?.recommendations?.map((rec) => ({ ...rec, id: uuid() })),
      );
    },
  );
