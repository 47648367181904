import { createAsyncThunk } from "@reduxjs/toolkit";
import { createResourceExplorerThunk } from "./createResourceExplorerThunk";
import { updateResourceExplorerThunk } from "./updateResourceExplorerThunk";
import { RootState } from "../../../store";
import { ResourceExplorerActionType } from "../../../../components/pages/resource-explorer/utils/types/types";
import { resourceExplorerProvidersSelector } from "../../selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { resourceExplorerDataSelector } from "../../selectors/current-resource-explorer/data/resourceExplorerDataSelector";
import { resourceExplorerAppliedFiltersToSaveOnViewSelector } from "../../selectors/filters/resourceExplorerAppliedFiltersToSaveOnViewSelector";
import { enabledProvidersByBillingStatusSelector } from "../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

interface ResourceExplorerPersistThunkArg {
  name: string;
  type: ResourceExplorerActionType;
  viewId?: string;
}

export const resourceExplorerPersistThunk = createAsyncThunk(
  "resourceExplorer/resourceExplorerPersist",
  async (
    { name, type, viewId }: ResourceExplorerPersistThunkArg,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const mutatedFilters =
      resourceExplorerAppliedFiltersToSaveOnViewSelector(state);

    const data = resourceExplorerDataSelector(state);
    const enableProviders = enabledProvidersByBillingStatusSelector(state);

    if (type === "create") {
      const resourceExplorerProviders =
        resourceExplorerProvidersSelector(state);

      return await dispatch(
        createResourceExplorerThunk({
          providers: viewId ? resourceExplorerProviders : enableProviders,
          name: name,
          filters: !viewId ? [] : mutatedFilters,
          data,
        }),
      ).unwrap();
    } else if (viewId) {
      dispatch(
        updateResourceExplorerThunk({
          viewId,
          name: name,
          data,
        }),
      );
    }
  },
);
