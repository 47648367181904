import { billingErrorAccountStatusesByProviderSelector } from "./billingErrorAccountStatusesByProviderSelector";

import { RootState } from "../../../../store";
import { BillingAccountStatusWithProvider } from "../../../utils/types/types";
import { enabledProvidersByBillingStatusSelector } from "../enabledProvidersByBillingStatusSelector";

export const billingErrorAccountStatusesSelector = (
  state: RootState,
): BillingAccountStatusWithProvider[] => {
  const enabledProviders = enabledProvidersByBillingStatusSelector(state);

  return enabledProviders.reduce((result, provider) => {
    const data = billingErrorAccountStatusesByProviderSelector(state, provider);
    result = result.concat(data);

    return result;
  }, [] as BillingAccountStatusWithProvider[]);
};
