import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAppLoading } from "./commonSlice";
import { getCurrentOrganisationSettingsThunk } from "./thunks/org-current-settings/getCurrentOrganisationSettingsThunk";
import { getProfileThunk } from "../profile/profileThunks";
import { getCurrentSubscriptionThunk } from "../subscriptions/subscriptionsThunks";
import { getCurrentOrganizationThunk } from "../organizations/organizationsThunks";
import { getAccountsThunk } from "../accounts/thunks/getAccountsThunk";
import { getAllowedFiltersThunk } from "../filters/thunks/getAllowedFiltersThunk";
import { setLogout } from "../auth/authSlice";

export const postAuthDataFetchThunk = createAsyncThunk(
  "accounts/postAuthDataFetch",
  async (_, { dispatch }) => {
    const results = await Promise.all([
      dispatch(getCurrentOrganizationThunk()),
      dispatch(getProfileThunk()).unwrap(),
      dispatch(getCurrentSubscriptionThunk()),
      dispatch(getCurrentOrganisationSettingsThunk()),
    ]);
    const isSubscriptionExists = results?.[2]?.payload;
    const profileData = results?.[1];

    if (
      profileData?.status === "awaiting_invitation" ||
      profileData?.status === "in_review"
    ) {
      return;
    }
    if (isSubscriptionExists) {
      await Promise.all([
        dispatch(getAllowedFiltersThunk()),
        dispatch(getAccountsThunk()),
      ]);
      dispatch(setAppLoading(false));
    } else {
      dispatch(setLogout(true));
    }
  },
);
