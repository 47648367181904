import { enqueueSnackbar } from "notistack";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { setNavigationDashboard } from "../../../navigation/navigationSlice";
import { flattenHierarchyData } from "../../../navigation/utils/helpers/flattenHierarchyData";

export const getDashboardsVisibilityHierarchyThunk = createAsyncThunk(
  "dashboard/getDashboardsVisibilityHierarchy",
  async (_, { dispatch }) => {
    const { getV2UsersMeOrganisationsCurrentDashboardsHierarchy } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        getV2UsersMeOrganisationsCurrentDashboardsHierarchy.initiate(
          {},
          { forceRefetch: true },
        ),
      ).unwrap();

      if (response?.visible_only_to_me) {
        dispatch(
          setNavigationDashboard({
            data: flattenHierarchyData(response.visible_only_to_me) ?? [],
            type: "visible_only_to_me",
          }),
        );
      }
      if (response?.visible_to_everyone) {
        dispatch(
          setNavigationDashboard({
            data: flattenHierarchyData(response.visible_to_everyone) ?? [],
            type: "visible_to_everyone",
          }),
        );
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
