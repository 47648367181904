import { SavingsPlanRecommendation } from "../../../../services/cloudchipr.api";
import { CommitmentsRecommendationsFilters } from "../types/types";

export const filterRecommendationsByFilters = (
  filters: CommitmentsRecommendationsFilters,
  recommendations?: (SavingsPlanRecommendation & { id: string })[],
) => {
  const {
    type,
    account_ids,
    term_in_years,
    account_scope,
    payment_option,
    look_back_period,
  } = filters;

  return recommendations?.filter((rec) => {
    const match =
      look_back_period.includes(rec.lookback_period) &&
      payment_option.includes(rec.payment_option) &&
      term_in_years.includes(rec.term_in_years) &&
      type.includes(rec.savings_plan_type);

    const accountsMatch =
      account_scope === "linked"
        ? account_ids?.includes(rec.account.id)
        : rec.account_scope === account_scope;

    return match && accountsMatch;
  });
};
