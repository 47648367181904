import { FC } from "react";

import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerV2ViewCreateFolderOrViewDialogV2 } from "../create-folder-or-view-dialog-v2/ResourceExplorerV2ViewCreateFolderOrViewDialogV2";
import { ResourceExplorerV2ViewCreateFolderOrViewDialog } from "../create-folder-or-view-dialog/ResourceExplorerV2ViewCreateFolderOrViewDialog";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../../../../store/navigation/utils/types";

interface ResourceExplorerCreateFolderOrItemDialogWrapperProps {
  onClose(): void;
  type: NavigationHierarchyItemType;
  folderId?: string;
  visibility?: NavigationItemsVisibilityType;
  title?: string;
  viewId?: string;
}

export const ResourceExplorerCreateFolderOrItemDialogWrapper: FC<
  ResourceExplorerCreateFolderOrItemDialogWrapperProps
> = ({ onClose, type, folderId, visibility, title, viewId }) => {
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  if (enableNavigationHierarchyVisibility) {
    return (
      <ResourceExplorerV2ViewCreateFolderOrViewDialogV2
        onClose={onClose}
        type={type}
        folderId={folderId}
        visibility={visibility}
        title={title}
        viewId={viewId}
      />
    );
  }

  return (
    <ResourceExplorerV2ViewCreateFolderOrViewDialog
      onClose={onClose}
      type={type}
      folderId={folderId}
    />
  );
};
