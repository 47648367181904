import {
  cloudChiprApi,
  ResourceExplorerPossibleFilter,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { enabledProvidersByBillingStatusSelector } from "../../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

const selector =
  cloudChiprApi.endpoints
    .getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFilters
    .select;

export const resourceExplorerAllPossibleInitialFiltersSelector = (
  state: RootState,
) => {
  const enabledProviders = enabledProvidersByBillingStatusSelector(state);

  return enabledProviders.reduce(
    (result, provider) => {
      result[provider] = selector({ provider })(state)?.data;

      return result;
    },
    {} as Partial<Record<ProviderType, ResourceExplorerPossibleFilter[]>>,
  );
};
