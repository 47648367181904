import { FC, useCallback } from "react";
import { PopoverProps } from "@mui/material";
import { CommitmentsDateRangeSelector } from "./CommitmentsDateRangeSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsDatesSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { setCommitmentsDates } from "../../../../../../../store/commitments/commitmentsSlice";
import { Dates } from "../../../../../../../services/cloudchipr.api";
import { getSavingsPlansThunk } from "../../../../../../../store/commitments/thunks/savings-plans/getSavingsPlansThunk";
import { getReservationsThunk } from "../../../../../../../store/commitments/thunks/reservations/getReservationsThunk";
import { commitmentsTypeTabSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";

export const CommitmentsDateRange: FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(commitmentsDatesSelector);
  const activeTab = useAppSelector(commitmentsTypeTabSelector);

  const dateChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(setCommitmentsDates(dates));

      if (activeTab === "reservation") {
        dispatch(getReservationsThunk());
      } else {
        dispatch(getSavingsPlansThunk());
      }
    },
    [dispatch, activeTab],
  );

  return (
    <CommitmentsDateRangeSelector
      size="xsmall"
      dates={dates}
      PopoverProps={popoverProps}
      onChange={dateChangeHandler}
    />
  );
};

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};
