import { FC, useCallback } from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { ResourceExplorerGrouping } from "../../../../../../services/cloudchipr.api";

interface CategoryGroupingOptionProps {
  onSelect(grouping: ResourceExplorerGrouping): void;
  activeGrouping: ResourceExplorerGrouping;
}

export const CategoryGroupingOption: FC<CategoryGroupingOptionProps> = ({
  onSelect,
  activeGrouping,
}) => {
  const groupingItemClickHandler = useCallback(() => {
    onSelect("category");
  }, [onSelect]);

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={groupingItemClickHandler}
        selected={activeGrouping === "category"}
      >
        <ListItemText primary="Category" />
      </ListItemButton>
    </ListItem>
  );
};
