import { FC, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";

import { HierarchySelect } from "../hierarchy-select/HierarchySelect";
import { HierarchySelectVisibility } from "../hierarchy-select-visibility/HierarchySelectVisibility";
import { SelectViewTrigger } from "../view-select-trigger/SelectViewTrigger";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { DropdownFooterComponent } from "../../../common/select/dropdown-select/utils/types/types";
import { useAppSelector } from "../../../../store/hooks";
import { resourceExplorerViewsHierarchyViewsToItemsSelector } from "../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyViewsToItemsSelector";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";
import { resourceExplorerViewsHierarchyViewsSelector } from "../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyViewsSelector";
import { NavigationItemsVisibilityType } from "../../../../store/navigation/utils/types";
import { navigationResourceExplorerDataSelector } from "../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerDataSelector";

interface HierarchyViewSelectionWrapperProps {
  onChange(id: string): void;
  selectedItemId: string;
  selectedViews?: string[];
  CustomTriggerComponent?: FC<FilterTriggerComponentProps>;
  DropdownFooter?: DropdownFooterComponent;
  visibility?: NavigationItemsVisibilityType;
}

export const HierarchyViewSelectionWrapper: FC<
  HierarchyViewSelectionWrapperProps
> = ({
  onChange,
  selectedItemId,
  selectedViews,
  CustomTriggerComponent,
  DropdownFooter,
  visibility,
}) => {
  const hierarchy = useAppSelector(
    resourceExplorerViewsHierarchyViewsToItemsSelector,
  );

  const hierarchyViews = useAppSelector(
    resourceExplorerViewsHierarchyViewsSelector,
  );

  const mergedHierarchyVisibilityItems = useAppSelector(
    resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector,
  );

  const hierarchyVisibility = useAppSelector(
    navigationResourceExplorerDataSelector,
  );
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  const enableResourceExplorerFolderViews = useFlag(
    "EnableResourceExplorerFolderViews",
  );

  const labelV1 = useMemo(() => {
    const selectedItem = hierarchyViews?.find((hierarchyItem) => {
      if (hierarchyItem.id === selectedItemId) {
        return hierarchyItem;
      }
    });

    return selectedItem?.name ?? "";
  }, [hierarchyViews, selectedItemId]);

  const labelV2 = useMemo(() => {
    const selectedItem = mergedHierarchyVisibilityItems?.find(
      (visibilityItem) => {
        if (visibilityItem.id === selectedItemId) {
          return visibilityItem;
        }
      },
    );

    return selectedItem?.name ?? "";
  }, [mergedHierarchyVisibilityItems, selectedItemId]);

  if (enableNavigationHierarchyVisibility) {
    return (
      <HierarchySelectVisibility
        label={labelV2}
        selectedItemId={selectedItemId}
        hierarchy={hierarchyVisibility}
        onChange={onChange}
        ItemIcon={InsertChartOutlinedOutlinedIcon}
        disabledOptionsIds={selectedViews}
        CustomTriggerComponent={CustomTriggerComponent ?? SelectViewTrigger}
        DropdownFooter={DropdownFooter}
        visibility={visibility}
      />
    );
  }

  if (enableResourceExplorerFolderViews) {
    return (
      <HierarchySelect
        extendAnchorWidth
        label={labelV1}
        selectedItemId={selectedItemId}
        hierarchy={hierarchy ?? []}
        onChange={onChange}
        ItemIcon={InsertChartOutlinedOutlinedIcon}
        disabledOptionsIds={selectedViews}
        CustomTriggerComponent={CustomTriggerComponent ?? SelectViewTrigger}
        DropdownFooter={DropdownFooter}
      />
    );
  }

  return null;
};
