import { FC, useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FilterSelect } from "../FilterSelect";
import {
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
} from "../../../../../../../services/cloudchipr.api";

interface AddNewFilterButtonProps {
  onAddNewFilter(
    provider: ResourceExplorerFilterProvider,
    key: ResourceExplorerDynamicFilterItemType,
    operator: FilterOperatorType,
  ): void;
}

export const AddNewFilterButton: FC<AddNewFilterButtonProps> = ({
  onAddNewFilter,
}) => {
  const [showSelect, setShowSelect] = useState(false);

  const addFilterClickHandler = useCallback(() => {
    setShowSelect(true);
  }, []);

  const addFilterRemoveHandler = useCallback(() => {
    setShowSelect(false);
  }, []);

  return (
    <Stack spacing={1}>
      {showSelect && (
        <FilterSelect
          onSelect={onAddNewFilter}
          onRemove={addFilterRemoveHandler}
        />
      )}

      <Button
        size="small"
        variant="outlined"
        startIcon={<AddIcon />}
        disabled={showSelect}
        sx={{ alignSelf: "flex-start" }}
        onClick={addFilterClickHandler}
      >
        Add Filter
      </Button>
    </Stack>
  );
};
