import { FC, Fragment, ReactNode, useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuid } from "uuid";
import {
  FilterOperatorType,
  ProviderType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
} from "../../../../../../../services/cloudchipr.api";
import { FilterSelect } from "../FilterSelect";
import { GroupItemsOperator } from "../../provider-dynamic-filters/GroupItemsOperator";
import {
  DynamicProviderFilterItem,
  DynamicProviderFilterGroup,
} from "../../../utils/types/common";

interface AddFilterInProviderProps {
  provider: ProviderType;
  inGroup?: boolean;
  filters: DynamicProviderFilterGroup;
  onFiltersChange(
    newFilters: DynamicProviderFilterGroup,
    provider: ResourceExplorerFilterProvider,
  ): void;
  children?: ReactNode;
}

export const AddFilterInProvider: FC<AddFilterInProviderProps> = ({
  onFiltersChange,
  provider,
  filters,
  inGroup,
  children,
}) => {
  const [selectionType, setSelectionType] = useState<
    "addGroup" | "addFilter" | null
  >(null);

  const addFilterClickHandler = useCallback(() => {
    setSelectionType("addFilter");
  }, []);

  const addFilterGroupClickHandler = useCallback(() => {
    setSelectionType("addGroup");
  }, []);

  const addFilterRemoveHandler = useCallback(() => {
    setSelectionType(null);
  }, []);

  const addFilterHandler = useCallback(
    (
      provider: ResourceExplorerFilterProvider,
      key: ResourceExplorerDynamicFilterItemType,
      operator: FilterOperatorType,
    ) => {
      const newFilter: DynamicProviderFilterItem = {
        id: uuid(),
        type: key,
        cloud_provider: provider,
        value: null,
        operator,
      };

      const filterItem =
        selectionType === "addGroup"
          ? ({
              filters: [newFilter],
              operator: "AND",
            } as DynamicProviderFilterGroup)
          : newFilter;

      onFiltersChange(
        {
          ...filters,
          filters: [...filters.filters, filterItem],
        },
        provider,
      );
    },
    [onFiltersChange, filters, selectionType],
  );

  return (
    <Stack spacing={1} flex={1}>
      <Stack direction="row" spacing={1} flex={1}>
        {selectionType && (
          <Fragment>
            <GroupItemsOperator first={false} operator={filters.operator} />

            <FilterSelect
              padding={0}
              inGroup={inGroup}
              provider={provider}
              onSelect={addFilterHandler}
              onRemove={addFilterRemoveHandler}
            />
          </Fragment>
        )}
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        flex={1}
        justifyContent={inGroup ? "space-between" : undefined}
      >
        <Button
          size="small"
          startIcon={<AddIcon />}
          disabled={!!selectionType}
          sx={{ alignSelf: "flex-start" }}
          onClick={addFilterClickHandler}
        >
          Add Filter
        </Button>

        {!inGroup && (
          <Button
            size="small"
            startIcon={<AddIcon />}
            disabled={!!selectionType}
            sx={{ alignSelf: "flex-start" }}
            onClick={addFilterGroupClickHandler}
          >
            Add Filter Group
          </Button>
        )}

        {children}
      </Stack>
    </Stack>
  );
};
