import { ProviderType } from "../../../../services/cloudchipr.api";

export const mergeREViewAndEnabledProviders = (
  enableProvidersSet: ProviderType[],
  providers: ProviderType[],
  isDefault: boolean,
): ProviderType[] => {
  const data = providers.filter((item) => enableProvidersSet.includes(item));

  if (isDefault) {
    return enableProvidersSet;
  }

  return data.length ? data : enableProvidersSet;
};
