import { alertsCostAnomalyDataViewIdSelector } from "./alertsCostAnomalyDataViewIdSelector";
import { RootState } from "../../../../store";
import { sortedResourceExplorersSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/sortedResourceExplorersSelector";

export const selectedViewFilteredByDimensionsSelector = (state: RootState) => {
  const views = sortedResourceExplorersSelector(state);
  const selectedViewId = alertsCostAnomalyDataViewIdSelector(state);
  const selectedView = views?.find((item) => item.id === selectedViewId);

  return !!selectedView?.filters?.some(
    (filter) => filter.key === "dimension_id",
  );
};
