import { deleteDashboardHierarchyItemThunkFixedCacheKey } from "../../../thunks/common/deleteDashboardItemThunk";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.deleteUsersMeOrganisationsCurrentDashboardsByDashboardId.select(
    deleteDashboardHierarchyItemThunkFixedCacheKey,
  );

export const deleteDashboardHierarchyItemLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(state)?.isLoading;
};
