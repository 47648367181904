import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { darken } from "@mui/material";
import { ChartColorsType, ChartType } from "./utils/types/types";
import { ChartsColors } from "./utils/constants/colors";
import {
  MultiTypeChartContextType,
  MultiTypeChartProviderProps,
} from "./utils/types/contextTypes";
import { chartDataPointKey } from "./utils/constants/constants";

const MultiTypeChartContext = createContext({} as MultiTypeChartContextType);

export const MultiTypeChartProvider: FC<MultiTypeChartProviderProps> = ({
  children,
  value: { data, colors, initialChartType },
}) => {
  const [visibleKeys, setVisibleKeys] = useState<string[]>([]);
  const [hoveredKey, setHoveredKey] = useState<string>("");
  const [chartType, setChartType] = useState<ChartType>(initialChartType);

  const allKeys = useMemo(() => {
    const dataSum = data.reduce(
      (acc, item) => {
        Object.entries(item).forEach(([key, value]) => {
          acc[key] = (acc[key] ?? 0) + +(value ?? 0);
        });

        return acc;
      },
      {} as Record<string, number>,
    );

    const sortedData = Object.entries(dataSum).sort(([, a], [, b]) =>
      a > b ? -1 : 1,
    );

    return sortedData
      .map(([key]) => key)
      .filter((key) => key !== chartDataPointKey);
  }, [data]);

  const chartColors = useMemo(() => {
    if (colors) {
      return colors;
    } else {
      let index = 0;

      return allKeys.reduce((acc, key, arrIndex) => {
        let color: string = ChartsColors[index];

        if (!color) {
          index = 0;
          const iteration = Math.round(arrIndex / ChartsColors.length);

          color = darken(ChartsColors[index], iteration * 0.1);
        }

        acc[key] = color;
        index++;

        return acc;
      }, {} as ChartColorsType);
    }
  }, [colors, allKeys]);

  const contextValue = useMemo<MultiTypeChartContextType>(() => {
    return {
      wrappedWithProvider: true,
      colors: chartColors,
      data,
      keys: {
        allKeys,
        visibleKeys,
        setVisibleKeys,
      },
      chartType: {
        type: chartType,
        onChange: setChartType,
      },
      hover: {
        key: hoveredKey,
        setKey: setHoveredKey,
      },
      zoomAvailable: data.length > 2,
    };
  }, [allKeys, data, hoveredKey, visibleKeys, chartType, chartColors]);

  useEffect(() => {
    setVisibleKeys(allKeys);
  }, [allKeys]);

  return (
    <MultiTypeChartContext.Provider value={contextValue}>
      {children}
    </MultiTypeChartContext.Provider>
  );
};

export const useMultiTypeChartContext = () => {
  return useContext(MultiTypeChartContext);
};
