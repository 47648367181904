import { ColDef } from "@ag-grid-community/core";
import Stack from "@mui/material/Stack";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { WorkflowNameCell } from "../columns/name-cell/WorkflowNameCell";
import { ScheduledJobsDataGridNextRunCell } from "../../../../../schedule/classic/components/scheduled-jobs-list-grid/ScheduledJobsDataGridNextRunCell";
import { ScheduledJobsDataGridActionsCell } from "../../../../../schedule/classic/components/scheduled-jobs-list-grid/ScheduledJobsDataGridActionsCell";
import { SchedulesDataGridFrequencyCell } from "../../../../../schedule/common/list/SchedulesDataGridFrequencyCell";
import { AccountsWithPopover } from "../../../../../../common/accounts-with-popover/AccountsWithPopover";
import { automationsNextRunSortingFn } from "../../utils/sorting-functions/automationsNextRunSortingFn";
import { automationsAccountsSortingFn } from "../../utils/sorting-functions/automationsAccountsSortingFn";
import { automationsEnabledStateSortingFn } from "../../utils/sorting-functions/automationsEnabledStateSortingFn";
import { automationsFrequencySortingFn } from "../../utils/sorting-functions/automationsFrequencySortingFn";
import { quickFilterNextRunText } from "../../utils/quickFilterTextFunctions/quickFilterNextRunText";
import { quickFilterActionText } from "../../utils/quickFilterTextFunctions/quickFilterActionText";
import { quickFilterFrequencyText } from "../../utils/quickFilterTextFunctions/quickFilterFrequencyText";
import { quickFilterAccountsText } from "../../utils/quickFilterTextFunctions/quickFilterAccountsText";
import { quickFilterNameText } from "../../utils/quickFilterTextFunctions/quickFilterNameText";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { quickFilterCreatorText } from "../../utils/quickFilterTextFunctions/quickFilterCreatorText";
import { automationsCreatorSortingFn } from "../../utils/sorting-functions/automationsCreatorSortingFn";
import { WorkflowsActionsCell } from "../columns/WorkflowsActionsCell";

export const workflowsTableColumns: ColDef[] = [
  {
    field: "name",
    width: 500,
    minWidth: 200,
    maxWidth: 700,
    unSortIcon: true,
    lockPinned: true,
    rowDrag: true,
    pinned: "left",
    cellStyle: { paddingLeft: 8 },
    suppressColumnsToolPanel: true,
    getQuickFilterText: quickFilterNameText,
    cellRenderer: WorkflowNameCell,
  },
  {
    field: "time_zone",
    minWidth: 150,
    width: 250,
    maxWidth: 500,
    unSortIcon: true,
    headerName: "Next Run",
    comparator: automationsNextRunSortingFn,
    getQuickFilterText: quickFilterNextRunText,
    cellRenderer: (cell: any) => {
      const schedule = cell.data as Schedule;

      return (
        <ScheduledJobsDataGridNextRunCell
          status={schedule.status}
          timeZone={schedule.time_zone}
          nextRun={schedule?.metadata.next_run_at}
          id={schedule.id}
        />
      );
    },
  },
  {
    field: "action",
    minWidth: 150,
    width: 200,
    maxWidth: 300,
    unSortIcon: true,
    getQuickFilterText: quickFilterActionText,
    cellRenderer: (cell: any) => {
      const schedule = cell.data as Schedule;

      return <ScheduledJobsDataGridActionsCell schedule={schedule} />;
    },
  },
  {
    field: "cron",
    minWidth: 120,
    width: 200,
    maxWidth: 300,
    unSortIcon: true,
    headerName: "Frequency",
    comparator: automationsFrequencySortingFn,
    getQuickFilterText: quickFilterFrequencyText,
    cellRenderer: (cell: any) => {
      const schedule = cell.data as Schedule;

      return (
        <SchedulesDataGridFrequencyCell
          nextRun={schedule?.metadata?.next_run_at ?? ""}
          timeZone={schedule.time_zone}
          cron={schedule.cron}
          weeklyInterval={schedule.weekly_schedule_interval}
        />
      );
    },
  },
  {
    field: "account",
    minWidth: 150,
    width: 300,
    maxWidth: 700,
    unSortIcon: true,
    getQuickFilterText: quickFilterAccountsText,
    comparator: automationsAccountsSortingFn,
    cellRenderer: (cell: any) => {
      const schedule = cell.data as Schedule;
      const accounts = schedule.accounts;

      return (
        <Stack height="100%" justifyContent="center">
          <AccountsWithPopover accounts={accounts} />
        </Stack>
      );
    },
  },
  {
    field: "creator_details",
    width: 300,
    minWidth: 150,
    maxWidth: 300,
    unSortIcon: true,
    headerName: "Created by",
    getQuickFilterText: quickFilterCreatorText,
    comparator: automationsCreatorSortingFn,
    cellRenderer: (cell: any) => {
      const details = cell.data?.creator_details;

      return (
        <Stack height="100%" justifyContent="center">
          <TypographyWithTooltip
            variant="body2"
            title={details?.name ?? details?.email}
          />
        </Stack>
      );
    },
  },

  {
    // column to fit rest place
    flex: 1,
    colId: "toFitEmptySpace",
    headerName: "",
    sortable: false,
    cellDataType: false,
    resizable: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
  },

  {
    colId: "actions",
    maxWidth: 150,
    width: 150,
    minWidth: 150,
    resizable: false,
    unSortIcon: true,
    suppressMovable: true,
    pinned: "right",
    headerName: "Enabled",
    comparator: automationsEnabledStateSortingFn,
    suppressColumnsToolPanel: true,
    cellRenderer: WorkflowsActionsCell,
  },
];
