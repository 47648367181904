import { FC } from "react";
import { Box } from "@mui/material";
import { UtilizationAndCoverageDataGridToolbar } from "./components/UtilizationAndCoverageDataGridToolbar";
import { SavingsPlansDataGrid } from "./SavingsPlansDataGrid";
import { SavingsPlanCoverageDataGrid } from "./SavingsPlanCoverageDataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { savingsPlansForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForTableLoadingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { CommitmentsRecommendationsDataGrid } from "../recommendations/CommitmentsRecommendationsDataGrid";

export const UtilizationAndCoverageData: FC = () => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const loading = useAppSelector(savingsPlansForTableLoadingSelector);

  if (tab === "recommendations") {
    return <CommitmentsRecommendationsDataGrid />;
  }

  return (
    <Box position="relative" zIndex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      {tab === "commitment" && <SavingsPlansDataGrid toolbar={toolbar} />}

      {tab === "coverage" && <SavingsPlanCoverageDataGrid toolbar={toolbar} />}
    </Box>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => {
    return <UtilizationAndCoverageDataGridToolbar {...props} />;
  },
};
