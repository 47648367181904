import { resourceExplorerViewsHierarchyFoldersSelector } from "./resourceExplorerViewsHierarchyFoldersSelector";
import { RootState } from "../../../store";
import {
  ReFolderV2,
  ResourceExplorer,
  ResourceExplorerFolder,
} from "../../../../services/cloudchipr.api";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import UnleashClient from "../../../../services/UnleashClient";

export const resourceExplorerViewsHierarchyViewsByFolderIdSelector = (
  state: RootState,
  folderId: string,
  visibility: NavigationItemsVisibilityType,
): ResourceExplorer[] | undefined => {
  const folders = resourceExplorerViewsHierarchyFoldersSelector(
    state,
    visibility,
  );

  const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
    "EnableNavigationHierarchyVisibility",
  );

  if (enableNavigationHierarchyVisibility) {
    return (folders as ReFolderV2[])?.find((item) => item.id === folderId)
      ?.items;
  }

  return (folders as ResourceExplorerFolder[])?.find(
    (item) => item.id === folderId,
  )?.views;
};
