import { RootState } from "../../../store";
import { CostAnomalyThresholdType } from "../../../../components/pages/alerts/components/drawer/components/cost-anomaly/utils/types/types";
import { alertsDataThresholdsSelector } from "../common/data/alertsDataThresholdsSelector";

export const alertsDataThresholdByTypeSelector = (
  state: RootState,
  type: CostAnomalyThresholdType,
) => {
  const thresholds = alertsDataThresholdsSelector(state);

  return thresholds.find((thresholds) => thresholds.unit === type);
};
