import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { OffHoursSchedulerFieldLabel } from "../OffHoursSchedulerFieldLabel";
import { WeekDays } from "../../../../../../schedule/common/create/scheduler/WeekDays";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { offHoursSchedulerSelector } from "../../../../../../../../store/automations/selectros/off-hours/offHoursSchedulerSelector";
import { setOffHoursSpecificData } from "../../../../../../../../store/automations/automationsSlice";
import { automationFrequencySelector } from "../../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { OffHoursStopStartTime } from "../OffHoursStopStartTime";
import { StopDateInfoTooltip } from "../StopDateInfoTooltip";
import { AutomationTimeZone } from "../../../../../common/components/form/schedule-content/AutomationTimeZone";

export const OffHoursWeeklyScheduler: FC = () => {
  const dispatch = useAppDispatch();

  const scheduler = useAppSelector(offHoursSchedulerSelector);
  const frequency = useAppSelector(automationFrequencySelector);

  const weekDaysChangeHandler = useCallback(
    (key: "stopWeekDays" | "startWeekDays", weekDays: number[]) => {
      if (!weekDays.length) {
        return;
      }

      dispatch(
        setOffHoursSpecificData({
          scheduler: { ...scheduler, [key]: weekDays },
        }),
      );
    },
    [dispatch, scheduler],
  );

  const stopWeekDaysChangeHandler = useCallback(
    (stopWeekDays: number[]) => {
      weekDaysChangeHandler("stopWeekDays", stopWeekDays);
    },
    [weekDaysChangeHandler],
  );

  const startWeekDaysChangeHandler = useCallback(
    (startWeekDays: number[]) => {
      weekDaysChangeHandler("startWeekDays", startWeekDays);
    },
    [weekDaysChangeHandler],
  );

  if (frequency !== "weekly") {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <OffHoursSchedulerFieldLabel type="stop" title="Stop resources at" />
        <Stack direction="row" spacing={1}>
          <WeekDays
            color="warning"
            weekDays={scheduler?.stopWeekDays ?? []}
            onChange={stopWeekDaysChangeHandler}
          />

          <OffHoursStopStartTime value={scheduler?.stopTime} type="stop" />
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <OffHoursSchedulerFieldLabel type="stop" title="Start resources at" />
        <Stack direction="row" spacing={1} alignItems="center">
          <WeekDays
            color="success"
            weekDays={scheduler?.startWeekDays ?? []}
            onChange={startWeekDaysChangeHandler}
          />
          <OffHoursStopStartTime value={scheduler?.startTime} type="start" />
          <StopDateInfoTooltip />
        </Stack>
      </Stack>

      <AutomationTimeZone />
    </Stack>
  );
};
