import { FC } from "react";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import CopyToClipboard from "react-copy-to-clipboard";
import { SxProps, Theme } from "@mui/system";

interface CopyURLActionProps {
  onClose(): void;
  link: string;
  iconSx: SxProps<Theme>;
  menuItemSx: SxProps<Theme>;
}

export const CopyURLAction: FC<CopyURLActionProps> = ({
  link,
  menuItemSx,
  iconSx,
  onClose,
}) => {
  return (
    <CopyToClipboard onCopy={onClose} text={link}>
      <MenuItem sx={menuItemSx}>
        <ListItemIcon>
          <LinkIcon fontSize="small" sx={iconSx} />
        </ListItemIcon>
        <Typography variant="body2">Copy URL</Typography>
      </MenuItem>
    </CopyToClipboard>
  );
};
