import { FC, useCallback } from "react";
import { AutomationTimePicker } from "../../../../common/components/form/schedule-content/dates/AutomationTimePicker";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { NullableDate } from "../../../../../../../services/cloudchipr.api";
import { setOffHoursSpecificData } from "../../../../../../../store/automations/automationsSlice";
import { offHoursSchedulerSelector } from "../../../../../../../store/automations/selectros/off-hours/offHoursSchedulerSelector";

interface OffHoursStopStartTimeProps {
  value?: NullableDate;
  type: "stop" | "start";
}

export const OffHoursStopStartTime: FC<OffHoursStopStartTimeProps> = ({
  value,
  type,
}) => {
  const dispatch = useAppDispatch();

  const scheduler = useAppSelector(offHoursSchedulerSelector);

  const changeHandler = useCallback(
    (date: NullableDate) => {
      const key = type === "start" ? "startTime" : "stopTime";

      dispatch(
        setOffHoursSpecificData({
          scheduler: { ...scheduler, [key]: date },
        }),
      );
    },
    [dispatch, scheduler, type],
  );

  return <AutomationTimePicker icon value={value} onChange={changeHandler} />;
};
