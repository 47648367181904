import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { commitmentsCurrentOrgIdSelector } from "../../selectors/common/commitmentsCurrentOrgIdSelector";
import { organizationAccountsByCurrentOrgIdSelector } from "../../selectors/organisations/organizationAccountsByCurrentOrgIdSelector";
import { setCommitmentsRecommendationsFilters } from "../../commitmentsSlice";

export const setRecommendationsInitialFiltersThunk = createAsyncThunk(
  "commitments/setRecommendationsInitialFilters",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const orgId = commitmentsCurrentOrgIdSelector(state);
    const accounts = organizationAccountsByCurrentOrgIdSelector(state);

    const { getUsersMeOrganisationsCurrentSavingsPlansPotentialSavings } =
      cloudChiprApi.endpoints;

    const data = await dispatch(
      getUsersMeOrganisationsCurrentSavingsPlansPotentialSavings.initiate({
        providerOrganisationId: orgId,
      }),
    ).unwrap();

    if (!data) {
      return;
    }

    const withAccounts =
      data.recommendation_parameters.account_scope === "linked";

    const accountIds = withAccounts ? accounts?.map(({ id }) => id) : [];

    dispatch(
      setCommitmentsRecommendationsFilters({
        type: [data.recommendation_parameters.type],
        payment_option: [data.recommendation_parameters.payment_option],
        look_back_period: [data.recommendation_parameters.look_back_period],
        term_in_years: [data.recommendation_parameters.term_in_years],
        account_scope: data.recommendation_parameters.account_scope,

        account_ids: accountIds ?? [],
      }),
    );
  },
);
