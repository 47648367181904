import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { SvgIconComponent } from "@mui/icons-material";
import { HierarchyItemType } from "./utils/types/hierarchyTypes";
import { hierarchySelectFilterFn } from "./utils/helpers/hierarchySelectFilterFn";
import { HierarchySelectDropdownHeader } from "./components/HierarchySelectDropdownHeader";
import { TypographyWithTooltip } from "../../../common/TypographyWithTooltip";
import { DropdownSelect } from "../../../common/select/dropdown-select/DropdownSelect";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { DropdownFooterComponent } from "../../../common/select/dropdown-select/utils/types/types";

export interface HierarchySelectProps {
  onChange(id: string): void;
  label: string;
  selectedItemId?: string;
  ItemIcon?: SvgIconComponent;
  extendAnchorWidth?: boolean;
  disabledOptionsIds?: string[];
  hierarchy?: HierarchyItemType[];
  CustomTriggerComponent?: FC<FilterTriggerComponentProps>;
  DropdownFooter?: DropdownFooterComponent;
}

export const HierarchySelect: FC<HierarchySelectProps> = ({
  ItemIcon,
  onChange,
  hierarchy,
  selectedItemId,
  disabledOptionsIds,
  CustomTriggerComponent,
  extendAnchorWidth,
  label,
  DropdownFooter,
}) => {
  const options = useMemo(() => {
    return (
      hierarchy
        ?.map((item) => {
          if (!item?.items) {
            return {
              value: item.id,
              rawValue: item,
              label: getViewLabel(item.name, ItemIcon),
              disabled: disabledOptionsIds?.includes(item.id),
            };
          }

          let names = "";

          const folderViews =
            item.items?.map((row) => {
              names += row.name;

              return {
                value: row.id,
                rawValue: { ...row, valueForSearch: item.name },
                label: getViewLabel(row.name, ItemIcon, 2),
                disabled: disabledOptionsIds?.includes(row.id),
              };
            }) ?? [];

          return [
            {
              value: item.id,
              disableSelection: true,
              rawValue: { ...item, valueForSearch: names },
              label: (
                <Stack direction="row" spacing={1} px={2} alignItems="center">
                  <FolderOpenIcon fontSize="small" />
                  <TypographyWithTooltip title={item.name} />
                </Stack>
              ),
            },
            ...folderViews,
          ];
        })
        ?.flat() ?? []
    );
  }, [hierarchy, ItemIcon, disabledOptionsIds]);

  const selectHandler = useCallback(
    (selected: string[]) => {
      const id = selected.at(0);

      if (id) {
        onChange(id);
      }
    },
    [onChange],
  );

  if (!hierarchy) {
    return null;
  }

  return (
    <DropdownSelect
      singleSelect
      showSearch={false}
      label={label ?? ""}
      options={options}
      sortSelectedOptions={false}
      initialSelectedValues={[selectedItemId ?? ""]}
      submitHandlerOnClose={selectHandler}
      filterFn={hierarchySelectFilterFn}
      TriggerComponent={CustomTriggerComponent}
      DropdownHeader={HierarchySelectDropdownHeader}
      PopoverProps={{ extendAnchorWidth: extendAnchorWidth }}
      DropdownFooter={DropdownFooter}
    />
  );
};

const getViewLabel = (name: string, Icon?: SvgIconComponent, pl?: number) => {
  return (
    <Stack direction="row" spacing={1} pl={pl} alignItems="center">
      {Icon && <Icon fontSize="small" />}
      <TypographyWithTooltip title={name} />
    </Stack>
  );
};
