import { FC, useCallback } from "react";
import { CollapseSwitcher } from "./CollapseSwitcher";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import {
  setAutomationData,
  setWorkflowSpecificData,
} from "../../../../../../../store/automations/automationsSlice";
import { workflowActionSelector } from "../../../../../../../store/automations/selectros/workflow/workflowActionSelector";
import { automationHasAccountIdsSelectedSelector } from "../../../../../../../store/automations/selectros/common/fields/automationHasAccountIdsSelectedSelector";
import { AutomationNotifications } from "../../../../common/components/form/AutomationNotifications";
import {
  IntegrationCustomMessage,
  IntegrationCustomMessageProps,
} from "../../../../../schedule/classic/components/custom-message/IntegrationCustomMessage";

export const WorkflowNotificationsContent: FC = () => {
  const dispatch = useAppDispatch();
  const action = useAppSelector(workflowActionSelector);
  const accountIdsSelected = useAppSelector(
    automationHasAccountIdsSelectedSelector,
  );
  const switcher = action !== "notify";

  const collapseHandler = useCallback(
    (collapsed: boolean) => {
      dispatch(
        setWorkflowSpecificData({
          action: collapsed ? "clean_and_notify" : "clean",
        }),
      );
      dispatch(setAutomationData({ emails: null, notifications: null }));
    },
    [dispatch],
  );

  const integrationCustomMessage = useCallback(
    (props: IntegrationCustomMessageProps) => {
      return (
        <IntegrationCustomMessage
          {...props}
          actionFrom={action === "notify" ? "notify" : "clean"}
        />
      );
    },
    [action],
  );

  const content = (
    <AutomationNotifications
      IntegrationCustomMessage={integrationCustomMessage}
    />
  );

  if (!switcher) {
    return content;
  }

  return (
    <CollapseSwitcher
      disabled={!accountIdsSelected}
      onCollapse={collapseHandler}
      title="Action Notification"
      checkedOverride={action === "clean_and_notify"}
      info="When enabled, Cloudchipr will send detailed list of resources after taking actions."
    >
      {content}
    </CollapseSwitcher>
  );
};
