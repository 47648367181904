import { FC, Fragment, ReactNode } from "react";
import { Button, Collapse } from "@mui/material";
import { useToggle } from "rooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface NavigationVisibilitySectionProps {
  children?: ReactNode;
  title: string;
  selected?: boolean;
  expanded?: boolean;
}

export const NavigationVisibilitySection: FC<
  NavigationVisibilitySectionProps
> = ({ title, children, selected, expanded }) => {
  const [stateExpandedState, toggleExpandedState] = useToggle(
    expanded || selected,
  );

  return (
    <Fragment>
      <Button
        onClick={toggleExpandedState}
        endIcon={
          stateExpandedState ? (
            <KeyboardArrowDownIcon fontSize="small" color="action" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" color="action" />
          )
        }
        size="small"
        disableRipple
        sx={{
          ml: 3,
          textTransform: "none",
          typography: "caption",
          fontWeight: "medium",
          color: "grey.600",
        }}
      >
        {title}
      </Button>
      <Collapse in={stateExpandedState} sx={{ px: 0.5 }}>
        {children}
      </Collapse>
    </Fragment>
  );
};
