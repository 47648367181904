import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { CommitmentsChartMetadataCard } from "../../../../../common/components/chart/CommitmentsChartMetadataCard";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { savingsPlansGranularDataTotalCommitmentSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalCommitmentSelector";
import { savingsPlansGranularDataTotalCoverageSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalCoverageSelector";
import { savingsPlansGranularDataTotalUtilizationSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalUtilizationSelector";

import { savingsPlansForGranularLoadingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForGranularLoadingSelector";
import { savingsPlanDataIsNotAvailableSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/savingsPlanDataIsNotAvailableSelector";
import { money } from "../../../../../../../../utils/numeral/money";

export const UtilizationAndCoverageMultiTypeChartMetadata: FC = () => {
  const totalCommitment = useAppSelector(
    savingsPlansGranularDataTotalCommitmentSelector,
  );
  const totalUtilization = useAppSelector(
    savingsPlansGranularDataTotalUtilizationSelector,
  );

  const totalCoverage = useAppSelector(
    savingsPlansGranularDataTotalCoverageSelector,
  );

  const loading = useAppSelector(savingsPlansForGranularLoadingSelector);
  const notAvailable = useAppSelector(savingsPlanDataIsNotAvailableSelector);
  const notAvailableMessage = notAvailable ? "N/A" : "-";
  const utilizationAmount =
    totalUtilization?.amount === null
      ? notAvailableMessage
      : money(totalUtilization?.amount);

  return (
    <Stack
      spacing={2}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <CommitmentsChartMetadataCard
        title="Utilization"
        loading={loading}
        value={utilizationAmount}
        percent={totalUtilization?.percent}
      />
      <CommitmentsChartMetadataCard
        loading={loading}
        title="Commitments"
        value={money(totalCommitment)}
      />
      <CommitmentsChartMetadataCard
        loading={loading}
        title="Covered"
        value={money(totalCoverage?.amount)}
        percent={totalCoverage?.percent}
      />
    </Stack>
  );
};
