import { FC, useCallback, useMemo } from "react";
import { Menu, MenuItem, PopoverOrigin } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import { DashboardV2ItemMoveActionMenuFolderItem } from "./DashboardV2ItemMoveActionMenuFolderItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { dashboardHierarchyFoldersSelector } from "../../../../../../../../store/dashboards/selectors/dashboard-hierarchy/dashboardHierarchyFoldersSelector";
import { updateDashboardHierarchyThunk } from "../../../../../../../../store/dashboards/thunks/dashboard-hierarchy/updateDashboardHierarchyThunk";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { updateDashboardVisibilityHierarchyThunk } from "../../../../../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/updateDashboardVisibilityHierarchyThunk";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";
interface DashboardV2ItemMoveActionMenuProps {
  id: string;
  folderId?: string;
  anchor: HTMLElement | null;
  open: boolean;
  onClose(): void;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2ItemMoveActionMenu: FC<
  DashboardV2ItemMoveActionMenuProps
> = ({ id, folderId, anchor, onClose, open, visibility }) => {
  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) =>
    dashboardHierarchyFoldersSelector(state, visibility),
  );
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  const folderClickHandler = useCallback(
    async (folderId?: string) => {
      if (enableNavigationHierarchyVisibility) {
        await dispatch(
          updateDashboardVisibilityHierarchyThunk({
            id,
            folderId,
            visibility,
          }),
        );
      } else {
        await dispatch(
          updateDashboardHierarchyThunk({
            folderId,
            id,
          }),
        );
      }

      onClose();
    },
    [id, visibility, dispatch, onClose, enableNavigationHierarchyVisibility],
  );

  const itemClickHandler = useCallback(async () => {
    if (enableNavigationHierarchyVisibility) {
      await dispatch(
        updateDashboardVisibilityHierarchyThunk({
          id,
          index: 0,
          visibility,
        }),
      );
    } else {
      await dispatch(
        updateDashboardHierarchyThunk({
          id,
          index: 0,
        }),
      );
    }

    onClose();
  }, [id, visibility, dispatch, onClose, enableNavigationHierarchyVisibility]);

  const filteredFolders = useMemo(() => {
    return folders?.filter((item) => item.id !== folderId);
  }, [folders, folderId]);

  return (
    <Menu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      slotProps={slotProps}
      {...navigationHierarchyItemNoDndKey}
    >
      {!!folderId && (
        <MenuItem sx={{ px: 1 }} onClick={itemClickHandler}>
          <GridViewSharpIcon fontSize="small" />
          <TypographyWithTooltip
            title="Dashbaords"
            variant="body2"
            placement="top"
            sx={{ pl: 1 }}
          />
        </MenuItem>
      )}

      {filteredFolders?.map((folder) => {
        return (
          <DashboardV2ItemMoveActionMenuFolderItem
            key={folder.id}
            id={folder.id}
            name={folder.name}
            onClick={folderClickHandler}
          />
        );
      })}
    </Menu>
  );
};

const slotProps = {
  paper: {
    sx: {
      minWidth: 200,
      maxWidth: 400,
      maxHeight: 400,
    },
  },
};
const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
