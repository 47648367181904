import { Stack, Typography } from "@mui/material";
import { CommitmentsRecommendationsDataGridActionCell } from "./CommitmentsRecommendationsDataGridActionCell";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanRecommendation } from "../../../../../../../../services/cloudchipr.api";
import {
  commitmentsPaymentOptionLabels,
  savingsPlansTypeLabels,
  savingsPlanTermLabels,
} from "../../../../../common/utils/constants/labels";
import { money } from "../../../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

export const recommendationsTableColumns: ColumnSetupType<SavingsPlanRecommendation>[] =
  [
    {
      accessorKey: "term_in_years",
      header: () => (
        <Typography pl={1} variant="body2" fontWeight="medium">
          Type
        </Typography>
      ),
      cell: ({ row }) => (
        <Typography pl={1} variant="body2">
          {savingsPlansTypeLabels[row.original.savings_plan_type]}
        </Typography>
      ),
    },
    {
      minSize: 180,
      accessorKey: "term_in_years",
      header: () => (
        <Typography variant="body2" fontWeight="medium">
          Savings Plans term
        </Typography>
      ),
      cell: ({ row }) => (
        <Typography variant="body2">
          {savingsPlanTermLabels[row.original.term_in_years]}
        </Typography>
      ),
    },

    { accessorKey: "instance_family", header: "Instance Family" },

    { accessorKey: "region", header: "Region" },

    {
      accessorKey: "payment_option",
      header: "Payment option",
      cell: ({ row }) =>
        commitmentsPaymentOptionLabels[row.original.payment_option],
    },

    {
      accessorKey: "account",
      header: "Account",
      cell: ({ row }) => (
        <Stack>
          <TypographyWithTooltip
            variant="body2"
            title={row.original.account.provider_account_name}
          />
          <TypographyWithTooltip
            variant="body2"
            color="text.secondary"
            title={row.original.account.provider_account_id}
          />
        </Stack>
      ),
    },

    {
      accessorKey: "hourly_commitment",
      header: "Commitment",
      cell: (cell) => money(cell.getValue() as number),
    },

    {
      minSize: 220,
      accessorKey: "estimated_coverage",
      header: "Estimated On-Demand Coverage",
      cell: (cell) => `${cell.getValue() as number}%`,
    },

    {
      minSize: 200,
      accessorKey: "estimated_monthly_savings",
      header: "Estimated Monthly Savings",
      cell: (cell) => money(cell.getValue() as number),
    },

    {
      accessorKey: "action_url",
      header: "Action",
      cell: CommitmentsRecommendationsDataGridActionCell,
      meta: { sticky: "right", cellStyles: { bgcolor: "white" } },
    },
  ];
