import { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerV2ViewListItemActionsMenu } from "./ResourceExplorerV2ViewListItemActionsMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceExplorerIsDefaultSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIsDefaultSelector";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";
import { resourceExplorerHierarchyVisibilityIsDefaultSelector } from "../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilityIsDefaultSelector";

interface ResourceExplorerV2ViewActionButtonProps {
  id: string;
  name: string;
  folderId?: string;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerV2ViewActionButton: FC<
  ResourceExplorerV2ViewActionButtonProps
> = ({ id, name, folderId, visibility }) => {
  const { cannot } = useAppAbility();
  const cannotDeleteResourceExplorer = cannot("delete", "resource-explorer");
  const isViewDefault = useAppSelector(resourceExplorerIsDefaultSelector);
  const isVisibilityItemDefault = useAppSelector((state) =>
    resourceExplorerHierarchyVisibilityIsDefaultSelector(state, id),
  );

  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const isDefault = enableNavigationHierarchyVisibility
    ? isVisibilityItemDefault
    : isViewDefault;
  if (cannotDeleteResourceExplorer || isDefault) {
    return null;
  }

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <ResourceExplorerV2ViewListItemActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        folderId={folderId}
        visibility={visibility}
      />
    </Fragment>
  );
};
