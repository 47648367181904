import { FC } from "react";
import { Box, Card, LinearProgress, Stack } from "@mui/material";
import { CostByCategorySetupHeader } from "./CostByCategorySetupHeader";
import { CostByCategoryWidgetSetupDataGrid } from "./data-grid/CostByCategoryWidgetSetupDataGrid";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { CostByCategoryWidgetChart } from "../../../../../../widgets/cost-by-category/components/chart/CostByCategoryWidgetChart";
import { MultiTypeChartProvider } from "../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { costByCategorySetupChartDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/chart/costByCategorySetupChartDataSelector";
import { costByCategorySetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySetupPropertyByKeySelector";
import { buildingInProgressWidgetId } from "../../../../../../../../../../store/dashboards/thunks/widgets/utils/constants";
import { costByCategoryChartColorsSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/chart/costByCategoryChartColorsSelector";
import { WidgetContentInvalidState } from "../../../common/WidgetContentInvalidState";
import { costByCategoryWidgetLoadingSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/metadata/costByCategoryWidgetLoadingSelector";
import { CostByCategoryWidgetContentCosts } from "../../../../../../widgets/cost-by-category/components/CostByCategoryWidgetContentCosts";
import { widgetSetupContentChartHeight } from "../../../../../../../utils/constants/stylings";
import { WidgetSetupContentWrapper } from "../../../common/WidgetSetupContentWrapper";

export const CostByCategorySetupContent: FC = () => {
  const displayFilteredResources = useAppSelector(
    costByCategorySetupPropertyByKeySelector("displayFilteredResources"),
  );
  const categoryIds = useAppSelector(
    costByCategorySetupPropertyByKeySelector("categoryIds"),
  );
  const setupChartType = useAppSelector(
    costByCategorySetupPropertyByKeySelector("chartType"),
  );
  const frequency = useAppSelector(
    costByCategorySetupPropertyByKeySelector("frequency"),
  );
  const date = useAppSelector(costByCategorySetupPropertyByKeySelector("date"));

  const chartDataLoading = useAppSelector((state) =>
    costByCategoryWidgetLoadingSelector(state, buildingInProgressWidgetId),
  );

  const chartType = setupChartType ?? "line";

  const data = useAppSelector((state) =>
    costByCategorySetupChartDataSelector(
      state,
      buildingInProgressWidgetId,
      chartType !== "pie" && displayFilteredResources,
    ),
  );

  const colors = useAppSelector((state) =>
    costByCategoryChartColorsSelector(
      state,
      buildingInProgressWidgetId,
      chartType !== "pie" && displayFilteredResources,
    ),
  );

  return (
    <MultiTypeChartProvider
      value={{
        initialChartType: chartType,
        data: data || [],
        colors: colors,
      }}
    >
      <WidgetSetupContentWrapper>
        <Card elevation={3}>
          {chartDataLoading && <LinearProgress />}
          <Stack height={widgetSetupContentChartHeight} flex={1}>
            <CostByCategorySetupHeader />
            {categoryIds?.length ? (
              <Box p={2}>
                <CostByCategoryWidgetContentCosts dateLabel={date?.label} />

                <CostByCategoryWidgetChart
                  date={date}
                  chartType={chartType}
                  frequency={frequency}
                  widgetId={buildingInProgressWidgetId}
                  displayFilteredResources={displayFilteredResources}
                />
              </Box>
            ) : (
              <WidgetContentInvalidState message="Select at least one category from the list to get started." />
            )}
          </Stack>
        </Card>
      </WidgetSetupContentWrapper>

      <CostByCategoryWidgetSetupDataGrid />
    </MultiTypeChartProvider>
  );
};
