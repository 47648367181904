import { resourceExplorerFilterKeysAsProvidersSelector } from "./resourceExplorerFilterKeysAsProvidersSelector";
import { RootState } from "../../../../store";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { resourceExplorerProvidersSelector } from "../../current-resource-explorer/resourceExplorerProvidersSelector";
import { enabledProvidersByBillingStatusSelector } from "../../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

export const resourceExplorerProvidersWithoutFiltersSelector = (
  state: RootState,
): ProviderType[] => {
  const resourceExplorerProviders = resourceExplorerProvidersSelector(state);
  const filterProviders = resourceExplorerFilterKeysAsProvidersSelector(state);

  const enabledProviders = enabledProvidersByBillingStatusSelector(state);

  return enabledProviders.filter(
    (item) =>
      resourceExplorerProviders.includes(item) &&
      !filterProviders.includes(item),
  );
};
