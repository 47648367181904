import { FC, useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { FiltersCombinationOperatorType } from "../../utils/types/common";

interface GroupItemsOperatorProps {
  first: boolean;
  operator: FiltersCombinationOperatorType;
  onChange?(operator: FiltersCombinationOperatorType): void;
}

export const GroupItemsOperator: FC<GroupItemsOperatorProps> = ({
  first,
  operator,
  onChange,
}) => {
  const operatorClickHandler = useCallback(() => {
    onChange?.(operator === "AND" ? "OR" : "AND");
  }, [onChange, operator]);

  return (
    <Box minWidth={62}>
      {first ? (
        <Typography variant="body2">Where</Typography>
      ) : (
        <Button
          size="small"
          variant="outlined"
          endIcon={<UnfoldMoreIcon />}
          onClick={operatorClickHandler}
          sx={{
            width: 62,
            minWidth: 62,
            "& .MuiButton-icon.MuiButton-endIcon": { m: 0 },
          }}
        >
          {operator}
        </Button>
      )}
    </Box>
  );
};
