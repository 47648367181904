import { FC, useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerPathSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import { changePathOnBreadcrumbSelectThunk } from "../../../../../../../store/resource-explorer/thunks/widgets/path/changePathOnBreadcrumbSelectThunk";
import { getResourcesExplorerGroupingLabel } from "../../utils/helpers/getResourcesExplorerGroupingLabel";
import { selectedViewProvidersSelector } from "../../../../../../../store/dashboards/selectors/widgets/common/selectedViewProvidersSelector";

export const ResourceExplorerBreadcrumbsBackButton: FC = () => {
  const dispatch = useAppDispatch();

  const path = useAppSelector(resourceExplorerPathSelector);
  const providers = useAppSelector(selectedViewProvidersSelector);

  const lastPath = path.length - 2;
  const groupBy = path.at(-2)?.groupedBy;
  const backClickHandler = useCallback(() => {
    dispatch(changePathOnBreadcrumbSelectThunk(lastPath));
  }, [dispatch, lastPath]);

  if (!groupBy) {
    return null;
  }

  return (
    <Tooltip
      title={`Back to ${getResourcesExplorerGroupingLabel(
        providers,
        groupBy,
      ).toLowerCase()}`}
      arrow
      placement="top"
    >
      <IconButton
        sx={{
          bgcolor: "grey.100",
        }}
        size="small"
        onClick={backClickHandler}
      >
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
};
