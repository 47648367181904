import { FC, Fragment, useCallback, useMemo } from "react";
import {
  LineChart as RechartsLineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import { useWillUnmount } from "rooks";
import { grey } from "@mui/material/colors";
import { lineChartLines } from "./lineChartLines";
import {
  CustomizedYAxisTick,
  YAxisFormatter,
} from "../../common/CustomizedYAxisTick";
import {
  CustomizedXAxisTick,
  XAxisTickProps,
} from "../../common/CustomizedXAxisTick";
import {
  ChartsTooltipContent,
  ChartsTooltipProps,
} from "../../common/ChartsTooltipContent";
import { LegendContent } from "../../common/legend/LegentContent";
import { ZoomWrapper } from "../../common/ZoomWrapper";
import { getLoadingLabel } from "../../common/LoadingLabel";
import { ChartsTooltipZoom } from "../../common/ChartsTooltipZoom";
import { useMultiTypeChartContext } from "../../../MultiTypeChartProvider";
import { ReferenceLineType } from "../../../utils/types/types";
import {
  useChartZoom,
  ZoomChangeFunction,
} from "../../../utils/hooks/useChartZoom.hook";
import { calculateLabelInterval } from "../../../utils/helpers/helpers";
import { getYAxisMaxValue } from "../../../utils/helpers/getYAxisMaxValue";
import { chartDataPointKey } from "../../../utils/constants/constants";
import { hasZeroReferenceLine } from "../../../utils/helpers/hasZeroReferenceLine";

interface LineChartProps {
  zoom: boolean;
  highlight: boolean;
  selectable: boolean;
  loading: boolean;
  yAxisFormatter?: YAxisFormatter;
  xAxisTickProps?: XAxisTickProps;
  tooltipProps?: ChartsTooltipProps;
  referenceLines?: ReferenceLineType[];
  emptyText?: string;
  onZoomChange?: ZoomChangeFunction;
}

export const LineChart: FC<LineChartProps> = ({
  highlight,
  yAxisFormatter,
  xAxisTickProps,
  selectable,
  tooltipProps,
  referenceLines,
  zoom,
  loading,
  emptyText,
  onZoomChange,
}) => {
  const { data, keys, colors, hover, chartType } = useMultiTypeChartContext();
  const {
    referenceAreaProps,
    zoomResetHandler,
    handlers,
    zoomed,
    zoomedData,
    zooming,
  } = useChartZoom(data, zoom, onZoomChange);

  useWillUnmount(() => {
    zoomResetHandler();
  });

  const { maxDataValue: yAxisMaxData, minDataValue: yAxisMinData } = useMemo(
    () => getYAxisMaxValue(zoomedData, chartType?.type, referenceLines),
    [zoomedData, chartType?.type, referenceLines],
  );

  const onMouseEnter = useCallback((key: string) => hover.setKey(key), [hover]);
  const onMouseLeave = useCallback(() => hover.setKey(""), [hover]);

  const isEmpty = !data.length;

  if (!data) {
    return null;
  }

  return (
    <ZoomWrapper enabled={zoom} zoomed={zoomed} onReset={zoomResetHandler}>
      <ResponsiveContainer width="100%" height={300}>
        <RechartsLineChart
          height={335}
          data={zoomedData}
          margin={{ top: 16 }}
          style={{ cursor: "crosshair" }}
          {...handlers}
        >
          <CartesianGrid
            strokeWidth={1}
            strokeOpacity={0.5}
            vertical={false}
            strokeDasharray="3 3"
          />
          <XAxis
            height={xAxisTickProps?.parentHeight}
            padding={xAxisTickProps?.parentPadding}
            dataKey={chartDataPointKey}
            strokeOpacity={0.2}
            strokeDasharray="3 3"
            interval={calculateLabelInterval(zoomedData.length)}
            tick={<CustomizedXAxisTick chartType="line" empty={isEmpty} />}
          >
            {getLoadingLabel({
              loading,
              empty: isEmpty,
              emptyText,
            })}
          </XAxis>
          <YAxis
            domain={[yAxisMinData, yAxisMaxData]}
            strokeOpacity={0.2}
            strokeDasharray="3 3"
            tick={
              <CustomizedYAxisTick
                empty={isEmpty || loading}
                formatter={yAxisFormatter}
              />
            }
          />

          {hasZeroReferenceLine(data) && (
            <ReferenceLine
              y={0}
              stroke={grey["300"]}
              label={{
                value: "0",
                fontSize: 14,
                fill: grey[400],
                position: { x: -5, y: 5 },
                style: { fontWeight: 500 },
              }}
            />
          )}

          <Tooltip
            content={
              zooming ? (
                <ChartsTooltipZoom
                  x1={referenceAreaProps.x1}
                  x2={referenceAreaProps.x2}
                />
              ) : (
                <ChartsTooltipContent
                  hoveredKey={hover.key}
                  referenceLines={referenceLines}
                  {...tooltipProps}
                />
              )
            }
          />
          {keys.visibleKeys && (
            <Fragment>
              {lineChartLines({
                keys: keys.visibleKeys,
                highlighted: hover.key,
                onMouseEnter,
                onMouseLeave,
                colors,
              })}
            </Fragment>
          )}

          {referenceLines?.map((props) => (
            <ReferenceLine {...props} key={props.uniqueId} />
          ))}

          {zoom && (
            <ReferenceArea
              {...referenceAreaProps}
              y1={yAxisMinData}
              y2={yAxisMaxData}
              strokeOpacity={0.3}
            />
          )}
        </RechartsLineChart>
      </ResponsiveContainer>

      {keys && (
        <Legend
          wrapperStyle={{
            position: "initial",
            alignSelf: "center",
          }}
          content={
            <LegendContent
              keys={keys.allKeys}
              colors={colors}
              selectable={selectable}
              visibleKeys={keys.visibleKeys}
              highlightable={highlight}
              referenceLines={referenceLines}
              setVisibleKeys={keys.setVisibleKeys}
            />
          }
        />
      )}
    </ZoomWrapper>
  );
};
