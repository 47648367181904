import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";

export const alertsCreatedBySortingFn = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  currentRow: Row<T>,
  nextRow: Row<T>,
) => {
  const currentCreatedBy = (
    currentRow.original.createdByEmail ?? ""
  ).toLowerCase();
  const nextCreatedBy = (nextRow.original.createdByEmail ?? "").toLowerCase();

  return currentCreatedBy > nextCreatedBy ? 1 : -1;
};
