import { FC } from "react";
import { List, Popover, PopoverProps } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { GroupingsPopoverItem } from "./GroupingsPopoverItem";
import { GroupingsPopoverDisabledOptions } from "./GroupingsPopoverDisabledOptions";
import { CategoryGroupingOption } from "./CategoryGroupingOption";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { resourceExplorerProvidersSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { getDefaultGroupings } from "../../../../../../resource-explorer-card/utils/helpers/getDefaultGroupings";

interface GroupingsPopoverProps {
  open: boolean;
  anchor: PopoverProps["anchorEl"];
  onClose(): void;
}

export const GroupingsPopover: FC<GroupingsPopoverProps> = ({
  open,
  anchor,
  onClose,
}) => {
  const enableDimensions = useFlag("EnableDimensions");

  const providers = useAppSelector(resourceExplorerProvidersSelector);
  const { groupingOptions, disabledGroupings } = getDefaultGroupings(providers);

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <List dense sx={{ width: 240 }}>
        {groupingOptions.map((grouping) => {
          return (
            <GroupingsPopoverItem
              key={grouping}
              grouping={grouping}
              onClick={onClose}
            />
          );
        })}

        {enableDimensions && <CategoryGroupingOption onClose={onClose} />}

        <GroupingsPopoverDisabledOptions
          disabledGroupings={disabledGroupings}
        />
      </List>
    </Popover>
  );
};
