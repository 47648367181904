import { FC, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DashboardCreateFolderOrItemDialogContent } from "./DashboardCreateFolderOrItemDialogContent";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { NavigationHierarchyItemType } from "../../../../../../store/navigation/utils/types";
import { createDashboardHierarchyFolderOrItemThunk } from "../../../../../../store/dashboards/thunks/dashboard-hierarchy/createDashboardHierarchyFolderOrItemThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { getPressEnterHandler } from "../../../../../utils/helpers/getPressEnterHandler";
import { createDashboardLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/createDashboardLoadingSelector";
import { createDashboardFolderLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard-hierarchy/createDashboardFolderLoadingSelector";
import { DashboardCreateDialogActions } from "../../../dashboard/components/create-dialog/components/DashboardCreateDialogActions";

interface DashboardCreateFolderOrItemDialogProps {
  onClose(): void;
  type: NavigationHierarchyItemType;
  folderId?: string;
}

export const DashboardCreateFolderOrItemDialog: FC<
  DashboardCreateFolderOrItemDialogProps
> = ({ onClose, type, folderId }) => {
  const [dashboardName, setDashboardName] = useState<string>("");
  const dispatch = useAppDispatch();
  const createDashboardLoading = useAppSelector(createDashboardLoadingSelector);
  const createFolderLoading = useAppSelector(
    createDashboardFolderLoadingSelector,
  );
  const disabled = !dashboardName.trim();
  const loading = createDashboardLoading || createFolderLoading;
  const navigate = useNavigate();

  const closeDialogHandler = useCallback(() => {
    onClose();
    setDashboardName("");
  }, [onClose]);

  const nameChangeHandler = useCallback((event: any) => {
    setDashboardName(event.target.value);
  }, []);

  const createItemCallbackHandler = useCallback(
    (id: string) => {
      navigate({ pathname: `/dashboards/${id}` });
    },
    [navigate],
  );

  const submitFormHandler = useCallback(async () => {
    const response = await dispatch(
      createDashboardHierarchyFolderOrItemThunk({
        name: dashboardName,
        folderId,
        type,
      }),
    ).unwrap();

    if (response) {
      createItemCallbackHandler(response?.id);
    }
    onClose();
  }, [
    dispatch,
    onClose,
    dashboardName,
    type,
    folderId,
    createItemCallbackHandler,
  ]);

  const handlePressEnter = getPressEnterHandler(
    submitFormHandler,
    disabled || loading,
  );

  return (
    <Dialog
      open={!!type}
      onClose={closeDialogHandler}
      maxWidth="sm"
      fullWidth
      onKeyDown={handlePressEnter}
    >
      <DialogTitleClosable
        title={type === "item" ? "Add Dashboard" : "Add Folder"}
        onClose={closeDialogHandler}
      />

      <DialogContent
        sx={{
          pb: 3,
        }}
        dividers
      >
        <DashboardCreateFolderOrItemDialogContent
          value={dashboardName}
          onTextFieldChange={nameChangeHandler}
          label={type === "item" ? "Dashboard Name" : "Folder Name"}
        />
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <DashboardCreateDialogActions
          isLoading={loading}
          disabled={disabled}
          onSubmit={submitFormHandler}
          onCloseDialog={closeDialogHandler}
        />
      </DialogActions>
    </Dialog>
  );
};
