import { resourceExplorerViewsHierarchyDataSelector } from "./resourceExplorerViewsHierarchyDataSelector";
import { RootState } from "../../../store";
import { isResourceExplorerFolder } from "../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";

//TODO: delete after views renaming (it should be items)
export const resourceExplorerViewsHierarchyViewsToItemsSelector = (
  state: RootState,
) => {
  const hierarchy = resourceExplorerViewsHierarchyDataSelector(state);
  return hierarchy?.map((item) => {
    if (!isResourceExplorerFolder(item)) {
      return item;
    }

    return {
      ...item,
      items: item.views,
    };
  });
};
