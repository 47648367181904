import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  cloudChiprApi,
  DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg,
} from "../../../../services/cloudchipr.api";
import { getResourceExplorerPossibleFiltersThunk } from "../../../resource-explorer/thunks/resource-explorer/getResourceExplorerPossibleFiltersThunk";

export const categoryDeleteFixedCacheKey =
  "deleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId-fixed-cache-key";

export const deleteCategoryByIdThunk = createAsyncThunk(
  "dimensions/deleteCategoryById",
  async (
    {
      dimensionId,
      categoryId,
    }: DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg,
    { dispatch },
  ) => {
    const {
      deleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId.initiate(
          { dimensionId, categoryId },
          { fixedCacheKey: categoryDeleteFixedCacheKey },
        ),
      ).unwrap();

      dispatch(
        getResourceExplorerPossibleFiltersThunk({
          provider: "global",
          forceRefetch: true,
        }),
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
