import { FC, Fragment } from "react";
import { AlertsUtilizationThresholdsSingleSelect } from "./AlertsUtilizationThresholdsSingleSelect";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { alertsDataThresholdsSelector } from "../../../../../../../../store/alerts/selectors/common/data/alertsDataThresholdsSelector";
import { UtilizationAlertsType } from "../../../../../utils/types/types";

interface AlertsUtilizationThresholdsSelectsProps {
  utilizationAlertType: UtilizationAlertsType;
}

export const AlertsUtilizationThresholdsSelects: FC<
  AlertsUtilizationThresholdsSelectsProps
> = ({ utilizationAlertType }) => {
  const thresholds = useAppSelector(alertsDataThresholdsSelector);

  return (
    <Fragment>
      {thresholds.map((threshold) => (
        <AlertsUtilizationThresholdsSingleSelect
          id={threshold.id}
          key={threshold.id}
          utilizationAlertType={utilizationAlertType}
          amount={threshold.amount ?? "1"}
          disableDeleteButton={thresholds.length === 1}
        />
      ))}
    </Fragment>
  );
};
