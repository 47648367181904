import { FC, useCallback } from "react";
import { Link } from "@mui/material";
import { TypographyWithCopy } from "../../../../../common/TypographyWithCopy";
import { toggleAlertsOpenDrawer } from "../../../../../../store/alerts/alertsSlice";
import { useAppDispatch } from "../../../../../../store/hooks";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";
import { setCostAnomalyAlertEditDataThunk } from "../../../../../../store/alerts/thunks/cost-anomaly-alerts/setCostAnomalyAlertEditDataThunk";
import { setUtilizationAlertEditDataThunk } from "../../../../../../store/alerts/thunks/utilization-alerts/setUtilizationAlertEditDataThunk";

interface AlertsDataGridNameCellProps {
  alertName: string;
  alertId: string;
  alertType: AlertTabType;
}

export const AlertsDataGridNameCell: FC<AlertsDataGridNameCellProps> = ({
  alertId,
  alertType,
  alertName,
}) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(toggleAlertsOpenDrawer());

    if (alertType === "costAnomaly") {
      dispatch(setCostAnomalyAlertEditDataThunk(alertId));
    } else {
      dispatch(setUtilizationAlertEditDataThunk(alertId));
    }
  }, [dispatch, alertType, alertId]);

  return (
    <TypographyWithCopy
      fontSize="inherit"
      value={alertName}
      component={Link}
      onClick={handleClick}
      sx={{ cursor: "pointer" }}
    />
  );
};
