import { FC, useCallback } from "react";
import { Grid, Stack } from "@mui/material";
import * as Yup from "yup";
import { FormikErrors, useFormik } from "formik";
import { useDidMount } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { GracePeriodSection } from "./components/grace-period/GracePeriodSection";
import { ScheduleActionTogglers } from "./components/ScheduleActionTogglers";
import { threeMonthsBySeconds } from "./components/utils/constants";
import { NotificationSnoozing } from "../../../../../automations/workflows/single-workflow/form/actions-section/notification-options/NotificationSnoozing";
import {
  IntegrationCustomMessage,
  IntegrationCustomMessageProps,
} from "../../custom-message/IntegrationCustomMessage";
import { SchedulesNotifications } from "../../../../common/create/notifications/SchedulesNotifications";
import {
  ProviderType,
  ResourceFiltersWithAction,
  ScheduleActionType,
  GracePeriod,
  Schedule,
} from "../../../../../../../services/cloudchipr.api";
import { NextPrevious } from "../../../../common/create/NextPrevious";
import { getEmailsValidationSchema } from "../../../../../../common/integration-dialogs/components/email/utils/validation";
import { ScheduleActionsStepInitialData } from "../../../../common/utils/types/types";
import { schedulesIntegrationsValidationSchema } from "../../../../common/utils/constants/validation";
import { getGracePeriodNotificationsValidationSchema } from "../../../utils/helpers/gracePeriodValidation";
import { formatNotificationsFromSlackToHtml } from "../../../../../../utils/helpers/formatNotificationsFromSlackToHtml";

const validationSchema = Yup.object({
  action: Yup.string().required("Please select action"),

  emails: Yup.object()
    .nullable()
    .when("action", {
      is: (action: string) => action?.includes("notify"),
      then: getEmailsValidationSchema(true),
      otherwise: Yup.object().nullable(),
    }),

  notifications: schedulesIntegrationsValidationSchema,

  grace_period: Yup.object({
    period: Yup.number()
      .required()
      .min(1, "Minimum value is 1.")
      .max(
        threeMonthsBySeconds,
        `Value can’t be more than ${threeMonthsBySeconds}`,
      ),
    notifications: Yup.array()
      // @ts-expect-error | types looks ok, can't understand what's the reason
      .of(getGracePeriodNotificationsValidationSchema())
      .required()
      .min(1),
  }).nullable(),

  min_threshold: Yup.number()
    .nullable()
    .when({
      is: null,
      otherwise: (schema) =>
        schema.required("Minimum threshold is a required field"),
    })
    .positive("Minimum threshold mast be a positive number."),
});

const initialValues: ScheduleActionsStepInitialData = {
  initialized: false,
  action: "" as ScheduleActionType,
  min_threshold: null,
  is_snoozed: true,
  emails: null,
  grace_period: null,
};

interface ScheduledJobsActionsStepProps extends ScheduleActionsStepInitialData {
  accountIds: string[];
  provider: ProviderType;
  onNext(): void;
  hideCancelButton?: boolean;
  onPrevious(): void;
  resourceFilters?: ResourceFiltersWithAction[];
  onFieldChange(key: string, value: any): void;
  isDirty: boolean;
  snoozed?: boolean;
  gracePeriod?: GracePeriod;
  minThreshold?: Schedule["min_threshold"];
}

export const ScheduledJobsActionsStep: FC<ScheduledJobsActionsStepProps> = ({
  isDirty,
  action,
  onNext,
  onPrevious,
  onFieldChange,
  resourceFilters,
  notifications,
  accountIds,
  provider,
  minThreshold,
  hideCancelButton,
  snoozed,
  emails,
  gracePeriod,
}) => {
  const enableWorkflowGracePeriod = useFlag("EnableWorkflowGracePeriod");

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      min_threshold: minThreshold,
    } as ScheduleActionsStepInitialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Object.keys(values).forEach((item) => {
        const key = item as keyof ScheduleActionsStepInitialData;
        onFieldChange(key, values[key]);
      });

      onNext();
    },
  });

  const {
    values,
    submitForm,
    setFieldValue: setFormikFieldValue,
    resetForm,
    errors,
    setFieldError,
    isValid,
    setValues,
  } = formik;

  const setFieldValue = useCallback(
    (key: string, value: any) => {
      setFormikFieldValue(key, value);
      onFieldChange(key, value);
    },
    [setFormikFieldValue, onFieldChange],
  );

  const notificationSnoozeHandler = useCallback(
    (snoozed: boolean) => setFieldValue("is_snoozed", snoozed),
    [setFieldValue],
  );

  const integrationCustomMessageHandler = useCallback(
    (props: IntegrationCustomMessageProps) => {
      return (
        <IntegrationCustomMessage
          {...props}
          actionFrom={values.action === "notify" ? "notify" : "clean"}
        />
      );
    },
    [values.action],
  );

  useDidMount(() => {
    const formatedNotifications =
      formatNotificationsFromSlackToHtml(notifications);

    const formatedGracePeriod = formatNotificationsFromSlackToHtml(
      gracePeriod?.notifications,
    );
    resetForm({
      values: {
        initialized: true,
        action,
        emails,
        notifications: formatedNotifications,
        min_threshold: minThreshold,
        is_snoozed: snoozed,
        grace_period: gracePeriod
          ? {
              ...gracePeriod,
              notifications: formatedGracePeriod,
            }
          : null,
      },
    });
  });

  if (!values.initialized) {
    return null;
  }

  return (
    <form>
      <Grid container justifyContent="center">
        <Grid item sm={10} md={10}>
          <Stack spacing={3}>
            <ScheduleActionTogglers
              setValues={setValues}
              currentAction={values.action}
              accountIds={accountIds}
              onFieldChange={onFieldChange}
              provider={provider}
              resourceFilters={resourceFilters}
            />

            {values.action?.includes("notify") && (
              <SchedulesNotifications
                errors={errors}
                // @ts-expect-error | types looks ok, can't understand what's the reason
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                selectedEmails={values.emails}
                threshold={values.action === "notify"}
                selectedIntegrations={values.notifications}
                selectedMinThreshold={values.min_threshold}
                IntegrationCustomMessage={integrationCustomMessageHandler}
              />
            )}

            {values.action === "notify" && (
              <NotificationSnoozing
                snoozed={!!values.is_snoozed}
                onChange={notificationSnoozeHandler}
              />
            )}

            {enableWorkflowGracePeriod && values.action?.includes("clean") && (
              <GracePeriodSection
                // @ts-expect-error | types looks ok, can't understand what's the reason
                setFieldValue={setFieldValue}
                gracePeriod={values.grace_period}
                error={
                  errors?.grace_period as FormikErrors<
                    ScheduleActionsStepInitialData["grace_period"]
                  >
                }
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      <NextPrevious
        isDirty={isDirty}
        onNext={submitForm}
        onPrevious={onPrevious}
        isNextDisabled={!isValid}
        hideCancelButton={hideCancelButton}
      />
    </form>
  );
};
