import { FC, Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";

import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { TreeItems } from "../../../sortable-tree/utils/types";

interface DashboardV2FoldersDeleteDialogContentProps {
  name: string;
  folderItems?: TreeItems;
}

export const DashboardV2FoldersDeleteDialogContent: FC<
  DashboardV2FoldersDeleteDialogContentProps
> = ({ name, folderItems }) => {
  return (
    <Stack {...navigationHierarchyItemNoDndKey}>
      <Typography variant="body1" color="text.primary">
        Please confirm to delete the
        <Typography component="span" fontWeight="bold">
          {" "}
          {name}{" "}
        </Typography>
        folder.{" "}
        {!!folderItems?.length &&
          "Deleting it will also delete all dashboards within it, including:"}
      </Typography>
      {!!folderItems?.length && (
        <Fragment>
          <Stack
            my={1.5}
            px={1}
            py={1}
            overflow="auto"
            spacing={1}
            maxHeight={400}
            borderRadius={0.5}
            bgcolor="grey.100"
          >
            {folderItems?.map((item) => {
              return (
                <Stack direction="row" spacing={1} key={item.id}>
                  <InsertChartOutlinedOutlinedIcon fontSize="small" />
                  <Typography variant="subtitle2">{item.name}</Typography>
                </Stack>
              );
            })}
          </Stack>

          <Typography color="text.secondary" variant="body2" fontStyle="italic">
            If you want to keep any dashboard in this folder, please move them
            to another folder before deleting.
          </Typography>
        </Fragment>
      )}
    </Stack>
  );
};
