import { createAsyncThunk } from "@reduxjs/toolkit";

import { getDashboardsVisibilityHierarchyThunk } from "./getDashboardsVisibilityHierarchyThunk";
import { updateDashboardVisibilityPublicHierarchyThunk } from "./updateDashboardVisibilityPublicHierarchyThunk";
import { updateDashboardVisibilityPrivateHierarchyThunk } from "./updateDashboardVisibilityPrivateHierarchyThunk";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";

interface UpdateDashboardVisibilityHierarchyThunkArg {
  id: string;
  folderId?: string;
  index?: number;
  visibility: NavigationItemsVisibilityType;
}

export const updateDashboardVisibilityHierarchyThunk = createAsyncThunk(
  "dashboard/updateDashboardVisibilityHierarchy",
  async (
    {
      id,
      folderId,
      index,
      visibility,
    }: UpdateDashboardVisibilityHierarchyThunkArg,
    { dispatch },
  ) => {
    if (visibility === "visible_to_everyone") {
      await dispatch(
        updateDashboardVisibilityPublicHierarchyThunk({
          id,
          folderId,
          index,
        }),
      );
    } else {
      await dispatch(
        updateDashboardVisibilityPrivateHierarchyThunk({
          id,
          folderId,
          index,
        }),
      );
    }
    dispatch(getDashboardsVisibilityHierarchyThunk());
  },
);
