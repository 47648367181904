import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getOffHoursThunk } from "./getOffHoursThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { automationIdSelector } from "../../selectros/common/automationIdSelector";
import { RootState } from "../../../store";
import { offHoursPayloadDataSelector } from "../../selectros/off-hours/data/offHoursPayloadDataSelector";
import {
  createAutomationFixedCacheKey,
  updateAutomationFixedCacheKey,
} from "../../utils/constants/fixedCacheKeys";
import { validateAutomationDataThunk } from "../validateAutomationDataThunk";
import { setAutomationStep } from "../../automationsSlice";

export const saveOffHoursThunk = createAsyncThunk(
  "automation/saveOffHours",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      postV2UsersMeOffHoursSchedules,
      patchV2UsersMeOffHoursSchedulesByScheduleId,
    } = cloudChiprApi.endpoints;

    const errors = await dispatch(validateAutomationDataThunk()).unwrap();

    if (errors) {
      dispatch(setAutomationStep("setup"));
      return;
    }

    const automationId = automationIdSelector(state);
    const payload = offHoursPayloadDataSelector(state);

    try {
      if (automationId) {
        await dispatch(
          patchV2UsersMeOffHoursSchedulesByScheduleId.initiate(
            {
              scheduleId: automationId,
              body: payload,
            },
            { fixedCacheKey: updateAutomationFixedCacheKey },
          ),
        ).unwrap();
      } else {
        await dispatch(
          postV2UsersMeOffHoursSchedules.initiate(
            {
              body: payload,
            },
            { fixedCacheKey: createAutomationFixedCacheKey },
          ),
        ).unwrap();
      }

      await dispatch(getOffHoursThunk(true)).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
