import { FC, Fragment } from "react";
import { GroupingByTagValuesTrigger } from "./components/GroupingByTagValuesTrigger";
import { GroupingsPopover } from "./components/GroupingsPopover";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../../services/cloudchipr.api";

interface GroupBySelectorProps {
  value: ResourceExplorerGrouping;
  providers: ProviderType[];
  groupValues?: string[];
  groupValueOptions?: { value: string; provider: ProviderType }[];
  disabled?: boolean;
  hiddenGroupings?: ResourceExplorerGrouping[];
  onGroupingChange?(
    grouping: ResourceExplorerGrouping,
    groupValue?: string[],
  ): void;
  categoryGroupingAvailable: boolean;
}

export const GroupBySelector: FC<GroupBySelectorProps> = ({
  value,
  providers,
  groupValues,
  groupValueOptions,
  disabled,
  onGroupingChange,
  hiddenGroupings,
  categoryGroupingAvailable,
}) => {
  const { open, anchor, openMenu, closeMenu } = useMenuHook();

  return (
    <Fragment>
      <GroupingByTagValuesTrigger
        open={open}
        groupBy={value}
        onClick={openMenu}
        disabled={disabled}
        providers={providers}
        groupValues={groupValues}
      />
      <GroupingsPopover
        open={open}
        anchor={anchor}
        onClose={closeMenu}
        providers={providers}
        activeGrouping={value}
        groupValues={groupValues}
        hiddenGroupings={hiddenGroupings}
        onGroupingSelect={onGroupingChange}
        groupValueOptions={groupValueOptions}
        categoryGroupingAvailable={categoryGroupingAvailable}
      />
    </Fragment>
  );
};
