import { FC, useCallback, useRef } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  Popover,
  PopoverOrigin,
  Select,
  Stack,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDidMount } from "rooks";
import { FilterSelectPopoverContent } from "./popover/FilterSelectPopoverContent";
import { NewFilterAddFn } from "./popover/FilterItemSelectMenuItem";

import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { ProviderType } from "../../../../../../services/cloudchipr.api";

interface FilterSelectProps {
  padding?: number;
  inGroup?: boolean;
  provider?: ProviderType;
  onRemove(): void;
  onSelect: NewFilterAddFn;
}

export const FilterSelect: FC<FilterSelectProps> = ({
  onRemove,
  onSelect,
  provider,
  padding = 1,
  inGroup,
}) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const onSelectHandler: NewFilterAddFn = useCallback(
    (...args) => {
      onSelect(...args);
      closeMenu();
      onRemove();
    },
    [onRemove, closeMenu, onSelect],
  );

  useDidMount(() => {
    if (ref.current) {
      openMenu({ currentTarget: ref.current });
    }
  });

  return (
    <Stack
      flex={1}
      padding={padding}
      direction="row"
      borderRadius={2}
      bgcolor={inGroup ? "grey.100" : "grey.50"}
      justifyContent="space-between"
    >
      <FormControl
        ref={ref}
        size="xsmall"
        sx={{ width: inGroup ? 170 : 190 }}
        onClick={openMenu}
      >
        <InputLabel>Select Filter</InputLabel>

        <Select
          value=""
          open={open}
          onClose={closeMenu}
          label="Select Filter"
          MenuProps={{ sx: { display: "none" } }}
        />
      </FormControl>

      <IconButton size="small" onClick={onRemove}>
        <DeleteOutlineIcon fontSize="small" />
      </IconButton>

      <Popover
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
      >
        <FilterSelectPopoverContent
          provider={provider}
          onSelect={onSelectHandler}
        />
      </Popover>
    </Stack>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
