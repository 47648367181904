import { FC, useCallback } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDidMount } from "rooks";
import { getEmailsValidationSchema } from "../../../../../common/integration-dialogs/components/email/utils/validation";
import { ActionTypeSwitch } from "../../../common/create/actions/ActionTypeSwitch";
import { SchedulesNotifications } from "../../../common/create/notifications/SchedulesNotifications";
import { NextPrevious } from "../../../common/create/NextPrevious";
import { ScheduleActionsStepInitialData } from "../../../common/utils/types/types";
import { schedulesIntegrationsValidationSchema } from "../../../common/utils/constants/validation";
import {
  OffHoursScheduleActionType,
  Schedule,
} from "../../../../../../services/cloudchipr.api";

const validationSchema = Yup.object({
  action: Yup.string(),

  emails: getEmailsValidationSchema(true),

  notifications: schedulesIntegrationsValidationSchema,

  min_threshold: Yup.number()
    .nullable()
    .when({
      is: null,
      otherwise: (schema) =>
        schema.required("Minimum threshold is a required field"),
    })
    .positive("Minimum threshold mast be a positive number."),
});

const initialValues: ScheduleActionsStepInitialData = {
  action: "" as OffHoursScheduleActionType,
  min_threshold: null,
  emails: null,
  initialized: false,
};

interface OffHoursActionsStepProps extends ScheduleActionsStepInitialData {
  onNext(): void;
  hideCancelButton?: boolean;
  onPrevious(): void;
  onFieldChange(key: string, value: any): void;
  isDirty: boolean;
  minThreshold?: Schedule["min_threshold"];
}

export const OffHoursActionsStep: FC<OffHoursActionsStepProps> = ({
  isDirty,
  action,
  onNext,
  onPrevious,
  onFieldChange,
  notifications,
  minThreshold,
  hideCancelButton,
  emails,
}) => {
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      min_threshold: minThreshold,
    },
    validationSchema: validationSchema,
    onSubmit: onNext,
  });

  const {
    values,
    submitForm,
    setFieldValue: setFormikFieldValue,
    resetForm,
    errors,
    setFieldError,
    isValid,
  } = formik;

  const setFieldValue = useCallback(
    (key: string, value: any) => {
      setFormikFieldValue(key, value);
      onFieldChange(key, value);
    },
    [setFormikFieldValue, onFieldChange],
  );

  useDidMount(() => {
    resetForm({
      values: {
        initialized: true,
        action,
        notifications,
        min_threshold: minThreshold,
        emails,
      },
    });
  });

  const changeHandler = useCallback(() => {
    const action = values.action === "notify" ? "silent" : "notify";

    if (!action?.includes("notify")) {
      setFieldValue("notifications", undefined);
    }

    setFieldValue("action", action);
  }, [setFieldValue, values.action]);

  if (!values.initialized) {
    return null;
  }

  return (
    <form>
      <Grid container justifyContent="center">
        <Grid item sm={10} md={8}>
          <Stack spacing={3}>
            <Typography variant="subtitle1">Select Notification</Typography>

            <ActionTypeSwitch
              onChange={changeHandler}
              isChecked={values.action === "notify"}
              value="notify"
              label="Notify"
            />

            {values.action === "notify" && (
              <SchedulesNotifications
                threshold
                errors={errors}
                // @ts-expect-error | types looks ok, can't understand what's the reason
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                selectedEmails={values.emails}
                selectedIntegrations={values.notifications}
                selectedMinThreshold={values.min_threshold}
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      <NextPrevious
        isDirty={isDirty}
        onNext={submitForm}
        onPrevious={onPrevious}
        isNextDisabled={!isValid}
        hideCancelButton={hideCancelButton}
      />
    </form>
  );
};
