import { FC, Fragment, useMemo } from "react";
import { Chip, Stack } from "@mui/material";
import { v4 as uuid } from "uuid";
import { NotificationsChip } from "./NotificationsChip";
import { NotificationsChipPopover } from "./NotificationsChipPopover";
import {
  CostAnomalyAlert,
  Integration,
} from "../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";

interface AlertsDataGridNotificationsCellProps {
  integrations: Integration[];
  emails: CostAnomalyAlert["emails"];
}

export const AlertsDataGridNotificationsCell: FC<
  AlertsDataGridNotificationsCellProps
> = ({ emails, integrations }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const allIntegrations = useMemo(() => {
    const emailsAsIntegrations: Partial<Integration>[] =
      emails?.to?.map((email) => ({
        name: email,
        type: "email",
        id: uuid(),
      })) ?? [];
    return [...integrations, ...emailsAsIntegrations];
  }, [integrations, emails]);

  const firstIntegration = allIntegrations?.at(0);
  const restIntegrations = useMemo(() => {
    return allIntegrations.slice(1, allIntegrations.length);
  }, [allIntegrations]);

  if (!firstIntegration) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      <NotificationsChip
        type={firstIntegration.type ?? "email"}
        name={firstIntegration.name ?? ""}
      />

      {restIntegrations?.length > 0 && (
        <Fragment>
          <Chip
            label={`+${restIntegrations.length}`}
            onClick={openMenu}
            variant="outlined"
            size="small"
            sx={{ ml: 0.5 }}
          />

          <NotificationsChipPopover
            open={open}
            anchor={anchor}
            closeMenu={closeMenu}
            integrations={restIntegrations}
          />
        </Fragment>
      )}
    </Stack>
  );
};
