import { FC, useCallback } from "react";
import moment from "moment/moment";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { IconButton, InputAdornment } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { NullableDate } from "../../../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";

interface AutomationTimePickerProps {
  value?: NullableDate;
  error?: string | boolean;
  icon?: boolean;
  onChange(date: NullableDate): void;
}

export const AutomationTimePicker: FC<AutomationTimePickerProps> = ({
  onChange,
  value,
  error,
  icon,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const dateAcceptHandler = useCallback(
    (value: any) => {
      if (value) {
        onChange(moment(value).format());
      }
    },
    [onChange],
  );

  // As we don't wanna react after every date change, we react only after onAccept
  const emptyOnChange = useCallback(() => {}, []);

  return (
    <MobileTimePicker
      ampm
      open={open}
      onOpen={openDialog}
      onClose={closeDialog}
      value={value ? moment(value) : null}
      onAccept={dateAcceptHandler}
      onChange={emptyOnChange}
      slotProps={{
        textField: {
          fullWidth: true,
          size: "small",
          variant: "outlined",
          error: !!error,
          sx: { width: icon ? 160 : 90 },
          InputProps: {
            endAdornment: icon ? (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={openDialog}>
                  <AccessTimeIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          },
        },
      }}
    />
  );
};
