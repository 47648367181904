import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { resourceExplorerCloudProvidersByViewIdSelector } from "../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerCloudProvidersByViewIdSelector";
import { getDefaultGroupings } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getDefaultGroupings";
import { setAlertsData } from "../../alertsSlice";
import { alertsCostAnomalyDataGroupingSelector } from "../../selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataGroupingSelector";

export const changeCostAnomalyAlertGroupingByViewIdThunk = createAsyncThunk(
  "alert/changeCostAnomalyAlertGroupingByViewId",
  (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const providers = resourceExplorerCloudProvidersByViewIdSelector(
      state,
      viewId,
    );
    const { groupingOptions } = getDefaultGroupings(providers ?? []);
    const currentGroupings = alertsCostAnomalyDataGroupingSelector(state);

    dispatch(
      setAlertsData({
        grouping: groupingOptions.includes(currentGroupings)
          ? currentGroupings
          : "service",
      }),
    );
  },
);
