import { ChangeEvent, FC } from "react";
import { Stack, TextField } from "@mui/material";

interface DashboardCreateDialogContentProps {
  value: string;
  onTextFieldChange: (e: ChangeEvent<any>) => void;
}
export const DashboardCreateDialogContent: FC<
  DashboardCreateDialogContentProps
> = ({ value, onTextFieldChange }) => {
  return (
    <Stack gap={3} pt={1}>
      <Stack gap={0.5}>
        <TextField
          value={value}
          onChange={onTextFieldChange}
          name="name"
          label="Dashboard Name"
          fullWidth
        />
      </Stack>
    </Stack>
  );
};
