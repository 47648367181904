import { FC, useCallback, useMemo } from "react";
import {
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import {
  setCommitmentsRecommendationsAccountIds,
  setCommitmentsRecommendationsAccountScope,
} from "../../../../../../../../../store/commitments/commitmentsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { commitmentsRecommendationsAccountIdsSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountIdsSelector";
import { organizationAccountsByCurrentOrgIdSelector } from "../../../../../../../../../store/commitments/selectors/organisations/organizationAccountsByCurrentOrgIdSelector";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

interface RecommendationLevelFilterAccountsSelectProps {
  toggleOpen(action: any): void;
}

export const RecommendationLevelFilterAccountsSelect: FC<
  RecommendationLevelFilterAccountsSelectProps
> = ({ toggleOpen }) => {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(organizationAccountsByCurrentOrgIdSelector);
  const accountIds = useAppSelector(
    commitmentsRecommendationsAccountIdsSelector,
  );

  const options = useMemo(() => {
    return (
      accounts?.map((account) => {
        return {
          value: account.id,
          listItemHeight: account.provider_account_id ? 55 : undefined,
          label: (
            <Stack justifyContent="space-between">
              <Typography variant="body2">
                {account.provider_account_name ?? account.provider_account_id}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {account.provider_account_id}
              </Typography>
            </Stack>
          ),
          rawValue: account,
        };
      }) ?? []
    );
  }, [accounts]);

  const submitHandlerOnClose = useCallback(
    (value: string[]) => {
      const newScope = value.length ? "linked" : "payer";

      dispatch(setCommitmentsRecommendationsAccountIds(value));
      toggleOpen(value);

      dispatch(setCommitmentsRecommendationsAccountScope(newScope));
    },
    [toggleOpen, dispatch],
  );

  const TriggerComponent = useCallback(
    ({ onClick }: FilterTriggerComponentProps) => {
      return (
        <ListItem
          onClick={onClick}
          sx={{
            py: 0.5,
            "&:hover": { bgcolor: "grey.100", cursor: "pointer" },
          }}
        >
          <ListItemText>Linked Account</ListItemText>

          <Tooltip
            arrow
            placement="bottom"
            title="Linked account - Recommendations are calculated at the individual or member account level to maximize savings for each account."
          >
            <InfoOutlinedIcon color="action" fontSize="small" />
          </Tooltip>

          <ChevronRightIcon fontSize="small" color="action" />
        </ListItem>
      );
    },
    [],
  );

  return (
    <DropdownSelect
      label="Accounts"
      options={options}
      values={accountIds}
      filterFn={accountOptionFilterFn}
      TriggerComponent={TriggerComponent}
      submitHandlerOnClose={submitHandlerOnClose}
      PopoverProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    />
  );
};

const accountOptionFilterFn = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  if (!option.rawValue) {
    return false;
  }

  const aim = `${option.rawValue.provider_account_name} ${option.rawValue.provider_account_id}`;

  return aim.toLowerCase().includes(keyword.trim().toLowerCase());
};
