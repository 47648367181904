import { alertsDataSelector } from "./data/alertsDataSelector";
import { isAlertDataChangedSelector } from "./isAlertDataChangedSelector";
import { RootState } from "../../../store";
import { AlertTabType } from "../../utils/types/types";

export const saveAlertButtonDisabledSelector = (
  state: RootState,
  alertType: AlertTabType,
) => {
  const alertData = alertsDataSelector(state);
  const nameValidation = !alertData.name;
  const isDataChange = isAlertDataChangedSelector(state, alertType);
  const isEditModeAndNoDataChange = !!alertData.id && !isDataChange;

  const timeIntervalValidation =
    alertType === "costAnomaly" &&
    (!alertData.timeInterval || Number(alertData.timeInterval) <= 0);

  const notificationsValidation = alertData?.notifications?.some(
    (integration) => {
      if (integration.type === "email" && !integration.to?.length) {
        return true;
      }
      return integration.type === "slack" && !integration.conversations?.length;
    },
  );

  const integrationValidation =
    notificationsValidation ||
    !(alertData?.notifications?.length || alertData?.emails?.to.length);

  const thresholdsValidation =
    !alertData.thresholds.length ||
    alertData.thresholds.some(
      (threshold) => !threshold.amount || Number(threshold.amount) <= 0,
    );

  return (
    nameValidation ||
    thresholdsValidation ||
    integrationValidation ||
    timeIntervalValidation ||
    isEditModeAndNoDataChange
  );
};
