import { resourceExplorerProviderFiltersForPayloadSelector } from "./payload-data/resourceExplorerProviderFiltersForPayloadSelector";
import { RootState } from "../../../../store";
import { resourceExplorerDatesToFetchSelector } from "../data/resourceExplorerDatesToFetchSelector";
import { ResourceExplorerFilterSchema } from "../../../../../services/cloudchipr.api";
import { resourceExplorerQuarterEndForecastVisibilitySelector } from "../data-grid/resourceExplorerQuarterEndForecastVisibilitySelector";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import { resourceExplorerDataPointSelector } from "../data/resourceExplorerDataPointSelector";
import { resourceExplorerGroupingSelector } from "../data/resourceExplorerGroupingSelector";
import { resourceExplorerGroupValuesSelector } from "../data/resourceExplorerGroupValuesSelector";
import { resourceExplorerProvidersSelector } from "../resourceExplorerProvidersSelector";
import { resourceExplorerDatesSelector } from "../data/resourceExplorerDatesSelector";
import { resourceExplorerDimensionSelector } from "../../filters/dimension/resourceExplorerDimensionSelector";
import { resourceExplorerTrendTypeSelector } from "../resourceExplorerTrendTypeSelector";

export const resourceExplorerPayloadDataSelector = (
  state: RootState,
): ResourceExplorerFilterSchema => {
  const date = resourceExplorerDatesToFetchSelector(state);
  const dates = resourceExplorerDatesSelector(state);
  const dataPoint = resourceExplorerDataPointSelector(state);
  const groupBy = resourceExplorerGroupingSelector(state);
  const groupValues = resourceExplorerGroupValuesSelector(state);
  const cloudProviders = resourceExplorerProvidersSelector(state);
  const dimension = resourceExplorerDimensionSelector(state);
  const trendType = resourceExplorerTrendTypeSelector(state);

  const quarterEndForecastVisibility =
    resourceExplorerQuarterEndForecastVisibilitySelector(state);
  const providerFilters =
    resourceExplorerProviderFiltersForPayloadSelector(state);

  const loadMonthlyForecastForGroupingItems =
    groupBy && groupingsExcludingForecastedCostsColumns.has(groupBy)
      ? undefined
      : true;

  const loadQuarterlyForecastForGroupingItems =
    groupBy && groupingsExcludingForecastedCostsColumns.has(groupBy)
      ? undefined
      : quarterEndForecastVisibility;

  return {
    dates,

    group_by: groupBy,
    group_values: groupValues ?? undefined,

    data_point: dataPoint ?? "monthly",
    is_tracked: false,

    date_from: date.label ? null : date.from,
    date_to: date.label ? null : date.to,
    date_label: date.label ?? null,

    load_monthly_forecast_for_grouping_items:
      loadMonthlyForecastForGroupingItems,
    load_quarterly_forecast_for_grouping_items:
      loadQuarterlyForecastForGroupingItems,

    cloud_providers: cloudProviders,

    dimension_id: dimension?.dimensionId,
    category_ids: dimension?.categoryIds?.length
      ? dimension?.categoryIds
      : null,

    trend_type: trendType,

    ...providerFilters,
  };
};
