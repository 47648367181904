import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";
import { joinThresholds } from "../../../cost-anomaly/utils/helpers/joinThresholds";
import { isCostAnomalyAlertsDataGridDataType } from "../../../cost-anomaly/utils/helpers/isCostAnomalyAlertsDataGridDataType";

export const alertsThresholdFilter = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const joinedThresholds = joinThresholds(
    row.original.thresholds,
    isCostAnomalyAlertsDataGridDataType(row.original)
      ? "costAnomaly"
      : "utilization",
  )
    .toLowerCase()
    .trim();
  const filterValue = keyword.toLowerCase().trim();

  return joinedThresholds.includes(filterValue);
};
