import { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Reservation } from "../../../../../../../../../services/cloudchipr.api";
import { getPercentage } from "../../../../../../../../../utils/numeral/getPercentage";
import { CommitmentsProgressBar } from "../../../../../../common/components/CommitmentsProgressBar";
import { getColorByValue } from "../../../../../../common/utils/helpers/grid/dataGridHelpers";
import { money } from "../../../../../../../../../utils/numeral/money";

interface UtilizationCellProps {
  reservation: Reservation;
}

export const UtilizationCell: FC<UtilizationCellProps> = ({ reservation }) => {
  const usedCost = reservation.used_commitment_to_date?.cost;
  const totalCost = reservation.total_commitment_to_date?.cost;
  const usedHours = reservation.used_commitment_to_date?.hours;
  const totalHours = reservation.total_commitment_to_date?.hours;

  const state = reservation.state;
  const expired = state === "expired";
  const percent = +getPercentage(usedCost ?? 0, totalCost ?? 0).toFixed(2);
  const color = getColorByValue(percent);

  return (
    <Stack>
      <Tooltip
        title={
          <Stack direction="row" spacing={0.5} width="fit-content">
            <Typography variant="body2" fontWeight="medium" color={color}>
              {usedHours}h
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              / {totalHours}h
            </Typography>
          </Stack>
        }
        arrow
        placement="top"
        slotProps={slotPropsSlotProps}
      >
        <Stack direction="row" spacing={0.5} width="fit-content">
          <Typography
            variant="body2"
            fontWeight="medium"
            color={expired ? "text.secondary" : color}
          >
            {money(usedCost)}
          </Typography>
          <Typography variant="body2" fontWeight="medium">
            / {money(totalCost)}
          </Typography>
        </Stack>
      </Tooltip>

      <CommitmentsProgressBar
        value={percent}
        overrideColor={expired ? grey[500] : undefined}
      />
    </Stack>
  );
};

const slotPropsSlotProps = {
  arrow: { sx: { color: "white" } },
  tooltip: {
    sx: {
      bgcolor: "white",
      color: "black",
      boxShadow: "0 0 20px -6px #000",
    },
  },
};
