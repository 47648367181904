import { FC, Fragment, useCallback } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CostAndUsageSumWidgetSetupContent } from "./content/CostAndUsageSumWidgetSetupContent";
import { WidgetViewSelect } from "../common/toolbar/WidgetViewSelect";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetSetupDateRangeSelect } from "../common/toolbar/date-picker/WidgetSetupDateRangeSelect";
import { WidgetsViewsHierarchySelect } from "../common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { sortedResourceExplorersSelector } from "../../../../../../../../store/resource-explorer/selectors/all-resource-explorers/sortedResourceExplorersSelector";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { costAndUsageSumREViewChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/widget-change/costAndUsageSumREViewChangeThunk";
import { costAndUsageSumDateChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/widget-change/costAndUsageSumDateChangeThunk";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { buildCostAndUsageSumWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/buildCostAndUsageSumWidgetThunk";
import { costAndUsageSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSubmitButtonDisabledSelector";

interface CostAndUsageSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CostAndUsageSumSetup: FC<CostAndUsageSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const enableResourceExplorerFolderViews = useFlag(
    "EnableResourceExplorerFolderViews",
  );

  const viewId = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("viewId"),
  );
  const setupDate = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("date"),
  );
  const views = useAppSelector(sortedResourceExplorersSelector);

  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const setupId = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("id"),
  );
  const disabled = useAppSelector(costAndUsageSubmitButtonDisabledSelector);

  const dateChangeHandler = useCallback(
    (date: any) => {
      dispatch(costAndUsageSumDateChangeThunk(date));
    },
    [dispatch],
  );

  const viewChangeHandler = useCallback(
    (e: any) => {
      dispatch(costAndUsageSumREViewChangeThunk(e.target.value));
    },
    [dispatch],
  );

  const viewChangeHandlerV2 = useCallback(
    (id: string) => {
      dispatch(costAndUsageSumREViewChangeThunk(id));
    },
    [dispatch],
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildCostAndUsageSumWidgetThunk(),
    ).unwrap();
    onSubmit();

    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Resource Explorer View to get started." : ""}
        appBar={
          <Fragment>
            {enableResourceExplorerFolderViews ? (
              <WidgetsViewsHierarchySelect
                selectedItemId={viewId}
                onChange={viewChangeHandlerV2}
              />
            ) : (
              <WidgetViewSelect
                viewId={viewId}
                views={views}
                onChange={viewChangeHandler}
              />
            )}

            {setupDate && (
              <WidgetSetupDateRangeSelect
                dates={setupDate}
                onChange={dateChangeHandler}
                disabled={viewGettingLoading}
              />
            )}
          </Fragment>
        }
      >
        <CostAndUsageSumWidgetSetupContent dateLabel={setupDate?.label} />
      </WidgetSetupLayout>
    </Fragment>
  );
};
