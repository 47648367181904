import { FC, Fragment, useCallback, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  PopoverOrigin,
} from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { ResourceExplorerCreateFolderOrItemDialogWrapper } from "./common/ResourceExplorerCreateFolderOrItemDialogWrapper";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../../../store/navigation/utils/types";

interface ResourceExplorerV2NavigationAddActionMenuProps {
  open: boolean;
  onCloseMenu(): void;
  anchorEl: MenuProps["anchorEl"];
  visibility?: NavigationItemsVisibilityType;
}
export const ResourceExplorerV2NavigationAddActionMenu: FC<
  ResourceExplorerV2NavigationAddActionMenuProps
> = ({ open, onCloseMenu, anchorEl, visibility }) => {
  const [dialogType, setDialogType] = useState<
    NavigationHierarchyItemType | undefined
  >();

  const viewClickHandler = useCallback(() => {
    setDialogType("item");
  }, []);

  const folderClickHandler = useCallback(() => {
    setDialogType("folder");
  }, []);

  const closeDialog = useCallback(() => {
    setDialogType(undefined);
    onCloseMenu();
  }, [onCloseMenu]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={onCloseMenu}
        anchorEl={anchorEl}
        disablePortal={false}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem onClick={viewClickHandler}>
          <ListItemIcon>
            <InsertChartOutlinedOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>

        <MenuItem onClick={folderClickHandler}>
          <ListItemIcon>
            <FolderOpenIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Folder</ListItemText>
        </MenuItem>
      </Menu>

      {dialogType && (
        <ResourceExplorerCreateFolderOrItemDialogWrapper
          onClose={closeDialog}
          type={dialogType}
          visibility={visibility}
        />
      )}
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
