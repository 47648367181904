import { FC } from "react";
import { Stack } from "@mui/material";
import { PotentialSavingsMetadata } from "./PotentialSavingsMetadata";
import { CommitmentsPageHeaderMetadataCard } from "../../../common/components/CommitmentsPageHeaderMetadataCard";
import { useAppSelector } from "../../../../../../store/hooks";
import { savingsPlansNetSavingsSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/totals/savingsPlansNetSavingsSelector";
import { savingsPlansCoverageAverageSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/totals/savingsPlansCoverageAverageSelector";
import { money } from "../../../../../../utils/numeral/money";
import { savingsPlansForGranularLoadingSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForGranularLoadingSelector";
import { savingsPlansGranularDataUncoveredUsageSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataUncoveredUsageSelector";

export const UtilizationAndCoverageMetadata: FC = () => {
  const netSavings = useAppSelector(savingsPlansNetSavingsSelector);
  const coverage = useAppSelector(savingsPlansCoverageAverageSelector);
  const loading = useAppSelector(savingsPlansForGranularLoadingSelector);
  const uncoveredUsage = useAppSelector(
    savingsPlansGranularDataUncoveredUsageSelector,
  );

  return (
    <Stack
      gap={2}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <CommitmentsPageHeaderMetadataCard
        primary="Not Covered(On-Demand)"
        loading={loading}
        value={money(uncoveredUsage)}
        tooltip="Eligible On-Demand spend over the selected time period that was not covered by a Savings Plan."
      />

      <CommitmentsPageHeaderMetadataCard
        primary="Coverage AVG"
        loading={loading}
        value={coverage !== null ? `${coverage}%` : "N/A"}
        tooltip="Average percentage of AWS usage covered by Savings Plans during the selected date range."
      />

      <CommitmentsPageHeaderMetadataCard
        primary="Net Savings"
        loading={loading}
        value={money(netSavings)}
        tooltip="Net Savings from commitments for the selected date range. Calculated by comparing the usage cost covered by Savings Plans to the public On-Demand cost."
      />

      <PotentialSavingsMetadata />
    </Stack>
  );
};
