import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { FC, RefObject, useCallback, useMemo } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnResizedEvent, ModuleRegistry } from "@ag-grid-community/core";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ColumnSizingState } from "@ag-grid-community/core/dist/types/src/interfaces/gridState";
import { workflowsTableColumns } from "./grid-options/workflowsTableColumns";
import { WorkflowDataGridNoRowsComponent } from "./grid-options/WorkflowDataGridNoRowsComponent";
import { Schedule } from "../../../../../../services/cloudchipr.api";
import {
  SettingsType,
  useAutomationsRowsOrder,
} from "../utils/hooks/useAutomationsRowsOrder.hook";
import { workflowsPinnedColumns } from "../utils/constans/workflowsPinnedColumns";
import { agGridOverwrittenIcons } from "../utils/constans/grid-icons";
import { useAutomationsColumnsSizes } from "../utils/hooks/useAutomationsColumnsSizes.hook";
import { useAppSelector } from "../../../../../../store/hooks";
import { originalDataCountSelector } from "../../../../../../store/automations/selectros/workflow/list-data/originalDataCountSelector";
import type {
  DragStoppedEvent,
  FilterChangedEvent,
  SortChangedEvent,
} from "@ag-grid-community/core/dist/types/src/events";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface WorkflowsDataGridComponentProps {
  data?: Schedule[];
  refToPassGrid: RefObject<AgGridReact>;
  onFilterChanged(event: FilterChangedEvent): void;
}

export const WorkflowsDataGridComponent: FC<
  WorkflowsDataGridComponentProps
> = ({ refToPassGrid, onFilterChanged, data }) => {
  const { sorting, onColumnsSortingChange, sortedData, onRowsOrderChange } =
    useAutomationsRowsOrder<Schedule>("workflow", data ?? []);
  const { setColumnSize, getColumnSize } =
    useAutomationsColumnsSizes("workflow");
  const originalDataCount = useAppSelector(originalDataCountSelector);
  const filterApplied = originalDataCount === data?.length;

  const columns = useMemo(() => {
    return workflowsTableColumns.map((column) => {
      const savedSize = getColumnSize(column.field);
      const rowDrag = column.rowDrag ? filterApplied : undefined;

      if (savedSize) {
        return { ...column, width: savedSize, rowDrag };
      }

      return { ...column, rowDrag };
    });
  }, [getColumnSize, filterApplied]);

  const columnSizing: ColumnSizingState = useMemo(() => {
    return {
      columnSizingModel: columns.map((column) => {
        return {
          colId: column.colId ?? column.field ?? "",
          width: column.width,
          flex: column.width ? undefined : column.flex,
        };
      }),
    };
  }, [columns]);

  const initialState = useMemo(() => {
    if (!sorting) {
      return null;
    }

    return {
      sort: { sortModel: sorting },
      columnSizing,
      columnPinning: workflowsPinnedColumns,
    };
  }, [sorting, columnSizing]);

  const sortChangeHandler = useCallback(
    (event: SortChangedEvent) => {
      const columnState = event.api.getColumnState();
      const sortState = columnState
        .filter(({ sort }) => sort !== null)
        .map((s) => {
          return {
            colId: s.colId,
            sort: s.sort,
            sortIndex: s.sortIndex,
          } as SettingsType;
        });

      onColumnsSortingChange(sortState);
    },
    [onColumnsSortingChange],
  );

  const columnResizeHandler = useCallback(
    (event: ColumnResizedEvent) => {
      if (!event.finished || event.source !== "uiColumnResized") {
        return;
      }

      const colId = event.column?.getColId();
      const size = event.column?.getActualWidth();
      setColumnSize(colId, size);
    },
    [setColumnSize],
  );

  const rowDragStopHandler = useCallback(
    (event: DragStoppedEvent<Schedule>) => {
      const rows: Schedule[] = [];
      event.api.forEachNode((node) => rows.push(node.data));

      onRowsOrderChange(rows);
    },
    [onRowsOrderChange],
  );

  if (!initialState) {
    return null;
  }

  return (
    <Box className="ag-theme-quartz" sx={wrapperStyles}>
      <AgGridReact
        rowDragManaged
        suppressMovableColumns
        ref={refToPassGrid}
        rowHeight={64}
        rowData={sortedData}
        columnDefs={columns}
        gridOptions={griOptions}
        initialState={initialState}
        onSortChanged={sortChangeHandler}
        onDragStopped={rowDragStopHandler}
        onFilterChanged={onFilterChanged}
        onColumnResized={columnResizeHandler}
        noRowsOverlayComponent={WorkflowDataGridNoRowsComponent}
      />
    </Box>
  );
};

const columnSortIconStyles = {
  "& .ag-sort-indicator-icon": { visibility: "hidden" },
  "& .ag-header-active:hover .ag-sort-indicator-icon": {
    visibility: "visible",
  },

  "& .ag-header-cell-sorted-desc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },
  "& .ag-header-cell-sorted-asc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },

  '& .ag-header-cell[aria-sort="ascending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
  '& .ag-header-cell[aria-sort="descending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
};

const rowHoverStyles = {
  "--ag-active-color": grey[100],
  "& .ag-row-hover": { backgroundColor: grey[100] },
};

const resizeHandle = {
  "& .ag-header-cell-resize": { width: 20, visibility: "hidden" },
  "& .ag-header-cell:hover .ag-header-cell-resize": { visibility: "visible" },
  "--ag-header-column-resize-handle-height": "80%",
};

const wrapperStyles = {
  height: "100%",
  width: "100%",
  "& .ag-cell-wrapper": { height: "100%" },
  "& .ag-root-wrapper": { borderRadius: 0, border: "none" },
  "--ag-header-height": "56px",
  "--ag-header-background-color": grey[100],

  ...columnSortIconStyles,
  ...rowHoverStyles,
  ...resizeHandle,
};

const griOptions = {
  icons: agGridOverwrittenIcons,
};
