import { FC, Fragment, SyntheticEvent, useCallback } from "react";
import { TabList } from "@mui/lab";
import { Divider, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/hooks";
import { commitmentTypesLabels } from "../utils/constants/labels";
import { CommitmentsTypeTab } from "../../../../../store/commitments/utils/types/types";
import { setCommitmentsTypeTab } from "../../../../../store/commitments/commitmentsSlice";

export const CommitmentTypeTabs: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGroupChange = useCallback(
    (_: SyntheticEvent, type: CommitmentsTypeTab) => {
      dispatch(setCommitmentsTypeTab(type));
      navigate({ search: `active=${type}` });
    },
    [dispatch, navigate],
  );

  return (
    <Fragment>
      <TabList onChange={handleGroupChange} sx={{ bgcolor: "white" }}>
        <Tab
          value="savings_plan"
          label={commitmentTypesLabels.savings_plan}
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="reservation"
          label={commitmentTypesLabels.reservation}
          sx={{ textTransform: "none" }}
        />
      </TabList>

      <Divider />
    </Fragment>
  );
};
