import { FC, Fragment, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { GroupItemsOperator } from "./GroupItemsOperator";
import { FilterRow } from "./FilterRow";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import {
  DynamicProviderFilterGroup,
  FiltersCombinationOperatorType,
  DynamicProviderFilter,
} from "../../utils/types/common";
import { AddFilterInProvider } from "../new-filter-creator/add-buttons/AddFilterInProvider";
import { generateFilterKey } from "../../utils/helpers/generateFilterKey";

interface FilterItemsGroupProps {
  parentOperator: FiltersCombinationOperatorType;
  index: number;
  provider: ProviderType;
  filters: DynamicProviderFilterGroup;
  onOperatorChange(operator: FiltersCombinationOperatorType): void;
  onFilterRemove(index: number): void;
  onFiltersChange(filter: DynamicProviderFilterGroup, index: number): void;
}

export const FilterItemsGroup: FC<FilterItemsGroupProps> = ({
  filters,
  parentOperator,
  index,
  onOperatorChange,
  onFiltersChange,
  provider,
  onFilterRemove,
}) => {
  const operatorChangeHandler = useCallback(
    (operator: FiltersCombinationOperatorType) => {
      onFiltersChange({ ...filters, operator }, index);
    },
    [index, onFiltersChange, filters],
  );

  const filterChangeHandler = useCallback(
    (newFilter: DynamicProviderFilter, newFilterIndex: number) => {
      onFiltersChange(
        {
          ...filters,
          filters: filters.filters.map((filter, i) => {
            if (i === newFilterIndex) {
              return newFilter;
            }

            return filter;
          }),
        },
        index,
      );
    },
    [onFiltersChange, index, filters],
  );

  const filterRemoveHandler = useCallback(
    (newFilterIndex: number) => {
      const filtered = filters.filters.filter((_, i) => i !== newFilterIndex);
      onFiltersChange({ ...filters, filters: filtered }, index);
    },
    [index, onFiltersChange, filters],
  );

  const addNewFilerInGroupHandler = useCallback(
    (newFilters: DynamicProviderFilterGroup) => {
      onFiltersChange({ ...filters, filters: newFilters.filters }, index);
    },
    [onFiltersChange, index, filters],
  );

  const clearGroupHandler = useCallback(() => {
    onFilterRemove(index);
  }, [index, onFilterRemove]);

  return (
    <Fragment>
      <GroupItemsOperator
        first={index === 0}
        operator={parentOperator}
        onChange={onOperatorChange}
      />

      <Stack
        p={1}
        ml={1}
        flex={1}
        border={1}
        spacing={1}
        borderRadius={2}
        bgcolor="grey.100"
        borderColor="grey.300"
      >
        {filters.filters.map((filter, index) => {
          return (
            <FilterRow
              index={index}
              filter={filter}
              provider={provider}
              key={generateFilterKey(filter)}
              parentOperator={filters.operator}
              onFilterRemove={filterRemoveHandler}
              onFiltersChange={filterChangeHandler}
              onOperatorChange={operatorChangeHandler}
            />
          );
        })}

        <AddFilterInProvider
          inGroup
          filters={filters}
          provider={provider}
          onFiltersChange={addNewFilerInGroupHandler}
        >
          <Button
            size="small"
            color="tertiary"
            sx={{ justifySelf: "end" }}
            onClick={clearGroupHandler}
          >
            Clear Group
          </Button>
        </AddFilterInProvider>
      </Stack>
    </Fragment>
  );
};
