import { Row } from "@tanstack/react-table";
import moment from "moment/moment";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { getTimeZoneObject } from "../../../../../../../utils/helpers/date-time/timeZones";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const scheduledJobsNextRunFilter = (
  row: Row<Schedule>,
  _: string,
  keyword: string,
) => {
  const schedule = row.original;
  const status = schedule.status;
  const nextRun = schedule?.metadata.next_run_at;
  const timeZone = `${
    getTimeZoneObject(schedule.time_zone).name
  } More`.toLowerCase();
  const filterValue = keyword.toLowerCase().trim();

  if (status === "inactive") {
    return false;
  }

  if (!nextRun) {
    return "never".includes(filterValue);
  }

  const formattedNextRunText = `${moment(nextRun).format("ddd")}, 
  ${formatDate(nextRun, {
    type: "dateWithComma",
  })} ${formatDate(nextRun, {
    type: "time",
  })}`.toLowerCase();

  return (
    formattedNextRunText.includes(filterValue) || timeZone.includes(filterValue)
  );
};
