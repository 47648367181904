import { FC } from "react";
import { TrendChip } from "../../../../../../../../../../common/chips/TrendChip";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerDataGridDataTotalCostDateDetailsSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalCostDateDetailsSelector";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";
import { getTrendTooltipTitle } from "../../../../../../../../../dashboard/utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { resourceExplorerDataGridDataPreviousCostDateDetailsSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataPreviousCostDateDetailsSelector";
import { resourceExplorerDateLabelSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDateLabelSelector";

interface TrendCellProps {
  value: number | null;
}

export const TrendCell: FC<TrendCellProps> = ({ value }) => {
  const totalCostDates = useAppSelector(
    resourceExplorerDataGridDataTotalCostDateDetailsSelector,
  );
  const previousPeriodCostDates = useAppSelector(
    resourceExplorerDataGridDataPreviousCostDateDetailsSelector,
  );
  const dateLabel = useAppSelector(resourceExplorerDateLabelSelector);
  const tooltipTitle = getTrendTooltipTitle(
    totalCostDates?.from_date,
    totalCostDates?.to_date,
    previousPeriodCostDates?.from_date,
    previousPeriodCostDates?.to_date,
    undefined,
    dateLabel,
  );

  const fromDate = formatDate(totalCostDates?.from_date ?? "", {
    type: "date",
  });
  const toDate = formatDate(totalCostDates?.to_date ?? "", {
    type: "date",
  });

  if (value === null) {
    return (
      <TooltipText
        tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
      >
        N/A
      </TooltipText>
    );
  }

  return <TrendChip value={value} tooltipTitle={tooltipTitle} />;
};
