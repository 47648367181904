import { FC, useCallback, useEffect, useState } from "react";
import { Divider, Drawer, Stack } from "@mui/material";
import { AlertsDrawerTabs } from "./components/AlertsDrawerTabs";
import { AlertDrawerHeader } from "./components/common/AlertsDrawerHeader";
import { AlertsCostsAnomalyDrawer } from "./components/cost-anomaly/AlertsCostAnomalyDrawer";
import { AlertsUtilizationDrawer } from "./components/utilization/AlertsUtilizationDrawer";
import { AlertTabType } from "../../../../../store/alerts/utils/types/types";
import { useAppSelector } from "../../../../../store/hooks";
import { alertsActiveTabSelector } from "../../../../../store/alerts/selectors/alertsActiveTabSelector";
import { alertsOpenDrawerSelector } from "../../../../../store/alerts/selectors/alertsOpenDrawerSelector";

interface AlertsDrawerProps {
  onClose(): void;
}

export const AlertsDrawer: FC<AlertsDrawerProps> = ({ onClose }) => {
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);
  const alertsOpenDrawer = useAppSelector(alertsOpenDrawerSelector);
  const [drawerTab, setDrawerTab] = useState<AlertTabType>(alertsActiveTab);

  const handleClose = useCallback(() => {
    onClose();
    setDrawerTab(alertsActiveTab);
  }, [onClose, alertsActiveTab]);

  useEffect(() => {
    setDrawerTab(alertsActiveTab);
  }, [alertsActiveTab]);

  return (
    <Drawer
      anchor="right"
      open={alertsOpenDrawer}
      onClose={handleClose}
      PaperProps={paperProps}
      slotProps={slotProps}
    >
      <AlertDrawerHeader onClose={handleClose} />
      <Divider />

      <Stack p={2} spacing={3}>
        <AlertsDrawerTabs drawerTab={drawerTab} setDrawerTab={setDrawerTab} />
        {drawerTab === "costAnomaly" && (
          <AlertsCostsAnomalyDrawer onClose={handleClose} />
        )}
        {drawerTab === "utilization" && (
          <AlertsUtilizationDrawer onClose={handleClose} />
        )}
      </Stack>
    </Drawer>
  );
};

const paperProps = {
  sx: {
    width: "50dvw",
  },
};

const slotProps = {
  backdrop: {
    invisible: true,
  },
};
