import { grey } from "@mui/material/colors";
import { StylesProps } from "../../../../../../../storybook/data-grid/utils/types/prop-types";

export const getCommitmentsTableStyles = (empty: boolean) => {
  if (!styles) {
    return undefined;
  }

  if (empty) {
    return {
      ...styles,
      tableContainer: { ...styles.tableContainer, borderRadius: "8px 8px 0 0" },
    };
  }

  return styles;
};

const styles: StylesProps["styles"] = {
  table: {
    "& th": { py: 2, bgcolor: "grey.100", height: 60 },
    "& th:first-of-type": { pl: 2 },
    "& td:first-of-type": { pl: 2 },
    "& tr:hover td": { bgcolor: grey[50] },
    "& tr td": { borderColor: grey[100], height: 60 },
  },

  tableContainer: {
    bgcolor: "white",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "grey.300",
  },
};
