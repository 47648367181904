import { FC } from "react";
import { Stack } from "@mui/material";
import { SavingsPlanTermFilter } from "./SavingsPlanTermFilter";
import { PaymentOptionsFilter } from "./PaymentOptionsFilter";
import { LockBackPeriodFilter } from "./LockBackPeriodFilter";
import { RecommendationLevelFilter } from "./recommendation-level-filter/RecommendationLevelFilter";
import { SavingsPlanTypeFilter } from "./SavingsPlanTypeFilter";

export const CommitmentsRecommendationsFilters: FC = () => {
  return (
    <Stack direction="row" spacing={1}>
      <SavingsPlanTypeFilter />

      <RecommendationLevelFilter />

      <SavingsPlanTermFilter />

      <PaymentOptionsFilter />

      <LockBackPeriodFilter />
    </Stack>
  );
};
