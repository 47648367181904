import {
  CategoryFilter,
  FilterGroup,
  ResourceExplorerDynamicFilter,
} from "../../../../../services/cloudchipr.api";
import {
  DynamicFiltersType,
  DynamicProviderFilter,
} from "../../../common/dynamic-filters/utils/types/common";
import { isDynamicProviderFilters } from "../../../common/dynamic-filters/utils/helpers/isDynamicProviderFilters";

export const convertDynamicFiltersToCategoryFilters = (
  filters: DynamicFiltersType,
): CategoryFilter => {
  const collector: ResourceExplorerDynamicFilter = {
    filter_items: [],
    filter_groups: [],
    operators: ["OR"],
  };

  let index = 0;

  const collectRecursive = (filters: DynamicProviderFilter[]): FilterGroup => {
    return filters.map((filter) => {
      if (isDynamicProviderFilters(filter)) {
        collector.operators.push(filter.operator);
        return collectRecursive(filter.filters);
      }

      collector.filter_items.push(filter);
      return index++;
    });
  };

  Object.values(filters).forEach((providerFilters) => {
    collector.operators.push(providerFilters.operator);
    collector.filter_groups.push(collectRecursive(providerFilters.filters));
  });

  return { filters: collector };
};
