import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { getWorkflowByIdThunk } from "./getWorkflowByIdThunk";
import {
  setAutomationData,
  setAutomationInitialized,
  setWorkflowSpecificData,
} from "../../automationsSlice";
import { getCronFrequency } from "../../utils/helpers/cron/getCronFrequency";
import { initiateEmptyAutomationThunk } from "../initiateEmptyAutomationThunk";
import { parseCron } from "../../utils/helpers/cron/parseCron";

export const initiateWorkflowThunk = createAsyncThunk(
  "automation/initiateWorkflow",
  async (id: string | undefined, { dispatch }) => {
    if (!id) {
      dispatch(initiateEmptyAutomationThunk("workflow"));
      return;
    }

    const workflow = await dispatch(getWorkflowByIdThunk(id)).unwrap();

    if (!workflow) {
      return;
    }

    const frequency = getCronFrequency(workflow.cron);
    const { daysOfMonth, daysOfWeek, hours, minutes } = parseCron(
      workflow.cron,
    );

    const hourlyRepeatValue = (frequency === "hourly" && hours) || undefined;
    const dailyRepeatValue =
      (frequency === "daily" && daysOfMonth) || undefined;

    const repeatValue =
      workflow.daily_schedule_interval ??
      workflow.weekly_schedule_interval ??
      hourlyRepeatValue ??
      dailyRepeatValue;

    const minute = minutes || 0;
    const hour = hours || 0;
    const runTime = workflow.cron
      ? moment().set({ hour, minute }).format()
      : workflow.start_date_time;
    const weekDays = daysOfWeek ?? undefined;

    dispatch(
      setWorkflowSpecificData({
        action: workflow.action,
        gracePeriod: workflow.grace_period,
        snoozed: workflow.is_snoozed ?? undefined,

        scheduler: { repeatValue, runTime, weekDays },
      }),
    );

    dispatch(
      setAutomationData({
        frequency,

        id: workflow.id,

        name: workflow.name,
        description: workflow.description ?? undefined,

        threshold: workflow.min_threshold,
        startDate: workflow.start_date_time,
        endDate: workflow.end_date_time,
        timeZone: workflow.time_zone,

        provider: workflow.cloud_provider,
        accountIds: workflow.accounts.map(({ id }) => id),
        regions: workflow.regions,

        notifications: workflow.notifications,
        emails: workflow.emails,

        filter: workflow.filter,

        status: workflow.status,
      }),
    );

    dispatch(setAutomationInitialized(true));
  },
);
