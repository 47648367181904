import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getDimensionsThunk } from "./getDimensionsThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { getResourceExplorerPossibleFiltersThunk } from "../../../resource-explorer/thunks/resource-explorer/getResourceExplorerPossibleFiltersThunk";

export const dimensionDeleteFixedCacheKey =
  "deleteUsersMeOrganisationsCurrentDimensionsByDimensionId-fixed-cache-key";

export const deleteDimensionThunk = createAsyncThunk(
  "dimensions/deleteDimension",
  async (dimensionId: string, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentDimensionsByDimensionId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentDimensionsByDimensionId.initiate(
          { dimensionId },
          { fixedCacheKey: dimensionDeleteFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(getDimensionsThunk(true));

      dispatch(
        getResourceExplorerPossibleFiltersThunk({
          provider: "global",
          forceRefetch: true,
        }),
      );

      enqueueSnackbar(`Dimension successfully deleted.`, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
