import { FC } from "react";
import Stack from "@mui/material/Stack";
import { TooltipText } from "../../../../common/TooltipText";
import { useSchedulePreviewDatesInfoHook } from "../../classic/utils/hooks/useSchedulePreviewDatesInfo.hook";

interface ScheduledListGridFrequencyCellProps {
  nextRun: string;
  cron: string | null;
  timeZone: string;
  weeklyInterval?: number | null;
}

export const SchedulesDataGridFrequencyCell: FC<
  ScheduledListGridFrequencyCellProps
> = ({ nextRun, timeZone, cron, weeklyInterval }) => {
  const { frequency } = useSchedulePreviewDatesInfoHook({
    nextRun,
    timeZone,
    cron,
    weeklyInterval,
  });

  return (
    <Stack justifyContent="center" height="100%" width="fit-content">
      <TooltipText tooltipTitle={frequency?.at(0)?.tooltip ?? ""}>
        {frequency?.at(0)?.label}
      </TooltipText>
    </Stack>
  );
};
