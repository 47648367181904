import { FC, Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";

import { DashboardV2NavigationAddActionMenu } from "./DashboardV2NavigationAddActionMenu";
import { NavItem } from "../../nav-item/NavItem";
import { useAppAbility } from "../../../../../services/permissions";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import { NavigationItemsVisibilityType } from "../../../../../store/navigation/utils/types";

interface DashboardV2NavigationAddDashboardNavItemPros {
  visibility?: NavigationItemsVisibilityType;
}
export const DashboardV2NavigationAddDashboardNavItem: FC<
  DashboardV2NavigationAddDashboardNavItemPros
> = ({ visibility }) => {
  const { cannot } = useAppAbility();

  const cannotCreateDashboard = cannot("create", "dashboard");
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  return (
    <Fragment>
      <NavItem
        nested
        primary="Add"
        disabled={cannotCreateDashboard}
        onButtonClick={openMenu}
        primaryTypographyProps={{ color: "primary" }}
        icon={AddIcon}
        iconProps={{ color: "primary" }}
      />
      <DashboardV2NavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
        visibility={visibility}
      />
    </Fragment>
  );
};
