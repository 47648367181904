import { FC, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDidMount } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { ScheduledJobsGrid } from "./components/ScheduledJobsGrid";
import { PageHeader } from "../../common/PageHeader";
import { useAppAbility } from "../../../../services/permissions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getWorkflowsThunk } from "../../../../store/automations/thunks/wokrflows/getWorkflowsThunk";
import { workflowsExistSelector } from "../../../../store/automations/selectros/workflow/list-data/workflowsExistSelector";
import { schedulesLoadingSelector } from "../../../../store/schedules/selectors/schedule/schedulesLoadingSelector";

export const ScheduledJobs: FC = () => {
  const { cannot } = useAppAbility();
  const dispatch = useAppDispatch();
  const canNotCreateSchedule = cannot("create", "schedule");
  const exist = useAppSelector(workflowsExistSelector);
  const loading = useAppSelector(schedulesLoadingSelector);
  const enableNewAutomations = useFlag("EnableWorkflowAutomationSinglePage");

  useDidMount(() => {
    dispatch(getWorkflowsThunk());
  });

  return (
    <Fragment>
      <PageHeader
        breadcrumbs={breadcrumbs}
        actions={
          exist ? (
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={
                enableNewAutomations
                  ? "/automations/workflow/create"
                  : "/schedule/classic/create"
              }
              disabled={canNotCreateSchedule}
            >
              <AddIcon sx={{ mr: 1 }} /> Create workflow
            </Button>
          ) : null
        }
      />

      {loading && <LinearProgress />}

      <ScheduledJobsGrid />
    </Fragment>
  );
};

const breadcrumbs = [{ title: "Workflows" }];
