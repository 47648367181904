import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../../../../services/cloudchipr.api";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";
import { resourceExplorerViewsHierarchyDefaultViewSelector } from "../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyDefaultViewSelector";
import { deleteResourceExplorerViewThunk } from "../../../../../../store/resource-explorer/thunks/common/deleteResourceExplorerViewThunk";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { deleteResourceExplorerHierarchyItemLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/deleteResourceExplorerHierarchyItemLoadingSelector";

interface ResourceExplorerV2ViewDeleteDialogProps {
  id: string;
  open: boolean;
  name: string;
  closeMenu(): void;
}

export const ResourceExplorerV2ViewDeleteDialog: FC<
  ResourceExplorerV2ViewDeleteDialogProps
> = ({ closeMenu, id, name, open }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { viewId } = useParams<{ viewId: string }>();

  const { refetch } = useGetUsersMeOrganisationsCurrentBudgetsQuery();

  const defaultResourceExplorerId = useAppSelector(
    resourceExplorerViewsHierarchyDefaultViewSelector,
  );
  const loading = useAppSelector(
    deleteResourceExplorerHierarchyItemLoadingSelector,
  );

  const removeResourceExplorerViewHandler = useCallback(async () => {
    await dispatch(deleteResourceExplorerViewThunk(id));

    if (viewId === id) {
      navigate({
        pathname: `/resource-explorer/${defaultResourceExplorerId}`,
      });
    }
    refetch();
  }, [refetch, dispatch, navigate, viewId, id, defaultResourceExplorerId]);

  const confirmRemoveViewHandler = useCallback(async () => {
    await removeResourceExplorerViewHandler();
    closeMenu();
  }, [closeMenu, removeResourceExplorerViewHandler]);

  return (
    <DeleteConfirmationDialog
      open={open}
      title="Delete View?"
      onClose={closeMenu}
      onDelete={confirmRemoveViewHandler}
      loading={loading}
      content={
        <div {...navigationHierarchyItemNoDndKey}>
          <Typography>
            Please confirm if you want to delete
            <Typography component="span" fontWeight="bold">
              {" "}
              {name}{" "}
            </Typography>
            view.
          </Typography>
        </div>
      }
    />
  );
};
