import { FC, ReactNode, useCallback } from "react";
import { FormControl, InputLabel, Select, Stack } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const AlertsHierarchySelectTrigger: FC<FilterTriggerComponentProps> = ({
  onClick,
  open,
  label,
}) => {
  const renderValue = useCallback((value: ReactNode) => {
    if (value) {
      return (
        <Stack direction="row" alignItems="center">
          <InsertChartOutlinedOutlinedIcon fontSize="small" sx={{ pr: 1 }} />
          {value}
        </Stack>
      );
    }
  }, []);

  return (
    <FormControl fullWidth>
      {label && <InputLabel>Select View</InputLabel>}
      <Select
        onClick={onClick}
        size="small"
        open={false}
        label={label ? "Select View" : ""}
        value={label}
        IconComponent={open ? ArrowDropUpIcon : ArrowDropDownIcon}
        renderValue={renderValue}
      />
    </FormControl>
  );
};
