import { currentAccountFilterTemplatesSelector } from "./currentAccountFilterTemplatesSelector";
import { RootState } from "../../../store";
import { FilterSet } from "../../../../services/cloudchipr.api";

export const filterTemplateByIdSelector = (
  state: RootState,
  id: string,
): FilterSet | undefined => {
  const filterSets = currentAccountFilterTemplatesSelector(state);

  return filterSets?.find(({ filter_set }) => filter_set.id === id)?.filter_set;
};
