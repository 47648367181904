import {
  CommitmentPaymentOption,
  CommitmentResourceType,
  CommitmentState,
  CommitmentType,
  LookbackPeriod,
  SavingsPlanAccountScope,
  SavingsPlanCoverageGrouping,
  SavingsPlanTermInYears,
  SavingsPlanType,
} from "../../../../../../services/cloudchipr.api";

export const commitmentTypesLabels: Record<CommitmentType, string> = {
  savings_plan: "Savings Plan",
  reservation: "Reservation",
};

export const commitmentsResourceTypeNames: Record<
  CommitmentResourceType,
  string
> = {
  ec2: "EC2",
  lambda: "Lambda",
  fargate: "Fargate",
  sagemaker: "SageMaker",
  eks: "EKS",
  elasticache: "ElastiCache",
  redshift: "Redshift",
  rds: "RDS",
  opensearch: "OpenSearch",
};

export const commitmentsPaymentOptionLabels: Record<
  CommitmentPaymentOption,
  string
> = {
  no_upfront: "No Upfront",
  partial_upfront: "Partial Upfront",
  all_upfront: "All Upfront",
};

export const commitmentsStateLabels: Record<CommitmentState, string> = {
  active: "Active",
  expired: "Expired",
  expires_soon: "Expires Soon",
};

export const savingsPlansTypeLabels: Record<SavingsPlanType, string> = {
  sage_maker: "SageMaker",
  compute: "Compute",
  ec2_instance: "EC2",
};

export const coverageGroupingNames: Record<
  SavingsPlanCoverageGrouping,
  string
> = {
  resource_type: "Resources",
  account: "Accounts",
  commitment: "Commitments",
};

export const savingsPlanTermLabels: Record<SavingsPlanTermInYears, string> = {
  one_year: "1-year",
  three_years: "3-years",
};

export const savingsPlanLockBackPeriodLabels: Record<LookbackPeriod, string> = {
  seven_days: "7 days",
  thirty_days: "30 days",
  sixty_days: "60 days",
};

export const savingsPlanLockBackPeriodDays: Record<LookbackPeriod, number> = {
  seven_days: 7,
  thirty_days: 30,
  sixty_days: 60,
};

export const savingsPlanAccountScopeLabels: Record<
  SavingsPlanAccountScope,
  string
> = {
  payer: "Payer",
  linked: "Linked",
};
