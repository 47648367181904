import { currentAccountProviderTypeSelector } from "./currentAccountProviderTypeSelector";
import { currentAccountSelectedRegionsSelector } from "./currentAccountSelectedRegionsSelector";
import { currentAccountSelector } from "./currentAccountSelector";
import { RootState } from "../../../store";
import {
  ResourceFiltersWithAction,
  ResourceType,
  ScheduleAccount,
} from "../../../../services/cloudchipr.api";
import { resourceGroups } from "../../../../utils/constants/resources/resourceGroups";
import { appliedFiltersByResourceTypeSelector } from "../../../filters/selectors/appliedFiltersByResourceTypeSelector";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { isStringResourceType } from "../../../../utils/helpers/resources/isStringResourceType";

export const currentAccountWorkflowDataSelector = (
  state: RootState,
  resourceType: string | null,
) => {
  const account = currentAccountSelector(state);
  const provider = currentAccountProviderTypeSelector(state);
  const regions = currentAccountSelectedRegionsSelector(state);

  if (!provider || !account) {
    return null;
  }

  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType as ResourceType) ??
    resourceType;

  const providerResourceGroups = resourceGroups.get(provider);
  const service =
    providerResourceGroups?.find(
      ({ resourceTypes }) =>
        isStringResourceType(parentResourceType) &&
        resourceTypes.includes(parentResourceType),
    )?.value ?? providerResourceGroups?.[0]?.value;

  let resourceTypes = providerResourceGroups?.find(
    ({ value }) => value === service,
  )?.resourceTypes;

  if (!resourceTypes) {
    resourceTypes = providerResourceGroups?.[0]?.resourceTypes ?? [];
  }

  const filters: ResourceFiltersWithAction[] = resourceTypes.map((rt) => {
    const emptyFilterTemplate = {
      type: rt,
      filter_items: [],
      filter_groups: [],
      operators: [],
    };

    return {
      filter:
        appliedFiltersByResourceTypeSelector(state, rt) ?? emptyFilterTemplate,
    };
  });

  const scheduleAccount: ScheduleAccount = {
    id: account.id,
    provider: account.type,
    status: account.status,
    provider_account_id: account.provider_account_id ?? "",
    provider_account_name: account.provider_account_name ?? "",
  };

  return {
    provider,
    filters,
    resourceType: service,
    account: scheduleAccount,
    regions: regions ?? [],
  };
};
