import { FC, Fragment, useCallback } from "react";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { useLocation } from "react-router-dom";
import { UniqueIdentifier } from "@dnd-kit/core";
import { useFlag } from "@unleash/proxy-client-react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { DashboardV2NavigationAddActionMenu } from "./components/DashboardV2NavigationAddActionMenu";
import { DashboardV2ItemActionButton } from "./components/item-action-menu/DashboardV2ItemActionButton";
import { DashboardV2FolderActionButton } from "./components/folder-action-menu/DashboardV2FolderActionButton";
import { DashboardV2NavigationAddDashboardNavItem } from "./components/DashboardV2NavigationAddDashboardNavItem";
import { DashboardV2Visibility } from "./components/visibility-types/DashboardV2Visibility";
import { NavItem } from "../nav-item/NavItem";
import {
  SortableTreeFolderActionArgs,
  SortableTreeItemActionArgs,
  SortableTreeOnchangeArgs,
} from "../sortable-tree/utils/types";
import { SortableTree } from "../sortable-tree/SortableTree";
import { useAppAbility } from "../../../../services/permissions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { getDashboardsHierarchyThunk } from "../../../../store/dashboards/thunks/dashboard-hierarchy/getDashboardsHierarchyThunk";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";
import { updateDashboardHierarchyThunk } from "../../../../store/dashboards/thunks/dashboard-hierarchy/updateDashboardHierarchyThunk";
import {
  resetNavigationDashboard,
  setNavigationDashboard,
} from "../../../../store/navigation/navigationSlice";
import { getDashboardsVisibilityHierarchyThunk } from "../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";
import { navigationDashboardPublicDataSelector } from "../../../../store/navigation/selectors/dashboard/navigationDashboardPublicDataSelector";

export const DashboardV2Navigation: FC = () => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  const dispatch = useAppDispatch();
  const { cannot, can } = useAppAbility();

  const location = useLocation();
  const selected = location.pathname.startsWith("/dashboards");

  const cannotCreateDashboard = cannot("create", "dashboard");
  const dashboardsAvailable = can("view", "dashboard");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const renderItemAction = useCallback(
    ({ id, name, parentId }: SortableTreeItemActionArgs) => {
      return (
        <DashboardV2ItemActionButton
          id={id}
          name={name}
          folderId={(parentId as string) ?? undefined}
          visibility="visible_to_everyone"
        />
      );
    },
    [],
  );

  const hierarchyChangeHandler = useCallback(
    ({ index, id, parentId, items }: SortableTreeOnchangeArgs) => {
      if (!items) {
        return;
      }
      dispatch(
        setNavigationDashboard({
          data: items,
          type: "visible_to_everyone",
        }),
      );
      dispatch(
        updateDashboardHierarchyThunk({
          id: id as string,
          index,
          folderId: (parentId as string) ?? undefined,
        }),
      );
    },
    [dispatch],
  );

  const hierarchyData = useAppSelector(navigationDashboardPublicDataSelector);

  const navigateToHandler = useCallback((id: UniqueIdentifier) => {
    return `/dashboards/${id}`;
  }, []);

  const renderFolderAction = useCallback(
    ({ id, name }: SortableTreeFolderActionArgs) => {
      return (
        <DashboardV2FolderActionButton
          id={id}
          name={name}
          visibility="visible_to_everyone"
        />
      );
    },
    [],
  );

  const resetHandler = useCallback(() => {
    dispatch(resetNavigationDashboard());
  }, [dispatch]);

  useEffectOnceWhen(() => {
    if (enableNavigationHierarchyVisibility) {
      dispatch(getDashboardsVisibilityHierarchyThunk());
    } else {
      dispatch(getDashboardsHierarchyThunk());
    }
  }, dashboardsAvailable);

  return (
    <NavItem
      primary="Dashboards"
      icon={DashboardCustomizeOutlinedIcon}
      selected={selected}
      secondaryAction={
        <IconButton
          size="small"
          disabled={cannotCreateDashboard}
          onClick={openMenu}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      }
      navCollapsed={navigationCollapsed}
    >
      {enableNavigationHierarchyVisibility ? (
        <DashboardV2Visibility />
      ) : (
        <Fragment>
          <SortableTree
            items={hierarchyData ?? []}
            renderItemAction={renderItemAction}
            renderFolderAction={renderFolderAction}
            onChange={hierarchyChangeHandler}
            getNavigateTo={navigateToHandler}
            onReset={resetHandler}
            icon={GridViewOutlinedIcon}
            disabled={cannotCreateDashboard}
            emptyText="No dashboards inside"
          />
          <DashboardV2NavigationAddDashboardNavItem />
        </Fragment>
      )}

      <DashboardV2NavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
      />
    </NavItem>
  );
};
