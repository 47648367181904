import { workflowsDataSelector } from "../../../automations/selectros/workflow/list-data/workflowsDataSelector";
import { RootState } from "../../../store";
import { Schedule } from "../../../../services/cloudchipr.api";

export const workflowByIdSelector = (
  state: RootState,
  id: string,
): Schedule | undefined => {
  const workflows = workflowsDataSelector(state);

  return workflows?.find((workflow) => workflow.id === id);
};
