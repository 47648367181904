import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { automationIdSelector } from "../../../common/automationIdSelector";

const selector =
  cloudChiprApi.endpoints.getV2UsersMeOffHoursSchedulesByScheduleId.select;

export const currentEditingOffHoursSelector = (state: RootState) => {
  const automationId = automationIdSelector(state);

  return selector({ scheduleId: automationId ?? "" })(state);
};
