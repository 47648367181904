import { FC, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { useToggle } from "rooks";
import { AlertCostAnomalyTimeIntervalSelect } from "./components/AlertCostAnomalyTimeIntervalSelect";
import { AlertsCostsAnomalyViewSelect } from "./components/AlertsCostsAnomalyViewSelect";
import { AlertsCostsAnomalyGroupingSelect } from "./components/AlertsCostsAnomalyGroupingSelect";
import { AlertsCostsAnomalyThresholdsSection } from "./components/AlertsCostsAnomalyThresholdsSection";
import { AlertsCostsAnomalyPreviewSection } from "./components/preview-section/AlertsCostsAnomalyPreviewSection";
import { AlertsNameTextField } from "../common/AlertsNameTextField";
import { AlertsNotifications } from "../common/AlertsNotifications";
import { AlertActions } from "../common/AlertActions";

interface AlertsCostsAnomalyDrawerProps {
  onClose(): void;
}

export const AlertsCostsAnomalyDrawer: FC<AlertsCostsAnomalyDrawerProps> = ({
  onClose,
}) => {
  const [averageCostCollapsed, toggleAverageCostCollapsed] = useToggle(true);

  return (
    <Fragment>
      <Typography variant="body1" fontWeight="medium">
        Alert details
      </Typography>

      <AlertsNameTextField />

      <Stack direction="row" spacing={1}>
        <AlertsCostsAnomalyViewSelect
          averageCostCollapsed={averageCostCollapsed}
        />
        <AlertsCostsAnomalyGroupingSelect
          averageCostCollapsed={averageCostCollapsed}
        />
      </Stack>

      <AlertCostAnomalyTimeIntervalSelect
        averageCostCollapsed={averageCostCollapsed}
      />

      <AlertsCostsAnomalyThresholdsSection />

      <AlertsCostsAnomalyPreviewSection
        collapsed={averageCostCollapsed}
        toggleCollapsed={toggleAverageCostCollapsed}
      />

      <AlertsNotifications actionFrom="cost-anomaly-alert" />

      <AlertActions onClose={onClose} alertType="costAnomaly" />
    </Fragment>
  );
};
