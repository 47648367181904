import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const automationsCreatorSortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current =
    nodeA.data.creator_details?.name ?? nodeA.data.creator_details?.email ?? "";
  const next =
    nodeB.data.creator_details?.name ?? nodeB.data.creator_details?.email ?? "";

  return current.localeCompare(next);
};
