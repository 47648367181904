import React, { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import NoFilteredData from "../../../../../../assets/images/no-filtered-data.png";

export const AlertsNoFilteredData: FC = () => {
  return (
    <Stack pt={12} spacing={4} alignItems="center" justifyContent="center">
      <img src={NoFilteredData} alt="NoFilteredData" />
      <Typography variant="h6" fontWeight="normal">
        No matching results found.
      </Typography>
    </Stack>
  );
};
