import { FC, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDidMount, useEffectOnceWhen } from "rooks";
import { Box, Stack, Typography } from "@mui/material";
import { TabPanel } from "@mui/lab";
import moment from "moment";
import { WeeksInterval } from "./WeeksInterval";
import { WeekDays } from "../../../../../common/create/scheduler/WeekDays";
import {
  DateTimeContent,
  ScheduleDatesInterface,
} from "../../../../../common/create/scheduler/DateTimeContent";
import { NextPrevious } from "../../../../../common/create/NextPrevious";
import { startEndDatesValidation } from "../../../../../common/utils/constants/validation";
import { RunAt } from "../../RunAt";
import {
  WorkflowSchedulerData,
  WorkflowSchedulerDateChangeType,
} from "../../../../utils/types/types";
import { parseCron } from "../../../../../../../../store/automations/utils/helpers/cron/parseCron";
import { generateWeeklyCron } from "../../../../../../../../store/automations/utils/helpers/cron/generateWeeklyCron";

interface InitialValuesInterface {
  weekDays: number[];
  runAt: string;
  weeklyInterval: number | null;
}

const initialValues: InitialValuesInterface & ScheduleDatesInterface = {
  runAt: moment().set({ hour: 0, minute: 0 }).format(),
  weekDays: [],
  startDate: "",
  endDate: "",
  timeZone: "",
  id: "",
  weeklyInterval: null,
};

const validationSchema = Yup.object({
  weekDays: Yup.array().of(Yup.number()).min(1, "Please select week day"),
  startDate: startEndDatesValidation,
  endDate: startEndDatesValidation,
});

interface WeeklyProps {
  cron: string | null;
  weeklyInterval: number | null;
  setScheduleDatesData(data: WorkflowSchedulerData): void;
  onPreviousStep(): void;
  hideCancelButton?: boolean;
  isDirty: boolean;
  onDateChange(data: WorkflowSchedulerDateChangeType): void;
}

export const Weekly: FC<WeeklyProps & ScheduleDatesInterface> = ({
  cron,
  startDate,
  setScheduleDatesData,
  endDate,
  timeZone,
  onPreviousStep,
  id,
  isDirty,
  hideCancelButton,
  weeklyInterval,
  onDateChange,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const cron = generateWeeklyCron(values.weekDays ?? [], values.runAt);

      setScheduleDatesData({
        cron,
        endDate: values.endDate,
        timeZone: values.timeZone,
        startDate: values.startDate,
        weeklyInterval: values.weeklyInterval,
      });
    },
  });

  const {
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    resetForm,
    dirty,
    isValid,
    setValues,
  } = formik;

  const changeHandler = useCallback(
    (days: number[]) => {
      const updatedValues = {
        ...values,
        weekDays: days,
      };

      const { startDate, weekDays, runAt, weeklyInterval, endDate, timeZone } =
        updatedValues;

      if (weekDays?.length) {
        onDateChange({
          endDate,
          timeZone,
          startDate: startDate ?? "",
          interval: +(weeklyInterval ?? 1),
          cron: generateWeeklyCron(weekDays, runAt),
        });
      }

      setFieldValue("weekDays", days);
    },
    [setFieldValue, onDateChange, values],
  );

  useDidMount(() => {
    const { daysOfWeek, hours, minutes } = parseCron(cron);

    const minute = minutes || 0;
    const hour = hours || 0;
    const weekDays = (daysOfWeek || []) as number[];
    const runAt = moment().set({ hour, minute }).format();
    const values = {
      id,
      weekDays,
      startDate,
      endDate,
      timeZone,
      weeklyInterval,
      runAt,
    };

    if (id) {
      resetForm({ values });
    } else {
      setValues(values);
    }
  });

  useEffectOnceWhen(() => {
    const { daysOfWeek, hours, minutes } = parseCron(cron);

    const minute = minutes || 0;
    const hour = hours || 0;
    const weekDays = (daysOfWeek || []) as number[];
    const runAt = moment().set({ hour, minute }).format();

    if (!weekDays.length) {
      return;
    }
    if (weekDays?.length) {
      onDateChange({
        endDate,
        timeZone,
        startDate: startDate ?? "",
        interval: +(weeklyInterval ?? 1),
        cron: generateWeeklyCron(weekDays, runAt),
      });
    }
  }, !!cron);

  const dateChangeHandler = useCallback(
    (key: string, value: number | string) => {
      const updatedValues = {
        ...values,
        [key]: value,
      };

      const { startDate, weekDays, runAt, weeklyInterval, endDate, timeZone } =
        updatedValues;

      if (weekDays?.length) {
        onDateChange({
          timeZone,
          endDate,
          startDate: startDate ?? "",
          cron: generateWeeklyCron(weekDays, runAt),
          interval: +(weeklyInterval ?? 1),
        });
      }
    },
    [values, onDateChange],
  );

  const setFieldValueHandler = useCallback(
    (key: string, value: number | string) => {
      setFieldValue(key, value);
      dateChangeHandler(key, value);
    },
    [setFieldValue, dateChangeHandler],
  );

  return (
    <TabPanel value="weekly">
      <form>
        <Stack direction="row" alignItems="baseline">
          <Typography variant="subtitle2" mr={9}>
            Every
          </Typography>
          <WeekDays
            color="primary"
            weekDays={values.weekDays}
            onChange={changeHandler}
            error={touched.weekDays && errors.weekDays}
          />
        </Stack>

        <WeeksInterval
          value={values.weeklyInterval}
          setFieldValue={setFieldValueHandler}
        />

        <RunAt
          value={values.runAt}
          setFieldValue={setFieldValueHandler}
          mr={8.5}
        />

        <DateTimeContent
          spacing="long"
          endDate={values.endDate}
          startDate={values.startDate}
          timeZone={values.timeZone}
          endDateError={touched.endDate && errors.endDate}
          startDateError={touched.startDate && errors.startDate}
          setFieldValue={setFieldValueHandler}
          showNextRuns={!!values?.weekDays?.length}
          tabType="weekly"
        />

        <Box mt={4}>
          <NextPrevious
            onNext={submitForm}
            hideCancelButton={hideCancelButton}
            onPrevious={onPreviousStep}
            isDirty={dirty || isDirty}
            isNextDisabled={!id ? !dirty || !isValid : !isValid}
          />
        </Box>
      </form>
    </TabPanel>
  );
};
