import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { Schedule } from "../../../../../../../services/cloudchipr.api";

export const quickFilterCreatorText = (
  params: GetQuickFilterTextParams<Schedule>,
) => {
  const details = params.data.creator_details;
  const target = `${details?.name} ${details?.email}`;

  return target.toLowerCase();
};
