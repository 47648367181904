import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { enabledProvidersByBillingStatusSelector } from "../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

export const resourceExplorerMinDateFixedCacheKey = `postUsersMeOrganisationsCurrentMinimumAvailableDate-fixed-cache-key`;

export const getResourceExplorerMinAvailableDateThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerMinAvailableDate",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const enabledProviders = enabledProvidersByBillingStatusSelector(state);

    const { postUsersMeOrganisationsCurrentMinimumAvailableDate } =
      cloudChiprApi.endpoints;

    await dispatch(
      postUsersMeOrganisationsCurrentMinimumAvailableDate.initiate(
        {
          body: { cloud_providers: enabledProviders },
        },
        { fixedCacheKey: resourceExplorerMinDateFixedCacheKey },
      ),
    ).unwrap();
  },
);
