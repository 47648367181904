import { dashboardHierarchySelector } from "./dashboardHierarchySelector";
import { RootState } from "../../../store";
import {
  Dashboard,
  DashboardFolder,
} from "../../../../services/cloudchipr.api";
import UnleashClient from "../../../../services/UnleashClient";
import { currentDashboardHierarchyVisibilitiesDataSelector } from "../dashboard-hierarchy-visibility/currentDashboardHierarchyVisibilitiesDataSelector";

export const dashboardHierarchyDataSelector = (
  state: RootState,
): (Dashboard | DashboardFolder)[] | undefined => {
  const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
    "EnableNavigationHierarchyVisibility",
  );

  if (enableNavigationHierarchyVisibility) {
    return currentDashboardHierarchyVisibilitiesDataSelector(state);
  }
  return dashboardHierarchySelector(state)?.data;
};
