import { FC, useCallback } from "react";
import { FormGroup } from "@mui/material";
import { ResourceExplorerProviderItem } from "./ResourceExplorerProviderItem";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { resourceExplorerProvidersSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { resourceExplorerProviderChangeThunk } from "../../../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerProviderChangeThunk";
import { resourceExplorerDimensionFilterAppliedSelector } from "../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionFilterAppliedSelector";
import { enabledProvidersByBillingStatusSelector } from "../../../../../store/common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

export const ResourceExplorerProviders: FC = () => {
  const dispatch = useAppDispatch();

  const enabledProviders = useAppSelector(
    enabledProvidersByBillingStatusSelector,
  );

  const providers = useAppSelector(resourceExplorerProvidersSelector);
  const dimensionFilterApplied = useAppSelector(
    resourceExplorerDimensionFilterAppliedSelector,
  );

  const onChipClickHandler = useCallback(
    (provider: ProviderType) => {
      dispatch(resourceExplorerProviderChangeThunk(provider));
    },
    [dispatch],
  );

  return (
    <FormGroup sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      {enabledProviders.map((item) => {
        return (
          <ResourceExplorerProviderItem
            value={item}
            onClick={onChipClickHandler}
            key={item}
            selected={providers.includes(item)}
            disabled={
              (providers.length === 1 && providers.includes(item)) ||
              dimensionFilterApplied
            }
          />
        );
      })}
    </FormGroup>
  );
};
