import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { updateDashboardHierarchyItemNameLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/updateDashboardHierarchyItemNameLoadingSelector";
import { getPressEnterHandler } from "../../../../../utils/helpers/getPressEnterHandler";
import { updateDashboardHierarchyFolderNameThunk } from "../../../../../../store/dashboards/thunks/common/updateDashboardHierarchyFolderNameThunk";

interface DashboardV2FoldersRenameDialogProps {
  open: boolean;
  id: string;
  name: string;
  closeMenu(): void;
}

export const DashboardV2FoldersRenameDialog: FC<
  DashboardV2FoldersRenameDialogProps
> = ({ open, closeMenu, id, name }) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(name);

  const loading = useAppSelector(
    updateDashboardHierarchyItemNameLoadingSelector,
  );

  const renameHandler = useCallback(async () => {
    await dispatch(
      updateDashboardHierarchyFolderNameThunk({
        folderId: id,
        name: value,
      }),
    );
    closeMenu();
  }, [dispatch, closeMenu, id, value]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  const disabled = value === name || !value;
  const handlePressEnter = getPressEnterHandler(
    renameHandler,
    disabled || loading,
  );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeMenu}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable title="Rename Folder" onClose={closeMenu} />

      <DialogContent dividers sx={{ py: 3 }}>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={value}
          size="small"
          variant="outlined"
          onKeyDown={handlePressEnter}
          onChange={inputChangeHandler}
          label="Folder Name"
          placeholder="Folder Name"
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeMenu}>
          Cancel
        </Button>
        <LoadingButton
          disabled={disabled}
          onClick={renameHandler}
          loading={loading}
          variant="contained"
        >
          Rename
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
