import { commitmentsRecommendationsFilteredDataSelector } from "./commitmentsRecommendationsFilteredDataSelector";
import { RootState } from "../../../../store";
import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../filters/commitmentsRecommendationsSavingsPlanTypeSelector";
import { connectedOrganizationExistsSelector } from "../../organisations/connectedOrganizationExistsSelector";
import {
  commitmentsPaymentOptionLabels,
  savingsPlansTypeLabels,
  savingsPlanTermLabels,
} from "../../../../../components/pages/commtiments/common/utils/constants/labels";

export const commitmentsRecommendationsCSVDataSelector = (state: RootState) => {
  const recommendations = commitmentsRecommendationsFilteredDataSelector(state);
  const savingsPlanType =
    commitmentsRecommendationsSavingsPlanTypeSelector(state);
  const orgExists = connectedOrganizationExistsSelector(state);

  return recommendations?.map((rec) => {
    const data: Record<string, any> = {
      Type: savingsPlansTypeLabels[rec.savings_plan_type],
      "Savings Plans term": savingsPlanTermLabels[rec.term_in_years],
    };

    if (savingsPlanType?.includes("ec2_instance")) {
      data["Instance Family"] = rec.instance_family;
      data["Region"] = rec.region;
    }

    if (!orgExists) {
      data["Account"] =
        `${rec.account.provider_account_name} (${rec.account.provider_account_id})`;
    }

    return {
      ...data,
      "Payment option": commitmentsPaymentOptionLabels[rec.payment_option],
      Commitment: rec.hourly_commitment,
      "Estimated On-Demand Coverage": rec.estimated_coverage,
      "Estimated Monthly Savings": rec.estimated_monthly_savings,
      URL: rec.action_url,
    };
  });
};
