import { dashboardByIdSelector } from "../dashboard/dashboardByIdSelector";
import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";

export const dashboardVisibilitySelectorByIdSelector = (
  state: RootState,
  dashboardId: string,
): NavigationItemsVisibilityType | undefined => {
  const data = dashboardByIdSelector(state, dashboardId);
  return data?.visibility;
};
