import { FC, ReactNode } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { SxProps } from "@mui/system";

interface CommitmentsDrawerHeaderInfoCardProps {
  title: string;
  value?: ReactNode;
  loading?: boolean;
  children?: ReactNode;
  keySx?: SxProps;
  valueSx?: SxProps;
}

export const CommitmentsDrawerHeaderInfoCard: FC<
  CommitmentsDrawerHeaderInfoCardProps
> = ({ value, title, loading, children, keySx, valueSx }) => {
  const child = children ?? (
    <Typography variant="body1" fontWeight="medium">
      {value ?? "N/A"}
    </Typography>
  );

  return (
    <Stack
      direction="row"
      border={1}
      overflow="hidden"
      borderRadius={1.5}
      borderColor="grey.300"
    >
      <Box bgcolor="grey.100" py={0.5} px={1.25} sx={keySx}>
        <Typography variant="caption" color="text.secondary">
          {title}
        </Typography>
      </Box>

      <Box
        bgcolor="white"
        py={0.5}
        sx={valueSx}
        px={1.25}
        borderLeft={1}
        borderColor="grey.300"
      >
        {loading ? (
          <Skeleton variant="text" height={24} width={150} />
        ) : (
          <Stack>{child}</Stack>
        )}
      </Box>
    </Stack>
  );
};
