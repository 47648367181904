import { Typography } from "@mui/material";
import { CostBreakdownV2WidgetTableForecastedCostHeaderCell } from "../../cells/header/CostBreakdownV2WidgetTableForecastedCostHeaderCell";
import { CostBreakdownV2WidgetLabelCell } from "../../cells/CostBreakdownV2WidgetLabelCell";
import { CostBreakdownPreviousPeriodCell } from "../../cells/CostBreakdownPreviousPeriodCell";
import { CostBreakdownTrendCell } from "../../cells/CostBreakdownTrendCell";
import { CostBreakdownV2WidgetTableLabelHeaderCell } from "../../cells/header/CostBreakdownV2WidgetTableLabelHeaderCell";
import { CostBreakdownV2WidgetTableTotalCostHeaderCell } from "../../cells/header/CostBreakdownV2WidgetTableTotalCostHeaderCell";
import { CostBreakdownV2WidgetTablePrevPeriodTotalCostHeaderCell } from "../../cells/header/CostBreakdownV2WidgetTablePrevPeriodTotalCostHeaderCell";
import { CostBreakdownV2WidgetTableTrendHeaderCell } from "../../cells/header/CostBreakdownV2WidgetTableTrendHeaderCell";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { labelColumnSortingFn } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import {
  ForecastOption,
  NullableForecastedCost,
  ResourceExplorerGrouping,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { forecastedCostOptionLabels } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";

const getColumnsStartPart = (
  widgetId: string,
): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  return [
    {
      size: 400,
      minSize: 150,
      accessorKey: "label",
      header: () => {
        return (
          <CostBreakdownV2WidgetTableLabelHeaderCell widgetId={widgetId} />
        );
      },
      sortingFn: labelColumnSortingFn,
      cell: CostBreakdownV2WidgetLabelCell,
    },
    {
      minSize: 110,
      accessorKey: "total_cost",
      header: () => (
        <CostBreakdownV2WidgetTableTotalCostHeaderCell widgetId={widgetId} />
      ),
      cell: (cell) => {
        const val = cell.getValue() as number;

        return (
          <Typography variant="body2" align="right">
            {isNaN(val) ? "N / A" : money(val)}
          </Typography>
        );
      },
    },
    {
      minSize: 170,
      accessorKey: "previous_period_total_cost",
      header: () => (
        <CostBreakdownV2WidgetTablePrevPeriodTotalCostHeaderCell
          widgetId={widgetId}
        />
      ),
      cell: (cell) => {
        return (
          <CostBreakdownPreviousPeriodCell
            widgetId={widgetId}
            value={cell.getValue() as number | null}
          />
        );
      },
    },
    {
      size: 100,
      accessorKey: "trend",
      header: () => (
        <CostBreakdownV2WidgetTableTrendHeaderCell widgetId={widgetId} />
      ),
      cell: (cell) => {
        return (
          <CostBreakdownTrendCell
            value={cell.getValue() as number | null}
            widgetId={widgetId}
          />
        );
      },
    },
  ];
};

interface GetCostBreakdownWidgetTableViewColumnsArgs {
  widgetId: string;
  forecastOption?: ForecastOption;
  grouping?: ResourceExplorerGrouping;
}

export const getCostBreakdownV2WidgetTableViewColumns = ({
  widgetId,
  forecastOption,
  grouping,
}: GetCostBreakdownWidgetTableViewColumnsArgs): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  const startPart = getColumnsStartPart(widgetId);
  if (
    !grouping ||
    groupingsExcludingForecastedCostsColumns.has(grouping) ||
    !forecastOption
  ) {
    return startPart;
  }
  return [
    ...startPart,
    {
      minSize: 190,
      accessorKey:
        forecastOption === "month" ? "monthly_forecast" : "quarterly_forecast",
      header: () => (
        <CostBreakdownV2WidgetTableForecastedCostHeaderCell
          widgetId={widgetId}
        />
      ),
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "month",
        )} Cost`,
      },
      cell: (cell) => {
        const data: NullableForecastedCost = cell.getValue();

        return (
          <Typography variant="body2" align="right" pr={1}>
            {data === null || data === undefined
              ? "-"
              : `~${money(data?.cost)}`}
          </Typography>
        );
      },
    },
  ];
};
