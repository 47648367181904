import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { utilizationAlertTypeTitles } from "../utils/constants/constants";
import { UtilizationAlertsType } from "../../../../../utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { alertsUtilizationDataAlertTypeSelector } from "../../../../../../../../store/alerts/selectors/utilization-alerts/data/alertsUtilizationDataAlertTypeSelector";
import {
  addDefaultThresholdByType,
  setAlertsData,
} from "../../../../../../../../store/alerts/alertsSlice";

export const AlertsUtilizationAlertTypeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const selectedAlertType = useAppSelector(
    alertsUtilizationDataAlertTypeSelector,
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<UtilizationAlertsType>) => {
      const alertType = event.target.value as UtilizationAlertsType;

      if (alertType !== selectedAlertType) {
        dispatch(setAlertsData({ basedOn: alertType, thresholds: [] }));
        dispatch(
          addDefaultThresholdByType(
            alertType === "commitments_expiration" ? "day" : "percent",
          ),
        );
      }
    },
    [dispatch, selectedAlertType],
  );

  const renderValue = useCallback((value: UtilizationAlertsType) => {
    return utilizationAlertTypeTitles.get(value);
  }, []);

  return (
    <FormControl size="small" fullWidth sx={{ flex: 1 }}>
      <InputLabel>Alert Type</InputLabel>

      <Select
        fullWidth
        size="small"
        label="Alert Type"
        onChange={handleChange}
        renderValue={renderValue}
        value={selectedAlertType}
      >
        <MenuItem value="commitments_expiration">
          <ListItemText
            primary={utilizationAlertTypeTitles.get("commitments_expiration")}
            secondary="Get notified when your Commitments are about to expire."
            primaryTypographyProps={{ variant: "body1" }}
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </MenuItem>
        <MenuItem value="commitments_utilization">
          <ListItemText
            primary={utilizationAlertTypeTitles.get("commitments_utilization")}
            secondary="Get notified about your Commitments utilization."
            primaryTypographyProps={{ variant: "body1" }}
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </MenuItem>
        <MenuItem value="coverage">
          <ListItemText
            primary={utilizationAlertTypeTitles.get("coverage")}
            secondary="Get notified about your Resource Type coverage."
            primaryTypographyProps={{ variant: "body1" }}
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
