import { FC, memo, useCallback } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { AddResourceComponent } from "./AddResourceComponent";
import { SchedulesResourcesListItem } from "./SchedulesResourcesListItem";
import {
  ProviderType,
  ResourceType,
  ResourceFiltersWithAction,
  ResourceFilters,
} from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { defaultFilterTemplateFiltersByAccountIdSelector } from "../../../../../../store/filters/selectors/filter-set/default/defaultFilterTemplateFiltersByAccountIdSelector";
import { getActionableResourceTypeByParentResourceType } from "../../../../../../utils/helpers/resources/getActionableResourceTypeByParentResourceType";
import { CleanActionTypes } from "../../../../../../utils/clean-options";
import { useAllResourcesVisibility } from "../../utils/hooks/useAllResourcesVisibility.hook";
import { AutomationType } from "../../../../../../store/automations/utils/types/common";

interface SchedulesResourcesListProps {
  parentScheduleType: AutomationType;
  resources: ResourceFiltersWithAction[];
  provider: ProviderType;
  accountIds: string[];
  errors?: any;
  disabled: boolean;
  setFieldValue(field: string, value: any): void;
  scheduleAction: string | null;
  regions: string[];
  scheduleId: string;
  gracePeriodEnabled: boolean;
}

export const SchedulesResourcesList: FC<SchedulesResourcesListProps> = memo(
  ({
    accountIds,
    provider,
    resources,
    errors,
    setFieldValue,
    disabled,
    parentScheduleType,
    regions,
    scheduleAction,
    scheduleId,
    gracePeriodEnabled,
  }) => {
    const allDefaultFilters = useAppSelector((state) =>
      defaultFilterTemplateFiltersByAccountIdSelector(
        state,
        accountIds[0],
        provider,
      ),
    );

    const resourcesVisibility = useAllResourcesVisibility({
      accountIds: accountIds.join(", "),
      provider,
    });

    const addActionToFilter = useCallback(
      (filter: ResourceFilters): ResourceFiltersWithAction => {
        const actionName = (
          parentScheduleType === "offHours" ? "stop" : "delete"
        ) as CleanActionTypes;

        const actionsAvailability =
          Object.values(resourcesVisibility)?.at(0)?.[
            getActionableResourceTypeByParentResourceType(filter.type)
          ]?.actions;

        if (actionsAvailability?.[actionName] !== null) {
          return {
            filter,
            action: actionName === "delete" ? "terminate" : "stop",
          };
        }

        return { filter };
      },
      [parentScheduleType, resourcesVisibility],
    );

    const addResource = useCallback(
      (type: ResourceType) => {
        const defaultFilters = allDefaultFilters?.[type];

        defaultFilters &&
          setFieldValue("filter", [
            ...resources,
            addActionToFilter(defaultFilters),
          ]);
      },
      [resources, setFieldValue, allDefaultFilters, addActionToFilter],
    );

    const setChangedFilters = useCallback(
      (filters: ResourceFiltersWithAction[]) => {
        setFieldValue("filter", filters);
      },
      [setFieldValue],
    );

    return (
      <Grid container justifyContent="center">
        <Grid item sm={10} md={8}>
          <Stack spacing={4}>
            <Typography variant="subtitle1">Select Services</Typography>

            {resources.map((resourceFilters, index) => {
              return (
                <SchedulesResourcesListItem
                  index={index}
                  regions={regions}
                  provider={provider}
                  gracePeriodEnabled={gracePeriodEnabled}
                  resources={resources}
                  scheduleId={scheduleId}
                  accountIds={accountIds}
                  scheduleAction={scheduleAction}
                  error={errors?.[index]?.filters}
                  key={resourceFilters.filter.type}
                  resourceFilters={resourceFilters}
                  scheduleType={parentScheduleType}
                  setChangedFilters={setChangedFilters}
                  addActionToFilter={addActionToFilter}
                />
              );
            })}

            <AddResourceComponent
              resources={resources}
              scheduleType={parentScheduleType}
              disabled={disabled}
              provider={provider}
              accountIds={accountIds}
              addResource={addResource}
              error={typeof errors === "string" && errors}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  },
);
