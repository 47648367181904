import { FC, useEffect, useMemo } from "react";
import { RecommendationsDataGridToolbar } from "./data-grid/RecommendationsDataGridToolbar";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { getCommitmentsTableStyles } from "../../../../common/utils/helpers/grid/getCommitmentsTableStyles";
import {
  PaginationConfig,
  ToolbarConfig,
} from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsRecommendationsFilteredDataSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsFilteredDataSelector";
import { connectedOrganizationExistsSelector } from "../../../../../../../store/commitments/selectors/organisations/connectedOrganizationExistsSelector";
import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTypeSelector";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { EmptyDataGridFooter } from "../../../../common/components/EmptyDataGridFooter";
import { setCommitmentsRecommendationsFilters } from "../../../../../../../store/commitments/commitmentsSlice";
import { getCommitmentsRecommendationsThunk } from "../../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { filterRecommendationsColumns } from "../../../utils/helpers/filterRecommendationsColumns";
import { setRecommendationsInitialFiltersThunk } from "../../../../../../../store/commitments/thunks/recommendations/setRecommendationsInitialFiltersThunk";

export const CommitmentsRecommendationsDataGrid: FC = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(commitmentsRecommendationsFilteredDataSelector);
  const orgExists = useAppSelector(connectedOrganizationExistsSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);
  const savingsPlanTypes = useAppSelector(
    commitmentsRecommendationsSavingsPlanTypeSelector,
  );
  const empty = !loading && !data?.length;

  const columns = useMemo(() => {
    return filterRecommendationsColumns(orgExists, savingsPlanTypes);
  }, [orgExists, savingsPlanTypes]);

  useEffect(() => {
    dispatch(setCommitmentsRecommendationsFilters(null));
    dispatch(getCommitmentsRecommendationsThunk());
    dispatch(setRecommendationsInitialFiltersThunk());
  }, [dispatch, orgId]);

  return (
    <DataGrid
      columnSorting
      enableStickyColumns
      data={data ?? []}
      columns={columns}
      toolbar={toolbar}
      styles={getCommitmentsTableStyles(empty)}
      pagination={empty ? pagination : undefined}
    />
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => <RecommendationsDataGridToolbar {...props} />,
};

const pagination: PaginationConfig = {
  renderPagination: () => <EmptyDataGridFooter name="Recommendations" />,
};
