import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { v4 as uuid } from "uuid";
import { AddNewFilterButton } from "./new-filter-creator/add-buttons/AddNewFilterButton";
import { ProvidersDynamicFilters } from "./provider-dynamic-filters/ProvidersDynamicFilters";
import { DynamicFiltersProvider } from "./DynamicFiltersProvider";
import {
  FilterOperatorType,
  ProviderType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerPossibleFilterV2,
} from "../../../../../services/cloudchipr.api";
import {
  DynamicFiltersType,
  DynamicProviderFilterItem,
} from "../utils/types/common";

interface DynamicFiltersProps {
  possibleFilters: ResourceExplorerPossibleFilterV2[];
  filters: DynamicFiltersType;
  setFilters(filters: DynamicFiltersType): void;
}

export const DynamicFilters: FC<DynamicFiltersProps> = ({
  filters,
  setFilters,
  possibleFilters,
}) => {
  const addNewFiltersHandler = useCallback(
    (
      provider: ProviderType,
      key: ResourceExplorerDynamicFilterItemType,
      operator: FilterOperatorType,
    ) => {
      const newFilter: DynamicProviderFilterItem = {
        id: uuid(),
        type: key,
        cloud_provider: provider,
        value: null,
        operator,
      };

      setFilters({
        ...filters,

        [provider]: {
          ...filters[provider],
          operator: filters[provider]?.operator ?? "AND",
          filters: [...(filters[provider]?.filters ?? []), newFilter],
        },
      });
    },
    [setFilters, filters],
  );

  return (
    <DynamicFiltersProvider possibleFilters={possibleFilters}>
      <Stack spacing={1} pt={1}>
        <ProvidersDynamicFilters filters={filters} setFilters={setFilters} />
        <AddNewFilterButton onAddNewFilter={addNewFiltersHandler} />
      </Stack>
    </DynamicFiltersProvider>
  );
};
