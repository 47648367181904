import { FC, useCallback, useMemo } from "react";
import { Menu, MenuItem, PopoverOrigin } from "@mui/material";
import BarChartOutlined from "@mui/icons-material/BarChartOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerV2ViewMoveActionMenuFolderItem } from "./ResourceExplorerV2ViewMoveActionMenuFolderItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerViewsHierarchyFoldersSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyFoldersSelector";
import { updateResourceExplorerHierarchyThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/updateResourceExplorerHierarchyThunk";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { updateResourceExplorerVisibilityHierarchyThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyThunk";

interface ResourceExplorerV2ViewMoveActionMenuProps {
  id: string;
  folderId?: string;
  anchor: HTMLElement | null;
  open: boolean;
  onClose(): void;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerV2ViewMoveActionMenu: FC<
  ResourceExplorerV2ViewMoveActionMenuProps
> = ({ id, folderId, anchor, onClose, open, visibility }) => {
  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) =>
    resourceExplorerViewsHierarchyFoldersSelector(state, visibility),
  );
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  const folderClickHandler = useCallback(
    async (folderId?: string) => {
      if (enableNavigationHierarchyVisibility) {
        await dispatch(
          updateResourceExplorerVisibilityHierarchyThunk({
            id,
            folderId,
            visibility,
          }),
        );
      } else {
        await dispatch(
          updateResourceExplorerHierarchyThunk({
            folderId,
            viewId: id,
          }),
        );
      }

      onClose();
    },
    [visibility, id, dispatch, onClose, enableNavigationHierarchyVisibility],
  );

  const resourceExplorerClickHandler = useCallback(async () => {
    if (enableNavigationHierarchyVisibility) {
      await dispatch(
        updateResourceExplorerVisibilityHierarchyThunk({
          id,
          index: 1,
          visibility,
        }),
      );
    } else {
      await dispatch(
        updateResourceExplorerHierarchyThunk({
          viewId: id,
          index: 1,
        }),
      );
    }

    onClose();
  }, [id, dispatch, onClose, visibility, enableNavigationHierarchyVisibility]);

  const filteredFolders = useMemo(() => {
    return folders?.filter((item) => item.id !== folderId);
  }, [folders, folderId]);

  return (
    <Menu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      slotProps={slotProps}
      {...navigationHierarchyItemNoDndKey}
    >
      {!!folderId && (
        <MenuItem sx={{ px: 1 }} onClick={resourceExplorerClickHandler}>
          <BarChartOutlined fontSize="small" />
          <TypographyWithTooltip
            title="Resource Explorer"
            variant="body2"
            placement="top"
            sx={{ pl: 1 }}
          />
        </MenuItem>
      )}

      {filteredFolders?.map((folder) => {
        return (
          <ResourceExplorerV2ViewMoveActionMenuFolderItem
            key={folder.id}
            id={folder.id}
            name={folder.name}
            onClick={folderClickHandler}
          />
        );
      })}
    </Menu>
  );
};

const slotProps = {
  paper: {
    sx: {
      minWidth: 200,
      maxWidth: 400,
      maxHeight: 400,
    },
  },
};
const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
