import { commitmentsRecommendationsFiltersSelector } from "./commitmentsRecommendationsFiltersSelector";
import { RootState } from "../../../../store";
import { SavingsPlanType } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsSavingsPlanTypeSelector = (
  state: RootState,
): SavingsPlanType[] | undefined => {
  const filters = commitmentsRecommendationsFiltersSelector(state);

  return filters.type;
};
