import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getResourceExplorerVisibilityHierarchyThunk } from "./getResourceExplorerVisibilityHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

interface UpdateResourceExplorerVisibilityPublicHierarchyThunkArg {
  id: string;
  folderId?: string;
  index?: number;
}

export const updateResourceExplorerVisibilityPublicHierarchyThunk =
  createAsyncThunk(
    "resourceExplorer/updateResourceExplorerVisibilityPublicHierarchy",
    async (
      {
        id,
        folderId,
        index,
      }: UpdateResourceExplorerVisibilityPublicHierarchyThunkArg,
      { dispatch },
    ) => {
      const {
        patchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleToEveryone,
      } = cloudChiprApi.endpoints;

      try {
        await dispatch(
          patchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleToEveryone.initiate(
            {
              body: {
                id,
                parent_id: folderId,
                index,
              },
            },
          ),
        ).unwrap();
        dispatch(getResourceExplorerVisibilityHierarchyThunk());
      } catch (e) {
        // @ts-expect-error TODO: return to this after adding error type
        const errMessage = e?.data?.message || "Something went wrong";
        const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
            onClose: () => closeSnackbar(snackbarId),
          },
        });
      }
    },
  );
