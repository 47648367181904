import { Stack } from "@mui/material";
import { OffHoursDataGridNameCell } from "./OffHoursDataGridNameCell";
import { OffHoursDataGridNextRunGridCell } from "./OffHoursDataGridNextRunGridCell";
import { OffHoursDataGridActionCell } from "./OffHoursDataGridActionCell";
import {
  OffHoursSchedule,
  ScheduleStatus,
} from "../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { SchedulesDataGridFrequencyCell } from "../../../common/list/SchedulesDataGridFrequencyCell";
import { AccountsWithPopover } from "../../../../../common/accounts-with-popover/AccountsWithPopover";
import { SchedulesDataGridStatusChip } from "../../../common/list/SchedulesDataGridStatusChip";
import { SchedulesDataGridActivationSwitchCell } from "../../../common/list/SchedulesDataGridActivationSwitchCell";
import { automationsAllActions } from "../../../common/utils/constants/common";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { offHoursNextRunFilter } from "../utils/filterFunctions/offHoursNextRunFilter";
import { offHoursFrequencyFilter } from "../utils/filterFunctions/offHoursFrequencyFilter";
import { offHoursFrequencySortingFn } from "../utils/sortingFunctions/offHoursFrequencySortingFn";
import { schedulesAccountFilter } from "../../../common/utils/filterFunctions/schedulesAccountFilter";

export const offHoursTableColumns: ColumnSetupType<OffHoursSchedule>[] = [
  {
    accessorKey: "name",
    header: "Name",
    size: 150,
    minSize: 120,
    cell: (cell) => {
      const schedule = cell.row.original;

      return <OffHoursDataGridNameCell schedule={schedule} />;
    },
    meta: {
      sticky: "left",
      hideFromSettings: true,
      cellStyles: { pl: 3, bgcolor: "white" },
    },
  },
  {
    id: "nextRun",
    accessorKey: "start_date_time",
    header: "Next Run",
    size: 150,
    minSize: 150,
    enableSorting: false,
    filterFn: offHoursNextRunFilter,
    cell: (cell) => {
      const schedule = cell.row.original;

      return <OffHoursDataGridNextRunGridCell schedule={schedule} />;
    },
  },
  {
    accessorKey: "start_cron",
    header: "Frequency",
    size: 90,
    minSize: 90,
    sortingFn: offHoursFrequencySortingFn,
    filterFn: offHoursFrequencyFilter,
    cell: (cell) => {
      const schedule = cell.row.original;

      return (
        <SchedulesDataGridFrequencyCell
          nextRun={schedule?.metadata?.next_start_run_at ?? ""}
          timeZone={schedule.time_zone}
          cron={schedule.start_cron ?? ""}
        />
      );
    },
  },
  {
    accessorKey: "id",
    header: "Account",
    enableSorting: false,
    size: 200,
    minSize: 200,
    filterFn: schedulesAccountFilter,
    cell: (cell) => {
      const accounts = cell.row.original.accounts;

      return <AccountsWithPopover accounts={accounts} />;
    },
  },
  {
    accessorKey: "action",
    header: "Actions",
    size: 100,
    minSize: 80,
    cell: (cell) => {
      const schedule = cell.row.original;
      const action = schedule.action && automationsAllActions[schedule.action];

      return <TypographyWithTooltip title={action} variant="body2" />;
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 85,
    minSize: 85,
    cell: (cell) => {
      const status = cell.getValue() as ScheduleStatus;

      return <SchedulesDataGridStatusChip status={status} />;
    },
  },
  {
    id: "enable",
    header: "Enable",
    size: 65,
    enableSorting: false,
    enableResizing: false,
    cell: (cell) => {
      const schedule = cell.row.original;

      return (
        <SchedulesDataGridActivationSwitchCell
          schedule={schedule}
          type="off-hours"
        />
      );
    },
  },
  {
    id: "actions",
    size: 40,
    meta: {
      sticky: "right",
      hideFromSettings: true,
      cellStyles: {
        bgcolor: "white",
      },
    },
    enableResizing: false,
    enableGlobalFilter: false,
    cell: (cell) => {
      const schedule = cell.row.original;

      return (
        <Stack alignItems="center" justifyContent="center">
          <OffHoursDataGridActionCell schedule={schedule} />
        </Stack>
      );
    },
  },
];
