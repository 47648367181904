import { FC, useMemo } from "react";
import { usePreviousDifferent } from "rooks";
import { reservationCoverageTableColumns } from "./columns/reservationCoverageTableColumns";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { commitmentsCoverageGroupingSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsCoverageGroupingSelector";
import { EmptyDataGridFooter } from "../../../../common/components/EmptyDataGridFooter";
import { reservationsCoverageDataSelector } from "../../../../../../../store/commitments/selectors/reservations/data/reservationsCoverageDataSelector";
import { reservationsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForTableLoadingSelector";
import { coverageGroupingNames } from "../../../../common/utils/constants/labels";
import { getCommitmentsTableStyles } from "../../../../common/utils/helpers/grid/getCommitmentsTableStyles";

interface CoverageDataGridProps {
  toolbar: ToolbarConfig;
}

export const ReservationsCoverageDataGrid: FC<CoverageDataGridProps> = ({
  toolbar,
}) => {
  const data = useAppSelector(reservationsCoverageDataSelector);
  const loading = useAppSelector(reservationsForTableLoadingSelector);
  const grouping = useAppSelector(commitmentsCoverageGroupingSelector);

  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData;
  const empty = !loading && !gridData?.length;

  const columns = reservationCoverageTableColumns(grouping);

  const pagination = useMemo(() => {
    if (!empty) {
      return undefined;
    }

    return {
      renderPagination: () => {
        return <EmptyDataGridFooter name={coverageGroupingNames[grouping]} />;
      },
    };
  }, [empty, grouping]);

  if (!gridData) {
    return null;
  }

  return (
    <DataGrid
      toolbar={toolbar}
      styles={getCommitmentsTableStyles(empty)}
      data={gridData}
      columnSorting={coverageSortingConfig}
      columns={columns}
      pagination={pagination}
    />
  );
};

const coverageSortingConfig = {
  initialSort: [{ id: "covered_usage", desc: true }],
};
