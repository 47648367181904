import { FC, useCallback } from "react";
import { useToggle } from "rooks";
import { Card, CircularProgress, Collapse, Stack } from "@mui/material";
import { CategoryCollapseHeader } from "./CategoryCollapseHeader";
import { DynamicFilters } from "../../../../../../common/dynamic-filters/components/DynamicFilters";
import { DimensionCategory } from "../../../../../../../../store/dimensions/utils/types/common";
import { DynamicFiltersType } from "../../../../../../common/dynamic-filters/utils/types/common";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { setCategoryFilters } from "../../../../../../../../store/dimensions/dimensionsSlice";
import { useGetUsersMeOrganisationsCurrentResourceExplorerPossibleFiltersQuery } from "../../../../../../../../services/cloudchipr.api";

interface CategoryItemProps {
  category: DimensionCategory;
  deleteDisabled?: boolean;
}

export const CategoryItem: FC<CategoryItemProps> = ({
  category,
  deleteDisabled,
}) => {
  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentResourceExplorerPossibleFiltersQuery({
      providersOnly: true,
    });

  const dispatch = useAppDispatch();
  const [collapsed, toggleCollapsed] = useToggle();

  const filtersChangeHandler = useCallback(
    (filters: DynamicFiltersType) => {
      dispatch(setCategoryFilters({ id: category.id, filters }));
    },
    [dispatch, category.id],
  );

  return (
    <Card variant="outlined">
      <CategoryCollapseHeader
        title="Filters"
        collapsed={collapsed}
        category={category}
        deleteDisabled={!!deleteDisabled}
        toggleCollapsed={toggleCollapsed}
      />

      <Collapse in={!collapsed}>
        <Stack p={1} pt={0}>
          {isLoading ? (
            <Stack alignItems="center" padding={2}>
              <CircularProgress />
            </Stack>
          ) : (
            <DynamicFilters
              possibleFilters={data ?? []}
              filters={category.filters}
              setFilters={filtersChangeHandler}
            />
          )}
        </Stack>
      </Collapse>
    </Card>
  );
};
