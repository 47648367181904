import { FC, useMemo } from "react";
import { TypographyProps } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsDatesSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { calculateFromToByDatesData } from "../../../../../../common/date-range-picker-v2/utils/helpers/calculateFromToByDatesData";
import { TooltipText } from "../../../../../../common/TooltipText";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

interface HeaderCellWidthDateTooltipProps extends TypographyProps {
  title: string;
}

export const HeaderCellWidthDateTooltip: FC<
  HeaderCellWidthDateTooltipProps
> = ({ title, ...props }) => {
  const dates = useAppSelector(commitmentsDatesSelector);

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const tooltip = useMemo(() => {
    const { toDate, fromDate } = calculateFromToByDatesData(
      dates,
      quarterStartMonth,
    );

    const from = fromDate || formatDate(dates.from ?? "", { type: "date" });
    const to = toDate || formatDate(dates.to ?? "", { type: "date" });

    return from && to ? `from ${from} to ${to}` : "";
  }, [dates, quarterStartMonth]);

  return (
    <TooltipText
      tooltipTitle={`${title} ${tooltip}`}
      variant="body2"
      fontWeight="medium"
      tooltipPlacement="top"
      {...props}
    >
      {title}
    </TooltipText>
  );
};
