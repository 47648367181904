import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ProviderLogo } from "../../../../../../common/provider-logo/ProviderLogo";
import {
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  ResourceExplorerPossibleFilterV2,
} from "../../../../../../../services/cloudchipr.api";
import { isStringProviderType } from "../../../../../../../utils/helpers/providers/isStringProviderType";

export type NewFilterAddFn = (
  provider: ResourceExplorerFilterProvider,
  key: ResourceExplorerDynamicFilterItemType,
  operator: FilterOperatorType,
) => void;

interface FilterItemSelectMenuItemProps {
  filter: ResourceExplorerPossibleFilterV2;
  onSelect: NewFilterAddFn;
}

export const FilterItemSelectMenuItem: FC<FilterItemSelectMenuItemProps> = ({
  filter,
  onSelect,
}) => {
  const clickHandler = useCallback(() => {
    const operator = filter.operators.at(0)?.key;
    if (!operator) {
      return;
    }

    onSelect(filter.filter_provider, filter.key, operator);
  }, [onSelect, filter]);

  return (
    <MenuItem sx={menuItemSx} onClick={clickHandler}>
      {isStringProviderType(filter.filter_provider) && (
        <ListItemIcon>
          <ProviderLogo provider={filter.filter_provider} />
        </ListItemIcon>
      )}

      <ListItemText primary={filter.label} />
    </MenuItem>
  );
};

const menuItemSx = {
  px: 0.5,
  my: 1,
  borderRadius: 2,
  "&.Mui-selected": { color: "primary.main" },
};
