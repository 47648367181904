import { FC, useCallback } from "react";
import { useEffectOnceWhen } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { GroupBySelector } from "../../../../../../../../common/resource-explorer/group-by-selector/GroupBySelector";
import { getResourceExplorerPossibleFiltersByProvidersThunk } from "../../../../../../../../../../store/common/thunks/resource-explorer/getResourceExplorerPossibleFiltersByProvidersThunk";
import { costBreakdownV2GroupingChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2GroupingChangeThunk";
import { ResourceExplorerGrouping } from "../../../../../../../../../../services/cloudchipr.api";
import { resourceExplorerDataGroupingAvailableTagsSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataGroupingAvailableTagsSelector";
import { selectedViewProvidersSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/common/selectedViewProvidersSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { selectedViewFilteredByDimensionsSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/common/selectedViewFilteredByDimensionsSelector";

interface CostBreakdownWidgetSetupGroupingSelectProps {
  disabled?: boolean;
}
export const CostBreakdownV2WidgetSetupGroupingSelect: FC<
  CostBreakdownWidgetSetupGroupingSelectProps
> = ({ disabled }) => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("grouping"),
  );
  const groupValues = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("group_values"),
  );
  const availableTags = useAppSelector(
    resourceExplorerDataGroupingAvailableTagsSelector,
  );
  const providers = useAppSelector(selectedViewProvidersSelector);
  const filteredByDimensions = useAppSelector(
    selectedViewFilteredByDimensionsSelector,
  );

  const groupingChangeHandler = useCallback(
    (grouping: ResourceExplorerGrouping, groupValues?: string[]) => {
      dispatch(
        costBreakdownV2GroupingChangeThunk({
          grouping,
          groupValues,
        }),
      );
    },
    [dispatch],
  );

  useEffectOnceWhen(() => {
    dispatch(getResourceExplorerPossibleFiltersByProvidersThunk(providers));
  }, !!providers.length);

  return (
    <GroupBySelector
      value={groupBy}
      disabled={disabled}
      providers={providers}
      groupValues={groupValues}
      categoryGroupingAvailable={filteredByDimensions}
      onGroupingChange={groupingChangeHandler}
      groupValueOptions={availableTags ?? undefined}
    />
  );
};
