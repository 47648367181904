import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { deleteResourceExplorerFolderThunkFixedCacheKey } from "../../thunks/common/deleteResourceExplorerFolderThunk";

const selector =
  cloudChiprApi.endpoints
    .deleteUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId
    .select;

const selectorV2 =
  cloudChiprApi.endpoints
    .deleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId
    .select;

export const deleteResourceExplorerHierarchyFolderLoadingSelector = (
  state: RootState,
): boolean => {
  return (
    !!selector(deleteResourceExplorerFolderThunkFixedCacheKey)(state)
      ?.isLoading ||
    !!selectorV2(deleteResourceExplorerFolderThunkFixedCacheKey)(state)
      ?.isLoading
  );
};
