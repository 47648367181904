import { FC } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import NoMatchImage from "../../../../../../../assets/images/no_match.svg";
import { useAppSelector } from "../../../../../../../store/hooks";
import { schedulesLoadingSelector } from "../../../../../../../store/schedules/selectors/schedule/schedulesLoadingSelector";

export const WorkflowDataGridNoRowsComponent: FC = () => {
  const loading = useAppSelector(schedulesLoadingSelector);

  if (loading) {
    return null;
  }

  return (
    <Stack spacing={3} alignItems="center">
      <img src={NoMatchImage} alt="EmptyStateImage" width={140} />

      <Typography variant="h6" fontWeight="normal">
        No matching results found.
      </Typography>
    </Stack>
  );
};
