import { createAsyncThunk } from "@reduxjs/toolkit";

import { updateResourceExplorerVisibilityPublicHierarchyThunk } from "./updateResourceExplorerVisibilityPublicHierarchyThunk";
import { updateResourceExplorerVisibilityPrivateHierarchyThunk } from "./updateResourceExplorerVisibilityPrivateHierarchyThunk";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";

interface UpdateResourceExplorerVisibilityHierarchyThunkArg {
  id: string;
  folderId?: string;
  index?: number;
  visibility: NavigationItemsVisibilityType;
}

export const updateResourceExplorerVisibilityHierarchyThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorerVisibilityHierarchy",
  async (
    {
      id,
      folderId,
      index,
      visibility,
    }: UpdateResourceExplorerVisibilityHierarchyThunkArg,
    { dispatch },
  ) => {
    if (visibility === "visible_to_everyone") {
      await dispatch(
        updateResourceExplorerVisibilityPublicHierarchyThunk({
          id,
          folderId,
          index,
        }),
      );
    } else {
      await dispatch(
        updateResourceExplorerVisibilityPrivateHierarchyThunk({
          id,
          folderId,
          index,
        }),
      );
    }
  },
);
