import { FC, ReactNode } from "react";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import CloseIcon from "@mui/icons-material/Close";

interface DrawerHeaderProps {
  title?: string;
  url?: string;
  onClose(): void;
  children?: ReactNode;
}

export const CommitmentsDrawerHeader: FC<DrawerHeaderProps> = ({
  onClose,
  title,
  url,
  children,
}) => {
  return (
    <Stack
      p={2}
      pt={1}
      pb={children ? 2 : 1}
      top={0}
      spacing={1}
      position="sticky"
      bgcolor="white"
      zIndex="appBar"
      borderBottom={1}
      borderColor="grey.300"
      maxWidth="80dvw"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontWeight="medium" variant="body1">
            {title}
          </Typography>

          {url && (
            <Link href={url} target="_blank" sx={{ fontSize: 0 }}>
              <LaunchIcon fontSize="small" color="action" />
            </Link>
          )}
        </Stack>

        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>

      {children}
    </Stack>
  );
};
