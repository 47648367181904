import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { getResourcesExplorerGroupingLabel } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { selectedViewProvidersSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/common/selectedViewProvidersSelector";
import { costBreakdownV2WidgetSetupTotalDataCountSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupTotalDataCountSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const CostBreakdownV2WidgetSetupTableLabelHeaderCell: FC = () => {
  const dataCount = useAppSelector((state) =>
    costBreakdownV2WidgetSetupTotalDataCountSelector(state),
  );
  const grouping = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("grouping"),
  );
  const providers = useAppSelector((state) =>
    selectedViewProvidersSelector(state),
  );

  if (!grouping) {
    return null;
  }

  return (
    <Stack pl={1}>
      {getResourcesExplorerGroupingLabel(providers, grouping, true)}
      <Typography variant="tiny" color="text.disabled" fontWeight="medium">
        {dataCount}
      </Typography>
    </Stack>
  );
};
