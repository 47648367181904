import { resourceExplorerViewsSelector } from "./resourceExplorerViewsSelector";
import { ResourceExplorer } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import UnleashClient from "../../../../services/UnleashClient";
import { resourceExplorerViewsHierarchyViewsSelector } from "../views-hierarchy/resourceExplorerViewsHierarchyViewsSelector";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";

export const resourceExplorerViewsDataSelector = (
  state: RootState,
): ResourceExplorer[] => {
  const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
    "EnableNavigationHierarchyVisibility",
  );
  const enableResourceExplorerFolderViews = UnleashClient.isEnabled(
    "EnableResourceExplorerFolderViews",
  );

  if (enableNavigationHierarchyVisibility) {
    return (
      resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector(state) ?? []
    );
  }

  if (enableResourceExplorerFolderViews) {
    return resourceExplorerViewsHierarchyViewsSelector(state) ?? [];
  }
  return resourceExplorerViewsSelector(state)?.data ?? [];
};
