import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import { WidgetNumeralViewLoader } from "./WidgetNumeralViewLoader";
import { getNumeralViewItemsPaddings } from "./utils/helpers/getNumeralViewItemsPaddings";

interface WidgetNumeralViewProps {
  loading: boolean;
  skeletonCount?: number;
  children?: ReactNode;
}

export const WidgetNumeralView: FC<WidgetNumeralViewProps> = ({
  loading,
  skeletonCount,
  children,
}) => {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      flexGrow={1}
      p={getNumeralViewItemsPaddings(skeletonCount || skeletonDefaultCount)}
    >
      {loading ? (
        <WidgetNumeralViewLoader
          count={skeletonCount || skeletonDefaultCount}
        />
      ) : (
        children
      )}
    </Stack>
  );
};

const skeletonDefaultCount = 10;
