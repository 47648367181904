import { FC, useCallback, SyntheticEvent, JSXElementConstructor } from "react";
import { Autocomplete, Popper, TextField } from "@mui/material";
import { PopperProps } from "@mui/material/Popper";
import InputAdornment from "@mui/material/InputAdornment";
import LanguageIcon from "@mui/icons-material/Language";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { automationTimeZoneSelector } from "../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setAutomationData } from "../../../../../../../store/automations/automationsSlice";
import {
  getTimeZoneObject,
  TimeZoneObjectType,
  timeZonesList,
} from "../../../../../../../utils/helpers/date-time/timeZones";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";

export const AutomationTimeZone: FC = () => {
  const dispatch = useAppDispatch();
  const timeZone = useAppSelector(automationTimeZoneSelector);

  const activeTimeZone = getTimeZoneObject(timeZone);

  const changeHandler = useCallback(
    (_: SyntheticEvent, value: unknown) => {
      const timeZone = (value as TimeZoneObjectType)?.tzCode;

      if (timeZone) {
        dispatch(setAutomationData({ timeZone }));
        dispatch(fetchWorkflowNextRunsThunk());
      }
    },
    [dispatch],
  );

  const PopperComponent: JSXElementConstructor<PopperProps> = useCallback(
    (props) => <Popper {...props} style={{ width: 600 }} />,
    [],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        sx={{ minWidth: 220 }}
        placeholder="Search"
        InputProps={{
          ...params.InputProps,
          startAdornment,
          endAdornment,
        }}
        variant="outlined"
      />
    ),
    [],
  );

  return (
    <Autocomplete
      freeSolo
      disablePortal
      disableClearable
      clearOnBlur
      size="small"
      onChange={changeHandler}
      value={activeTimeZone}
      getOptionLabel={(option: any) => option.name}
      sx={{ overflow: "unset", flex: 1 }}
      PopperComponent={PopperComponent}
      options={timeZonesList}
      renderInput={renderInput}
    />
  );
};

const startAdornment = (
  <InputAdornment position="start">
    <LanguageIcon />
  </InputAdornment>
);

const endAdornment = (
  <InputAdornment position="end">
    <ArrowDropDownIcon />
  </InputAdornment>
);
