import {
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { Tune } from "@mui/icons-material";
import { RenderToolbar } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { getResourceTypeName } from "../../../../../../../utils/helpers/resources/getResourceTypeName";
import { ColumnsSwitcher } from "../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import {
  CleanActionTypes,
  getCleanActionLabel,
} from "../../../../../../../utils/clean-options";
import { money } from "../../../../../../../utils/numeral/money";
import { GlobalFilter } from "../../../../../../../storybook/data-grid/toolbar/GlobalFilter";

interface PreviewDataGridToolbarProps {
  resourceType: ResourceType;
  count: number;
  action: CleanActionTypes;
  loading: boolean;
  scheduleAction: string | null;
  totalMonthlySpending?: number | null;
}

export const PreviewDataGridToolbar: RenderToolbar<
  PreviewDataGridToolbarProps
> = ({
  table,
  resourceType,
  count,
  action,
  loading,
  scheduleAction,
  totalMonthlySpending,
  globalFilter,
  setGlobalFilter,
}) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const actionName = useMemo(
    () =>
      scheduleAction?.includes("clean") &&
      getCleanActionLabel(action, resourceType),
    [action, scheduleAction, resourceType],
  );

  return (
    <div>
      <Stack
        p={0.5}
        pb={1.5}
        direction="row"
        borderBottom={1}
        borderColor="grey.300"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle1" fontWeight="medium">
            {getResourceTypeName(resourceType, {
              type: "long",
            })}{" "}
            ({count}){" "}
            {totalMonthlySpending ? `- ${money(totalMonthlySpending)}` : ""}
          </Typography>

          {actionName && (
            <Chip
              variant="filled"
              size="small"
              sx={{ mr: 2 }}
              label={
                <Typography variant="caption">
                  Action:{" "}
                  <Typography
                    component="span"
                    fontWeight="medium"
                    variant="inherit"
                  >
                    {actionName}
                  </Typography>
                </Typography>
              }
            />
          )}
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder="Search..."
            size="xsmall"
            fullWidth
          />
          <Tooltip arrow title="Table Settings" placement="top">
            <IconButton onClick={openMenu} size="small">
              <Tune fontSize="small" />
            </IconButton>
          </Tooltip>
          <ColumnsSwitcher
            open={open}
            onClose={closeMenu}
            anchor={anchor}
            table={table}
          />
        </Stack>
      </Stack>

      {loading && <LinearProgress />}
    </div>
  );
};
