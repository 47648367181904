import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { costBreakdownV2WidgetSetupPrevTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupPrevTotalCostSelector";

interface CostBreakdownPreviousPeriodCellProps {
  value: number | null;
}

export const CostBreakdownV2WidgetSetupPreviousPeriodCell: FC<
  CostBreakdownPreviousPeriodCellProps
> = ({ value }) => {
  const previousPeriodTotal = useAppSelector(
    costBreakdownV2WidgetSetupPrevTotalCostSelector,
  );

  const fromDate = formatDate(
    previousPeriodTotal?.dateDetails?.from_date ?? "",
    { type: "date" },
  );
  const toDate = formatDate(previousPeriodTotal?.dateDetails?.to_date ?? "", {
    type: "date",
  });

  return value === null ? (
    <Box textAlign="right">
      <TooltipText
        tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
      >
        N/A
      </TooltipText>
    </Box>
  ) : (
    <Typography variant="body2" align="right">
      {money(value)}
    </Typography>
  );
};
