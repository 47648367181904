import { FC, useCallback } from "react";
import { Grid } from "@mui/material";
import { FilterTypeSelect } from "./components/filter-type/FilterTypeSelect";
import { FilterOperatorSelect } from "./components/FilterOperatorSelect";
import {
  FilterKeySelect,
  FilterWithKey,
} from "./components/filter-key/FilterKeySelect";
import { FilterValue } from "./components/filter-value/FilterValue";
import {
  ResourceExplorerDynamicFilterItem,
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
  KeyValuePair,
} from "../../../../../../../services/cloudchipr.api";
import { filterAvailableFieldsChecker } from "../../../utils/helpers/data-type-checkers/filterAvailableFieldsChecker";
import { DynamicProviderFilterItem } from "../../../utils/types/common";
import { useDynamicFiltersContext } from "../../DynamicFiltersProvider";
import { findOptionByProviderAndKey } from "../../../utils/helpers/findOptionByProviderAndKey";
import { getFilterValueDataTypeByOperator } from "../../../utils/helpers/data-type-checkers/getFilterValueDataTypeByOperator";

interface FilterItemFieldsProps {
  index: number;
  filter: DynamicProviderFilterItem;
  onFilterChange(filter: DynamicProviderFilterItem, index: number): void;
}

export const FilterItemFields: FC<FilterItemFieldsProps> = ({
  onFilterChange,
  filter,
  index,
}) => {
  const { hasKey, hasValue } = filterAvailableFieldsChecker(
    filter.type,
    filter.operator,
  );
  const { possibleFilters } = useDynamicFiltersContext();

  const filterTypeChangeHandler = useCallback(
    (type: ResourceExplorerDynamicFilterItemType) => {
      const operator = findOptionByProviderAndKey(
        possibleFilters,
        filter.cloud_provider,
        type,
      )?.operators?.at(0)?.key;

      if (!operator) {
        return;
      }

      onFilterChange({ ...filter, type, operator, value: null }, index);
    },
    [index, onFilterChange, filter, possibleFilters],
  );

  const filterOperatorChangeHandler = useCallback(
    (operator: FilterOperatorType) => {
      const prevDataType = getFilterValueDataTypeByOperator(filter.operator);
      const newDataType = getFilterValueDataTypeByOperator(operator);
      const { hasKey } = filterAvailableFieldsChecker(filter.type, operator);

      const newFilter = {
        ...filter,
        operator,
      };

      if (prevDataType !== newDataType) {
        if (hasKey) {
          newFilter.value = {
            key: (newFilter?.value as KeyValuePair)?.key ?? "",
            value: newDataType === "string" ? "" : [],
          };
        } else {
          newFilter.value = newDataType === "string" ? "" : [];
        }
      }

      onFilterChange(newFilter, index);
    },
    [index, onFilterChange, filter],
  );

  const filterValueChangeHandler = useCallback(
    (value: ResourceExplorerDynamicFilterItem["value"]) => {
      onFilterChange({ ...filter, value }, index);
    },
    [index, onFilterChange, filter],
  );

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={3}>
        <FilterTypeSelect filter={filter} onChange={filterTypeChangeHandler} />
      </Grid>

      {hasKey && (
        <Grid item xs={hasValue ? 3 : 6}>
          <FilterKeySelect
            index={index}
            filter={filter as FilterWithKey}
            onFilterChange={onFilterChange}
          />
        </Grid>
      )}

      <Grid item xs={3}>
        <FilterOperatorSelect
          filterType={filter.type}
          selectedOperator={filter.operator}
          filterProvider={filter.cloud_provider}
          onChange={filterOperatorChangeHandler}
        />
      </Grid>

      {hasValue && (
        <Grid item xs={hasKey ? 3 : 6}>
          <FilterValue filter={filter} onChange={filterValueChangeHandler} />
        </Grid>
      )}
    </Grid>
  );
};
