import { FC, useCallback } from "react";
import { IconButton, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Theme } from "@mui/system";
import { ResourceExplorerRegionFilter } from "./filters/ResourceExplorerRegionFilter";
import { ResourceExplorerAccountFilter } from "./filters/account-filter/ResourceExplorerAccountFilter";
import { ResourceExplorerServiceFilter } from "./filters/ResourceExplorerServiceFilter";
import { ResourceExplorerChargeTypeFilter } from "./filters/ResourceExplorerChargeTypeFilter";
import { ResourceExplorerCreditTypeFilter } from "./filters/credit-type-filter/ResourceExplorerCreditTypeFilter";
import { ResourceExplorerProductFamilyFilter } from "./filters/ResourceExplorerProductFamilyFilter";
import { ResourceExplorerInstanceTypeFilter } from "./filters/ResourceExplorerInstanceTypeFilter";
import { ResourceExplorerInstanceFamilyFilter } from "./filters/ResourceExplorerInstanceFamilyFilter";
import { ResourceExplorerCostAllocationTagFilter } from "./filters/cost-allocation-tag-filter/ResourceExplorerCostAllocationTagFilter";
import { ProviderLogo } from "../../../../../../../common/provider-logo/ProviderLogo";
import {
  ResourceExplorerFilterKeyType,
  ProviderType,
} from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceExplorerAllPossibleFiltersSelector } from "../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerAllPossibleFiltersSelector";
import {
  removeFilterByProvider,
  setProviders,
} from "../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerProvidersSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { getResourceExplorerWidgetDataThunk } from "../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerWidgetDataThunk";
import { enabledProvidersByBillingStatusSelector } from "../../../../../../../../store/common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

interface ResourceExplorerFiltersProps {
  provider: ProviderType;
  viewId: string;
}

export const ResourceExplorerFilterItem: FC<ResourceExplorerFiltersProps> = ({
  provider,
  viewId,
}) => {
  const dispatch = useAppDispatch();
  const allPossibleFilters = useAppSelector(
    resourceExplorerAllPossibleFiltersSelector,
  );

  const resourceExplorerProviders = useAppSelector(
    resourceExplorerProvidersSelector,
  );
  const enabledProviders = useAppSelector(
    enabledProvidersByBillingStatusSelector,
  );

  const removeFilterHandler = useCallback(() => {
    dispatch(removeFilterByProvider(provider));
    const filteredData = resourceExplorerProviders.filter(
      (item) => item !== provider,
    );
    dispatch(setProviders([...filteredData, provider]));
    dispatch(getResourceExplorerWidgetDataThunk());
  }, [dispatch, provider, resourceExplorerProviders]);

  return (
    <Stack
      bgcolor="grey.100"
      px={1}
      py={0.5}
      borderRadius={8}
      direction="row"
      alignItems="center"
      maxWidth="fit-content"
      spacing={1}
    >
      <ProviderLogo provider={provider} />
      <Stack
        direction="row"
        alignItems="center"
        sx={styles}
        maxWidth="fit-content"
        overflow="hidden"
      >
        {(
          Object.keys(filterKeyToComponent) as ResourceExplorerFilterKeyType[]
        ).map((item) => {
          const key = item;
          const Component = filterKeyToComponent?.[key];

          if (!allPossibleFilters[provider]?.[key]) {
            return null;
          }

          return Component ? (
            <Component viewId={viewId} key={key} provider={provider} />
          ) : null;
        })}
      </Stack>
      {enabledProviders.length > 1 && (
        <IconButton sx={{ p: 0 }} onClick={removeFilterHandler}>
          <CancelIcon
            sx={{
              color: "grey.400",
            }}
          />
        </IconButton>
      )}
    </Stack>
  );
};

// keep object ordering
const filterKeyToComponent: FilterKeyToComponent = {
  account: ResourceExplorerAccountFilter,
  region: ResourceExplorerRegionFilter,
  service: ResourceExplorerServiceFilter,
  product_family: ResourceExplorerProductFamilyFilter,
  instance_family: ResourceExplorerInstanceFamilyFilter,
  instance_type: ResourceExplorerInstanceTypeFilter,
  cost_allocation_tag: ResourceExplorerCostAllocationTagFilter,
  charge_type: ResourceExplorerChargeTypeFilter,
  credit_type: ResourceExplorerCreditTypeFilter,
  category_id: null,
  dimension_id: null,
};

type FilterKeyToComponent = Record<
  ResourceExplorerFilterKeyType,
  FC<{ viewId: string; provider: ProviderType }> | null
>;

const styles = (theme: Theme) => ({
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
    "& .MuiButton-root": {
      my: 0.25,
    },
  },
  flexWrap: "nowrap",
});
