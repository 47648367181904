import { FC, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";

import { HierarchySelectVisibility } from "../hierarchy-select-visibility/HierarchySelectVisibility";
import { SelectViewTrigger } from "../view-select-trigger/SelectViewTrigger";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { DropdownFooterComponent } from "../../../common/select/dropdown-select/utils/types/types";
import { useAppSelector } from "../../../../store/hooks";
import { dashboardHierarchyVisibilitiesMergedDataItemsSelector } from "../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardHierarchyVisibilitiesMergedDataItemsSelector";
import { navigationDashboardDataSelector } from "../../../../store/navigation/selectors/dashboard/navigationDashboardDataSelector";

export interface HierarchyDashboardSelectionWrapperProps {
  onChange(id: string): void;
  selectedItemId: string;
  CustomTriggerComponent?: FC<FilterTriggerComponentProps>;
  DropdownFooter?: DropdownFooterComponent;
}

export const HierarchyDashboardSelectionWrapper: FC<
  HierarchyDashboardSelectionWrapperProps
> = ({ onChange, selectedItemId, CustomTriggerComponent, DropdownFooter }) => {
  const mergedHierarchyVisibilityItems = useAppSelector(
    dashboardHierarchyVisibilitiesMergedDataItemsSelector,
  );

  const navigationDashboardData = useAppSelector(
    navigationDashboardDataSelector,
  );
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );

  const label = useMemo(() => {
    const selectedItem = mergedHierarchyVisibilityItems?.find(
      (visibilityItem) => {
        if (visibilityItem.id === selectedItemId) {
          return visibilityItem;
        }
      },
    );

    return selectedItem?.name ?? "";
  }, [mergedHierarchyVisibilityItems, selectedItemId]);

  if (enableNavigationHierarchyVisibility) {
    return (
      <HierarchySelectVisibility
        label={label}
        selectedItemId={selectedItemId}
        hierarchy={navigationDashboardData}
        onChange={onChange}
        ItemIcon={GridViewOutlinedIcon}
        CustomTriggerComponent={CustomTriggerComponent ?? SelectViewTrigger}
        DropdownFooter={DropdownFooter}
      />
    );
  }

  return null;
};
