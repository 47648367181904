import { FC } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { createResourceExplorerLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerLoadingSelector";
import { createResourceExplorerFolderLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerFolderLoadingSelector";

interface ResourceExplorerV2ViewCreateFolderOrViewDialogActionsProps {
  onCloseDialog(): void;
  onSubmit(): void;
  name: string;
}

export const ResourceExplorerV2ViewCreateFolderOrViewDialogActions: FC<
  ResourceExplorerV2ViewCreateFolderOrViewDialogActionsProps
> = ({ onCloseDialog, name, onSubmit }) => {
  const createViewLoading = useAppSelector(
    createResourceExplorerLoadingSelector,
  );

  const createFolderLoading = useAppSelector(
    createResourceExplorerFolderLoadingSelector,
  );

  return (
    <DialogActions>
      <Button onClick={onCloseDialog} color="tertiary">
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        loading={createViewLoading || createFolderLoading}
        onClick={onSubmit}
        disabled={!name?.length}
      >
        create
      </LoadingButton>
    </DialogActions>
  );
};
