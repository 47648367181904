import { multipleValueSelectOperators } from "../constants/filter-types/autocomplete-multipe-selection-operator-types";
import { ResourceExplorerFilterOperator } from "../../../../../../services/cloudchipr.api";

export const operatorTypeChanged = (
  prevOperator: ResourceExplorerFilterOperator,
  currentOperator: ResourceExplorerFilterOperator,
): boolean => {
  return (
    (multipleValueSelectOperators.includes(currentOperator) &&
      multipleValueSelectOperators.includes(prevOperator)) ||
    (!multipleValueSelectOperators.includes(currentOperator) &&
      !multipleValueSelectOperators.includes(prevOperator))
  );
};
