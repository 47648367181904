import { Stack } from "@mui/material";
import { FC } from "react";
import Typography from "@mui/material/Typography";
import { IntegrationCostAnomalyAlertSubject } from "./IntegrationCostAnomalyAlertSubject";
import { IntegrationCostAnomalyAlertBody } from "./IntegrationCostAnomalyAlertBody";
import { IntegrationCustomMessageDataGrid } from "../../../../../../../schedule/classic/components/custom-message/common/IntegrationCustomMessageDataGrid";
import { EmailIntegrationCsvInfo } from "../../../../../../../schedule/classic/components/custom-message/email/common/EmailIntegrationCsvInfo";
import { integrationCustomEmailCostAnomalyDataGridColumns } from "../../utils/constants/constants";

interface IntegrationCostAnomalyAlertMessageProps {
  message: string;
  type: "email" | "slack";
}

export const IntegrationCostAnomalyAlertMessage: FC<
  IntegrationCostAnomalyAlertMessageProps
> = ({ message, type }) => {
  return (
    <Stack spacing={1}>
      {type === "email" && <IntegrationCostAnomalyAlertSubject />}

      <IntegrationCostAnomalyAlertBody />

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />

      <Typography variant="body1">
        Please review and take the necessary actions.
      </Typography>
      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailCostAnomalyDataGridColumns}
        label=""
      />
      {type === "email" && <EmailIntegrationCsvInfo />}
    </Stack>
  );
};
