import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { GracePeriod } from "../../../../../../../services/cloudchipr.api";
import { WorkflowGracePeriodPreview } from "../../../../../automations/workflows/single-workflow/preview/components/WorkflowGracePeriodPreview";

interface SchedulePreviewNotificationsProps {
  gracePeriod?: GracePeriod;
}

export const SchedulePreviewGracePeriod: FC<
  SchedulePreviewNotificationsProps
> = ({ gracePeriod }) => {
  return (
    <Grid container px={2} mb={4} alignItems="baseline">
      <Grid item sm={4}>
        <Typography variant="subtitle2">Grace Period Before Actions</Typography>
      </Grid>

      <Grid item sm={8}>
        <WorkflowGracePeriodPreview gracePeriod={gracePeriod} />
      </Grid>
    </Grid>
  );
};
