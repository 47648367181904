import { FC, useCallback } from "react";
import { DeleteCategoryConfirmationDialog } from "./DeleteCategoryConfirmationDialog";
import {
  ActionsMenu,
  ActionsMenuConfirmationDialogProps,
} from "../../common/ActionsMenu";
import { generateDimensionCategoriesFromCategoriesWithCosts } from "../../../../utils/helpers/generateDimensionCategoriesFromCategoriesWithCosts";
import { CategoryWithCosts } from "../../../../../../../services/cloudchipr.api";
import { setDimensionsState } from "../../../../../../../store/dimensions/dimensionsSlice";
import { useAppDispatch } from "../../../../../../../store/hooks";

interface CategoryActionsProps {
  dimensionId: string;
  category: CategoryWithCosts;
  categories: CategoryWithCosts[];
}

export const CategoryActions: FC<CategoryActionsProps> = ({
  category,
  dimensionId,
  categories,
}) => {
  const dispatch = useAppDispatch();
  const categoryId = category.id;

  const editDialogOpenHandler = useCallback(() => {
    dispatch(
      setDimensionsState({
        dimensionId,
        drawerMode: "categoryEdit",
        dimensionName: "",
        editingCategoryId: categoryId,
        categories:
          generateDimensionCategoriesFromCategoriesWithCosts(categories),
      }),
    );
  }, [dispatch, dimensionId, categories, categoryId]);

  const ConfirmationDialog = useCallback(
    ({ open, onClose }: ActionsMenuConfirmationDialogProps) => {
      return (
        <DeleteCategoryConfirmationDialog
          name={category.name}
          categoryId={category.id}
          dimensionId={dimensionId}
          open={open}
          onClose={onClose}
        />
      );
    },
    [dimensionId, category],
  );

  return (
    <ActionsMenu
      deleteDisabled={categories.length === 1}
      onEditOpen={editDialogOpenHandler}
      ConfirmationDialog={ConfirmationDialog}
    />
  );
};
