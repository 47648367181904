import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getDimensionsFromFiltersThunk } from "./dimensions/getDimensionsFromFiltersThunk";
import { getResourceExplorerPossibleFiltersThunk } from "../resource-explorer/getResourceExplorerPossibleFiltersThunk";
import { setMultiFilters, setProviders } from "../../resourceExplorerSlice";
import { getResourceExplorerMutatedFilters } from "../../utils/helpers/getResourceExplorerMutatedFilters";
import { RootState } from "../../../store";
import { mergeREViewAndEnabledProviders } from "../../utils/helpers/mergeREViewAndEnabledProviders";
import {
  ProviderType,
  ResourceExplorer,
  ResourceExplorerPossibleFilter,
} from "../../../../services/cloudchipr.api";
import { enabledProvidersByBillingStatusSelector } from "../../../common/selectors/billing-status/enabledProvidersByBillingStatusSelector";

export const setInitialFiltersDataThunk = createAsyncThunk(
  "resourceExplorer/setInitialFilters",
  async (resourceExplorerView: ResourceExplorer, { dispatch, getState }) => {
    const state = getState() as RootState;

    try {
      dispatch(getDimensionsFromFiltersThunk(resourceExplorerView.filters));

      const possibleFilters: Partial<
        Record<ProviderType, ResourceExplorerPossibleFilter[]>
      > = {};
      const enabledProviders = enabledProvidersByBillingStatusSelector(state);

      const cloudProviders = mergeREViewAndEnabledProviders(
        enabledProviders,
        resourceExplorerView.cloud_providers ?? [],
        resourceExplorerView.is_default,
      );

      const promises = [];

      for (const provider of enabledProviders) {
        promises.push(
          dispatch(
            getResourceExplorerPossibleFiltersThunk({
              provider,
            }),
          )
            .unwrap()
            .then((data) => {
              if (data) {
                possibleFilters[provider] = data;
              }
            }),
        );
      }

      await Promise.all(promises);
      const filters = getResourceExplorerMutatedFilters(
        resourceExplorerView.filters,
        possibleFilters,
        cloudProviders,
        enabledProviders,
      );

      dispatch(setProviders(cloudProviders));
      dispatch(setMultiFilters(filters));
    } catch (e) {
      // @ts-expect-error//TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
