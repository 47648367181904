import { FC, Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import { NavItem } from "../../../nav-item/NavItem";
import { useAppAbility } from "../../../../../../services/permissions";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { ResourceExplorerV2NavigationAddActionMenu } from "../ResourceExplorerV2NavigationAddActionMenu";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";

interface ResourceExplorerV2NavigationAddViewNavItemProps {
  visibility?: NavigationItemsVisibilityType;
}
export const ResourceExplorerV2NavigationAddViewNavItem: FC<
  ResourceExplorerV2NavigationAddViewNavItemProps
> = ({ visibility }) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const { cannot } = useAppAbility();

  const cannotCreateView = cannot("create", "resource-explorer");

  return (
    <Fragment>
      <NavItem
        nested
        primary="Add"
        primaryTypographyProps={{ color: "primary" }}
        onButtonClick={openMenu}
        disabled={cannotCreateView}
        icon={AddIcon}
        iconProps={{ color: "primary" }}
      />

      <ResourceExplorerV2NavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
        visibility={visibility}
      />
    </Fragment>
  );
};
