import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { scheduledJobsPatchFixedCacheKey } from "../thunks/toggleScheduledJobsStatusThunk";
import { workflowsDataLoadingSelector } from "../../automations/selectros/workflow/list-data/workflowsDataLoadingSelector";

const patchLoadingSelector =
  cloudChiprApi.endpoints.patchV2UsersMeSchedulesByScheduleId.select(
    scheduledJobsPatchFixedCacheKey,
  );

export const scheduleStatusToggleLoadingSelector = (state: RootState) => {
  const isPatchLoading = patchLoadingSelector(state)?.isLoading;
  const isGetLoading = workflowsDataLoadingSelector(state);

  return isPatchLoading || isGetLoading;
};
