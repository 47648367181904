import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import {
  AFlexibleKeyValueMapContainingMetadataAboutTheRow,
  ForecastedCost,
  ResourceExplorerGrouping,
} from "../../../../../../../../../../services/cloudchipr.api";
import { LabelColumnHeaderCell } from "../components/header-cells/LabelColumnHeaderCell";
import { LabelColumnCell } from "../components/labels-column/LabelColumnCell";
import { ResourceExplorerTotalCostColumnHeaderCell } from "../components/header-cells/ResourceExplorerTotalCostColumnHeaderCell";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { ResourceExplorerForecastedCostHeaderCell } from "../components/header-cells/ResourceExplorerForecastedCostHeaderCell";
import { forecastedCostOptionLabels } from "../../../../resource-explorer-metadata/components/utils/constants";
import { ResourceExplorerPrevPeriodHeaderCell } from "../components/header-cells/ResourceExplorerPrevPeriodHeaderCell";
import { ResourceExplorerTrendColumnHeaderCell } from "../components/header-cells/ResourceExplorerTrendColumnHeaderCell";
import {
  regionSortingFn,
  accountSortingFn,
  labelColumnSortingFn,
} from "../utils/helpers/sortingFunctions";
import {
  regionColumnFilter,
  accountColumnFilter,
} from "../utils/helpers/filterFunctions";
import { PreviousPeriodCostCell } from "../components/common/PreviousPeriodCostCell";
import { TrendCell } from "../components/common/TrendCell";
import { groupingsExcludingForecastedCostsColumns } from "../../utils/constants/groupingsExcludingForecastedCostsColumns";
import { TypographyWithCopy } from "../../../../../../../../../common/TypographyWithCopy";
import { ResourceExplorerTableAccountCell } from "../components/common/ResourceExplorerTableAccountCell";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

const getStartColumns = (
  enableSorting: boolean = true,
): ColumnSetupType<ResourceExplorerGridDataWithId>[] => [
  {
    minSize: 240,
    id: "label_cell",
    accessorKey: "label",
    header: () => <LabelColumnHeaderCell />,
    sortingFn: labelColumnSortingFn,
    meta: { headerTitle: "Resources", hideFromSettings: true },
    enableSorting: enableSorting,
    cell: (cell) => {
      const data = cell.row.original;
      return (
        <LabelColumnCell
          provider={data.cloud_provider}
          label={data.label}
          field={data.field}
          accountExistInLiveUsage={
            !!data.accountExistInLiveUsage &&
            data.resourceExplorerGrouping === "account"
          }
          grouping={data.resourceExplorerGrouping}
          accountId={data.metadata?.account_id}
          liveAccountId={data.metadata?.live_account_id}
          isTracked={!!data.metadata?.is_tracked}
          aggregatedByValue={data.metadata?.aggregated_by_value}
          filterValue={data?.metadata?.filter_value}
          // @ts-expect-error // TODO: https://app.clickup.com/t/6655588/C8R-4739
          clickable={data.metadata?.clickable}
        />
      );
    },
  },
];

const middleColumns: ColumnSetupType<ResourceExplorerGridDataWithId>[] = [
  {
    enableGlobalFilter: true,
    minSize: 120,
    id: "metadata_account",
    accessorKey: "total_cost",
    header: "Account",
    sortingFn: accountSortingFn,
    filterFn: accountColumnFilter,
    cell: (cell) => {
      return <ResourceExplorerTableAccountCell data={cell.row.original} />;
    },
  },
  {
    size: 140,
    id: "metadata_region",
    accessorKey: "total_cost",
    header: "Region",
    sortingFn: regionSortingFn,
    filterFn: regionColumnFilter,
    cell: (cell) => {
      const metadata = cell.row.original
        .metadata as AFlexibleKeyValueMapContainingMetadataAboutTheRow;

      return (
        <TypographyWithCopy
          variant="body2"
          value={metadata?.region ?? "-"}
          maxWidth={140}
        />
      );
    },
  },
];

const endColumns: ColumnSetupType<ResourceExplorerGridDataWithId>[] = [
  {
    minSize: 110,
    accessorKey: "total_cost",
    header: () => <ResourceExplorerTotalCostColumnHeaderCell />,
    meta: { headerTitle: "Total Cost" },
    cell: (cell) => {
      const val = cell.getValue() as number;

      return (
        <Typography variant="body2" align="right" pr={4}>
          {isNaN(val) ? "N / A" : money(val)}
        </Typography>
      );
    },
  },
  {
    minSize: 170,
    accessorKey: "previous_period_total_cost",
    header: () => <ResourceExplorerPrevPeriodHeaderCell />,
    meta: { headerTitle: "Previous Period Cost" },
    cell: (cell) => {
      const data = cell.getValue() as number | null;
      return <PreviousPeriodCostCell value={data} />;
    },
  },
  {
    minSize: 100,
    accessorKey: "trend",
    header: () => <ResourceExplorerTrendColumnHeaderCell />,
    meta: { headerTitle: "Trend" },
    cell: (cell) => {
      const data = cell.getValue() as number | null;
      return <TrendCell value={data} />;
    },
  },
];

const getForecastedCostColumns = (
  groupBy: ResourceExplorerGrouping,
): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  if (!groupBy || groupingsExcludingForecastedCostsColumns.has(groupBy)) {
    return [];
  }
  return [
    {
      minSize: 230,
      accessorKey: "monthly_forecast",
      header: () => (
        <ResourceExplorerForecastedCostHeaderCell forecastOption="month" />
      ),
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "month",
        )} Cost`,
      },
      cell: (cell) => {
        const data = cell.getValue() as ForecastedCost;

        return (
          <Typography variant="body2" align="right" pr={4}>
            {data === null ? "-" : `~${money(data?.cost)}`}
          </Typography>
        );
      },
    },
    {
      minSize: 230,
      accessorKey: "quarterly_forecast",
      header: () => (
        <ResourceExplorerForecastedCostHeaderCell forecastOption="quarter" />
      ),
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "quarter",
        )} Cost`,
      },
      cell: (cell) => {
        const data = cell.getValue() as ForecastedCost;

        return (
          <Typography variant="body2" align="right" pr={4}>
            {data === null ? "-" : `~${money(data.cost)}`}
          </Typography>
        );
      },
    },
  ];
};

export const getResourceExplorerDataGridColumns = (
  groupBy: ResourceExplorerGrouping,
): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  if (groupBy === "resource") {
    return [...getStartColumns(), ...middleColumns, ...endColumns];
  }

  return [
    ...getStartColumns(groupBy !== "none"),
    ...endColumns,
    ...getForecastedCostColumns(groupBy),
  ];
};
