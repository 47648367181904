import { FC, useMemo } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsCSVFileNameSelector } from "../../../../../../../../store/commitments/selectors/common/commitmentsCSVFileNameSelector";
import { commitmentsDatesSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { calculateFromToByDatesData } from "../../../../../../../common/date-range-picker-v2/utils/helpers/calculateFromToByDatesData";
import { reservationsCSVDataSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/csv/reservationsCSVDataSelector";

export const ReservationsCSVDownloader: FC = () => {
  const fileName = useAppSelector(commitmentsCSVFileNameSelector);
  const dates = useAppSelector(commitmentsDatesSelector);
  const data = useAppSelector(reservationsCSVDataSelector);

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const tooltip = useMemo(() => {
    const { toDate, fromDate } = calculateFromToByDatesData(
      dates,
      quarterStartMonth,
    );

    return fromDate && toDate ? `from ${fromDate} to ${toDate}` : "";
  }, [dates, quarterStartMonth]);

  if (!data) {
    return null;
  }

  return (
    <CSVLink filename={fileName} data={data}>
      <Tooltip title={`Export CSV ${tooltip}`} arrow placement="top">
        <IconButton size="medium">
          <DownloadOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </CSVLink>
  );
};
