import { FC, ReactNode, SVGProps } from "react";
import { XAxisProps } from "recharts";

export type XAxisTickProps = {
  labelFormatter?(label: string): ReactNode;
  parentHeight?: number;
  parentPadding?: XAxisProps["padding"];
  textProps?: SVGProps<SVGTextElement>;
};

export const CustomizedXAxisTick: FC<any> = ({
  x,
  y,
  payload,
  visibleTicksCount,
  index,
  chartType,
  empty,
  xAxisTickProps,
}) => {
  const toEnd =
    (chartType === "line" || chartType === "area") &&
    index === visibleTicksCount - 1;

  const { labelFormatter, textProps } = xAxisTickProps ?? {};

  if (empty) {
    return null;
  }

  return (
    <g transform={`translate(${x},${y + 5})`}>
      <text
        x={0}
        y={10}
        fill="#3D3F53"
        fontSize={11}
        fontWeight={500}
        textAnchor={toEnd ? "end" : "middle"}
        {...textProps}
      >
        {labelFormatter ? labelFormatter(payload.value) : payload.value}
      </text>
    </g>
  );
};
