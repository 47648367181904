import {
  Dashboard,
  DashboardFolder,
  ReFolderV2,
  ResourceExplorer,
} from "../../../../services/cloudchipr.api";

//TODO: merge with isResourceExplorerFolder after views variable rename
export const isHierarchyFolder = (
  data: Dashboard | DashboardFolder | ResourceExplorer | ReFolderV2,
): data is DashboardFolder => {
  return "items" in data;
};
