import { FC, Fragment } from "react";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerV2NavigationAddViewNavItem } from "./components/nav-items/ResourceExplorerV2NavigationAddViewNavItem";
import { ResourceExplorerV2NavigationAddActionMenu } from "./components/ResourceExplorerV2NavigationAddActionMenu";
import { ResourceExplorerV2NavigationFolderNavItem } from "./components/nav-items/ResourceExplorerV2NavigationFolderNavItem";
import { ResourceExplorerV2NavigationViewNavItem } from "./components/nav-items/ResourceExplorerV2NavigationViewNavItem";
import { isResourceExplorerFolder } from "./utils/helpers/isResourceExplorerFolder";
import { ResourceExplorerHierarchyDndWrapper } from "./components/dnd/ResourceExplorerHierarchyDndWrapper";
import { NavItem } from "../nav-item/NavItem";
import { useAppAbility } from "../../../../services/permissions";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";
import { resourceExplorerViewsHierarchyDataSelector } from "../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyDataSelector";

export const ResourceExplorerV2Navigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const { can } = useAppAbility();

  const canCreateView = can("create", "resource-explorer");
  const enableNavigationHierarchyVisibility = useFlag(
    "EnableNavigationHierarchyVisibility",
  );
  const enableResourceExplorerDnd = useFlag("EnableResourceExplorerDnd");
  const location = useLocation();
  const resourceExplorerSelected =
    location.pathname.startsWith("/resource-explorer");

  const resourceExplorerViewsHierarchyData = useAppSelector(
    resourceExplorerViewsHierarchyDataSelector,
  );

  return (
    <Fragment>
      <NavItem
        primary="Resource Explorer"
        icon={BarChartOutlinedIcon}
        selected={resourceExplorerSelected}
        secondaryAction={
          <IconButton size="small" onClick={openMenu} disabled={!canCreateView}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        }
        navCollapsed={navigationCollapsed}
      >
        {enableResourceExplorerDnd ? (
          <ResourceExplorerHierarchyDndWrapper />
        ) : (
          resourceExplorerViewsHierarchyData?.map((resourceExplorer) =>
            isResourceExplorerFolder(resourceExplorer) ? (
              <ResourceExplorerV2NavigationFolderNavItem
                folder={resourceExplorer}
                key={resourceExplorer.id}
              />
            ) : (
              <ResourceExplorerV2NavigationViewNavItem
                view={resourceExplorer}
                key={resourceExplorer.id}
              />
            ),
          )
        )}

        {!enableNavigationHierarchyVisibility && (
          <ResourceExplorerV2NavigationAddViewNavItem />
        )}
      </NavItem>

      <ResourceExplorerV2NavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
      />
    </Fragment>
  );
};
