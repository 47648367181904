import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { GroupBySelector } from "../../../../../../common/resource-explorer/group-by-selector/GroupBySelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { alertsCostAnomalyDataGroupingSelector } from "../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataGroupingSelector";
import { setAlertsData } from "../../../../../../../../store/alerts/alertsSlice";
import { ResourceExplorerGrouping } from "../../../../../../../../services/cloudchipr.api";
import { alertsCostAnomalyDataViewIdSelector } from "../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataViewIdSelector";
import { resourceExplorerCloudProvidersByViewIdSelector } from "../../../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerCloudProvidersByViewIdSelector";
import { getCostAnomalyPreviewDataGridDataThunk } from "../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/getCostAnomalyPreviewDataGridDataThunk";
import { selectedViewFilteredByDimensionsSelector } from "../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/selectedViewFilteredByDimensionsSelector";

interface AlertsCostsAnomalyGroupingSelectProps {
  averageCostCollapsed: boolean;
}

export const AlertsCostsAnomalyGroupingSelect: FC<
  AlertsCostsAnomalyGroupingSelectProps
> = ({ averageCostCollapsed }) => {
  const dispatch = useAppDispatch();
  const groupBy = useAppSelector(alertsCostAnomalyDataGroupingSelector);
  const selectedViewId = useAppSelector(alertsCostAnomalyDataViewIdSelector);
  const selectedViewFilteredByDimensions = useAppSelector(
    selectedViewFilteredByDimensionsSelector,
  );
  const providers = useAppSelector((state) =>
    resourceExplorerCloudProvidersByViewIdSelector(state, selectedViewId),
  );

  const handleGroupingChange = useCallback(
    (grouping: ResourceExplorerGrouping) => {
      if (grouping !== groupBy) {
        dispatch(setAlertsData({ grouping }));

        if (averageCostCollapsed) {
          dispatch(getCostAnomalyPreviewDataGridDataThunk());
        }
      }
    },
    [dispatch, groupBy, averageCostCollapsed],
  );

  if (!groupBy || !providers) {
    return null;
  }

  return (
    <Stack flex={1}>
      <GroupBySelector
        value={groupBy}
        providers={providers}
        categoryGroupingAvailable={selectedViewFilteredByDimensions}
        hiddenGroupings={["cost_allocation_tag", "resource"]}
        onGroupingChange={handleGroupingChange}
      />
    </Stack>
  );
};
