import { FC } from "react";
import { Stack } from "@mui/material";
import { FilterItemsGroup } from "./FilterItemsGroup";
import { FilterItem } from "./filter-item/FilterItem";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import {
  FiltersCombinationOperatorType,
  DynamicProviderFilter,
} from "../../utils/types/common";
import { isDynamicProviderFilters } from "../../utils/helpers/isDynamicProviderFilters";

interface FilterRowProps {
  filter: DynamicProviderFilter;
  index: number;
  provider: ProviderType;
  parentOperator: FiltersCombinationOperatorType;
  onOperatorChange(operator: FiltersCombinationOperatorType): void;
  onFiltersChange(filter: DynamicProviderFilter, index: number): void;
  onFilterRemove(index: number): void;
}

export const FilterRow: FC<FilterRowProps> = ({
  filter,
  index,
  onFiltersChange,
  onOperatorChange,
  parentOperator,
  provider,
  onFilterRemove,
}) => {
  const filterGroup = isDynamicProviderFilters(filter);

  return (
    <Stack direction="row">
      {filterGroup ? (
        <FilterItemsGroup
          index={index}
          provider={provider}
          filters={filter}
          onFilterRemove={onFilterRemove}
          parentOperator={parentOperator}
          onOperatorChange={onOperatorChange}
          onFiltersChange={onFiltersChange}
        />
      ) : (
        <FilterItem
          index={index}
          filter={filter}
          onFilterRemove={onFilterRemove}
          parentOperator={parentOperator}
          onFilterChange={onFiltersChange}
          onOperatorChange={onOperatorChange}
        />
      )}
    </Stack>
  );
};
