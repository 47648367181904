import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Rds } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceMetricsHeaderCell } from "../../../../../cells/metrics-cells/components/ResourceMetricsHeaderCell";

export const rdsColumns: ColumnSetupType<Rds>[] = [
  {
    accessorKey: "db_id",
    header: "Database Id",
    type: "identifierWithRecommendationLink",
    size: 200,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "unused_since",
    header: "State",
    type: "state",
  },
  {
    id: "cpu_max",
    size: 220,
    minSize: 165,
    maxSize: 300,
    meta: { cellStyles: { p: 0 }, headerTitle: "CPU Max" },
    accessorKey: "inline_metrics",
    type: "computeMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    id: "total_connection_sum",
    size: 220,
    minSize: 165,
    maxSize: 300,
    meta: { cellStyles: { p: 0 }, headerTitle: "Connections" },
    accessorKey: "inline_metrics",
    type: "dbMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  {
    accessorKey: "instance_type",
    header: "Instance type",
  },
  { accessorKey: "db_type", header: "Database type" },
  {
    accessorKey: "cpu_value",
    header: "CPU Max",
    headerTooltip: "The maximum usage of CPU for the last 7 days.",
    type: "cpuStatistic",
  },
  {
    accessorKey: "connections_value",
    header: "Connections",
    type: "statistic",
    headerTooltip: "The total number of connections for the last 7 days.",
  },
  {
    accessorKey: "nodes_count",
    header: "Node count",
    type: "nodesCount",
  },
  { accessorKey: "status", header: "Status" },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
