import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { dashboardHierarchyItemsByVisibilitySelector } from "../../../../../../../store/dashboards/selectors/dashboard-hierarchy/dashboardHierarchyItemsByVisibilitySelector";
import { deleteDashboardHierarchyItemLoadingSelector } from "../../../../../../../store/dashboards/selectors/dashboard/loadings/deleteDashboardHierarchyItemLoadingSelector";
import { deleteDashboardItemThunk } from "../../../../../../../store/dashboards/thunks/common/deleteDashboardItemThunk";
import { getNextDashboardId } from "../../../../dashboard/components/utils/getNextDashboardId";
import { DeleteConfirmationDialog } from "../../../../../../common/modals/DeleteConfirmationDialog";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";

interface DashboardV2ItemDeleteDialogProps {
  id: string;
  open: boolean;
  name: string;
  closeMenu(): void;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2ItemDeleteDialog: FC<
  DashboardV2ItemDeleteDialogProps
> = ({ closeMenu, id, name, open, visibility }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const dashboardHierarchy = useAppSelector((state) =>
    dashboardHierarchyItemsByVisibilitySelector(state, visibility),
  );

  const loading = useAppSelector(deleteDashboardHierarchyItemLoadingSelector);

  const removeDashboardItemHandler = useCallback(async () => {
    await dispatch(deleteDashboardItemThunk(id));

    if (dashboardId === id) {
      const nextDashboardId = getNextDashboardId(id, dashboardHierarchy ?? []);
      navigate({
        pathname: `/dashboards/${nextDashboardId}`,
      });
    }
  }, [dispatch, navigate, dashboardId, id, dashboardHierarchy]);

  const confirmRemoveItemHandler = useCallback(async () => {
    await removeDashboardItemHandler();
    closeMenu();
  }, [closeMenu, removeDashboardItemHandler]);

  return (
    <DeleteConfirmationDialog
      open={open}
      title="Delete Dashboard?"
      onClose={closeMenu}
      onDelete={confirmRemoveItemHandler}
      loading={loading}
      content={
        <div {...navigationHierarchyItemNoDndKey}>
          <Typography>
            Please confirm if you want to delete
            <Typography component="span" fontWeight="bold">
              {" "}
              {name}{" "}
            </Typography>
            dashboard.
          </Typography>
        </div>
      }
    />
  );
};
