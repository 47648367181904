import { FC, useCallback } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { SavingsPlanCoverageGrouping } from "../../../../../../../services/cloudchipr.api";
import { setCommitmentsDetailsDrawerCoverageGrouping } from "../../../../../../../store/commitments/commitmentsSlice";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { drawerDataGridPossibleGroupings } from "../../../utils/constants/drawerDataGridPossibleGroupings";
import { getReservationDetailsThunk } from "../../../../../../../store/commitments/thunks/getReservationDetailsThunk";
import { commitmentsTypeTabSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";

export const CommitmentsDrawerDataGridGrouping: FC = () => {
  const dispatch = useAppDispatch();
  const type = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);
  const grouping = useAppSelector(
    commitmentsDetailsDrawerCoverageGroupingSelector,
  );
  const activeTab = useAppSelector(commitmentsTypeTabSelector);

  const tabChangeHandler = useCallback(
    (_: unknown, grouping: SavingsPlanCoverageGrouping) => {
      if (!grouping) {
        return;
      }

      dispatch(setCommitmentsDetailsDrawerCoverageGrouping(grouping));

      if (activeTab === "reservation") {
        dispatch(getReservationDetailsThunk());
      } else {
        dispatch(getUtilizationAndCoverageDetailsThunk());
      }
    },
    [dispatch, activeTab],
  );

  if (!type) {
    return null;
  }

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={grouping}
      color="primary"
      onChange={tabChangeHandler}
    >
      {drawerDataGridPossibleGroupings[type]?.map(
        ({ value, spLabel, riLabel }) => {
          return (
            <ToggleButton
              key={value}
              value={value}
              sx={{ textTransform: "none", borderRadius: 2 }}
            >
              {activeTab === "savings_plan" ? spLabel : riLabel}
            </ToggleButton>
          );
        },
      )}
    </ToggleButtonGroup>
  );
};
