import { FC, Fragment } from "react";
import { Divider } from "@mui/material";
import { DashboardV2VisibilityItem } from "./DashboardV2VisibilityItem";
import { useAppSelector } from "../../../../../../store/hooks";
import { navigationDashboardPublicDataSelector } from "../../../../../../store/navigation/selectors/dashboard/navigationDashboardPublicDataSelector";
import { navigationDashboardPrivateDataSelector } from "../../../../../../store/navigation/selectors/dashboard/navigationDashboardPrivateDataSelector";

export const DashboardV2Visibility: FC = () => {
  const publicHierarchyData = useAppSelector(
    navigationDashboardPublicDataSelector,
  );
  const privateHierarchyData = useAppSelector(
    navigationDashboardPrivateDataSelector,
  );

  return (
    <Fragment>
      <DashboardV2VisibilityItem
        data={publicHierarchyData}
        visibility="visible_to_everyone"
      />

      <Divider sx={{ my: 1, mx: 1 }} />

      <DashboardV2VisibilityItem
        data={privateHierarchyData}
        visibility="visible_only_to_me"
      />
    </Fragment>
  );
};
