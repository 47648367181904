import { FC, Fragment, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDidMount, useEffectOnceWhen } from "rooks";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";
import { Stack } from "@mui/material";
import { UtilizationAndCoveragePageHeader } from "./utilization-and-coverage/components/UtilizationAndCoveragePageHeader";
import { CommitmentTypeTabs } from "./common/components/CommitmentTypeTabs";
import { UtilizationAndCoverage } from "./utilization-and-coverage/UtilizationAndCoverage";
import { Reservations } from "./reservations/Reservations";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { missingSetupSelector } from "../../../store/commitments/selectors/organisations/missingSetupSelector";
import {
  setCommitmentsOrgId,
  setCommitmentsTypeTab,
} from "../../../store/commitments/commitmentsSlice";
import { getAllProvidersBillingStatusThunk } from "../../../store/common/thunks/billing-status/getAllProvidersBillingStatusThunk";
import { ProviderBillingDataAccessController } from "../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { commitmentsTypeTabSelector } from "../../../store/commitments/selectors/common/commitmentsTypeTabSelector";
import { CommitmentType } from "../../../services/cloudchipr.api";

export const Commitments: FC = () => {
  const params = useParams();
  const orgId = params?.orgId ?? "";
  const [searchParams] = useSearchParams();
  const tabFromRouter = searchParams.get("active") as CommitmentType;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const missingSetup = useAppSelector(missingSetupSelector);
  const activeTab = useAppSelector(commitmentsTypeTabSelector);

  useEffect(() => {
    dispatch(setCommitmentsOrgId(orgId));
  }, [dispatch, orgId]);

  useDidMount(() => {
    dispatch(getAllProvidersBillingStatusThunk());
  });

  useEffectOnceWhen(() => {
    navigate({ search: "active=savings_plan" });
    dispatch(setCommitmentsTypeTab("savings_plan"));
  }, !tabFromRouter);

  useEffectOnceWhen(() => {
    if (tabFromRouter) {
      dispatch(setCommitmentsTypeTab(tabFromRouter));
    }
  }, !!tabFromRouter);

  if (missingSetup) {
    return <ProviderBillingDataAccessController provider="aws" />;
  }

  if (!activeTab) {
    return null;
  }

  return (
    <Fragment>
      <UtilizationAndCoveragePageHeader />

      <TabContext value={activeTab}>
        <CommitmentTypeTabs />

        <Stack p={1}>
          <TabPanel value="savings_plan" sx={{ p: 0 }}>
            <UtilizationAndCoverage />
          </TabPanel>

          <TabPanel value="reservation" sx={{ p: 0 }}>
            <Reservations />
          </TabPanel>
        </Stack>
      </TabContext>
    </Fragment>
  );
};
