import { FC, Fragment } from "react";

import { Divider } from "@mui/material";
import { ResourceExplorerV2VisibilityItem } from "./ResourceExplorerV2VisibilityItem";
import { useAppSelector } from "../../../../../../store/hooks";
import { navigationResourceExplorerPublicDataSelector } from "../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerPublicDataSelector";
import { navigationResourceExplorerPrivateDataSelector } from "../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerPrivateDataSelector";

export const ResourceExplorerV2Visibility: FC = () => {
  const publicHierarchyData = useAppSelector(
    navigationResourceExplorerPublicDataSelector,
  );
  const privateHierarchyData = useAppSelector(
    navigationResourceExplorerPrivateDataSelector,
  );

  return (
    <Fragment>
      <ResourceExplorerV2VisibilityItem
        data={publicHierarchyData}
        visibility="visible_to_everyone"
      />
      <Divider sx={{ my: 1, mx: 1 }} />
      <ResourceExplorerV2VisibilityItem
        data={privateHierarchyData}
        visibility="visible_only_to_me"
      />
    </Fragment>
  );
};
