import { ReservationsCoverageDataWithId } from "../../../../../components/pages/commtiments/common/utils/types";
import {
  commitmentTypesLabels,
  commitmentsResourceTypeNames,
} from "../../../../../components/pages/commtiments/common/utils/constants/labels";
import { SavingsPlanCoverageGrouping } from "../../../../../services/cloudchipr.api";
import { DataViewTab } from "../../types/types";
import { getUtilizationAndCoverageDrawerDynamicHeaderCellTitles } from "../../../../../components/pages/commtiments/utilization-and-coverage/utils/helpers/getUtilizationAndCoverageDrawerDynamicHeaderCellTitles";

interface CommitmentsCSVDataGeneratorArgs {
  viewTab: DataViewTab;
  grouping: SavingsPlanCoverageGrouping;
  data?: ReservationsCoverageDataWithId[];
}

export const reservationCSVDataGenerator = ({
  viewTab,
  grouping,
  data,
}: CommitmentsCSVDataGeneratorArgs) => {
  return data?.map((item) => {
    const csv: Record<string, string | number | null | undefined> = {};

    if (item.resource_type) {
      const rt = item.resource_type?.resource_type;

      csv["Resource Type"] = rt ? commitmentsResourceTypeNames[rt] : "";
      csv["Instance Type"] = item.resource_type?.instance_type;
      csv["Platform"] = item.resource_type?.platform;
      csv["Region"] = item.resource_type?.region;
    }

    if (item.account) {
      csv["Account"] = item.account?.name;
      csv["Account ID"] = item.account?.provider_account_id;
    }

    if (item.reservation) {
      csv["Commitment"] = item.reservation?.provider_id;
      csv["Commitment Type"] = commitmentTypesLabels[item.reservation?.type];
    }

    if (!item.reservation) {
      const coverageTitle =
        getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
          viewTab,
          grouping,
        )?.coveredUsageCell ?? "";

      csv[coverageTitle] = item.covered_usage;
      if (viewTab === "coverage") {
        csv["On-Demand Spend"] = item.uncovered_usage;
      }
    }

    csv[
      getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(viewTab, grouping)
        ?.coverageCell ?? ""
    ] = `${item.coverage}%`;

    return csv;
  });
};
