import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { useToggle } from "rooks";
import { RecommendationLevelFilterAccountsSelect } from "./RecommendationLevelFilterAccountsSelect";
import { RecommendationLevelFilterPayer } from "./RecommendationLevelFilterPayer";
import { setCommitmentsRecommendationsAccountScope } from "../../../../../../../../../store/commitments/commitmentsSlice";
import { SavingsPlanAccountScope } from "../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { commitmentsRecommendationsAccountScopeSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountScopeSelector";
import { commitmentsRecommendationsAccountIdsSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountIdsSelector";
import { organizationAccountsByCurrentOrgIdSelector } from "../../../../../../../../../store/commitments/selectors/organisations/organizationAccountsByCurrentOrgIdSelector";

export const RecommendationLevelFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(commitmentsRecommendationsAccountScopeSelector);
  const accountIds = useAppSelector(
    commitmentsRecommendationsAccountIdsSelector,
  );
  const accounts = useAppSelector(organizationAccountsByCurrentOrgIdSelector);
  const allSelected = accounts?.length === accountIds?.length;

  const [open, toggleOpen] = useToggle(false);
  const changeHandler = useCallback(
    (e: SelectChangeEvent<string>) => {
      const scope = e.target.value as SavingsPlanAccountScope;

      dispatch(setCommitmentsRecommendationsAccountScope(scope));
    },
    [dispatch],
  );

  const renderValue = useCallback(
    (value: unknown) => {
      if (value === "payer") {
        return "Payer";
      }

      return (
        <Typography component="div" variant="caption">
          Linked Account{" "}
          <Chip
            size="small"
            sx={{ ml: 1 }}
            variant="filled"
            label={
              <Typography
                fontWeight="bold"
                color="text.secondary"
                variant="caption"
              >
                {allSelected ? "All" : accountIds?.length}
              </Typography>
            }
          />
        </Typography>
      );
    },
    [accountIds, allSelected],
  );

  if (!value) {
    return null;
  }

  return (
    <FormControl size="xsmall" sx={{ width: 170 }}>
      <InputLabel>Recommendation Level</InputLabel>
      <Select
        open={open}
        value={value}
        label="Recommendation Level"
        onChange={changeHandler}
        renderValue={renderValue}
        onOpen={toggleOpen}
        onClose={toggleOpen}
      >
        <RecommendationLevelFilterPayer />

        <RecommendationLevelFilterAccountsSelect toggleOpen={toggleOpen} />
      </Select>
    </FormControl>
  );
};
