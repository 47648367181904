import { ChangeEvent, FC, useCallback } from "react";
import { Checkbox, FormControlLabel, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { CostAnomalyThresholdType } from "../utils/types/types";
import { costsAnomalyThresholdDataByType } from "../utils/constants/constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { alertsDataThresholdByTypeSelector } from "../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/alertsDataThresholdByTypeSelector";
import {
  addDefaultThresholdByType,
  deleteThresholdById,
  updateThresholdAmountById,
} from "../../../../../../../../store/alerts/alertsSlice";
import { AlertsThresholdUnit } from "../../../../../../../../store/alerts/utils/types/types";
import { alertsDataThresholdsSelector } from "../../../../../../../../store/alerts/selectors/common/data/alertsDataThresholdsSelector";
import { NumberField } from "../../../../../../../common/NumberField";

interface AlertsCostsAnomalySetThresholdProps {
  type: CostAnomalyThresholdType;
}

export const AlertsCostsAnomalySetThreshold: FC<
  AlertsCostsAnomalySetThresholdProps
> = ({ type }) => {
  const dispatch = useAppDispatch();
  const thresholdData = costsAnomalyThresholdDataByType.get(type);
  const threshold = useAppSelector((state) =>
    alertsDataThresholdByTypeSelector(state, type),
  );
  const allThresholds = useAppSelector(alertsDataThresholdsSelector);
  const checked = !!threshold;
  const disabled = allThresholds.length === 1 && checked;

  const toggleThreshold = useCallback(() => {
    if (!checked) {
      dispatch(addDefaultThresholdByType(type as AlertsThresholdUnit));
    } else {
      if (!threshold?.id) {
        return;
      }

      dispatch(deleteThresholdById(threshold.id));
    }
  }, [dispatch, type, threshold?.id, checked]);

  const handleThresholdAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const amount = event.target.value;
      if (!threshold?.id) {
        return;
      }

      dispatch(
        updateThresholdAmountById({
          id: threshold.id,
          amount,
        }),
      );
    },
    [dispatch, threshold?.id],
  );

  return (
    <Stack justifyContent="space-between" direction="row" gap={5}>
      <FormControlLabel
        disableTypography
        disabled={disabled}
        checked={checked}
        control={<Checkbox size="small" />}
        onChange={toggleThreshold}
        label={
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="body1">{thresholdData?.title}</Typography>
            <Tooltip
              arrow
              placement="right"
              title={thresholdData?.tooltipTitle}
            >
              <InfoOutlinedIcon color="action" fontSize="small" />
            </Tooltip>
          </Stack>
        }
      />

      <NumberField
        minValue={1}
        maxValue={type === "percent" ? 100 : undefined}
        size="small"
        sx={{ width: 160 }}
        disabled={!threshold}
        value={threshold?.amount ?? ""}
        onChange={handleThresholdAmountChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" disableTypography>
              <Typography
                variant="inherit"
                color={!threshold ? "grey.400" : undefined}
              >
                {thresholdData?.unit}
              </Typography>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
