import { FC, Fragment } from "react";
import { IconButton, Stack } from "@mui/material";
import { Tune } from "@mui/icons-material";
import { RenderToolbarProps } from "../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { ColumnsSwitcher } from "../../../../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";
import { CsvDataDownloader } from "../toolbar/CsvDataDownloader";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { currentAccountApplyingFilterTemplateFiltersByResourceTypeSelector } from "../../../../../../../../../../store/filters/selectors/filter-set/currentAccountApplyingFilterTemplateFiltersByResourceTypeSelector";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { appliedFiltersByResourceTypeSelector } from "../../../../../../../../../../store/filters/selectors/appliedFiltersByResourceTypeSelector";
import { ResourcesCount } from "../toolbar/ResourcesCount";
import { resourceTypeDataLoadingSelector } from "../../../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataLoadingSelector";
import { ResourceFiltersTextView } from "../../../../resource-filters/ResourceFiltersTextView";
import { isFilterAppliedToResourceSelector } from "../../../../../../../../../../store/filters/selectors/isFilterAppliedToResourceSelector";

interface ResourcesDataGridSubRowToolbarProps extends RenderToolbarProps {
  totalCount: number;
  resourceType: ResourceType;
  count: number;
  showResourcesCountAndFilters?: boolean;
  csvData?: Record<string, string | number>[] | null;
}

export const ResourcesDataGridSubRowToolbar: FC<
  ResourcesDataGridSubRowToolbarProps
> = ({
  table,
  totalCount,
  count,
  showResourcesCountAndFilters,
  resourceType,
  csvData,
}) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const localResourceType =
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;
  const accountApplyingFilters = useAppSelector((state) =>
    currentAccountApplyingFilterTemplateFiltersByResourceTypeSelector(
      state,
      localResourceType,
    ),
  );
  const appliedFilters = useAppSelector((state) =>
    appliedFiltersByResourceTypeSelector(state, localResourceType),
  );
  const isLoading = useAppSelector((state) =>
    resourceTypeDataLoadingSelector(state, resourceType),
  );
  const filtersApplied = useAppSelector((state) =>
    isFilterAppliedToResourceSelector(state, localResourceType),
  );

  if (!accountApplyingFilters || !appliedFilters) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-between" px={2}>
      <Stack direction="row" spacing={0.5}>
        {showResourcesCountAndFilters && (
          <Fragment>
            <ResourceFiltersTextView resourceType={localResourceType} />
            <ResourcesCount
              resourceType={resourceType}
              dataCount={count}
              isLoading={isLoading}
              totalDataCount={totalCount}
              filtersApplied={filtersApplied}
            />
          </Fragment>
        )}
      </Stack>

      <Stack justifyContent="flex-end" direction="row">
        <CsvDataDownloader resourceType={resourceType} data={csvData} />

        <IconButton onClick={openMenu} size="small">
          <Tune fontSize="small" />
        </IconButton>

        <ColumnsSwitcher
          open={open}
          onClose={closeMenu}
          anchor={anchor}
          table={table}
        />
      </Stack>
    </Stack>
  );
};
