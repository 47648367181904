import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { updateResourceExplorerFolderNameThunkFixedCacheKey } from "../../thunks/common/updateResourceExplorerFolderNameThunk";

const selector =
  cloudChiprApi.endpoints
    .patchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId
    .select;

export const updateResourceExplorerFolderNameLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(updateResourceExplorerFolderNameThunkFixedCacheKey)(state)
    ?.isLoading;
};
