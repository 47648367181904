import { Row } from "@tanstack/react-table";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { frequencySortingFn } from "../../../../common/utils/sortingFunctions/frequencySortingFn";

export const scheduledJobsFrequencySortingFn = (
  currentRow: Row<Schedule>,
  nextRow: Row<Schedule>,
) => {
  const current = currentRow.original.cron;
  const next = nextRow.original.cron;

  return frequencySortingFn(current, next);
};
