import { createDraftSafeSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { cloudChiprApi, Organisation } from "../../services/cloudchipr.api";

const getUsersMeOrganisationsCurrentSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrent.select();

export const currentOrganizationSelector = createDraftSafeSelector(
  [getUsersMeOrganisationsCurrentSelector],
  (getUsersMeOrganisationsCurrent): Organisation | null => {
    const { data: organisation } = getUsersMeOrganisationsCurrent;

    return organisation ?? null;
  },
);

const currentOrganizationRestrictionSelector = createDraftSafeSelector(
  [currentOrganizationSelector],
  (currentOrganization): Organisation["restriction_violation"] =>
    currentOrganization?.restriction_violation,
);

const currentOrganizationRestrictionEffectiveAtDateSelector =
  createDraftSafeSelector(
    [currentOrganizationSelector],
    (
      currentOrganization,
    ): Organisation["restriction_violation_effective_from_date"] =>
      currentOrganization?.restriction_violation_effective_from_date,
  );

export const disableByRestrictionViolationSelector = createDraftSafeSelector(
  [
    currentOrganizationRestrictionSelector,
    currentOrganizationRestrictionEffectiveAtDateSelector,
  ],
  (restriction, effectiveAtDate) => {
    return (
      !!restriction &&
      !!effectiveAtDate &&
      moment(effectiveAtDate).unix() < moment().unix()
    );
  },
);

export const currentOrganizationRestrictionGracePeriodSelector =
  createDraftSafeSelector(
    [currentOrganizationRestrictionEffectiveAtDateSelector],
    (effectiveDate) => {
      return Math.ceil(moment(effectiveDate).diff(moment(), "days", true));
    },
  );

export const accountsMaxNumberExceededSelector = createDraftSafeSelector(
  [currentOrganizationRestrictionSelector],
  (organizationRestriction) =>
    organizationRestriction === "max_number_of_accounts_exceeded",
);
export const accountsMaxSpendingExceededSelector = createDraftSafeSelector(
  [currentOrganizationRestrictionSelector],
  (organizationRestriction) =>
    organizationRestriction === "account_max_spending_exceeded",
);
