import { getCurrentProvidersByProviderBillingStatusDataSelector } from "./getCurrentProvidersByProviderBillingStatusDataSelector";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { isBillingInProgressStatus } from "../../utils/helpers/billing-status/isBillingInProgressStatus";
import { isBillingErrorStatus } from "../../utils/helpers/billing-status/isBillingErrorStatus";
import { isBillingWarningStatus } from "../../utils/helpers/billing-status/isBillingWarningStatus";
import { ResourceExplorerStatusTypes } from "../../../../components/pages/resource-explorer/components/app-bar/components/resource-explorer-billing-statuses/utils/types/resourceExplorerStatusTypes";

export type DisabledByReasonType = Record<
  string,
  {
    reasonType: ResourceExplorerStatusTypes;
    reason: string;
  }
>;

export const billingAccountDisabledReasonsByProviderSelector = (
  state: RootState,
  provider: ProviderType,
): DisabledByReasonType => {
  const data =
    getCurrentProvidersByProviderBillingStatusDataSelector(state, provider)
      ?.billing_account_statuses ?? [];

  return data.reduce((result, item) => {
    if (isBillingInProgressStatus(item.status) && item?.account_id) {
      result[item?.account_id] = {
        reasonType: "inProgress",
        reason: "Collecting Billing data",
      };
    }

    if (isBillingErrorStatus(item.status) && item?.account_id) {
      result[item?.account_id] = {
        reasonType: "error",
        reason: "Billing data is not available.",
      };
    }
    if (isBillingWarningStatus(item.status) && item?.account_id) {
      result[item?.account_id] = {
        reasonType: "warning",
        reason: "Enable billing exports, to see complete billing information.",
      };
    }

    return result;
  }, {} as DisabledByReasonType);
};
