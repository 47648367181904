import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { getOffHoursByIdThunk } from "./getOffHoursByIdThunk";
import { initiateEmptyAutomationThunk } from "../initiateEmptyAutomationThunk";
import { getCronFrequency } from "../../utils/helpers/cron/getCronFrequency";
import {
  setAutomationData,
  setAutomationInitialized,
  setOffHoursSpecificData,
} from "../../automationsSlice";
import { parseCron } from "../../utils/helpers/cron/parseCron";

export const initiateOffHoursThunk = createAsyncThunk(
  "automation/initiateOffHours",
  async (id: string | undefined, { dispatch }) => {
    if (!id) {
      dispatch(initiateEmptyAutomationThunk("offHours"));
      return;
    }

    const offHours = await dispatch(getOffHoursByIdThunk(id)).unwrap();

    if (!offHours) {
      return;
    }

    const frequency = getCronFrequency(offHours.start_cron);

    const parsedStartCron = parseCron(offHours.start_cron);
    const parsedStopCron = parseCron(offHours.stop_cron);

    const startTime = offHours.stop_cron
      ? moment()
          .set({
            hour: parsedStartCron?.hours || 0,
            minute: parsedStartCron?.minutes || 0,
          })
          .format()
      : offHours.end_date_time;

    const stopTime = offHours.start_cron
      ? moment()
          .set({
            hour: parsedStopCron?.hours || 0,
            minute: parsedStopCron?.minutes || 0,
          })
          .format()
      : offHours.start_date_time;

    const startWeekDays = parsedStartCron?.daysOfWeek ?? undefined;
    const stopWeekDays = parsedStopCron?.daysOfWeek ?? undefined;

    const repeatValue =
      (frequency === "daily" && parsedStartCron?.daysOfMonth) || undefined;

    dispatch(
      setOffHoursSpecificData({
        action: offHours.action,
        scheduler: {
          repeatValue,
          startTime,
          stopTime,
          startWeekDays,
          stopWeekDays,
        },
      }),
    );

    dispatch(
      setAutomationData({
        frequency,

        id: offHours.id,

        name: offHours.name,
        description: offHours.description ?? undefined,

        startDate: offHours.start_date_time,
        endDate: offHours.end_date_time,
        timeZone: offHours.time_zone,
        threshold: offHours.min_threshold,

        provider: offHours.cloud_provider,
        accountIds: offHours.accounts.map(({ id }) => id),
        regions: offHours.regions,

        notifications: offHours.notifications,
        emails: offHours.emails,

        filter: offHours.filter,

        status: offHours.status,
      }),
    );

    dispatch(setAutomationInitialized(true));
  },
);
