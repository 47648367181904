import { FC } from "react";
import { Button, Typography } from "@mui/material";
import { FiltersCombinationOperatorType } from "../../dynamic-filters/utils/types/common";

interface FiltersDisabledViewFilterOperatorProps {
  type: "text" | "button";
  operator?: FiltersCombinationOperatorType;
}

export const FiltersDisabledViewFilterOperator: FC<
  FiltersDisabledViewFilterOperatorProps
> = ({ operator, type }) => {
  if (type === "text") {
    return (
      <Typography variant="body2" color="text.secondary">
        Where
      </Typography>
    );
  }

  return (
    <Button
      disabled
      size="small"
      variant="outlined"
      sx={{ minWidth: 40, p: 0 }}
    >
      {operator}
    </Button>
  );
};
