import { Typography } from "@mui/material";

import { CostBreakdownV2WidgetSetupTableLabelHeaderCell } from "./cells/CostBreakdownV2WidgetSetupTableLabelHeaderCell";
import { CostBreakdownV2WidgetSetupTableTotalCostHeaderCell } from "./cells/CostBreakdownV2WidgetSetupTableTotalCostHeaderCell";
import { CostBreakdownV2WidgetSetupTablePrevTotalCostHeaderCell } from "./cells/CostBreakdownV2WidgetSetupTablePrevTotalCostHeaderCell";
import { CostBreakdownV2WidgetSetupTableTrendHeaderCell } from "./cells/CostBreakdownV2WidgetSetupTableTrendHeaderCell";
import { CostBreakdownV2WidgetSetupTableForecastedCostHeaderCell } from "./cells/CostBreakdownV2WidgetSetupTableForecastedCostHeaderCell";
import { CostBreakdownV2WidgetSetupPreviousPeriodCell } from "./cells/CostBreakdownV2WidgetSetupPreviousPeriodCell";
import { CostBreakdownV2WidgetSetupTrendCell } from "./cells/CostBreakdownV2WidgetSetupTrendCell";
import {
  ForecastOption,
  NullableForecastedCost,
  ResourceExplorerGrouping,
} from "../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { labelColumnSortingFn } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { forecastedCostOptionLabels } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";
import { CostBreakdownV2WidgetLabelCell } from "../../../../../../widgets/cost-breakdown-v2/components/chart/table/cells/CostBreakdownV2WidgetLabelCell";

const getColumnsStartPart =
  (): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
    return [
      {
        size: 400,
        minSize: 150,
        accessorKey: "label",
        header: () => {
          return <CostBreakdownV2WidgetSetupTableLabelHeaderCell />;
        },
        sortingFn: labelColumnSortingFn,
        cell: CostBreakdownV2WidgetLabelCell,
      },
      {
        minSize: 110,
        accessorKey: "total_cost",
        header: () => <CostBreakdownV2WidgetSetupTableTotalCostHeaderCell />,
        cell: (cell) => {
          const val = cell.getValue() as number;

          return (
            <Typography variant="body2" align="right">
              {isNaN(val) ? "N / A" : money(val)}
            </Typography>
          );
        },
      },
      {
        minSize: 170,
        accessorKey: "previous_period_total_cost",
        header: () => (
          <CostBreakdownV2WidgetSetupTablePrevTotalCostHeaderCell />
        ),
        cell: (cell) => {
          const val = cell.getValue() as number | null;

          return <CostBreakdownV2WidgetSetupPreviousPeriodCell value={val} />;
        },
      },
      {
        size: 100,
        accessorKey: "trend",
        header: () => <CostBreakdownV2WidgetSetupTableTrendHeaderCell />,
        cell: (cell) => {
          const data = cell.getValue() as number | null;

          return <CostBreakdownV2WidgetSetupTrendCell value={data} />;
        },
      },
    ];
  };

interface GetCostBreakdownWidgetTableViewColumnsArgs {
  widgetId?: string;
  forecastOption?: ForecastOption;
  grouping?: ResourceExplorerGrouping;
}

export const getCostBreakdownV2WidgetSetupTableViewColumns = ({
  forecastOption,
  grouping,
}: GetCostBreakdownWidgetTableViewColumnsArgs): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  const startPart = getColumnsStartPart();
  if (
    !grouping ||
    groupingsExcludingForecastedCostsColumns.has(grouping) ||
    !forecastOption
  ) {
    return startPart;
  }
  return [
    ...startPart,
    {
      minSize: 190,
      accessorKey:
        forecastOption === "month" ? "monthly_forecast" : "quarterly_forecast",
      header: () => <CostBreakdownV2WidgetSetupTableForecastedCostHeaderCell />,
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "month",
        )} Cost`,
      },
      cell: (cell) => {
        const data: NullableForecastedCost = cell.getValue();

        return (
          <Typography variant="body2" align="right" pr={1}>
            {data === null || data === undefined
              ? "-"
              : `~${money(data?.cost)}`}
          </Typography>
        );
      },
    },
  ];
};
