import { FC, forwardRef, Fragment, useCallback, useMemo, useRef } from "react";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { Chip, Stack, TextField, Typography } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const FilterValueSelectionTrigger: FC<FilterTriggerComponentProps> = (
  props,
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { selectedValues, onClick, open, label } = props;
  const inputWidth = inputRef?.current?.clientWidth ?? 0;

  const InputComponent: StandardInputProps["inputComponent"] = useMemo(() => {
    if (!props.selectedValues.length) {
      return;
    }

    return forwardRef(() => (
      <TriggerInputComponent {...props} parentWidth={inputWidth} />
    ));
  }, [props, inputWidth]);

  const error =
    props.error && typeof props.error === "string" ? props.error : undefined;

  return (
    <TextField
      fullWidth
      ref={inputRef}
      label={label}
      size="xsmall"
      focused={false}
      disabled={props.disabled}
      error={!!props.error}
      helperText={error}
      onClick={onClick}
      sx={{ p: 0, maxWidth: "100%" }}
      InputLabelProps={{ shrink: !!selectedValues.length || open }}
      InputProps={{
        inputComponent: InputComponent,
        endAdornment: open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
        ),
      }}
    />
  );
};

const TriggerInputComponent: FC<
  FilterTriggerComponentProps & { parentWidth: number }
> = ({
  options,
  selectedValues,
  disabled,
  setSelectedValues,
  onCloseAndSubmit,
  parentWidth,
}) => {
  const allSelected = selectedValues.length === options.length;

  const selected = options.find(
    (option) => option.value === selectedValues.at(0),
  );

  const deleteAllHandler = useCallback(() => {
    setSelectedValues([]);
    onCloseAndSubmit([]);
  }, [setSelectedValues, onCloseAndSubmit]);

  const deleteHandler = useCallback(() => {
    const updatedValues = selectedValues.filter((sv) => sv !== selected?.value);

    setSelectedValues(updatedValues);
    onCloseAndSubmit(updatedValues);
  }, [setSelectedValues, selected, onCloseAndSubmit, selectedValues]);

  return (
    <Stack p={0.5} direction="row" overflow="hidden" flex={1}>
      {allSelected ? (
        <Chip
          size="small"
          variant="outlined"
          label="All Selected"
          disabled={disabled}
          onDelete={deleteAllHandler}
        />
      ) : (
        <Fragment>
          {selected && (
            <Chip
              size="small"
              variant="outlined"
              disabled={disabled}
              onDelete={deleteHandler}
              label={selected.label || selected.value}
              sx={{
                pl: 1,
                maxHeight: 22,
                maxWidth: Math.round(parentWidth / 2),
              }}
            />
          )}

          {selectedValues.length > 1 && (
            <Typography
              ml={1}
              variant="body2"
              whiteSpace="nowrap"
              color="text.secondary"
            >
              + {selectedValues.length - 1} items
            </Typography>
          )}
        </Fragment>
      )}
    </Stack>
  );
};
