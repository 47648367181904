import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { AzSql } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceMetricsHeaderCell } from "../../../../../cells/metrics-cells/components/ResourceMetricsHeaderCell";

export const azSqlColumns: ColumnSetupType<AzSql>[] = [
  {
    accessorKey: "db_name",
    header: "Database Name",
    type: "identifierWithRecommendationLink",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    id: "cpu_max",
    size: 220,
    minSize: 165,
    maxSize: 300,
    meta: { cellStyles: { p: 0 }, headerTitle: "CPU Max" },
    accessorKey: "inline_metrics",
    type: "computeMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    id: "total_connection_sum",
    size: 220,
    minSize: 165,
    maxSize: 300,
    meta: { cellStyles: { p: 0 }, headerTitle: "Connections" },
    accessorKey: "inline_metrics",
    type: "dbMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  {
    accessorKey: "resource_group",
    header: "Resource Group",
    type: "copyWithTooltip",
    size: 180,
  },
  { accessorKey: "pricing_tier", header: "Pricing Tier", type: "pricingTier" },
  {
    accessorKey: "connections_value",
    header: "Connections",
    type: "statistic",
    headerTooltip: "The total number of connections for the last 7 days.",
  },
  {
    accessorKey: "number_of_replicas",
    header: "Replicas",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
