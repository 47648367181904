import { RootState } from "../../../store";
import { resourceExplorerIdSelector } from "../current-resource-explorer/resourceExplorerIdSelector";
import { ResourceExplorer } from "../../../../services/cloudchipr.api";
import { resourceExplorerViewsHierarchyViewsSelector } from "../views-hierarchy/resourceExplorerViewsHierarchyViewsSelector";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";
import UnleashClient from "../../../../services/UnleashClient";

export const resourceExplorerByCurrentIdSelector = (
  state: RootState,
): ResourceExplorer | undefined => {
  const id = resourceExplorerIdSelector(state);

  const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
    "EnableNavigationHierarchyVisibility",
  );

  if (enableNavigationHierarchyVisibility) {
    return resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector(
      state,
    )?.find((view) => view.id === id);
  }

  return resourceExplorerViewsHierarchyViewsSelector(state)?.find(
    (view) => view.id === id,
  );
};
