import {
  FC,
  useCallback,
  useState,
  Fragment,
  memo,
  useEffect,
  useMemo,
} from "react";
import { Button, Stack, IconButton } from "@mui/material";
import { useDidUpdate } from "rooks";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ScheduleResourceSelect } from "./select/ScheduleResourceSelect";
import { useVisibleScheduleResourceTypes } from "../../utils/hooks/useVisibleScheduleResourceTypes";
import {
  ProviderType,
  ResourceType,
  ResourceFiltersWithAction,
} from "../../../../../../services/cloudchipr.api";
import { AutomationType } from "../../../../../../store/automations/utils/types/common";

interface AddResourceComponentProps {
  error: string | boolean;
  addResource(type: ResourceType): void;
  disabled: boolean;
  resources: ResourceFiltersWithAction[];
  provider: ProviderType;
  accountIds: string[];
  scheduleType: AutomationType;
}

export const AddResourceComponent: FC<AddResourceComponentProps> = memo(
  ({
    provider,
    error,
    accountIds,
    scheduleType,
    addResource,
    resources,
    disabled,
  }) => {
    const hasFilters = !!resources.length;
    const [showEmptyTemplate, setShowEmptyTemplate] = useState(!hasFilters);
    const [type, setType] = useState("");

    const visibleResourceTypes = useVisibleScheduleResourceTypes(
      provider,
      scheduleType,
      accountIds,
    );

    const selectValue = resources.some(({ filter }) => filter.type === type)
      ? type
      : "";

    const setTypeHandler = useCallback(
      (type: ResourceType) => {
        addResource(type);
        setType(type);
        setShowEmptyTemplate(false);
      },
      [addResource],
    );

    const showEmptyTemplateHandler = useCallback(() => {
      setShowEmptyTemplate(true);
    }, []);

    const hideEmptyTemplateHandler = useCallback(() => {
      setShowEmptyTemplate(false);
    }, []);

    const addServiceButtonDisabled = useMemo(() => {
      return (
        showEmptyTemplate || visibleResourceTypes?.length === resources.length
      );
    }, [showEmptyTemplate, visibleResourceTypes, resources]);

    useDidUpdate(() => setType(""));

    useEffect(() => {
      if (!hasFilters) {
        setShowEmptyTemplate(true);
      }
    }, [hasFilters]);

    return (
      <Fragment>
        {showEmptyTemplate && (
          <Stack direction="row" spacing={2} alignItems="center">
            <ScheduleResourceSelect
              scheduleType={scheduleType}
              disabled={disabled}
              resources={resources}
              value={selectValue as ResourceType}
              onChange={setTypeHandler}
              accountIds={accountIds}
              provider={provider}
              error={error}
            />

            {hasFilters && (
              <IconButton onClick={hideEmptyTemplateHandler}>
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Stack>
        )}

        <Button
          sx={{ mt: 4, width: 240 }}
          variant="outlined"
          disabled={addServiceButtonDisabled}
          color="primary"
          endIcon={<AddCircleOutlineIcon />}
          onClick={showEmptyTemplateHandler}
        >
          Add Service
        </Button>
      </Fragment>
    );
  },
);
