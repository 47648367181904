import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import {
  createAutomationFixedCacheKey,
  updateAutomationFixedCacheKey,
} from "../../utils/constants/fixedCacheKeys";
import { offHoursDataLoadingSelector } from "../off-hours/data/offHoursDataLoadingSelector";
import { workflowsDataLoadingSelector } from "../workflow/list-data/workflowsDataLoadingSelector";

const workflowCreateResponse =
  cloudChiprApi.endpoints.postV2UsersMeSchedules.select(
    createAutomationFixedCacheKey,
  );
const workflowUpdateResponse =
  cloudChiprApi.endpoints.patchV2UsersMeSchedulesByScheduleId.select(
    updateAutomationFixedCacheKey,
  );

const offHoursCreateResponse =
  cloudChiprApi.endpoints.postV2UsersMeOffHoursSchedules.select(
    createAutomationFixedCacheKey,
  );
const offHoursUpdateResponse =
  cloudChiprApi.endpoints.patchV2UsersMeOffHoursSchedulesByScheduleId.select(
    updateAutomationFixedCacheKey,
  );

export const automationSavingLoadingSelector = (state: RootState) => {
  return (
    workflowCreateResponse(state)?.isLoading ||
    workflowUpdateResponse(state)?.isLoading ||
    offHoursCreateResponse(state)?.isLoading ||
    offHoursUpdateResponse(state)?.isLoading ||
    offHoursDataLoadingSelector(state) ||
    workflowsDataLoadingSelector(state)
  );
};
