import { FC } from "react";
import { CostAnomalyPreviewTableHeaderCell } from "./CostAnomalyPreviewTableHeaderCell";
import { getResourcesExplorerGroupingLabel } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { alertsCostAnomalyDataViewIdSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataViewIdSelector";
import { resourceExplorerCloudProvidersByViewIdSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerCloudProvidersByViewIdSelector";
import { alertsCostAnomalyDataGroupingSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataGroupingSelector";
import { costAnomalyPreviewDataGridDataLengthSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyPreviewDataGridDataLengthSelector";

export const CostAnomalyPreviewFieldHeaderCell: FC = () => {
  const count = useAppSelector(costAnomalyPreviewDataGridDataLengthSelector);
  const groupBy = useAppSelector(alertsCostAnomalyDataGroupingSelector);
  const selectedViewId = useAppSelector(alertsCostAnomalyDataViewIdSelector);
  const providers = useAppSelector((state) =>
    resourceExplorerCloudProvidersByViewIdSelector(state, selectedViewId),
  );

  return (
    <CostAnomalyPreviewTableHeaderCell
      title={getResourcesExplorerGroupingLabel(providers ?? ["aws"], groupBy)}
      description={count?.toString() ?? "0"}
    />
  );
};
