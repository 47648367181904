import { FC, memo, useEffect } from "react";
import { Alert, AlertTitle, Box, IconButton, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ScheduleResourceSelect } from "./select/ScheduleResourceSelect";
import { ScheduleFoundResources } from "./ScheduleFoundResources";
import {
  ResourceType,
  ProviderType,
  ResourceFilters,
  ResourceFiltersWithAction,
} from "../../../../../../services/cloudchipr.api";
import { FiltersSourceButton } from "../../../../common/filters/filters-selection/FiltersSourceButton";
import { useAppSelector } from "../../../../../../store/hooks";
import { defaultFilterTemplateFiltersByAccountIdSelector } from "../../../../../../store/filters/selectors/filter-set/default/defaultFilterTemplateFiltersByAccountIdSelector";
import { getScheduleSelectedResourcesThunk } from "../../../../../../store/schedules/thunks/getScheduleSelectedResourcesThunk";
import { AutomationType } from "../../../../../../store/automations/utils/types/common";

interface ScheduledJobsResourceProps {
  accountIds: string[];
  error: string | boolean;
  resourceFilters: ResourceFiltersWithAction;
  updateResource(type: ResourceType): void;
  removeResource(): void;
  onFiltersSubmit(filters: ResourceFilters): void;
  regions: string[];
  provider: ProviderType;
  resources: ResourceFiltersWithAction[];
  scheduleType: AutomationType;
  scheduleAction: string | null;
  scheduleId: string;
  gracePeriodEnabled: boolean;
}

export const SchedulesResource: FC<ScheduledJobsResourceProps> = memo(
  ({
    accountIds,
    error,
    resourceFilters,
    updateResource,
    removeResource,
    onFiltersSubmit,
    regions,
    provider,
    resources,
    scheduleType,
    scheduleAction,
    scheduleId,
    gracePeriodEnabled,
  }) => {
    const resourceType = resourceFilters.filter.type;

    const allDefaultFilters = useAppSelector((state) =>
      defaultFilterTemplateFiltersByAccountIdSelector(
        state,
        accountIds[0],
        provider,
      ),
    );

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        getScheduleSelectedResourcesThunk({
          scheduleId,
          accountIds,
          resourceFilters: resourceFilters.filter,
          regions,
        }),
      );
    }, [accountIds, regions, scheduleId, resourceFilters, dispatch]);

    return (
      <Box alignSelf="stretch" bgcolor="white" position="relative">
        <Stack direction="row" spacing={2}>
          <ScheduleResourceSelect
            error={error}
            provider={provider}
            resources={resources}
            accountIds={accountIds}
            value={resourceType || ""}
            scheduleType={scheduleType}
            onChange={updateResource}
          />

          {resources.length > 1 && (
            <IconButton onClick={removeResource}>
              <DeleteOutlineIcon />
            </IconButton>
          )}
        </Stack>

        <Stack
          mt={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {allDefaultFilters?.[resourceType] && (
            <FiltersSourceButton
              hideRevert
              accountIds={accountIds}
              onSubmit={onFiltersSubmit}
              resourceType={resourceType}
              filters={resourceFilters.filter}
              defaultFilters={allDefaultFilters[resourceType]}
            />
          )}

          <ScheduleFoundResources
            scheduleId={scheduleId}
            gracePeriodEnabled={gracePeriodEnabled}
            scheduleAction={scheduleAction}
            resourceFilters={resourceFilters}
            regions={regions}
            accountIds={accountIds}
            resourceType={resourceType}
          />
        </Stack>

        {!resourceFilters?.filter?.filter_items?.length && (
          <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
            <AlertTitle>Filter Required</AlertTitle>
            To proceed, please add at least one filter for the selected service.
          </Alert>
        )}
      </Box>
    );
  },
);
