import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { v4 as uuid } from "uuid";
import { useWillUnmount } from "rooks";
import { coverageDrawerRecommendationsGridColumns } from "./CoverageDrawerRecommendationsGridColumns";
import { useGetUsersMeOrganisationsCurrentSavingsPlansRecommendationsQuery } from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import {
  StylesProps,
  ToolbarConfig,
} from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { coverageRecommendationsPayloadSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/recommendations/coverageRecommendationsPayloadSelector";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { CommitmentsRecommendationsFilters } from "../../../content/recommendations/filters/CommitmentsRecommendationsFilters";
import { setRecommendationsInitialFiltersThunk } from "../../../../../../../../store/commitments/thunks/recommendations/setRecommendationsInitialFiltersThunk";
import { commitmentsRecommendationsFiltersSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsFiltersSelector";
import { filterRecommendationsByFilters } from "../../../../../../../../store/commitments/utils/helpers/filterRecommendationsByFilters";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";

export const CoverageDrawerRecommendationsSection: FC = () => {
  const dispatch = useAppDispatch();
  const payload = useAppSelector(coverageRecommendationsPayloadSelector);
  const filters = useAppSelector(commitmentsRecommendationsFiltersSelector);

  const { data } =
    useGetUsersMeOrganisationsCurrentSavingsPlansRecommendationsQuery(payload, {
      selectFromResult: ({ data }) => {
        return {
          data: filterRecommendationsByFilters(
            filters,
            data?.map((recommendation) => ({
              ...recommendation,
              id: uuid(),
            })),
          ),
        };
      },
    });

  useWillUnmount(() => {
    dispatch(setRecommendationsInitialFiltersThunk());
  });

  return (
    <CommitmentsDrawerContent title="Recommendations" icon="recommendation">
      {!data?.length ? (
        <Stack alignItems="center" p={3}>
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          >
            No Recommendations
          </Typography>
        </Stack>
      ) : (
        <DataGrid
          enableStickyColumns
          columnSorting
          styles={styles}
          data={data ?? []}
          toolbar={toolbar}
          columns={coverageDrawerRecommendationsGridColumns}
        />
      )}
    </CommitmentsDrawerContent>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: ({ setGlobalFilter, globalFilter }) => {
    return (
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <CommitmentsRecommendationsFilters />

        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          size="xsmall"
        />
      </Stack>
    );
  },
};

const styles: StylesProps["styles"] = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& th": { py: 2, bgcolor: "white" },
    "& th:first-of-type": { pl: 2 },
    "& td:first-of-type": { pl: 2 },
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], bgcolor: "white" },
  },

  tableContainer: {
    bgcolor: "white",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "grey.300",
  },
};
