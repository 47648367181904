import * as yup from "yup";
import { GracePeriodNotificationsType } from "../../../../automations/workflows/single-workflow/form/actions-section/grace-period/GracePeriodNotifications";
import { integrationNotificationValidationSchema } from "../../../../common/notification-selection/utils/validation/validation";
import { emailsValidationSchema } from "../../../../../common/integration-dialogs/components/email/utils/validation";

export const getGracePeriodNotificationsValidationSchema = () => {
  return yup.lazy((data: GracePeriodNotificationsType[0]) => {
    if (data.integration_id) {
      return integrationNotificationValidationSchema;
    }

    return emailsValidationSchema(data);
  });
};
