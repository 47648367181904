import { FC, Fragment, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";
import { resourceExplorerViewsHierarchyDefaultViewSelector } from "../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyDefaultViewSelector";
import { resourceExplorerViewsHierarchyViewsByFolderIdSelector } from "../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyViewsByFolderIdSelector";
import { deleteResourceExplorerFolderThunk } from "../../../../../../store/resource-explorer/thunks/common/deleteResourceExplorerFolderThunk";
import { deleteResourceExplorerHierarchyFolderLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/deleteResourceExplorerHierarchyFolderLoadingSelector";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";

interface ResourceExplorerViewWithFoldersDeleteDialogProps {
  id: string;
  open: boolean;
  name: string;
  closeMenu(): void;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerV2ViewFoldersDeleteDialog: FC<
  ResourceExplorerViewWithFoldersDeleteDialogProps
> = ({ closeMenu, id, name, open, visibility }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { viewId } = useParams<{ viewId: string }>();

  const defaultResourceExplorerId = useAppSelector(
    resourceExplorerViewsHierarchyDefaultViewSelector,
  );
  const loading = useAppSelector(
    deleteResourceExplorerHierarchyFolderLoadingSelector,
  );

  const folderViews = useAppSelector((state) =>
    resourceExplorerViewsHierarchyViewsByFolderIdSelector(
      state,
      id,
      visibility,
    ),
  );

  const removeResourceExplorerViewHandler = useCallback(async () => {
    await dispatch(deleteResourceExplorerFolderThunk(id));
    if (viewId === id) {
      navigate({
        pathname: `/resource-explorer/${defaultResourceExplorerId}`,
      });
    }
  }, [dispatch, navigate, viewId, id, defaultResourceExplorerId]);

  const confirmRemoveViewHandler = useCallback(async () => {
    await removeResourceExplorerViewHandler();
    closeMenu();
  }, [closeMenu, removeResourceExplorerViewHandler]);

  return (
    <DeleteConfirmationDialog
      open={open}
      title="Delete Folder?"
      onClose={closeMenu}
      onDelete={confirmRemoveViewHandler}
      loading={loading}
      content={
        <Stack {...navigationHierarchyItemNoDndKey}>
          <Typography variant="body1" color="text.primary">
            Please confirm to delete the
            <Typography component="span" fontWeight="bold">
              {" "}
              {name}{" "}
            </Typography>
            folder.{" "}
            {!!folderViews?.length &&
              "Deleting it will also delete all views within it, including:"}
          </Typography>

          {!!folderViews?.length && (
            <Fragment>
              <Stack
                my={1.5}
                px={1}
                py={1}
                overflow="auto"
                spacing={1}
                maxHeight={400}
                borderRadius={0.5}
                bgcolor="grey.100"
              >
                {folderViews?.map((view) => {
                  return (
                    <Stack direction="row" spacing={1} key={view.id}>
                      <InsertChartOutlinedOutlinedIcon fontSize="small" />
                      <Typography variant="subtitle2">{view.name}</Typography>
                    </Stack>
                  );
                })}
              </Stack>

              <Typography
                color="text.secondary"
                variant="body2"
                fontStyle="italic"
              >
                If you want to keep any view in this folder, please move them to
                another folder before deleting.
              </Typography>
            </Fragment>
          )}
        </Stack>
      }
    />
  );
};
