import { ChangeEvent, FC } from "react";
import { Stack, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "rooks";

interface FilterSearchBoxProps {
  placeholder?: string;
  onChange(value: ChangeEvent): void;
}

export const FilterSearchBox: FC<FilterSearchBoxProps> = ({
  onChange,
  placeholder,
}) => {
  const handleSearchDebounced = useDebounce(onChange, 200);

  return (
    <Stack p={2}>
      <TextField
        autoFocus
        size="xsmall"
        variant="outlined"
        placeholder={placeholder ?? "Search..."}
        onChange={handleSearchDebounced}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
