import { FC, useMemo } from "react";
import { usePreviousDifferent } from "rooks";
import { savingsPlanCoverageTableColumns } from "./columns/savingsPlanCoverageTableColumns";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { savingsPlansCoverageDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/savingsPlansCoverageDataSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { commitmentsCoverageGroupingSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsCoverageGroupingSelector";
import { EmptyDataGridFooter } from "../../../../common/components/EmptyDataGridFooter";
import { savingsPlansForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForTableLoadingSelector";
import { getCommitmentsTableStyles } from "../../../../common/utils/helpers/grid/getCommitmentsTableStyles";
import { coverageGroupingNames } from "../../../../common/utils/constants/labels";

interface CoverageDataGridProps {
  toolbar: ToolbarConfig;
}

export const SavingsPlanCoverageDataGrid: FC<CoverageDataGridProps> = ({
  toolbar,
}) => {
  const data = useAppSelector(savingsPlansCoverageDataSelector);
  const loading = useAppSelector(savingsPlansForTableLoadingSelector);
  const grouping = useAppSelector(commitmentsCoverageGroupingSelector);

  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData;
  const empty = !loading && !gridData?.length;

  const columns = savingsPlanCoverageTableColumns(grouping);

  const pagination = useMemo(() => {
    if (!empty) {
      return undefined;
    }

    return {
      renderPagination: () => {
        return <EmptyDataGridFooter name={coverageGroupingNames[grouping]} />;
      },
    };
  }, [empty, grouping]);

  if (!gridData) {
    return null;
  }

  return (
    <DataGrid
      toolbar={toolbar}
      styles={getCommitmentsTableStyles(empty)}
      data={gridData}
      columnSorting={coverageSortingConfig}
      columns={columns}
      pagination={pagination}
    />
  );
};

const coverageSortingConfig = {
  initialSort: [{ id: "covered_usage", desc: true }],
};
