import { FC, useCallback } from "react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { UniqueIdentifier } from "@dnd-kit/core";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useParams } from "react-router-dom";
import { DashboardV2FolderActionButton } from "../folder-action-menu/DashboardV2FolderActionButton";
import { DashboardV2ItemActionButton } from "../item-action-menu/DashboardV2ItemActionButton";
import { SortableTree } from "../../../sortable-tree/SortableTree";
import { NavigationVisibilitySection } from "../../../common/visibility-types/NavigationVisibilitySection";
import {
  SortableTreeFolderActionArgs,
  SortableTreeItemActionArgs,
  SortableTreeOnchangeArgs,
  TreeItems,
} from "../../../sortable-tree/utils/types";
import { setNavigationDashboard } from "../../../../../../store/navigation/navigationSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";
import { updateDashboardVisibilityHierarchyThunk } from "../../../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/updateDashboardVisibilityHierarchyThunk";
import { navigationDashboardVisibilitySelectedSelector } from "../../../../../../store/navigation/selectors/dashboard/navigationDashboardVisibilitySelectedSelector";
import { useAppAbility } from "../../../../../../services/permissions";
import { DashboardV2NavigationAddDashboardNavItem } from "../DashboardV2NavigationAddDashboardNavItem";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

interface DashboardV2VisibilityItemProps {
  visibility: NavigationItemsVisibilityType;
  data?: TreeItems;
}

export const DashboardV2VisibilityItem: FC<DashboardV2VisibilityItemProps> = ({
  visibility,
  data,
}) => {
  const { dashboardId: activeDashboardId } = useParams<{
    dashboardId: string;
  }>();

  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();

  const cannotCreateDashboard = cannot("create", "dashboard");

  const selected = useAppSelector((state) =>
    navigationDashboardVisibilitySelectedSelector(
      state,
      visibility,
      activeDashboardId,
    ),
  );

  const renderFolderAction = useCallback(
    ({ id, name }: SortableTreeFolderActionArgs) => {
      return (
        <DashboardV2FolderActionButton
          id={id}
          name={name}
          visibility={visibility}
        />
      );
    },
    [visibility],
  );

  const renderItemAction = useCallback(
    ({ id, name, parentId }: SortableTreeItemActionArgs) => {
      return (
        <DashboardV2ItemActionButton
          id={id}
          name={name}
          folderId={(parentId as string) ?? undefined}
          visibility={visibility}
        />
      );
    },
    [visibility],
  );

  const hierarchyChangeHandler = useCallback(
    ({ index, id, parentId, items }: SortableTreeOnchangeArgs) => {
      if (!items) {
        return;
      }
      dispatch(setNavigationDashboard({ data: items, type: visibility }));
      dispatch(
        updateDashboardVisibilityHierarchyThunk({
          id: id as string,
          index,
          folderId: (parentId as string) ?? undefined,
          visibility,
        }),
      );
    },
    [dispatch, visibility],
  );

  const resetHandler = useCallback(() => {
    dispatch(
      setNavigationDashboard({
        data: null,
        type: visibility,
      }),
    );
  }, [dispatch, visibility]);

  const navigateToHandler = useCallback((id: UniqueIdentifier) => {
    return `/dashboards/${id}`;
  }, []);

  if (!data) {
    return null;
  }

  return (
    <NavigationVisibilitySection
      expanded={!activeDashboardId}
      selected={selected}
      title={
        visibility === "visible_only_to_me"
          ? "Visible only to me"
          : "Visible to everyone"
      }
    >
      {data?.length ? (
        <SortableTree
          items={data}
          renderItemAction={renderItemAction}
          renderFolderAction={renderFolderAction}
          onChange={hierarchyChangeHandler}
          getNavigateTo={navigateToHandler}
          onReset={resetHandler}
          icon={GridViewOutlinedIcon}
          rightIcon={
            visibility === "visible_only_to_me" ? LockOutlinedIcon : undefined
          }
          emptyText="No dashboards inside"
          disabled={cannotCreateDashboard}
        />
      ) : (
        <TypographyWithTooltip
          variant="caption"
          color="text.secondary"
          title="No folders and dashboards inside"
          py={0.5}
          pl={4}
        />
      )}
      <DashboardV2NavigationAddDashboardNavItem visibility={visibility} />
    </NavigationVisibilitySection>
  );
};
