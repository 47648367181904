import {
  DynamicProviderFilterGroup,
  DynamicProviderFilter,
} from "../types/common";

export const isDynamicProviderFilters = (
  filters: DynamicProviderFilter,
): filters is DynamicProviderFilterGroup => {
  return "filters" in filters;
};
