import { FC, memo, useCallback } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { AutomationDateTimePicker } from "../../../../automations/common/components/form/schedule-content/dates/AutomationDateTimePicker";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { getStartsOnDate } from "../../utils/constants/common";

interface StartDateProps {
  timeZone: string;
  startDate: string | null;
  disabled?: boolean;
  error?: string | boolean;
  spacing: "long" | "short";
  setFieldValue(key: string, date: string | null): void;
}

export const StartDate: FC<StartDateProps> = memo(
  ({ startDate, setFieldValue, error, disabled, timeZone, spacing }) => {
    const dateAcceptHandler = useCallback(
      (value: string | null) => {
        if (value) {
          setFieldValue("startDate", formatDate(value));
        }
      },
      [setFieldValue],
    );

    const dateToggleHandler = useCallback(
      (e: any) => {
        if (e.target.value === "now") {
          setFieldValue("startDate", null);
        } else {
          setFieldValue("startDate", getStartsOnDate(timeZone));
        }
      },
      [setFieldValue, timeZone],
    );

    return (
      <Stack direction="row" alignItems="flex-start">
        <Typography variant="subtitle2" width={spacing === "short" ? 58 : 110}>
          Starts:
        </Typography>

        <Stack direction="row" alignItems="flex-end">
          <RadioGroup
            value={startDate ? "on" : "now"}
            onChange={dateToggleHandler}
          >
            <FormControlLabel
              disabled={disabled}
              value="now"
              control={<Radio sx={{ py: 0 }} />}
              label="Now"
            />
            <FormControlLabel
              disabled={disabled}
              sx={{ mt: 3 }}
              value="on"
              control={<Radio sx={{ py: 0 }} />}
              label="Starts On"
            />
          </RadioGroup>

          {startDate && (
            <Box mb={-1} ml={2}>
              <AutomationDateTimePicker
                error={error}
                date={startDate}
                disabled={disabled}
                onAccept={dateAcceptHandler}
                minDate={getStartsOnDate(timeZone)}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    );
  },
);
