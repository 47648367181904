import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { RootState } from "../../../store";
import { setAlertsData } from "../../alertsSlice";
import { utilizationAlertByIdSelector } from "../../selectors/utilization-alerts/utilizationAlertByIdSelector";
import { formatNotificationsFromSlackToHtml } from "../../../../components/utils/helpers/formatNotificationsFromSlackToHtml";

export const setUtilizationAlertEditDataThunk = createAsyncThunk(
  "alerts/setUtilizationAlertEditData",
  (id: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const alert = utilizationAlertByIdSelector(state, id);

    if (!alert) {
      return;
    }

    const thresholds = alert.thresholds.map((threshold) => ({
      ...threshold,
      amount: threshold?.amount?.toString(),
      id: uuid(),
    }));

    dispatch(
      setAlertsData({
        id: alert.id,
        name: alert.name,
        providerOrganisationId: alert.provider_organisation_id,
        thresholds,
        emails: alert.emails,
        notifications: formatNotificationsFromSlackToHtml(alert.notifications),
        basedOn: alert.based_on,
      }),
    );
  },
);
