import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerViewsHierarchyThunk } from "../resource-explorer-hierarchy/getResourceExplorerViewsHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import UnleashClient from "../../../../services/UnleashClient";
import { getResourceExplorerVisibilityHierarchyThunk } from "../resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";

export const updateResourceExplorerFolderNameThunkFixedCacheKey =
  "updateResourceExplorerFolderName-fixedCacheKey";

interface UpdateResourceExplorerFolderNameThunkArg {
  id: string;
  name: string;
}
export const updateResourceExplorerFolderNameThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorerFolderName",
  async (
    { name, id }: UpdateResourceExplorerFolderNameThunkArg,
    { dispatch },
  ) => {
    const {
      patchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId,
      patchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId,
    } = cloudChiprApi.endpoints;
    const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
      "EnableNavigationHierarchyVisibility",
    );
    try {
      if (enableNavigationHierarchyVisibility) {
        await dispatch(
          patchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId.initiate(
            {
              resourceExplorerFolderId: id,
              body: { name },
            },
            {
              fixedCacheKey: updateResourceExplorerFolderNameThunkFixedCacheKey,
            },
          ),
        ).unwrap();
        dispatch(getResourceExplorerVisibilityHierarchyThunk());
      } else {
        await dispatch(
          patchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId.initiate(
            {
              resourceExplorerFolderId: id,
              body: { name },
            },
            {
              fixedCacheKey: updateResourceExplorerFolderNameThunkFixedCacheKey,
            },
          ),
        ).unwrap();
        dispatch(getResourceExplorerViewsHierarchyThunk());
      }
      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Folder name successfully updated.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
