import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { costAnomalyAlertsSelector } from "./costAnomalyAlertsSelector";
import { resourceExplorerViewsDataSelector } from "../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";
import { integrationsDataAsObjectSelector } from "../../../integrations/selectors/integrationsDataAsObjectSelector";
import { CostAnomalyAlertsDataGridDataType } from "../../utils/types/types";

export const costAnomalyAlertsDataGridDataSelector = createDraftSafeSelector(
  [
    costAnomalyAlertsSelector,
    resourceExplorerViewsDataSelector,
    integrationsDataAsObjectSelector,
  ],
  (data, views, integrations): CostAnomalyAlertsDataGridDataType[] | null => {
    if (!data) {
      return null;
    }

    return data.map((costAnomalyAlert) => {
      const viewName = views.find(
        (item) => item.id === costAnomalyAlert.view_id,
      )?.name;

      const usedIntegrations = (
        costAnomalyAlert?.notifications?.map(
          (notification) => integrations[notification.integration_id],
        ) ?? []
      ).filter((integration) => !!integration);

      return {
        ...costAnomalyAlert,
        viewName,
        createdByEmail: costAnomalyAlert.creator_details.email,
        integrations: usedIntegrations,
      };
    });
  },
);
