import { RootState } from "../../../store";
import { alertsDataSelector } from "../common/data/alertsDataSelector";
import { formatNotificationsFromHtmlToSlack } from "../../../../components/utils/helpers/formatNotificationsFromHtmlToSlack";

export const constAnomalyAlertPayloadDataSelector = (state: RootState) => {
  const alertData = alertsDataSelector(state);

  const thresholds = alertData.thresholds.map((threshold) => ({
    amount: Number(threshold.amount),
    unit: threshold.unit,
  }));

  return {
    name: alertData.name,
    view_id: alertData.viewId ?? "",
    group_by: alertData.grouping,
    notifications:
      formatNotificationsFromHtmlToSlack(alertData.notifications) ?? null,
    thresholds,
    emails: alertData.emails ?? undefined,
    time_interval: {
      time_interval: Number(alertData.timeInterval),
      time_interval_unit: alertData.timeIntervalUnit,
    },
  };
};
