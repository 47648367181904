import { FC, useCallback, useMemo } from "react";
import moment from "moment/moment";
import { DateRange } from "mui-daterange-picker";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { DateRangePicker } from "../../../../../../../../../common/date-range-picker/DateRangePicker";
import { DateLabelNullable } from "../../../../../../../../../../services/cloudchipr.api";
import { DateRangePickerWithMore } from "../../../../../../../../../common/date-range-picker-with-more/DateRangePickerWithMore";
import { DatePeriodInputProps } from "../../../../../../../../../common/date-range-picker/utils/types";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { dateRangesListInDashboardWidgetSelector } from "../../../../../../../../../../store/common/selectors/date-ranges/dateRangesListInDashboardWidgetSelector";
import { generateDateFromDateLabelV1 } from "../../../../../../../../../common/date-range-picker/utils/helpers/generateDateFromDateLabelV1";

export type DateData = {
  from: string | null;
  to: string | null;
  label: DateLabelNullable;
};

interface WidgetSetupDateRangeSelectProps {
  dates: DateData | null;
  onChange(dates: DateData): void;
  disabled?: boolean;
}

export const WidgetSetupDateRangeSelect: FC<
  WidgetSetupDateRangeSelectProps
> = ({ dates, onChange, disabled }) => {
  const dateRanges = useAppSelector(dateRangesListInDashboardWidgetSelector);

  const dateSubmitHandler = useCallback(
    (range: DateRange) => {
      const dateTo = formatDate(moment(range?.endDate), {
        type: "yearMonthDay",
      });
      const dateFrom = formatDate(moment(range?.startDate), {
        type: "yearMonthDay",
      });

      if (!dateTo || !dateFrom) {
        return;
      }

      onChange({ from: dateFrom, to: dateTo, label: null });
    },
    [onChange],
  );

  const { dateFrom, dateTo } = useMemo(() => {
    if (dates?.label) {
      const range = generateDateFromDateLabelV1(dates?.label);
      if (range) {
        const dateTo = formatDate(moment(range.endDate));
        const dateFrom = formatDate(moment(range.startDate));

        return { dateFrom, dateTo };
      }
    }

    return {
      dateFrom: dates?.from ?? "",
      dateTo: dates?.to ?? "",
    };
  }, [dates]);

  const InputComponent = useCallback(
    (props: DatePeriodInputProps) => {
      return (
        <DateRangePickerWithMore
          {...props}
          options={dateRanges}
          displayThreshold={3}
          onChange={onChange}
          dateLabel={dates?.label}
          disabled={disabled}
        />
      );
    },
    [onChange, dates?.label, disabled, dateRanges],
  );

  return (
    <DateRangePicker
      viewType="popover"
      dateTo={dateTo}
      dateFrom={dateFrom}
      onSubmit={dateSubmitHandler}
      maxDate={moment().format()}
      InputComponent={InputComponent}
      disabled={disabled}
    />
  );
};
