import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { createDashboardFixedCacheKey } from "../../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

const selector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentDashboards.select;

const selectorV2 =
  cloudChiprApi.endpoints.postV2UsersMeOrganisationsCurrentResourceExplorerViews
    .select;

export const createDashboardLoadingSelector = (state: RootState): boolean => {
  return (
    !!selector(createDashboardFixedCacheKey)(state)?.isLoading ||
    !!selectorV2(createDashboardFixedCacheKey)(state)?.isLoading
  );
};
