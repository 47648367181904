import { costAnomalyAlertByIdSelector } from "./costAnomalyAlertByIdSelector";
import { RootState } from "../../../store";
import { isDeepEqual } from "../../../../utils/is-deep-equal";
import { alertsDataSelector } from "../common/data/alertsDataSelector";

export const isCostAnomalyAlertChangedSelector = (state: RootState) => {
  const alertData = alertsDataSelector(state);
  const alert = costAnomalyAlertByIdSelector(state, alertData.id ?? "");
  const alertDataThreshold = alertData.thresholds
    .map(({ unit, amount }) => ({
      unit,
      amount: Number(amount),
    }))
    .toSorted((first, second) => (first.amount > second.amount ? 1 : -1));
  const alertThreshold = alert?.thresholds
    ?.map(({ unit, amount }) => ({
      unit,
      amount: Number(amount),
    }))
    .toSorted((first, second) => (first.amount > second.amount ? 1 : -1));

  if (!alert) {
    return false;
  }

  return (
    alertData.name.trim() !== alert.name.trim() ||
    alertData.viewId !== alert.view_id ||
    alertData.grouping !== alert.grouping ||
    Number(alertData.timeInterval) !== alert.time_interval.time_interval ||
    alertData.timeIntervalUnit !== alert.time_interval.time_interval_unit ||
    !isDeepEqual(alertDataThreshold, alertThreshold ?? []) ||
    !isDeepEqual(alertData?.emails ?? {}, alert.emails ?? {}) ||
    !isDeepEqual(alertData.notifications ?? {}, alert.notifications ?? {})
  );
};
