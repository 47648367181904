import { FC } from "react";
import { Skeleton, Stack, Typography } from "@mui/material";

interface UtilizationAndCoverageChartHeaderCardProps {
  title: string;
  loading?: boolean;
  value?: string | null;
  percent?: number | null;
}

export const CommitmentsChartMetadataCard: FC<
  UtilizationAndCoverageChartHeaderCardProps
> = ({ percent, title, value, loading }) => {
  return (
    <Stack>
      <Typography variant="caption" color="text.secondary">
        {title}
      </Typography>

      {loading ? (
        <Skeleton variant="text" height={32} width={150} />
      ) : (
        <Typography variant="h6" fontWeight="medium">
          {value}

          {!!percent && (
            <Typography
              component="span"
              variant="inherit"
              color="text.secondary"
            >
              {" "}
              ({percent}%)
            </Typography>
          )}
        </Typography>
      )}
    </Stack>
  );
};
