import { Row } from "@tanstack/react-table";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { getCronFrequency } from "../../../../../../../store/automations/utils/helpers/cron/getCronFrequency";

export const scheduledJobsFrequencyFilter = (
  row: Row<Schedule>,
  _: string,
  keyword: string,
) => {
  const cron = row.original.cron;
  const frequency = getCronFrequency(cron).toLowerCase();

  return frequency.includes(keyword.toLowerCase().trim());
};
