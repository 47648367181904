import moment from "moment";
import { DatePeriod, PreDefinedRange } from "./types";
import { MomentDateTimeUnitsType } from "../../../../../utils/helpers/date-time/datetime-format";
import {
  DateLabel,
  DateLabelNullable,
} from "../../../../../services/cloudchipr.api";
import { getQuarterRange } from "../helpers/getQuarterRange";

export const labelByDateLabel: Map<
  DateLabelNullable,
  { long: string; short: string; withoutPrefix: string }
> = new Map([
  [
    "past_seven_days",
    { long: "Past 7 days", short: "Past 7D", withoutPrefix: "7 days" },
  ],
  [
    "past_fourteen_days",
    { long: "Past 14 days", short: "Past 14D", withoutPrefix: "14 days" },
  ],
  [
    "past_thirty_days",
    { long: "Past 30 days", short: "Past 30D", withoutPrefix: "30 days" },
  ],
  [
    "past_forty_five_days",
    { long: "Past 45 days", short: "Past 45D", withoutPrefix: "45 days" },
  ],
  [
    "past_ninety_days",
    { long: "Past 90 days", short: "Past 90D", withoutPrefix: "90 days" },
  ],
  [
    "past_one_hundred_eighty_days",
    { long: "Past 180 days", short: "Past 180D", withoutPrefix: "180 days" },
  ],
  [
    "last_quarter",
    { long: "Last Quarter", short: "Last Quarter", withoutPrefix: "Quarter" },
  ],
  [
    "year_to_date",
    { long: "Year to Date", short: "YTD", withoutPrefix: "Year to Date" },
  ],
  [
    "quarter_to_date",
    { long: "Quarter to Date", short: "QTD", withoutPrefix: "Quarter to Date" },
  ],
  [
    "month_to_date",
    { long: "Month to Date", short: "MTD", withoutPrefix: "Month to Date" },
  ],
  [
    "last_one_month",
    { long: "Last 1 month", short: "Last 1M", withoutPrefix: "1 month" },
  ],
  [
    "last_three_months",
    { long: "Last 3 months", short: "Last 3M", withoutPrefix: "3 month" },
  ],
  [
    "last_six_months",
    { long: "Last 6 months", short: "Last 6M", withoutPrefix: "6 month" },
  ],
  [
    "last_twelve_months",
    { long: "Last 12 months", short: "Last 12M", withoutPrefix: "12 month" },
  ],
]);

const MONTH_TO_DATE_PERIOD: DatePeriod = {
  to: moment().toISOString(),
  from: moment().startOf("month").toISOString(),
};

const YEAR_TO_DATE_PERIOD: DatePeriod = {
  to: moment().toISOString(),
  from: moment().startOf("year").toISOString(),
};

export const preDefinedThisMonth: PreDefinedRange = {
  dateLabel: "month_to_date",
  endDate: new Date(MONTH_TO_DATE_PERIOD.to),
  startDate: new Date(MONTH_TO_DATE_PERIOD.from),
  shortLabel: labelByDateLabel.get("month_to_date")?.short ?? "MTD",
  label: labelByDateLabel.get("month_to_date")?.long ?? "Month to Date",
};

const preDefinedThisYear: PreDefinedRange = {
  dateLabel: "year_to_date",
  startDate: new Date(YEAR_TO_DATE_PERIOD.from),
  endDate: new Date(YEAR_TO_DATE_PERIOD.to),
  label: labelByDateLabel.get("year_to_date")?.long ?? "Year to Date",
  shortLabel: labelByDateLabel.get("year_to_date")?.short ?? "YTD",
};

const getRange = (
  amount: number,
  unit: MomentDateTimeUnitsType,
  dateLabel: DateLabel,
  excludeMTD?: boolean,
): PreDefinedRange => {
  let from = moment().subtract(amount, unit);
  let to = moment();

  if (unit === "month") {
    if (excludeMTD) {
      to = moment().subtract(1, "month").endOf("month");
    }

    from = from.startOf("month");
  }

  if (unit === "week") {
    from = from.startOf("week").add(1, "day");

    if (excludeMTD) {
      to = to.subtract(amount, "week").endOf("week").add(1, "day");
    } else {
      to = to.add(1, "day");
    }
  }

  return {
    dateLabel,
    endDate: new Date(to.toISOString()),
    startDate: new Date(from.toISOString()),
    label: labelByDateLabel.get(dateLabel)?.long ?? dateLabel,
    shortLabel: labelByDateLabel.get(dateLabel)?.short ?? dateLabel,
  };
};

export const getPreDefinedRanges = (
  startFromMonth?: number,
): Record<
  "daily" | "monthly" | "quarterly" | "yearly",
  Partial<Record<DateLabel, PreDefinedRange>>
> => {
  return {
    daily: {
      past_fourteen_days: getRange(14, "day", "past_fourteen_days"),
      past_seven_days: getRange(7, "day", "past_seven_days"),
      past_thirty_days: getRange(30, "day", "past_thirty_days"),
      past_forty_five_days: getRange(45, "day", "past_forty_five_days"),
      past_ninety_days: getRange(90, "day", "past_ninety_days"),
      past_one_hundred_eighty_days: getRange(
        180,
        "day",
        "past_one_hundred_eighty_days",
      ),
      month_to_date: preDefinedThisMonth,
    },
    yearly: {
      year_to_date: preDefinedThisYear,
    },
    monthly: {
      last_one_month: getRange(1, "month", "last_one_month", true),
      last_three_months: getRange(3, "month", "last_three_months", true),
      last_six_months: getRange(6, "month", "last_six_months", true),
      last_twelve_months: getRange(12, "month", "last_twelve_months", true),
    },
    quarterly: {
      last_quarter: getQuarterRange(startFromMonth ?? 1, "last_quarter"),
      quarter_to_date: getQuarterRange(startFromMonth ?? 1, "quarter_to_date"),
    },
  };
};
