import { RootState } from "../../../../../store";

import { costBreakdownV2WidgetSetupSelector } from "../../../setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";
import { costBreakdownV2WidgetByIdSelector } from "../widget-view/costBreakdownV2WidgetByIdSelector";
import { arrayIsEqual } from "../../../../../../utils/arrayIsEqual";

export const costBreakdownV2WidgetEditChangesExistSelectorV2 = (
  state: RootState,
) => {
  const setup = costBreakdownV2WidgetSetupSelector(state);

  if (!setup?.id) {
    return false;
  }

  const widget = costBreakdownV2WidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  return (
    setup.name !== widget.name ||
    setup.grouping !== widget.grouping ||
    !arrayIsEqual(setup.group_values ?? [], widget.group_values ?? []) ||
    setup.viewId !== widget.view_id ||
    setup?.dates.label !== widget?.dates?.label ||
    setup?.dates.to !== widget?.dates?.to ||
    setup?.dates.from !== widget?.dates?.from ||
    (setup?.dates.unit ?? null) !== (widget?.dates?.unit ?? null) ||
    (setup?.dates.type ?? null) !== (widget?.dates?.type ?? null) ||
    (setup?.dates.value ?? null) !== (widget?.dates?.value ?? null) ||
    setup.forecastOption !== widget.forecast_option ||
    setup.frequency !== widget.date_granularity ||
    setup.viewType !== widget.chart_type
  );
};
