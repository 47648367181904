import { ChangeEvent, FC, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ResourceExplorerV2ViewCreateFolderOrViewDialogContent } from "./components/ResourceExplorerV2ViewCreateFolderOrViewDialogContent";
import { ResourceExplorerV2ViewCreateFolderOrViewDialogActions } from "./components/ResourceExplorerV2ViewCreateFolderOrViewDialogActions";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { NavigationHierarchyItemType } from "../../../../../../store/navigation/utils/types";
import { createResourceExplorerHierarchyFolderOrViewThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/createResourceExplorerHierarchyFolderOrViewThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { createResourceExplorerLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerLoadingSelector";
import { createResourceExplorerFolderLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerFolderLoadingSelector";
import { getPressEnterHandler } from "../../../../../utils/helpers/getPressEnterHandler";

interface ResourceExplorerV2ViewCreateFolderOrViewDialogProps {
  onClose(): void;
  type?: NavigationHierarchyItemType;
  folderId?: string;
}
export const ResourceExplorerV2ViewCreateFolderOrViewDialog: FC<
  ResourceExplorerV2ViewCreateFolderOrViewDialogProps
> = ({ onClose, type, folderId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const createViewLoading = useAppSelector(
    createResourceExplorerLoadingSelector,
  );

  const createFolderLoading = useAppSelector(
    createResourceExplorerFolderLoadingSelector,
  );

  const closeDialogHandler = useCallback(() => {
    onClose();
    setName("");
  }, [onClose]);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [],
  );

  const createViewCallbackHandler = useCallback(
    (id: string) => {
      navigate({ pathname: `/resource-explorer/${id}` });
    },
    [navigate],
  );

  const submitFormHandler = useCallback(async () => {
    if (!type) {
      return;
    }

    const response = await dispatch(
      createResourceExplorerHierarchyFolderOrViewThunk({
        type,
        folderId,
        name,
      }),
    ).unwrap();

    if (response) {
      createViewCallbackHandler(response?.id);
    }
    closeDialogHandler();
  }, [
    dispatch,
    name,
    type,
    closeDialogHandler,
    createViewCallbackHandler,
    folderId,
  ]);

  const handlePressEnter = getPressEnterHandler(
    submitFormHandler,
    createViewLoading || createFolderLoading || !name.length,
  );

  if (!type) {
    return null;
  }

  return (
    <Dialog
      open={!!type}
      onClose={closeDialogHandler}
      maxWidth="sm"
      fullWidth
      onKeyDown={handlePressEnter}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable
        title={type === "item" ? "Add View" : "Add Folder"}
        onClose={closeDialogHandler}
      />
      <DialogContent
        sx={{
          py: 3,
        }}
        dividers
      >
        <ResourceExplorerV2ViewCreateFolderOrViewDialogContent
          value={name}
          onChange={nameChangeHandler}
          label={type === "item" ? "View Name" : "Folder Name"}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <ResourceExplorerV2ViewCreateFolderOrViewDialogActions
          onCloseDialog={closeDialogHandler}
          onSubmit={submitFormHandler}
          name={name}
        />
      </DialogActions>
    </Dialog>
  );
};
