import { resourceExplorerViewsHierarchyDataSelector } from "./resourceExplorerViewsHierarchyDataSelector";
import { RootState } from "../../../store";
import { isResourceExplorerFolder } from "../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";
import { resourceExplorerHierarchyVisibilitiesDataSelector } from "../views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataSelector";
import UnleashClient from "../../../../services/UnleashClient";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";

export const resourceExplorerViewsHierarchyDefaultViewSelector = (
  state: RootState,
): string | undefined => {
  const hierarchy = resourceExplorerViewsHierarchyDataSelector(state);
  const publicVisibilities =
    resourceExplorerHierarchyVisibilitiesDataSelector(
      state,
    )?.visible_to_everyone;

  const enableNavigationHierarchyVisibility = UnleashClient.isEnabled(
    "EnableNavigationHierarchyVisibility",
  );

  if (enableNavigationHierarchyVisibility) {
    return publicVisibilities?.find(
      (item) => !isHierarchyFolder(item) && item.is_default,
    )?.id;
  }
  return hierarchy?.find(
    (item) => !isResourceExplorerFolder(item) && item.is_default,
  )?.id;
};
