import { FC, Fragment } from "react";
import { Divider, Paper } from "@mui/material";
import { useDidMount } from "rooks";
import { ScheduledJobsActionsPreview } from "./ScheduledJobsActionsPreview";
import { ScheduledJobsDetailsPreview } from "./ScheduledJobsDetailsPreview";
import { SchedulePreviewEnvironment } from "../../../../common/create/preview/SchedulePreviewEnvironment";
import { SchedulePreviewResources } from "../../../../common/create/preview/SchedulePreviewResources";
import { SchedulePreviewNotifications } from "../../../../common/create/preview/notifications/SchedulePreviewNotifications";
import { NextPrevious } from "../../../../common/create/NextPrevious";
import { SchedulePreviewSectionTitle } from "../../../../common/create/preview/SchedulePreviewSectionTitle";
import { WorkflowFormData } from "../../../utils/types/types";
import { SchedulePreviewGracePeriod } from "../../../../common/create/preview/notifications/SchedulePreviewGracePeriod";
import { useSchedulePreviewDatesInfoHook } from "../../../utils/hooks/useSchedulePreviewDatesInfo.hook";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { getScheduleNextRunDatesThunk } from "../../../../../../../store/schedules/thunks/getScheduleNextRunDatesThunk";
import { scheduleNextRunDateTimeSelector } from "../../../../../../../store/schedules/selectors/schedule/next-run/scheduleNextRunDateTimeSelector";
import { getCronFrequency } from "../../../../../../../store/automations/utils/helpers/cron/getCronFrequency";
import { useWorkflowsNextRunDates } from "../../../utils/hooks/useWorkflowsNextRunDates";

interface ScheduledJobsPreviewProps {
  isLoading: boolean;
  hideCancelButton?: boolean;
  onSubmit(): void;
  onPrevious(): void;
  onFieldChange(key: string, value: any): void;
}

export const ScheduledJobsPreview: FC<
  ScheduledJobsPreviewProps & WorkflowFormData
> = ({
  id,
  name,
  provider,
  regions,
  filter,
  end_date_time,
  start_date_time,
  time_zone,
  cron,
  notifications,
  onSubmit,
  onPrevious,
  isLoading,
  action,
  account_ids,
  min_threshold,
  onFieldChange,
  hideCancelButton,
  is_snoozed,
  emails,
  grace_period,
  status,
  weekly_schedule_interval,
}) => {
  const dispatch = useAppDispatch();

  const requestedNextRunDate = useWorkflowsNextRunDates({
    cron,
    timeZone: time_zone,
    startDate: start_date_time,
  });
  const nextRunDateTime = useAppSelector((state) =>
    scheduleNextRunDateTimeSelector(state, getCronFrequency(cron)),
  );

  const { nextRunDate, nextRuns, frequency } = useSchedulePreviewDatesInfoHook({
    cron,
    timeZone: time_zone,
    weeklyInterval: weekly_schedule_interval,
    nextRun: nextRunDateTime ?? requestedNextRunDate,
  });

  useDidMount(() => {
    if (!cron || nextRunDateTime) {
      return;
    }
    dispatch(
      getScheduleNextRunDatesThunk({
        cron,
        timeZone: time_zone,
        startDate: start_date_time,
      }),
    );
  });

  return (
    <Fragment>
      <Paper>
        <SchedulePreviewEnvironment
          name={name}
          provider={provider}
          accountIds={account_ids}
          regions={regions}
          onFieldChange={onFieldChange}
        />
        <SchedulePreviewResources
          scheduleId={id}
          scheduleAction={action}
          frequency={frequency}
          nextRuns={nextRuns}
          gracePeriodValue={grace_period?.period}
          status={status}
          regions={regions}
          name={name}
          filters={filter}
          accountIds={account_ids}
        />

        <SchedulePreviewSectionTitle title="Selected Actions (Resource Actions and Notify)" />

        <ScheduledJobsActionsPreview filter={filter} action={action} />

        {grace_period && (
          <SchedulePreviewGracePeriod gracePeriod={grace_period} />
        )}
        {(!!emails?.to?.length || !!notifications?.length) && (
          <Fragment>
            <Divider sx={{ mb: 2 }} />
            <SchedulePreviewNotifications
              emails={emails}
              notifications={notifications}
              minThreshold={min_threshold}
              snoozed={is_snoozed}
            />
          </Fragment>
        )}

        <ScheduledJobsDetailsPreview
          scheduleText={frequency?.at(0)?.tooltip}
          hideEnds={frequency?.at(0)?.hideEnds}
          nextRunDate={nextRunDate}
          timeZone={time_zone}
          startDate={start_date_time}
          endDate={end_date_time}
          tabType={getCronFrequency(cron)}
        />
      </Paper>
      <NextPrevious
        isLoading={isLoading}
        submitText={id ? "Save Changes" : "Confirm"}
        hideCancelButton={hideCancelButton}
        onNext={onSubmit}
        onPrevious={onPrevious}
        isNextDisabled={!name}
      />
    </Fragment>
  );
};
