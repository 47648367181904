import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentsPageHeaderMetadataCard } from "../../../common/components/CommitmentsPageHeaderMetadataCard";
import { useAppSelector } from "../../../../../../store/hooks";
import { money } from "../../../../../../utils/numeral/money";
import { reservationsNetSavingsSelector } from "../../../../../../store/commitments/selectors/reservations/data/totals/reservationsNetSavingsSelector";
import { reservationsCoverageAverageSelector } from "../../../../../../store/commitments/selectors/reservations/data/totals/reservationsCoverageAverageSelector";
import { reservationsForGranularLoadingSelector } from "../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForGranularLoadingSelector";
import { reservationsGranularDataUncoveredUsageSelector } from "../../../../../../store/commitments/selectors/reservations/data/granular/totals/reservationsGranularDataUncoveredUsageSelector";

export const ReservationsMetadata: FC = () => {
  const netSavings = useAppSelector(reservationsNetSavingsSelector);
  const coverage = useAppSelector(reservationsCoverageAverageSelector);
  const loading = useAppSelector(reservationsForGranularLoadingSelector);
  const uncoveredUsage = useAppSelector(
    reservationsGranularDataUncoveredUsageSelector,
  );

  return (
    <Stack
      gap={2}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <CommitmentsPageHeaderMetadataCard
        primary="Not Covered(On-Demand)"
        loading={loading}
        value={money(uncoveredUsage)}
        tooltip="Eligible On-Demand spend over the selected time period that was not covered by a Reserved Instance."
      />

      <CommitmentsPageHeaderMetadataCard
        primary="Coverage AVG"
        loading={loading}
        value={coverage !== null ? `${coverage}%` : "N/A"}
        tooltip="Average percentage of AWS usage covered by Reserved Instances during the selected date range."
      />

      <CommitmentsPageHeaderMetadataCard
        primary="Net Savings"
        loading={loading}
        value={money(netSavings)}
        tooltip="Total Net Savings from commitments for the selected date range. Calculated by comparing the usage cost covered by Reserved Instances to the public On-Demand cost."
      />
    </Stack>
  );
};
