import { FC, useCallback, useMemo } from "react";
import { Button, Tooltip } from "@mui/material";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { useFlag } from "@unleash/proxy-client-react";
import {
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../../../../services/permissions";
import { currentAccountWorkflowDataSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentAccountWorkflowDataSelector";
import { InitiateWorkflowThunkArgs } from "../../../../../../../../../../../store/schedules/thunks/initiateWorkflowThunk";
import { useCreateWorkflow } from "../../../../../../../../utils/hooks/useCreateWorkflow.hook";
import { setWorkflowCreationDrawerOpen } from "../../../../../../../../../../../store/schedules/schedulesSlice";
import { currentAccountSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/account/selectors/current-account/resources/selection/currentAccountSelectedResourcesByResourceTypeSelector";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { useCreateWorkflowV2 } from "../../../../../../../../utils/hooks/useCreateWorkflowV2.hook";

interface CreateWorkflowToolbarActionProps {
  resourceType: ResourceType;
}

export const CreateWorkflowToolbarAction: FC<
  CreateWorkflowToolbarActionProps
> = ({ resourceType }) => {
  const dispatch = useAppDispatch();

  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");
  const enableNewAutomations = useFlag("EnableWorkflowAutomationSinglePage");

  const currentAccountWorkflowData = useAppSelector((state) =>
    currentAccountWorkflowDataSelector(state, resourceType),
  );

  const selectedResources = useAppSelector((state) =>
    currentAccountSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const data = useMemo(() => {
    if (!currentAccountWorkflowData || !selectedResources) {
      return null;
    }

    const parentResourceType =
      getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

    const value = selectedResources.map(
      (resource) => resource.resource.provider_unique_identifier,
    );

    let filters: ResourceFiltersWithAction[] = [
      {
        filter: {
          type: parentResourceType,
          filter_groups: [0],
          operators: ["AND"],
          filter_items: [
            {
              type: resourceType,
              filter: {
                key: "provider_unique_identifier",
                operator: "in",
                value: value,
              },
            },
          ],
        },
      },
    ];

    if (!value.length) {
      filters = currentAccountWorkflowData.filters.filter(
        (filter) => filter.filter.type === parentResourceType,
      );
    }

    return {
      ...currentAccountWorkflowData,
      filters,
      resourceType,
    } as InitiateWorkflowThunkArgs;
  }, [resourceType, selectedResources, currentAccountWorkflowData]);

  const createWorkflowV1 = useCreateWorkflow(data);
  const createWorkflowV2 = useCreateWorkflowV2(data);
  const createWorkflow = enableNewAutomations
    ? createWorkflowV2
    : createWorkflowV1;

  const createHandler = useCallback(() => {
    createWorkflow();
    dispatch(setWorkflowCreationDrawerOpen(true));
  }, [dispatch, createWorkflow]);

  if (!data || canNotCreateSchedule) {
    return null;
  }

  return (
    <Tooltip title="Create Workflow." arrow placement="top">
      <Button
        onClick={createHandler}
        sx={buttonSx}
        variant="outlined"
        color="primary"
      >
        <AvTimerIcon fontSize="small" />
      </Button>
    </Tooltip>
  );
};

const buttonSx = {
  minWidth: 36,
  px: 0.5,
};
