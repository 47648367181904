import { FC } from "react";
import { Box } from "@mui/material";
import { ReservedInstancesDataGrid } from "./ReservedInstancesDataGrid";
import { ReservationsCoverageDataGrid } from "./ReservationsCoverageDataGrid";
import { ReservationsDataGridToolbar } from "./components/ReservationsDataGridToolbar";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { CommitmentsGridLoadingLayer } from "../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { reservationsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForTableLoadingSelector";

export const ReservationsDataGrid: FC = () => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const loading = useAppSelector(reservationsForTableLoadingSelector);

  if (tab === "recommendations") {
    return null;
  }

  return (
    <Box position="relative" zIndex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      {tab === "commitment" && <ReservedInstancesDataGrid toolbar={toolbar} />}

      {tab === "coverage" && <ReservationsCoverageDataGrid toolbar={toolbar} />}
    </Box>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => {
    return <ReservationsDataGridToolbar {...props} />;
  },
};
