import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ChangeEvent, FC, useCallback } from "react";
import { useDebounce } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { alertsCostAnomalyDataTimeIntervalSelector } from "../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataTimeIntervalSelector";
import { alertsCostAnomalyDataTimeIntervalUnitSelector } from "../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataTimeIntervalUnitSelector";
import { setAlertsData } from "../../../../../../../../store/alerts/alertsSlice";
import { CostAnomalyAlertTimeIntervalUnit } from "../../../../../../../../store/alerts/utils/types/types";
import { intervalLimitByIntervalUnit } from "../utils/constants/constants";
import { getCostAnomalyPreviewDataGridDataThunk } from "../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/getCostAnomalyPreviewDataGridDataThunk";
import { NumberField } from "../../../../../../../common/NumberField";

interface AlertCostAnomalyTimeIntervalSelectProps {
  averageCostCollapsed: boolean;
}

export const AlertCostAnomalyTimeIntervalSelect: FC<
  AlertCostAnomalyTimeIntervalSelectProps
> = ({ averageCostCollapsed }) => {
  const dispatch = useAppDispatch();
  const timeInterval = useAppSelector(
    alertsCostAnomalyDataTimeIntervalSelector,
  );
  const timeIntervalUnit = useAppSelector(
    alertsCostAnomalyDataTimeIntervalUnitSelector,
  );

  const intervalLimit = intervalLimitByIntervalUnit.get(
    timeIntervalUnit ?? "days",
  );

  const getCostAnomalyPreviewDataGridData = useCallback(() => {
    dispatch(getCostAnomalyPreviewDataGridDataThunk());
  }, [dispatch]);
  const debouncedRequest = useDebounce(getCostAnomalyPreviewDataGridData, 300);

  const handleTimeIntervalChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setAlertsData({ timeInterval: event.target.value }));

      if (averageCostCollapsed) {
        debouncedRequest();
      }
    },
    [dispatch, debouncedRequest, averageCostCollapsed],
  );

  const handleTimeIntervalUnitChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value as CostAnomalyAlertTimeIntervalUnit;
      const limit = intervalLimitByIntervalUnit.get(value ?? "days");

      dispatch(
        setAlertsData({
          timeIntervalUnit: value,
        }),
      );

      if (limit && Number(timeInterval) > limit) {
        dispatch(
          setAlertsData({
            timeInterval: "1",
          }),
        );
      }

      if (averageCostCollapsed) {
        dispatch(getCostAnomalyPreviewDataGridDataThunk());
      }
    },
    [dispatch, averageCostCollapsed, timeInterval],
  );

  return (
    <Stack gap={1}>
      <Typography variant="body2" fontWeight="medium">
        Time Interval
      </Typography>
      <Stack spacing={1} direction="row" alignItems="center">
        <NumberField
          size="small"
          sx={{ width: 90 }}
          minValue={1}
          maxValue={intervalLimit}
          value={timeInterval}
          onChange={handleTimeIntervalChange}
        />

        <Select
          size="small"
          value={timeIntervalUnit}
          sx={{ width: 145 }}
          onChange={handleTimeIntervalUnitChange}
        >
          <MenuItem value="days">Days</MenuItem>
          <MenuItem value="weeks">Weeks</MenuItem>
          <MenuItem value="months">Months</MenuItem>
        </Select>

        <Tooltip arrow placement="top" title={tooltipTitle}>
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </Stack>
    </Stack>
  );
};

const tooltipTitle = (
  <Typography variant="inherit">
    Select time interval to compare costs with the same preceding period.
    <br />
    <Typography variant="inherit" component="span" fontWeight="regular">
      e.g if 2 days is selected, Cloudchipr will compare the costs for the last
      2 days with the preceding 2 days and alert in case the change exceeds the
      configured value.
    </Typography>
  </Typography>
);
