import { CostAnomalyPreviewFieldHeaderCell } from "./header-cells/CostAnomalyPreviewFieldHeaderCell";
import { CostAnomalyPreviewAverageCostHeaderCell } from "./header-cells/CostAnomalyPreviewAverageCostHeaderCell";
import { CostAnomalyPreviewTotalCostHeaderCell } from "./header-cells/CostAnomalyPreviewTotalCostHeaderCell";
import { CostAnomalyPreviewPreviousPeriodCostHeaderCell } from "./header-cells/CostAnomalyPreviewPreviousPeriodCostHeaderCell";
import { CostAnomalyPreviewTrendHeaderCell } from "./header-cells/CostAnomalyPreviewTrendHeaderCell";
import { CostAnomalyPreviewTrendCell } from "./cells/CostAnomalyPreviewTrendCell";
import { CostAnomalyPreviewFieldCell } from "./cells/CostAnomalyPreviewFieldCell";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ResourceExplorerDataAverage } from "../../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";

export const alertsCostsAnomalyPreviewTableColumns: ColumnSetupType<ResourceExplorerDataAverage>[] =
  [
    {
      accessorKey: "field",
      header: () => <CostAnomalyPreviewFieldHeaderCell />,
      cell: (cell) => {
        const field = cell.row.original.field;
        const cloudProvider = cell.row.original.cloud_provider;

        return (
          <CostAnomalyPreviewFieldCell
            field={field}
            cloudProvider={cloudProvider}
          />
        );
      },
    },
    {
      accessorKey: "average_cost",
      size: 135,
      header: () => <CostAnomalyPreviewAverageCostHeaderCell />,
      cell: (cell) => {
        const cost = money(cell.row.original.average_cost);
        return <TypographyWithTooltip title={cost} variant="inherit" />;
      },
    },
    {
      accessorKey: "total",
      header: () => <CostAnomalyPreviewTotalCostHeaderCell />,
      size: 105,
      cell: (cell) => {
        const cost = money(cell.row.original.total);
        return <TypographyWithTooltip title={cost} variant="inherit" />;
      },
    },
    {
      accessorKey: "previous_period_cost",
      size: 155,
      header: () => <CostAnomalyPreviewPreviousPeriodCostHeaderCell />,
      cell: (cell) => {
        const cost = money(cell.row.original.previous_period_cost);
        return <TypographyWithTooltip title={cost} variant="inherit" />;
      },
    },
    {
      accessorKey: "trend",
      header: () => <CostAnomalyPreviewTrendHeaderCell />,
      size: 110,
      cell: (cell) => {
        const trend = cell.row.original.trend;
        return <CostAnomalyPreviewTrendCell trend={trend} />;
      },
    },
  ];
