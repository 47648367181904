import { FC } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import Stack from "@mui/material/Stack";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { SchedulesDataGridActivationSwitchCell } from "../../../../../schedule/common/list/SchedulesDataGridActivationSwitchCell";
import { ScheduledJobsDataGridActionCell } from "../../../../../schedule/classic/components/scheduled-jobs-list-grid/ScheduledJobsDataGridActionCell";

export const WorkflowsActionsCell: FC<CustomCellRendererProps<Schedule>> = ({
  data: schedule,
}) => {
  if (!schedule) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      height="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <SchedulesDataGridActivationSwitchCell
        schedule={schedule}
        type="schedule"
      />

      <ScheduledJobsDataGridActionCell schedule={schedule} />
    </Stack>
  );
};
