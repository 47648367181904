import { Stack } from "@mui/material";
import { ScheduledJobsDataGridNameCell } from "./ScheduledJobsDataGridNameCell";
import { ScheduledJobsDataGridNextRunCell } from "./ScheduledJobsDataGridNextRunCell";
import { ScheduledJobsDataGridActionCell } from "./ScheduledJobsDataGridActionCell";
import { ScheduledJobsDataGridActionsCell } from "./ScheduledJobsDataGridActionsCell";
import {
  Schedule,
  ScheduleStatus,
} from "../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { SchedulesDataGridFrequencyCell } from "../../../common/list/SchedulesDataGridFrequencyCell";
import { AccountsWithPopover } from "../../../../../common/accounts-with-popover/AccountsWithPopover";
import { SchedulesDataGridStatusChip } from "../../../common/list/SchedulesDataGridStatusChip";
import { SchedulesDataGridActivationSwitchCell } from "../../../common/list/SchedulesDataGridActivationSwitchCell";
import { scheduledJobsNextRunFilter } from "../utils/filterFunctions/scheduledJobsNextRunFilter";
import { scheduledJobsFrequencyFilter } from "../utils/filterFunctions/scheduledJobsFrequencyFilter";
import { scheduledJobsActionsFilter } from "../utils/filterFunctions/scheduledJobsActionsFilter";
import { scheduledJobsFrequencySortingFn } from "../utils/sortingFunctions/scheduledJobsFrequencySortingFn";
import { schedulesAccountFilter } from "../../../common/utils/filterFunctions/schedulesAccountFilter";

export const scheduledJobsTableColumns: ColumnSetupType<Schedule>[] = [
  {
    accessorKey: "name",
    header: "Name",
    size: 200,
    minSize: 100,
    cell: (cell) => {
      const schedule = cell.row.original;
      return <ScheduledJobsDataGridNameCell schedule={schedule} />;
    },
    meta: {
      sticky: "left",
      hideFromSettings: true,
      cellStyles: { pl: 3, bgcolor: "white" },
    },
  },
  {
    accessorKey: "time_zone",
    header: "Next Run",
    size: 190,
    minSize: 190,
    enableSorting: false,
    filterFn: scheduledJobsNextRunFilter,
    cell: (cell) => {
      const schedule = cell.row.original;

      return (
        <ScheduledJobsDataGridNextRunCell
          status={schedule.status}
          timeZone={schedule.time_zone}
          nextRun={schedule?.metadata.next_run_at}
          id={schedule.id}
        />
      );
    },
  },
  {
    id: "frequency",
    accessorKey: "id",
    header: "Frequency",
    size: 90,
    minSize: 90,
    sortingFn: scheduledJobsFrequencySortingFn,
    filterFn: scheduledJobsFrequencyFilter,
    cell: (cell) => {
      const schedule = cell.row.original;

      return (
        <SchedulesDataGridFrequencyCell
          nextRun={schedule?.metadata?.next_run_at ?? ""}
          timeZone={schedule.time_zone}
          cron={schedule.cron}
          weeklyInterval={schedule.weekly_schedule_interval}
        />
      );
    },
  },
  {
    accessorKey: "id",
    header: "Account",
    enableSorting: false,
    size: 240,
    minSize: 220,
    filterFn: schedulesAccountFilter,
    cell: (cell) => {
      const accounts = cell.row.original.accounts;

      return <AccountsWithPopover accounts={accounts} />;
    },
  },
  {
    accessorKey: "action",
    header: "Actions",
    size: 130,
    minSize: 100,
    filterFn: scheduledJobsActionsFilter,
    cell: (cell) => {
      const schedule = cell.row.original;

      return <ScheduledJobsDataGridActionsCell schedule={schedule} />;
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 85,
    minSize: 85,
    cell: (cell) => {
      const status = cell.getValue() as ScheduleStatus;

      return <SchedulesDataGridStatusChip status={status} />;
    },
  },
  {
    id: "enable",
    header: "Enable",
    size: 65,
    enableSorting: false,
    enableResizing: false,
    cell: (cell) => {
      const schedule = cell.row.original;

      return (
        <SchedulesDataGridActivationSwitchCell
          schedule={schedule}
          type="schedule"
        />
      );
    },
  },
  {
    id: "actions",
    size: 40,
    header: "",
    enableResizing: false,
    enableGlobalFilter: false,
    cell: (cell) => {
      const schedule = cell.row.original;

      return (
        <Stack alignItems="center" justifyContent="center">
          <ScheduledJobsDataGridActionCell schedule={schedule} />
        </Stack>
      );
    },
  },
];
