import { FC, Fragment } from "react";
import { Card, Stack } from "@mui/material";
import { FiltersDisabledViewListItem } from "./FiltersDisabledViewListItem";
import { FiltersDisabledViewFilterOperator } from "./FiltersDisabledViewFilterOperator";
import {
  FilterGroups,
  isFilterGroup,
} from "../filters-selection/utils/types/types";
import { FilterItemWithType } from "../../../../../services/cloudchipr.api";
import { FiltersCombinationOperatorType } from "../../dynamic-filters/utils/types/common";

interface FiltersDisabledViewGroupListProps {
  operator: FiltersCombinationOperatorType;
  filterGroup: FilterGroups;
  filterItems: FilterItemWithType[];
}

export const FiltersDisabledViewGroupList: FC<
  FiltersDisabledViewGroupListProps
> = ({ filterItems, filterGroup, operator }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        flexGrow: 1,
        bgcolor: "grey.50",
      }}
    >
      <Stack gap={1} direction="row" flexWrap="wrap" alignItems="center">
        {filterGroup.map((filterIndex, index) => {
          if (isFilterGroup(filterIndex)) {
            return null;
          }

          return (
            <Fragment key={filterIndex}>
              {index === 0 ? (
                <FiltersDisabledViewFilterOperator type="text" />
              ) : (
                <FiltersDisabledViewFilterOperator
                  operator={operator}
                  type="button"
                />
              )}
              <FiltersDisabledViewListItem filter={filterItems[filterIndex]} />
            </Fragment>
          );
        })}
      </Stack>
    </Card>
  );
};
